import { actionCreatorFactory } from 'typescript-fsa';
import * as moment from 'moment';

import type { BriefDraft } from 'sber-marketing-types/backend';
import type { Brief, BriefScheme, BriefHistory } from 'sber-marketing-types/frontend';
import type { BudgetItem } from '@mrm/budget';
import type {
    PageState,
    FieldChangeParams,
    BlockDoubleParams,
    FileToRemove,
    TaskDeadlineChangesParams,
    BriefLoading,
    ActivityReferenceData,
} from './types';

const actionCreator = actionCreatorFactory('BRIEF');

const loadBriefPage = actionCreator<PageState>('LOAD_BRIEF_PAGE');
const resetBriefPage = actionCreator('RESET_BRIEF_PAGE');
const replaceBrief = actionCreator<Brief>('REPLACE_BRIEF');
const applyBriefDraft = actionCreator<BriefDraft>('APPLY_BRIEF_DRAFT');
const setActivityPreparationDate = actionCreator<moment.Moment>('SET_ACTIVITY_PREPARATION_DATE');
const setActivityRealizationStart = actionCreator<moment.Moment>('SET_ACTIVITY_REALIZATION_START');
const setActivityRealizationEnd = actionCreator<moment.Moment>('SET_ACTIVITY_REALIZATION_END');
const setActivityDebriefingDate = actionCreator<moment.Moment>('SET_ACTIVITY_DEBRIEFING_DATE');
const setTaskDeadlineDate = actionCreator<TaskDeadlineChangesParams>('SET_TASK_DEADLINE_DATE');
const setBudgetItem = actionCreator<BudgetItem>('SET_BUDGET_ITEM');
const setFieldValue = actionCreator<FieldChangeParams>('SET_FIELD_VALUE');
const setBlockDouble = actionCreator<BlockDoubleParams>('SET_BLOCK_DOUBLE');
const removeBlockDouble = actionCreator<BlockDoubleParams>('REMOVE_BLOCK_DOUBLE');
const setFileListToRemove = actionCreator<FileToRemove[]>('SET_FILE_LIST_TO_REMOVE');
const setCurrentBrief = actionCreator<Brief>('SET_CURRENT_BRIEF');
const setCurrentBriefWasCopied = actionCreator<boolean>('SET_CURRENT_BRIEF_WAS_COPIED');
const setValidationDisplayStatus = actionCreator<boolean>('SET_VALIDATION_DISPLAY_STATUS');
const setBrief = actionCreator<Brief>('SET_BRIEF');
const setBriefLoading = actionCreator<BriefLoading>('SET_BRIEF_LOADING');
const setBriefSchemes = actionCreator<BriefScheme[]>('SET_BRIEFS_SCHEMES');
const removeMarkedFilesFromBrief = actionCreator('REMOVE_MARKED_FILES_FROM_BRIEF');
const setActivityReferenceData = actionCreator<ActivityReferenceData>('SET_ACTIVITY_REFERENCE_DATA');
const setActivityReferenceMenuSelectedBudgetItemIds = actionCreator<string[]>(
    'SET_ACTIVITY_REFERENCE_MENU_SELECTED_BUDGET_ITEM_IDS',
);
const setBriefHistory = actionCreator<BriefHistory>('SET_BRIEF_HISTORY');

export {
    loadBriefPage,
    resetBriefPage,
    replaceBrief,
    applyBriefDraft,
    setActivityPreparationDate,
    setActivityRealizationStart,
    setActivityRealizationEnd,
    setActivityDebriefingDate,
    setTaskDeadlineDate,
    setBudgetItem,
    setFieldValue,
    setBlockDouble,
    removeBlockDouble,
    setFileListToRemove,
    setCurrentBrief,
    setCurrentBriefWasCopied,
    setValidationDisplayStatus,
    setBrief,
    setBriefLoading,
    setBriefSchemes,
    removeMarkedFilesFromBrief,
    setActivityReferenceData,
    setActivityReferenceMenuSelectedBudgetItemIds,
    setBriefHistory,
};
