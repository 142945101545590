import * as React from 'react';
import * as qs from 'qs';

import { Chip, ChipProps, IconChip, Space, Text } from '@sbermarketing/mrm-ui';

import { OnboardingItem } from '@modules/onboarding/constants';

import { useSetOnboarding } from '@modules/onboarding/hooks';

export interface OnboardingItemChipProps extends ChipProps {
    item: OnboardingItem;
}

export function OnboardingItemChip({
    item: { url, icon, button, useFrom, id, title, qaId },
    ...props
}: OnboardingItemChipProps) {
    const setOnboarding = useSetOnboarding();

    const handleOnboardingStart = () => {
        setOnboarding(id);
    };

    return (
        <Chip gap={12} padding={[10, 12]} {...props}>
            <IconChip color="var(--color-green-50)" even icon={icon} iconSize={18} />
            <Text size={14}>{title}</Text>
            <Space />
            <Chip
                element="a"
                to={`${url}${qs.stringify(
                    {
                        from: useFrom ? '/onboarding' : undefined,
                    },
                    { addQueryPrefix: true },
                )}`}
                clickable
                color="var(--color-green-50)"
                padding={[10, 20]}
                round
                onClick={handleOnboardingStart}
                data-qa-id={qaId}
            >
                {button}
            </Chip>
        </Chip>
    );
}
