import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { withRouter } from 'react-router-dom';
import autobind from 'autobind-decorator';
import { parse as parseUrl } from 'url';
import { parse } from 'query-string';

import { PageOptions } from '@store/common';
import { StoreState } from '@store';
import { User } from '@store/user';

import { SberHeader } from 'sber-marketing-ui';
import { resetErrorState, setHeaderHeight } from '@store/common/actions';
import { getPageOptions } from '@store/common';
import { getLoginUser } from '@store/user';

interface Props extends MapProps, DispatchProps {
    firstLine: JSX.Element;
    additionalContent: JSX.Element;
}

interface MapProps {
    user?: User;
    pageOptions?: PageOptions;
    currentPath?: string;
}

interface DispatchProps {
    disableErrorState?: () => void;
    setHeaderHeight?: (height: number) => void;
}

const HEADER_LINE_HEIGHT = 49;

@(withRouter as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class HeaderContainer extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { pageOptions, firstLine, additionalContent } = this.props;

        return React.createElement(SberHeader, {
            shouldRender: pageOptions && !pageOptions.withoutHeader,
            pageLabel: pageOptions && pageOptions.currentLabel,
            previousLabel: pageOptions && pageOptions.previousLabel,
            previousUrl: this.getFrom() || (pageOptions ? pageOptions.previousUrl : ''),
            onBackButtonClick: this.onBackButtonClick,
            additionalContent,
            customFirstLine: firstLine,
        });
    }

    public componentDidUpdate(prevProps: Props) {
        const { additionalContent, firstLine } = this.props;

        if (prevProps.additionalContent != additionalContent || prevProps.firstLine != firstLine) {
            this.props.setHeaderHeight(
                additionalContent && (firstLine || firstLine === undefined)
                    ? HEADER_LINE_HEIGHT * 2
                    : HEADER_LINE_HEIGHT,
            );
        } else if (!prevProps.additionalContent && !prevProps.firstLine) {
            this.props.setHeaderHeight(0);
        }
    }

    @autobind
    private onBackButtonClick(): void {
        this.props.disableErrorState();
    }

    private getFrom(): string {
        const { search } = parseUrl(window.location.href);

        const res = parse(search).from as string;
        return res;
    }
}

function mapStateToProps(store: StoreState): MapProps {
    return {
        user: getLoginUser(store),
        pageOptions: getPageOptions(store),
        currentPath: store.common.currentPath,
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return {
        disableErrorState: () => dispatch(resetErrorState(null)),
        setHeaderHeight: (height: number) => dispatch(setHeaderHeight(height)),
    };
}
