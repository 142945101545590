import { Budget, BudgetListQueryParams } from '@mrm/budget';

import { rtkApi } from './rtkApi';

export const budgetApi = rtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getBudgets: builder.query<Budget[], BudgetListQueryParams>({
            query: (params) => ({
                url: '/budget',
                params,
            }),
        }),
        getBudget: builder.query<Budget, string>({
            query: (budgetId) => ({
                url: `/budget/${budgetId}`,
            }),
        }),
    }),
});

export const { useGetBudgetsQuery, useGetBudgetQuery } = budgetApi;
