import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { combineReducers } from 'redux';
import { Success } from 'typescript-fsa';
import * as lodash from 'lodash';

import { ActivityParams as Activity } from 'sber-marketing-types/frontend';
import {
    RestPageState as PageState,
    RequestStageForm,
    FieldName,
    InitCreativeRequestDomainParams,
    InitCreativeRequestDomainResult,
} from './types';
import { defineRequiredFieldByName } from './utils';

import { creativeSidebarReducer } from './sidebar';

import * as actions from './actions';
import { CreativeRequestStatus } from '@api';

export const initialState: PageState = {
    activity: null,
    requestStageForm: {
        // [FieldName.Lot]: {
        //     name: FieldName.Lot,
        //     required: defineRequiredFieldByName(FieldName.Lot)
        // },
        // [FieldName.Description]: {
        //     name: FieldName.Description,
        //     required: defineRequiredFieldByName(FieldName.Description)
        // },
        // [FieldName.Expert]: {
        //     name: FieldName.Expert,
        //     required: defineRequiredFieldByName(FieldName.Expert)
        // },
        // [FieldName.AgencyExecutor]: {
        //     name: FieldName.AgencyExecutor,
        //     required: defineRequiredFieldByName(FieldName.AgencyExecutor)
        // },
        // [FieldName.Block]: {
        //     name: FieldName.Block,
        //     required: defineRequiredFieldByName(FieldName.Block)
        // },
        // [FieldName.Division]: {
        //     name: FieldName.Division,
        //     required: defineRequiredFieldByName(FieldName.Division)
        // },
        // [FieldName.Segment]: {
        //     name: FieldName.Segment,
        //     required: defineRequiredFieldByName(FieldName.Segment)
        // },
        // [FieldName.Product]: {
        //     name: FieldName.Product,
        //     required: defineRequiredFieldByName(FieldName.Product)
        // },
        // [FieldName.Channel]: {
        //     name: FieldName.Channel,
        //     required: defineRequiredFieldByName(FieldName.Channel)
        // },
        // [FieldName.Initiator]: {
        //     name: FieldName.Initiator,
        //     required: defineRequiredFieldByName(FieldName.Initiator)
        // },
        // [FieldName.Customer]: {
        //     name: FieldName.Customer,
        //     required: defineRequiredFieldByName(FieldName.Customer)
        // },
        [FieldName.BudgetItemId]: {
            name: FieldName.BudgetItemId,
            required: defineRequiredFieldByName(FieldName.BudgetItemId),
        },
    },
    currentStage: null,
    selectedStage: 1,
    creativeRequestDomain: null,
    loading: true,
    underRevision: false,
};

export class Reducer {
    public static loadActivity(state: PageState, payload: Activity): PageState {
        return { ...state, activity: payload };
    }

    public static resetPageState(): PageState {
        return lodash.cloneDeep(initialState);
    }

    public static setRequestStageFormValues(state: PageState, payload: Partial<RequestStageForm>): PageState {
        return { ...state, requestStageForm: { ...state.requestStageForm, ...payload } };
    }

    public static setCurrentStage(state: PageState, payload: CreativeRequestStatus): PageState {
        return { ...state, currentStage: payload };
    }

    public static setUnderRevision(state: PageState, payload: boolean): PageState {
        return { ...state, underRevision: payload };
    }

    public static setSelectedStage(state: PageState, payload: number): PageState {
        return { ...state, selectedStage: payload };
    }

    public static initCreativeRequestDomainStarted(state: PageState): PageState {
        return {
            ...state,
            loading: true,
        };
    }

    public static updateCreativeRequestDomainStarted(state: PageState): PageState {
        return { ...state };
    }

    public static initCreativeRequestDomainDone(
        state: PageState,
        payload: Success<InitCreativeRequestDomainParams, InitCreativeRequestDomainResult>,
    ): PageState {
        return {
            ...state,
            loading: false,
            creativeRequestDomain: payload.result,
        };
    }

    public static initCreativeRequestDomainFailed(state: PageState): PageState {
        return {
            ...state,
            loading: false,
        };
    }
}

const restCreativeReducer = reducerWithInitialState(initialState)
    .case(actions.loadActivity, Reducer.loadActivity)
    .case(actions.setRequestStageFormValues, Reducer.setRequestStageFormValues)
    .case(actions.resetPageState, Reducer.resetPageState)
    .case(actions.setCurrentStage, Reducer.setCurrentStage)
    .case(actions.setUnderRevision, Reducer.setUnderRevision)
    .case(actions.setSelectedStage, Reducer.setSelectedStage)
    .case(actions.initCreativeRequestDomainAsync.started, Reducer.initCreativeRequestDomainStarted)
    .case(actions.initCreativeRequestDomainAsync.done, Reducer.initCreativeRequestDomainDone)
    .case(actions.initCreativeRequestDomainAsync.failed, Reducer.initCreativeRequestDomainFailed)
    .case(actions.updateCreativeRequestDomainAsync.started, Reducer.updateCreativeRequestDomainStarted)
    .case(actions.updateCreativeRequestDomainAsync.done, Reducer.initCreativeRequestDomainDone)
    .case(actions.updateCreativeRequestDomainAsync.failed, Reducer.initCreativeRequestDomainFailed);

export const creativeReducer = combineReducers({
    sidebar: creativeSidebarReducer,
    rest: restCreativeReducer,
});
