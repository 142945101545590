import { useCancelOnboarding, useOnboardingId } from '@modules/onboarding/hooks';
import { OnboardingId } from '@modules/onboarding/constants';

export interface UseOnboardingReturn {
    cancel: () => void;
    id?: OnboardingId;
}

export function useOnboarding(): UseOnboardingReturn {
    const cancel = useCancelOnboarding();
    const id = useOnboardingId();

    return {
        cancel,
        id,
    };
}
