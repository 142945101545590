import { create, Client, CookiesAuth } from '@sbermarketing/mrm-metacom-client';
import type {
    Action,
    BudgetAccessGroup,
    TagPicture,
    TagProject,
    TagTask,
    TagExecutionBudgetItem,
    TagPlanBudgetItem,
    Dictionary,
    Project,
    ProjectBudgetItem,
    CreativeRequest,
    CreativeRequestDto,
    CreativeRequestDonor,
    CreativeRequestItem,
    CreativeRequestItemFile,
    CreativeRequestParam,
    CreativeRequestItemEvents,
    CreativeRequestTableItem,
    CreativeRequestTableItemTask,
    CreativeRequestTableSettings,
    CreativeRequestTableItemEvents,
    CreativeRequestTableItemData,
    CreativeRequestTableItemTaskData,
    CreativeRequestTableSettingsData,
    CreativeRequestItemRightsDuration,
    CreativeRequestTableItemInterface,
    CreativeRequestTableItemTaskInterface,
    CreativeRequestBudgetItem,
    CreativeRequestBudgetItemFunds,
    CreativeRequestComment,
    CreativeRequestSubject,
    CreativeRequestTenderDocument,
    CreativeRequestTenderDocumentProtocolFile,
    CreativeRequestCommentFile,
    CreativeRequestAgency,
    CreativeRequestContract,
    CreativeRequestContractId,
    CreativeRequestContractDto,
    CreativeRequestItemComment,
    CreativeRequestCommentAuthor,
    CreativeRequestCorrectionEntry,
} from '@sbermarketing/mrm-metacom-client';
import type {
    ApproveTable,
    ApproveTableRow,
} from '@sbermarketing/mrm-metacom-client/types/creative-requests/approve-table';
import type {
    DivisionTable,
    DivisionTableRow,
    DivisionRecord,
} from '@sbermarketing/mrm-metacom-client/types/creative-requests/division-table';

export enum CreativeRequestItemFileKind {
    Estimate = 'estimate',
    Creative = 'creative',
}

export const enum CreativeRequestStatus {
    /** Черновик
    Нажата кнопку Создать ЗНК (Открылась форма ЗНК)**/
    draft = 'draft',
    /** Заполнение заказчиком
    Нажата кнопка продолжить**/
    fillingByCustomer = 'fillingByCustomer',
    /** Заполнение агентством
    Нажата кнопка Отправить в Агентство**/
    fillingByAgency = 'fillingByAgency',
    /** Проверка заказчиком
    Проверка заказчиком**/
    checkingByCustomer = 'checkingByCustomer',
    /** Согласование строк
    Согласование строк**/
    approvement = 'approvement',
    /** Закрытие актов
    Закрытие актов**/
    actClosing = 'actClosing',
    /** Заявка закрыта
    Все статусы актов в статусе Оплачен**/
    closed = 'closed',
}

// export const enum CreativeRequestStatus {
//     /**
//      * Черновик заявки
//      * - Доступен только автору
//      */
//     draft = 'draft',
//     /**
//      * Заявка создана. **Кнопка**
//      * - Нажата кнопка «Создать заявку»
//      */
//     created = 'created',
//     /**
//      * Получено. **Автоматически**
//      * - Данные получены от модуля Creator
//      */
//     received = 'received',
//     /**
//      * Назначено на агентство. **Автоматически**
//      * - Заявка назначена на сотрудника агентства
//      */
//     assigned = 'assigned',
//     /**
//      * Заявка передана заказчику. **Кнопка**
//      * - Нажата кнопка «Отправить заказчику»
//      */
//     transferred = 'transferred',
//     /**
//      * На согласовании. **Кнопка**
//      * - Заявка передана в отдел бизнес-планирования. Нажата кнопка «Отправить эксперту»
//      */
//     onApprovement = 'onApprovement',
//     /**
//      * Бюджет согласован. **Автоматически**
//      * - Равенство резерва и фактической стоимости согласованных строк ЗНК
//      */
//     budgetAgreed = 'budget_agreed',
//     /**
//      * Частично согласована
//      * - У заявки согласованна хотя бы одна активная строка
//      */
//     partiallyApproved = 'partiallyApproved',
//     /**
//      * Согласована
//      * - Заявка считается согласованной отделом бизнес-планирования,
//      * когда все активные строки получили имеют статусы "Строка согласована" и "Акт согласован"
//      */
//     approved = 'approved',

//     /**
//      * Закрыта. **Кнопка**
//      * - Заявка закрыта
//      */
//     closed = 'closed',

//     /**
//      * Заявка удалена
//      */
//     deleted = 'deleted',
// }

export const enum CreativeRequestItemStatus {
    /**
     * Получено
     * - Данные получены от модуля Creator
     */
    received = 'received',
    /**
     * На проверке
     * - Заявка назначена на сотрудника агентства
     * - Заявка передана в отдел бизнес-планирования
     */
    waiting = 'waiting',
    /**
     * Согласована
     * - Строка согласована отделом бизнес-планирования
     */
    approved = 'approved',
    /**
     * Архивирована
     * - Строка архивирована
     */
    archived = 'archived',
}

export const enum CreativeRequestItemActStatus {
    /**
     * Акт не согласован
     */
    waiting = 'actWaiting',

    /**
     * Акт согласован
     */
    approved = 'actApproved',
}

export const enum DictionaryType {
    ActivityType = 'activity_type',
    Agency = 'agency',
    Block = 'block',
    Channel = 'channel',
    CostCenter = 'cost_center',
    CostDirection = 'cost_direction',
    Direction = 'direction',
    Division = 'division',
    FunctionalBlock = 'functional_block',
    IFKV = 'ifkv',
    Item = 'item',
    LocationDriver = 'location_driver',
    Objective = 'objective',
    Product = 'product',
    Regionality = 'regionality',
    Resource = 'resource',
    ResourceUsage = 'resource_usage',
    Segment = 'segment',
    Subcategory = 'subcategory',
    Territory = 'territory',
    Tool = 'tool',
    CalendarGroup = 'calendar_group',
    Stage = 'stage',
    StageTemplate = 'stage_template',
    Task = 'task',
    Lot = 'lot',
    Tag = 'tag',
    CreativeRequestGroup = 'creativeRequestGroup',
    CreativeRequestTariff = 'creativeRequestTariff',
    CommissionAmount = 'commissionAmount',
    TransferredRightsType = 'transferredRightsType',
    TransferredRightsValidityTerritory = 'transferredRightsValidityTerritory',
}

export const enum CreativeRequestTableItemStatus {
    draft = 'draft',
    created = 'created',
    assigned = 'assigned',
    transferred = 'transferred',
    onApprovement = 'onApprovement',
    returnedForRework = 'returnedForRework',
    partiallyApproved = 'partiallyApproved',
    approved = 'approved',
    closed = 'closed',
}

export class MrmClient {
    private static client: Promise<Client<'creativeRequests' | 'tags' | 'projects' | 'budgets'>>;

    public static async getInstance() {
        if (!this.client) {
            this.client = MrmClient.init();
        }

        return await this.client;
    }

    private static async init() {
        return await create({
            url: MRM_CLIENT_URL,
            app: 'frontend',
            modules: ['creativeRequests', 'tags', 'projects', 'budgets'],
            debug: true,
            dictionary: true,
            preValidation: false,
            authStrategy: (client) => new CookiesAuth(client),
        });
    }
}

export type CreativeRequestLine = CreativeRequestTableItem;
export type CreativeRequestSubLine = CreativeRequestTableItemTask;

export type {
    Client,
    Action,
    BudgetAccessGroup,
    TagPicture,
    TagProject,
    TagTask,
    TagExecutionBudgetItem,
    TagPlanBudgetItem,
    Dictionary,
    Project,
    ProjectBudgetItem,
    CreativeRequest,
    CreativeRequestDto,
    CreativeRequestDonor,
    CreativeRequestItem,
    CreativeRequestItemFile,
    CreativeRequestParam,
    CreativeRequestItemEvents,
    CreativeRequestTableItem,
    CreativeRequestTableItemTask,
    CreativeRequestTableSettings,
    CreativeRequestTableItemEvents,
    CreativeRequestTableItemData,
    CreativeRequestTableItemTaskData,
    CreativeRequestTableSettingsData,
    CreativeRequestItemRightsDuration,
    CreativeRequestTableItemInterface,
    CreativeRequestTableItemTaskInterface,
    CreativeRequestBudgetItem,
    CreativeRequestBudgetItemFunds,
    CreativeRequestComment,
    CreativeRequestSubject,
    CreativeRequestTenderDocument,
    CreativeRequestTenderDocumentProtocolFile,
    CreativeRequestCommentFile,
    CreativeRequestAgency,
    CreativeRequestContract,
    CreativeRequestContractId,
    CreativeRequestContractDto,
    CreativeRequestItemComment,
    CreativeRequestCommentAuthor,
    CreativeRequestCorrectionEntry,
    ApproveTable,
    ApproveTableRow,
    DivisionTable,
    DivisionTableRow,
    DivisionRecord,
};
