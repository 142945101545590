import * as lodash from 'lodash';
import { DictionaryType } from '@mrm/dictionary';

import {
    REGIONALITY_TYPE_CENTRAL_BANK,
    REGIONALITY_TYPE_OTHER_BANKS,
    GroupedDictionaries,
    RegionalityUsecase,
    DictionariesByType,
    DictionaryValue,
    isMainRegionalitySelected,
} from './common';

export class RegionalityUsecasesCache {
    private dictionaries: GroupedDictionaries;
    private regionalityUsecases: RegionalityUsecase[];

    public constructor(dictionaries: GroupedDictionaries) {
        this.dictionaries = dictionaries;

        this.fillRegionalityUsecases();
    }

    public hasRegionalityUsecases(): boolean {
        return !!this.regionalityUsecases?.length;
    }

    public filterUsecasesByValue(value: DictionaryValue): RegionalityUsecase[] {
        return this.regionalityUsecases.filter((usecase) => this.usecaseMatchesValue(usecase, value));
    }

    private fillRegionalityUsecases() {
        const { byId: dictionariesById, byType: dictionariesByType } = this.dictionaries;

        const regionalityTypeDictionaries = dictionariesByType[DictionaryType.RegionalityType];

        this.regionalityUsecases = lodash.flatMapDeep(
            regionalityTypeDictionaries?.map((regionalityType) =>
                regionalityType.childrenRefs
                    ?.filter((usecase) =>
                        dictionariesById[usecase.id]?.childrenRefs?.every((childRef) => dictionariesById[childRef.id]),
                    )
                    ?.map((usecase) => {
                        const usecaseDictionaries: DictionariesByType = lodash.groupBy(
                            dictionariesById[usecase.id].childrenRefs?.map(
                                (usecaseChild) => dictionariesById[usecaseChild.id],
                            ),
                            (dictionary) => dictionary.type,
                        );

                        return usecaseDictionaries[DictionaryType.Resource]?.map(
                            (resource): RegionalityUsecase => ({
                                [DictionaryType.RegionalityType]: regionalityType,
                                [DictionaryType.Direction]: usecaseDictionaries[DictionaryType.Direction][0],
                                [DictionaryType.Tool]: usecaseDictionaries[DictionaryType.Tool][0],
                                [DictionaryType.Resource]: resource,
                                [DictionaryType.Item]: dictionariesById[resource.childrenRefs[0].id],
                            }),
                        );
                    }),
            ) || [],
        );
    }

    private usecaseMatchesValue(usecase: RegionalityUsecase, value: DictionaryValue): boolean {
        function fieldMatch(field: DictionaryType): boolean {
            return !value[field] || value[field].id === usecase[field].id;
        }

        let regionalityMatch = true;
        if (value[DictionaryType.Regionality]) {
            const regionalityType = isMainRegionalitySelected(value)
                ? REGIONALITY_TYPE_CENTRAL_BANK
                : REGIONALITY_TYPE_OTHER_BANKS;

            regionalityMatch = usecase[DictionaryType.RegionalityType].id === regionalityType;
        }

        return (
            regionalityMatch &&
            fieldMatch(DictionaryType.Direction) &&
            fieldMatch(DictionaryType.Tool) &&
            fieldMatch(DictionaryType.Resource) &&
            fieldMatch(DictionaryType.Item)
        );
    }
}
