import * as lodash from 'lodash';
import { Dictionary } from 'lodash';
import { TooltipThemes, TooltipAnchor } from 'sber-marketing-ui';

import { CorrectionType, BudgetItem } from '@mrm/budget';
import { PlainDictionary } from '@mrm/dictionary';
import {
    ChangeList,
    ColumnData,
    ColumnName,
    CustomCellType,
    SelectableCellsList,
    TableLine,
    UserRole,
    GroupedCorrections,
    DropdownOptions,
} from '@store/budgetExecution';
import { BudgetTransferMenuState, ComponentState, CellPosition } from '@store/budgetExecution/budgetTransferMenu';
import { CellColors, DropdownCellOptions } from '../CellTypes';

import {
    DatepickerCellParamsCreator,
    DropdownCellParamsCreator,
    InputCellParamsCreator,
    SelectableCellParamsCreator,
    TextCellParamsCreator,
} from './CellTypes';
import { ColumnsList } from '../../ColumnsConfig';

export enum CellBackgroundColor {
    SavedChange = 'rgba(245, 166, 35, 0.2)',
    UnsavedChange = 'rgba(248, 231, 28, 0.2)',
    PlanOverflow = 'rgba(255, 0, 255, 0.2)',
    Donor = 'rgba(230, 57, 0, 0.2)',
    Acceptor = 'rgba(25, 187, 79, 0.2)',
}

export enum CellBorderColor {
    Invalid = '#ff4444',
    Donor = 'rgba(230, 57, 0, 0.2)',
    Acceptor = 'rgba(25, 187, 79, 0.2)',
}

export interface ActualValue {
    value: string;
    color?: CellBackgroundColor;
}

export type LineCellsParams = { [columnName: string]: CellParams };

export interface CellParams {
    title?: string;
    value?: string | Date;
    originalValue?: string | Date;
    tooltip?: string;
    minDate?: Date;
    maxDate?: Date;
    options?: DropdownCellOptions[];
    isClickable?: boolean;
    isSelected?: boolean;
    isHovered?: boolean;
    bgColor?: CellBackgroundColor;
    cellColor?: CellColors;
    displayValidationError?: boolean;
    disabled?: boolean;
    cellBorder?: CellBorderColor;
    allowMultipleItems?: boolean;
    cellInfo?: string | JSX.Element;
    cellInfoTooltipTheme?: TooltipThemes;
    cellInfoTooltipAnchor?: TooltipAnchor;
}

export interface CellsParamsByLine {
    [lineId: string]: LineCellsParams;
}

interface Props {
    lines: TableLine[];
    dropdownsOptions: { [lineId: string]: { [columnName: string]: DropdownCellOptions[] } };
    unsavedChanges: ChangeList;
    userRole: UserRole;
    dictionaries: Record<string, PlainDictionary>;
    activityCorrections: GroupedCorrections<CorrectionType.Activity>;
    budgetItemCorrections: GroupedCorrections<CorrectionType.BudgetItem>;
    planCorrections: GroupedCorrections<CorrectionType.PlanFundsTransfer>;
    reserveCorrections: GroupedCorrections<CorrectionType.ReservedFunds>;
    incomeExternalPlanCorrections: GroupedCorrections<CorrectionType.IncomeExternalPlanFundsTransfer>;
    outcomeExternalPlanCorrections: GroupedCorrections<CorrectionType.OutcomeExternalPlanFundsTransfer>;
    transitionData: BudgetTransferMenuState;
    selectableCells: SelectableCellsList;
    validationStatus: boolean;
    budgetItemsByActivityId: Dictionary<BudgetItem[]>;
}

export class LayerManager {
    private static instance: LayerManager;

    private lines: TableLine[];
    private dropdownsOptions: { [lineId: string]: { [columnName: string]: DropdownCellOptions[] } };
    private unsavedChanges: ChangeList;
    private userRole: UserRole;
    private dictionaries: Record<string, PlainDictionary>;
    private activityCorrections: GroupedCorrections<CorrectionType.Activity>;
    private budgetItemCorrections: GroupedCorrections<CorrectionType.BudgetItem>;
    private planCorrections: GroupedCorrections<CorrectionType.PlanFundsTransfer>;
    private reserveCorrections: GroupedCorrections<CorrectionType.ReservedFunds>;
    private incomeExternalPlanCorrections: GroupedCorrections<CorrectionType.IncomeExternalPlanFundsTransfer>;
    private outcomeExternalPlanCorrections: GroupedCorrections<CorrectionType.OutcomeExternalPlanFundsTransfer>;
    private cellsParamsByLines: CellsParamsByLine;
    private transitionData: BudgetTransferMenuState;
    private selectableCells: SelectableCellsList;
    private validationStatus: boolean;
    private budgetItemsByActivityId: Dictionary<BudgetItem[]>;

    public static getInstance(props?: Props): LayerManager {
        if (!this.instance) {
            this.instance = new LayerManager();
        }

        if (props) {
            this.instance.init(props);
        }

        return this.instance;
    }

    public makeTableCellsParams(): CellsParamsByLine {
        const result = {};

        this.lines.forEach((line) => {
            result[line.id] = this.makeLineCellsParams(line, true);
        });

        return result;
    }

    public makeTalbeCellsParamsForXLSXExport(lines: TableLine[], dropdownOptions: DropdownOptions): CellsParamsByLine {
        const result = {};

        const savedDropwonOptions = this.dropdownsOptions;
        this.dropdownsOptions = dropdownOptions;

        lines.forEach((line) => {
            result[line.id] = this.makeLineCellsParams(line, false);
        });

        this.dropdownsOptions = savedDropwonOptions;

        return result;
    }

    public getCellsParams(): { [lineId: string]: LineCellsParams } {
        return this.cellsParamsByLines;
    }

    public applyUnsavedChanges(changes: ChangeList): void {
        const lineIds = lodash.keys({ ...this.unsavedChanges, ...changes });

        const cellsToUpdate: CellPosition[] = [];

        lineIds.forEach((lineId) => {
            const removedChanges = lodash.difference(this.unsavedChanges[lineId], changes[lineId]);
            const newChanges = lodash.difference(changes[lineId], this.unsavedChanges[lineId]);

            const changedCells = [...removedChanges, ...newChanges].map((change) => ({
                lineId: change.budgetItemId,
                columnName: change.columnName,
            }));

            cellsToUpdate.push(...changedCells);
        });

        this.unsavedChanges = changes;

        this.updateCellsParams(cellsToUpdate);
    }

    public updateDropdownsOptions(dropdownsOptions: {
        [lineId: string]: { [columnName: string]: DropdownCellOptions[] };
    }): void {
        const changedLinesIds: string[] = [];

        lodash.forEach(dropdownsOptions, (item, lineId) => {
            if (item !== this.dropdownsOptions[lineId]) {
                changedLinesIds.push(lineId);
            }
        });

        this.dropdownsOptions = dropdownsOptions;

        const dropdownColumns = ColumnsList.filter((item) => item.customCellType === CustomCellType.Dropdown);

        changedLinesIds.forEach((lineId) => {
            const line = this.lines.find((item) => item.id == lineId);

            dropdownColumns.forEach((column) => {
                this.cellsParamsByLines[lineId][column.name] = this.makeCellParams(line, column);
            });
        });
    }

    public updateTransitionDataState(transitionData: BudgetTransferMenuState): void {
        const cellsToUpdate: CellPosition[] = [];

        const transitionMenuWasOpened = this.transitionData.controls.componentState !== ComponentState.Closed;
        const transitionMenuIsOpened = transitionData.controls.componentState !== ComponentState.Closed;
        const transferModeChanged = transitionMenuWasOpened !== transitionMenuIsOpened;

        if (transferModeChanged) {
            this.transitionData = transitionData;

            this.updateAllSelectableCells();
        } else {
            const hoveredCellChanged = !lodash.isEqual(
                transitionData.cells.hoveredCell,
                this.transitionData.cells.hoveredCell,
            );
            const donorCellChanged = !lodash.isEqual(transitionData.cells.from, this.transitionData.cells.from);
            const acceptorCellChanged = !lodash.isEqual(transitionData.cells.to, this.transitionData.cells.to);

            if (hoveredCellChanged) {
                cellsToUpdate.push(transitionData.cells.hoveredCell, this.transitionData.cells.hoveredCell);
            }

            if (donorCellChanged) {
                cellsToUpdate.push(...[...transitionData.cells.from, ...this.transitionData.cells.from]);
            }

            if (acceptorCellChanged) {
                cellsToUpdate.push(...[...transitionData.cells.to, ...this.transitionData.cells.to]);
            }

            this.transitionData = transitionData;

            this.updateCellsParams(cellsToUpdate);
        }
    }

    public updateSelectableCellsList(selectableCells: SelectableCellsList): void {
        this.selectableCells = selectableCells;

        this.updateAllSelectableCells();
    }

    private init(props: Props): void {
        this.lines = props.lines;
        this.dropdownsOptions = props.dropdownsOptions;
        this.unsavedChanges = props.unsavedChanges;
        this.dictionaries = props.dictionaries;
        this.activityCorrections = props.activityCorrections;
        this.budgetItemCorrections = props.budgetItemCorrections;
        this.planCorrections = props.planCorrections;
        this.reserveCorrections = props.reserveCorrections;
        this.incomeExternalPlanCorrections = props.incomeExternalPlanCorrections;
        this.outcomeExternalPlanCorrections = props.outcomeExternalPlanCorrections;
        this.transitionData = props.transitionData;
        this.selectableCells = props.selectableCells;
        this.validationStatus = props.validationStatus;
        this.userRole = props.userRole;
        this.budgetItemsByActivityId = props.budgetItemsByActivityId;

        this.cellsParamsByLines = this.makeTableCellsParams();
    }

    private updateCellsParams(cells: CellPosition[]): void {
        cells.forEach((cellPosition) => {
            if (cellPosition.columnName && cellPosition.lineId) {
                const line = this.lines.find((item) => item.id == cellPosition.lineId);
                const column = ColumnsList.find((item) => item.name == cellPosition.columnName);

                if (line) {
                    const newParams = this.makeCellParams(line, column);
                    this.saveCellParams(line.id, column.name, newParams);

                    this.updateRelatedCells(line, column);
                }
            }
        });
    }

    private updateRelatedCells(line: TableLine, column: ColumnData): void {
        const relatedColumns = this.getRelatedColumnNames(column.name);

        relatedColumns.forEach((columnName) => {
            const relatedColumn = ColumnsList.find((item) => item.name == columnName);

            const relatedCellParams = this.makeCellParams(line, relatedColumn);

            this.saveCellParams(line.id, columnName, relatedCellParams);
        });
    }

    private updateAllSelectableCells(): void {
        const selectableCellsColumns = ColumnsList.filter((item) => item.customCellType === CustomCellType.Selectable);

        this.lines.forEach((line) => {
            selectableCellsColumns.forEach((column) => {
                const newParams = this.makeCellParams(line, column);

                this.saveCellParams(line.id, column.name, newParams);
            });
        });
    }

    private makeLineCellsParams(line: TableLine, applyChanges: boolean): LineCellsParams {
        const lineCellsParams: LineCellsParams = {};

        ColumnsList.forEach((column) => {
            lineCellsParams[column.name] = this.makeCellParams(line, column, applyChanges);
        });

        return lineCellsParams;
    }

    private makeCellParams(line: TableLine, column: ColumnData, applyChanges = true): CellParams {
        let cellParams: CellParams;

        switch (column.customCellType) {
            case CustomCellType.Input:
                cellParams = this.makeInputCellParams(line, column, applyChanges);
                break;

            case CustomCellType.Dropdown:
                cellParams = this.makeDropdownCellParams(line, column, applyChanges);
                break;

            case CustomCellType.Selectable:
                cellParams = this.makeSelectableCellParams(line, column, applyChanges);
                break;

            case CustomCellType.Datepicker:
                cellParams = this.makeDatepickerCellParams(line, column, applyChanges);
                break;

            default:
                cellParams = this.makeTextCellParams(line, column, applyChanges);
                break;
        }

        if (column.minAccessRole) {
            cellParams = this.checkAccessRight(cellParams);
        }

        return cellParams;
    }

    private checkAccessRight(cellParams: CellParams): CellParams {
        const hasEditRight = this.userRole === UserRole.BUDGET_EXPERT;

        return hasEditRight
            ? cellParams
            : {
                  ...cellParams,
                  disabled: true,
              };
    }

    private makeInputCellParams(line: TableLine, column: ColumnData, applyChanges: boolean): CellParams {
        const creactor = new InputCellParamsCreator({
            line,
            column,
            unsavedChanges: (applyChanges && this.unsavedChanges) || {},
            activityCorrections: (applyChanges && this.activityCorrections[line.activityId]) || [],
            budgetItemCorrections: (applyChanges && this.budgetItemCorrections[line.id]) || [],
            reserveCorrections: (applyChanges && this.reserveCorrections[line.id]) || [],
            validationStatus: this.validationStatus,
            isDisabled: line.isDisabled,
            userRole: this.userRole,
            budgetItemsByActivityId: this.budgetItemsByActivityId,
        });

        return creactor.makeCellParams();
    }

    private makeDropdownCellParams(line: TableLine, column: ColumnData, applyChanges: boolean): CellParams {
        const creator = new DropdownCellParamsCreator({
            line,
            column,
            dropdownsOptions: this.dropdownsOptions[line.id],
            dictionaries: this.dictionaries,
            unsavedChanges: this.unsavedChanges,
            budgetItemCorrections: (applyChanges && this.budgetItemCorrections[line.id]) || [],
            validationStatus: this.validationStatus,
            isDisabled: line.isDisabled,
        });

        return creator.makeCellParams();
    }

    private makeSelectableCellParams(line: TableLine, column: ColumnData, applyChanges: boolean): CellParams {
        const creator = new SelectableCellParamsCreator({
            line,
            column,
            transitionData: this.transitionData,
            selectableCells: this.selectableCells[line.id] || [],
            planCorrections: (applyChanges && this.planCorrections[line.id]) || [],
            incomeExternalPlanCorrections: (applyChanges && this.incomeExternalPlanCorrections[line.id]) || [],
            outcomeExternalPlanCorrections: (applyChanges && this.outcomeExternalPlanCorrections[line.id]) || [],
            isDisabled: line.isDisabled,
        });

        return creator.makeCellParams();
    }

    private makeDatepickerCellParams(line: TableLine, column: ColumnData, applyChanges: boolean): CellParams {
        const creator = new DatepickerCellParamsCreator({
            line,
            column,
            unsavedChanges: (applyChanges && this.unsavedChanges) || {},
            validationStatus: this.validationStatus,
            isDisabled: line.isDisabled,
            budgetItemCorrections: (applyChanges && this.budgetItemCorrections[line.id]) || [],
        });

        return creator.makeCellParams();
    }

    private makeTextCellParams(line: TableLine, column: ColumnData, applyChanges: boolean): CellParams {
        const creactor = new TextCellParamsCreator({
            line,
            column,
            unsavedChanges: this.unsavedChanges,
            planCorrections: (applyChanges && this.planCorrections[line.id]) || [],
            incomeExternalPlanCorrections: (applyChanges && this.incomeExternalPlanCorrections[line.id]) || [],
            outcomeExternalPlanCorrections: (applyChanges && this.outcomeExternalPlanCorrections[line.id]) || [],
            reserveCorrections: (applyChanges && this.reserveCorrections[line.id]) || [],
            isDisabled: line.isDisabled,
        });

        return creactor.makeCellParams();
    }

    private saveCellParams(lineId: string, columnName: ColumnName, newCellParams: CellParams): void {
        this.cellsParamsByLines[lineId] = {
            ...this.cellsParamsByLines[lineId],
            [columnName]: newCellParams,
        };
    }

    private getRelatedColumnNames(columnName: ColumnName): ColumnName[] {
        let relatedColumnNames: ColumnName[] = [];

        ColumnsList.forEach((column) => {
            if (
                lodash.get(column, 'metaData.relatedColumns') &&
                lodash.includes(column.metaData.relatedColumns, columnName)
            ) {
                relatedColumnNames.push(column.name);
            }
        });

        switch (columnName) {
            case ColumnName.StartDate:
                relatedColumnNames = [ColumnName.EndDate];
                break;

            case ColumnName.EndDate:
                relatedColumnNames = [ColumnName.StartDate];
                break;
        }

        return relatedColumnNames;
    }
}
