import { actionCreatorFactory } from 'typescript-fsa';
import type { ActivityParams, TaskCardParams } from 'sber-marketing-types/frontend';

import type { SwitchKeyActivityParams } from '../types';

const creator = actionCreatorFactory('ACTIVITY_TASKS_PAGE_ASYNC').async;

export const loadActivity = creator<number, ActivityParams, Error>('LOAD_ACTIVITY_ASYNC');
export const setActivityNeedStages = creator<boolean, void, Error>('SET_ACTIVITY_NEED_STAGES_ASYNC');
export const switchKeyActivity = creator<SwitchKeyActivityParams, void, Error>('SWITCH_KEY_ACTIVITY_ASYNC');
export const fillActivityTasks = creator<number, TaskCardParams[], Error>('FILL_GROUPED_ACTIVITY_TASKS');
export const resetActivityPage = creator<null, void, Error>('RESET_ACTIVITY_PAGE');
export const fillTaskFilters = creator<null, void, Error>('FILL_TASK_FILTERS');
