import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { TaskStatus } from 'sber-marketing-types/frontend';
import { DAMArchiveStatus } from '@mrm/dam';

import { DAMApi, TaskApi } from '@api';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { getActivityTasksPageState } from '@store/activityTasksPage';

import * as actions from './actions/sync';
import * as asyncActions from './actions/async';

import { getActivityDAMArchiveState, canEditArchive } from './selectors';
import { ArchiveContentPopupComponentState } from './types';

const POOLING_INTERVAL = 1000;
const MAX_POOLINGS_COUNT = 5;

async function withLoadingStatus(
    dispatcher: (loadingStatus: LoadingStatus) => void,
    callback: () => Promise<void>,
): Promise<void> {
    dispatcher(LoadingStatus.LOADING);

    try {
        await callback();
        dispatcher(LoadingStatus.LOADED);
    } catch (e) {
        dispatcher(LoadingStatus.ERROR);

        throw e;
    }
}

export const loadData = bindThunkAction<StoreState, number, void, Error>(
    asyncActions.loadData,
    async (activityId, dispatch, getState) => {
        await withLoadingStatus(
            (loadingStatus) => dispatch(actions.setCommonLoadingStatus(loadingStatus)),
            async () => {
                dispatch(actions.setActivityId(activityId));

                const archiveStatus = await DAMApi.getActivityStatus(activityId);
                dispatch(actions.setArchiveStatus(archiveStatus));

                if (canEditArchive(getState())) {
                    try {
                        const archive = await DAMApi.getActivityTasksStatus(activityId);
                        dispatch(actions.setArchive(archive));
                    } catch (e) {}
                }
            },
        );
    },
);

export const loadTasksFiles = bindThunkAction<StoreState, string, void, Error>(
    asyncActions.loadTasksFiles,
    async (taskId, dispatch, getState) => {
        const state = getState();
        const tasksFiles = getActivityDAMArchiveState(state).tasksFiles.byTaskId;
        const loadedTasks = getActivityTasksPageState(state).activityTasks.byId;

        let taskIdsToLoad: string[];
        if (taskId) {
            taskIdsToLoad = [taskId];
        } else {
            const allTaskIds = Object.keys(tasksFiles);
            const loadedTaskIds = Object.keys(loadedTasks);

            taskIdsToLoad = allTaskIds.length
                ? allTaskIds.filter((taskId) => !loadedTaskIds.includes(taskId))
                : loadedTaskIds;
        }

        if (taskIdsToLoad.length) {
            await withLoadingStatus(
                (loadingStatus) => dispatch(actions.setTasksFilesLoadingStatus(loadingStatus)),
                async () => {
                    const updTasksFiles = { ...tasksFiles };

                    for (let i = 0; i !== taskIdsToLoad.length; i++) {
                        const taskId = taskIdsToLoad[i];

                        const taskFiles =
                            loadedTasks[taskId].status !== TaskStatus.Draft
                                ? await TaskApi.getTaskFiles(taskId, { channelIds: [null] })
                                : [];
                        updTasksFiles[taskId] = taskFiles;
                    }

                    dispatch(actions.setTasksFiles(updTasksFiles));
                },
            );
        } else {
            dispatch(actions.setTasksFilesLoadingStatus(LoadingStatus.LOADED));
        }
    },
);

export const exportFiles = bindThunkAction<StoreState, null, void, Error>(
    asyncActions.exportFiles,
    async (_, dispatch, getState) => {
        const {
            activityId,
            archiveContentPopup: { filesToExport: files },
        } = getActivityDAMArchiveState(getState());

        dispatch(actions.setArchiveContentPopupState(ArchiveContentPopupComponentState.Hidden));

        await DAMApi.setActivityArchiveFiles(activityId, { files });

        let poolingsCount = 0;
        async function fetch() {
            if (poolingsCount++ > MAX_POOLINGS_COUNT) {
                console.warn(`Expected status change for activity ${activityId} was not received`);
                dispatch(actions.setCommonLoadingStatus(LoadingStatus.ERROR));
                dispatch(actions.setPoolingInProgress(false));
            } else {
                const updArchiveStatus = await DAMApi.getActivityTasksStatus(activityId);

                if (updArchiveStatus.status === DAMArchiveStatus.Connected) {
                    dispatch(actions.setArchiveStatus(updArchiveStatus));
                    dispatch(actions.setPoolingInProgress(false));

                    const archive = await DAMApi.getActivityTasksStatus(activityId);
                    dispatch(actions.setArchive(archive));
                }

                if (updArchiveStatus.status !== DAMArchiveStatus.Connected) {
                    setTimeout(fetch, POOLING_INTERVAL);
                }
            }
        }

        dispatch(actions.setPoolingInProgress(true));
        fetch();
    },
);
