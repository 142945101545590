/* tslint:disable:max-file-line-count */
import * as queryString from 'query-string';
import * as lodash from 'lodash';
import { bindActionCreators } from 'redux';
import { UserConfigType } from 'sber-marketing-types/openid';
import { PlanPermissionsSysNames } from 'sber-marketing-types/frontend';
import type { ActivityBudget, BudgetItem, Correction } from '@mrm/budget';
import { CorrectionType, CorrectionStatus, BudgetStatus, Budget } from '@mrm/budget';
import type { PlainDictionary } from '@mrm/dictionary';
import autobind from 'autobind-decorator';

import {
    ActivityBudgetApi,
    BudgetItemApi,
    DictionaryApi,
    BudgetCorrectionApi,
    UserApi,
    UserConfigApi,
    BudgetApi,
    MultiReferenceDictionaryApi,
} from '@api';

import { store } from '@store';
import {
    PageData,
    ColumnFilters,
    Filters,
    GroupedCorrections,
    TableLine,
    ExecutionTableUserConfig,
    CorrectionsGroup,
    GroupedDicitonaries,
} from '@store/budgetExecution/types';
import { ColumnName, UserRole, ColumnNameToFilterMap, ColumnsNameWithCodes } from '@store/budgetExecution/types';
import type { User } from '@store/user/types';
import { getLoginUser } from '@store/user/selector';
import { saveBudgetByStatusUserConfig, getBudgetByStatusUserConfig } from '@store/userConfig/budget';
import {
    PageFilters,
    loadPageData,
    loadBudgetItems,
    loadActivityBudgets,
    setColumnsVisiblityFilter,
    setFixedColumnsNames,
    setSortingMode,
    setFilters,
    setColumnsWidth,
    initUnsavedChanges,
    setShowOnlyLinesWithoutPlan,
    setCorrectionsToDisplay,
    setActivityReferenceMenuVisibility,
    setCreativeReferenceMenuVisibility,
    updateLinesData,
    setShowOnlyLinesWithNegativeBalance,
    setFiltersState,
    loadFiltersByColumn,
    setPreviouslyLoadedFilters,
    setAppliedFiltersNames,
    setShowOnlyLinesWithPlanBudget,
    setShowOnlyLinesWithNegativePlanFactDiff,
    setMultiReferenceDictionaryApi,
    setPageFilters,
} from '@store/budgetExecution';
import { getBudgetTransferMenuState } from '@store/budgetExecution/budgetTransferMenu';
import {
    getPageData,
    getDefaultFilters,
    getBudgetExecutionPageState,
    makeCorrectionsToDisplayInitialState,
    setUseLinesWithoutPlanInSorting,
} from '@store/budgetExecution';
import { getBudgetState } from '@store/budgetPage';
import { loadById as loadMiscBudgetItems } from '@store/budgetExecution/miscBudgetItems';
import { ColumnsList } from '../ColumnsConfig';

interface StoreProps {
    budgetId: string;
    activityBudgets: ActivityBudget[];
    selectedBudget: Budget;
    budgetItems: BudgetItem[];
    budgetItemsToIgnoreFilters: BudgetItem[];
    transitionMenuBudgetItems: BudgetItem[];
    user: User;
    defaultFilters: Filters;
    lines: TableLine[];
    filters: Filters;
}

export class Loader {
    private static instance: Loader;
    private activityReferenceBudgetItems: BudgetItem[] = [];

    private dispatch = bindActionCreators(
        {
            loadPageData,
            loadBudgetItems,
            loadActivityBudgets,
            setColumnsVisiblityFilter,
            setFixedColumnsNames,
            setSortingMode,
            setFilters,
            setColumnsWidth,
            initUnsavedChanges,
            setShowOnlyLinesWithoutPlan,
            setCorrectionsToDisplay,
            setActivityReferenceMenuVisibility,
            setCreativeReferenceMenuVisibility,
            updateLinesData,
            setShowOnlyLinesWithNegativeBalance,
            setFiltersState,
            loadFiltersByColumn,
            saveBudgetByStatusUserConfig,
            setPreviouslyLoadedFilters,
            setAppliedFiltersNames,
            loadMiscBudgetItems,
            setUseLinesWithoutPlanInSorting,
            setShowOnlyLinesWithPlanBudget,
            setShowOnlyLinesWithNegativePlanFactDiff,
            setMultiReferenceDictionaryApi,
            setPageFilters,
        },
        store.dispatch,
    );

    public static getInstance(): Loader {
        if (!Loader.instance) {
            Loader.instance = new Loader();
        }
        return Loader.instance;
    }

    public async init() {
        await this.initPageData();

        this.initFilters();

        await this.initFromUserConfig();
    }

    public async initPageData() {
        const pageData = await this.getPageData();

        const multiReferenceDictionaryApi = new MultiReferenceDictionaryApi();
        multiReferenceDictionaryApi.init(pageData.userDictionaries);
        this.dispatch.setMultiReferenceDictionaryApi(multiReferenceDictionaryApi);

        this.dispatch.loadPageData(pageData);
    }

    public initFilters() {
        const { defaultFilters } = this.getStoreProps();
        this.dispatch.setFilters(defaultFilters);
    }

    public async loadActivityReferenceBudgetItems(): Promise<void> {
        const { budgetId } = this.getStoreProps();

        if (!this.activityReferenceBudgetItems.length) {
            try {
                this.activityReferenceBudgetItems = await BudgetItemApi.getBudgetItemList({
                    budgetId,
                });
            } catch (error) {
                this.activityReferenceBudgetItems = [];
                console.error(error);
            }
        }
    }

    public async loadDataByFilters(userConfigFilters?: Filters): Promise<void> {
        const { budgetId, filters } = this.getStoreProps();

        const filtersToUse = userConfigFilters || filters;

        const appliedFilters = Object.keys(filtersToUse).reduce((acc, column) => {
            let selectedValues: any[] = Object.keys(filtersToUse[column])
                .filter((value) => !!filtersToUse[column][value])
                .map((item) => (item === 'null' ? null : item));

            if (selectedValues.length) {
                const res = { ...acc };

                let filterPropertyPath;

                if (column === ColumnName.Responsible) {
                    filterPropertyPath = 'responsibleIds';
                } else if (column === ColumnName.Author) {
                    filterPropertyPath = 'author.id';
                    selectedValues = selectedValues.map((item) => Number(item)).filter((item) => !!item);
                } else {
                    filterPropertyPath = `${ColumnNameToFilterMap[column]}${
                        ColumnNameToFilterMap[column].startsWith('dictionary') &&
                        !lodash.includes(ColumnsNameWithCodes, column)
                            ? '.id'
                            : ''
                    }`;
                }

                if (filterPropertyPath) {
                    // constructing filter object from selector
                    lodash.set(res, filterPropertyPath, selectedValues);
                }

                return res;
            }

            return acc;
        }, {});

        const [budgetItems, activityBudgets] = await Promise.all([
            !Object.keys(appliedFilters).length
                ? []
                : BudgetItemApi.getBudgetItemList({
                      budgetId,
                      filter: appliedFilters,
                  }),
            ActivityBudgetApi.getActivityBudgetList({ budgetId }),
        ]);

        this.dispatch.loadPageData({
            activityBudgets,
            budgetItems,
            budgetItemsToIgnoreFilters: [],
        });

        // const filteredUsers = users.filter(item => item.organizationId === userOrganizationId);

        // const userRole = this.defineUserRole(user);

        // const groupedCorrections = this.groupCorrections(corrections);

        // return {
        //     budgetId: budget.id,
        //     activityBudgets,
        //     budgetItems,
        //     activities: lodash.sortBy(activities, item => item.name),
        //     dictionaries: this.groupDictionariesByType(dictionaries),
        //     users: filteredUsers,
        //     userRole,
        //     corrections: groupedCorrections
        // };
    }

    public async initFromQuery(query: queryString.ParsedQuery) {
        if (query.budgetId) {
            this.dispatch.saveBudgetByStatusUserConfig({
                budgetStatus: BudgetStatus.Execution,
                payload: {
                    budgetId: query.budgetId as string,
                },
            });
        }

        if (query.filters || query.activityId || query.creativeRequestId) {
            const queryBudgetId = query?.budgetId as string;

            const [userConfig] = await Promise.all([
                UserConfigApi.getPageConfig(UserConfigType.BudgetExecution) as any,
                this.loadActivityReferenceBudgetItems(), // need to load all budgetItems before filters parsing
            ]);

            if (queryBudgetId) {
                userConfig[queryBudgetId] = userConfig[queryBudgetId] || {};
                userConfig[queryBudgetId].selectedBudgetId = query.budgetId;
                userConfig[queryBudgetId].filters = query.filters
                    ? this.parseFiltersFromQuery(query)
                    : userConfig[queryBudgetId].filters;
                userConfig[queryBudgetId].showOnlyLinesWithoutPlan = false;
                userConfig[queryBudgetId].correctionsToDisplay = makeCorrectionsToDisplayInitialState();

                if (userConfig[queryBudgetId].columnsVisiblityFilter) {
                    const columnsVisibilityFilterColumns = Object.keys(
                        userConfig[queryBudgetId].columnsVisiblityFilter,
                    );

                    const allColumnsAreVisible =
                        columnsVisibilityFilterColumns.every(
                            (column) => userConfig[queryBudgetId].columnsVisiblityFilter[column],
                        ) ||
                        columnsVisibilityFilterColumns.every(
                            (column) => !userConfig[queryBudgetId].columnsVisiblityFilter[column],
                        );

                    userConfig[queryBudgetId].columnsVisiblityFilter[query.column as string] = !allColumnsAreVisible;
                }

                await this.applyParamsFromUserConfig(userConfig);
            }
        }

        if (query.activityId) {
            this.dispatch.setActivityReferenceMenuVisibility({
                visibility: true,
                selectedActivityId: query.activityId as string,
                filters: query?.activityReferenceFilters ? JSON.parse(query.activityReferenceFilters as string) : null,
            });
        }

        if (query.creativeRequestId) {
            this.dispatch.setCreativeReferenceMenuVisibility({
                visibility: true,
                creativeRequestId: query.creativeRequestId as string,
            });
        }
    }

    public async initFromUserConfig() {
        const userConfig = await this.getUserConfig();
        await this.applyParamsFromUserConfig(userConfig);
    }

    public async getUserConfig(): Promise<ExecutionTableUserConfig> {
        return (await UserConfigApi.getPageConfig(UserConfigType.BudgetExecution)) as ExecutionTableUserConfig;
    }

    public async updatePageDataByLineIds(lineIds: string[]) {
        const activityIds = lodash.compact(
            lodash.uniq(
                lineIds
                    .map((lineId) => this.getActivityBudgetByLineId(lineId))
                    .map((activity) => (activity ? activity.id : null)),
            ),
        );

        const { budgetId, budgetItems, budgetItemsToIgnoreFilters } = this.getStoreProps();

        const [updatedActivities, updatedBudgetItems, activityCorrections, linesCorrections] = await Promise.all([
            Promise.all(activityIds.map((id) => ActivityBudgetApi.getActivityBudget(id))),
            Promise.all(lineIds.map((id) => BudgetItemApi.getBudgetItem(id))),
            !lodash.isEmpty(activityIds) &&
                BudgetCorrectionApi.getCorrectionsList({
                    budgetId,
                    activityId: activityIds,
                    filter: {
                        status: CorrectionStatus.NeedApproving,
                    },
                }),
            BudgetCorrectionApi.getCorrectionsList({
                budgetId,
                budgetItemId: lineIds,
                filter: {
                    status: CorrectionStatus.NeedApproving,
                },
            }),
        ]);

        const updatedActivityList = this.mergeActivities(updatedActivities);
        const updatedBudgetItemList = this.mergeBudgetItems(budgetItems, updatedBudgetItems, lineIds);
        const updatedBudgetItemToIgnoreFiltersList = this.mergeBudgetItems(
            budgetItemsToIgnoreFilters,
            updatedBudgetItems,
            lineIds,
        );
        const groupedCorrections = this.groupCorrections(lodash.concat(activityCorrections, linesCorrections));

        this.dispatch.updateLinesData({
            activityBudgets: updatedActivityList,
            budgetItems: updatedBudgetItemList,
            budgetItemsToIgnoreFilters: updatedBudgetItemToIgnoreFiltersList,
            corrections: this.supplementCorrectionsList(groupedCorrections, lineIds),
        });
    }

    public async updateLockedLinesData() {
        const { transitionMenuBudgetItems: transitionMenuData, budgetId } = this.getStoreProps();
        const transitionMenuBudgetItemIds = transitionMenuData.map((budgetItem) => budgetItem.id);

        const transitionMenuCorrections = await BudgetCorrectionApi.getCorrectionsList({
            budgetId,
            budgetItemId: transitionMenuBudgetItemIds,
            filter: {
                status: CorrectionStatus.NeedApproving,
            },
        });

        if (transitionMenuData.length) {
            this.dispatch.updateLinesData({
                activityBudgets: [],
                budgetItems: [],
                budgetItemsToIgnoreFilters: [],
                corrections: this.supplementCorrectionsList(
                    this.groupCorrections(transitionMenuCorrections),
                    transitionMenuBudgetItemIds,
                ),
            });
        }
    }

    public async loadBudgetRelatedData(): Promise<void> {
        const { budgetId } = this.getStoreProps();

        const [activityBudgets, corrections] = await Promise.all([
            budgetId ? ActivityBudgetApi.getActivityBudgetList({ budgetId }) : [],
            budgetId
                ? BudgetCorrectionApi.getCorrectionsList({
                      budgetId,
                      filter: {
                          status: CorrectionStatus.NeedApproving,
                      },
                  })
                : [],
        ]);

        const groupedCorrections = this.groupCorrections(corrections);

        this.dispatch.loadPageData({
            activityBudgets,
            corrections: groupedCorrections,
        });
    }

    private async applyParamsFromUserConfig(userConfig: ExecutionTableUserConfig = {}) {
        const { filters, budgetId } = this.getStoreProps();

        const existingColumns = ColumnsList.map((column) => column.name);

        const config = userConfig[budgetId];

        if (config) {
            const pageFilters: Partial<PageFilters> = {};

            if (config.filters) {
                const updatedFilters = this.mergeUserConfigFilters(filters, config.filters);

                this.dispatch.setFiltersState(updatedFilters);

                await this.loadDataByFilters(config.filters);

                this.dispatch.setPreviouslyLoadedFilters({
                    filters: updatedFilters,
                });

                pageFilters.appliedFiltersNames = Object.keys(config.filters).filter(
                    (filterKey) => !lodash.isEmpty(config.filters[filterKey]),
                ) as ColumnName[];
            }

            if (config.unsavedChanges) {
                const updatedUnsavedChanges = lodash.cloneDeep(config.unsavedChanges);
                this.dispatch.initUnsavedChanges(updatedUnsavedChanges);

                const activityNameUnsavedChangesLineIds = lodash
                    .flatten(lodash.values(updatedUnsavedChanges))
                    .filter((unsavedChange) => unsavedChange.columnName == ColumnName.ActivityName)
                    .map((unsavedChange) => unsavedChange.budgetItemId);
                if (activityNameUnsavedChangesLineIds.length) {
                    this.dispatch.loadMiscBudgetItems(activityNameUnsavedChangesLineIds);
                }
            }

            if (config.budgetItemsToIgnoreFilters) {
                await this.loadBudgetItemsToIgnoreFilters(config.budgetItemsToIgnoreFilters);
            }

            if (config.columnsWidth) {
                this.dispatch.setColumnsWidth(config.columnsWidth);
            }

            if (config.fixedColumnsNames) {
                this.dispatch.setFixedColumnsNames(
                    config.fixedColumnsNames.filter((column) => existingColumns.includes(column)),
                );
            }

            if (config.columnsVisiblityFilter) {
                const columnsVisibilityFilterFilter = Object.keys(config.columnsVisiblityFilter)
                    .filter((column: ColumnName) => existingColumns.includes(column))
                    .reduce(
                        (acc, column: ColumnName) => ({
                            ...acc,
                            [column]: config.columnsVisiblityFilter[column],
                        }),
                        {},
                    );

                pageFilters.columnsVisiblityFilter = columnsVisibilityFilterFilter;
            }

            if (config.sortingMode) {
                pageFilters.sortingMode = config.sortingMode;
            }

            if (config.showOnlyLinesWithPlanBudget) {
                pageFilters.showOnlyLinesWithPlanBudget = config.showOnlyLinesWithPlanBudget;
            }

            if (config.showOnlyLinesWithNegativePlanFactDiff) {
                pageFilters.showOnlyLinesWithNegativePlanFactDiff = config.showOnlyLinesWithNegativePlanFactDiff;
            }

            if (config.appliedFiltersNames) {
                pageFilters.appliedFiltersNames = pageFilters.appliedFiltersNames || config.appliedFiltersNames;
            }

            if (config.showOnlyLinesWithNegativeBalance !== undefined) {
                pageFilters.showOnlyLinesWithNegativeBalance = !!config.showOnlyLinesWithNegativeBalance;
            }

            if (config.showOnlyLinesWithoutPlan !== undefined) {
                pageFilters.showOnlyLinesWithoutPlan = !!config.showOnlyLinesWithoutPlan;
            }

            if (config.corrections) {
                pageFilters.correctionsToDisplay = config.corrections;
            }

            if (config.useLinesWithoutPlanInSorting !== undefined) {
                pageFilters.useLinesWithoutPlanInSorting = !!config.useLinesWithoutPlanInSorting;
            }

            this.dispatch.setPageFilters(pageFilters);
        }
    }

    private async loadBudgetItemsToIgnoreFilters(budgetItemsIds: string[]): Promise<void> {
        if (budgetItemsIds?.length) {
            const { budgetId } = this.getStoreProps();

            const budgetItemsToIgnoreFilters = await BudgetItemApi.getBudgetItemList({
                budgetId,
                filter: {
                    id: budgetItemsIds,
                },
            });

            this.dispatch.loadPageData({ budgetItemsToIgnoreFilters });
        }
    }

    private mergeUserConfigFilters(currentFilters: Filters, userConfigFilters: Filters): Filters {
        const updatedFilters = lodash.clone(currentFilters);

        lodash.forEach(updatedFilters, (columnFilters, columnName) => {
            if (userConfigFilters.hasOwnProperty(columnName)) {
                updatedFilters[columnName] = userConfigFilters[columnName];
            }
        });

        return updatedFilters;
    }

    private async getPageData(): Promise<Partial<PageData>> {
        const {
            user: {
                attributes: { id: userId, organizationId: userOrganizationId, permissions: userPermissions },
            },
            selectedBudget,
            budgetId,
        } = this.getStoreProps();

        const [userDictionariesData, allDictionariesData, users, budget] = await Promise.all([
            DictionaryApi.getDictionariesForBudget({
                organizationId: selectedBudget.dictionaryOrganizationId,
                userId,
                budgetId,
                treeview: true,
            }),
            DictionaryApi.getDictionaryList({
                organizationId: selectedBudget.dictionaryOrganizationId,
                treeview: true,
            }),
            UserApi.getFullUserList(),
            BudgetApi.getBudget(budgetId),
        ]);

        const filteredUsers = users.filter((item) => item.organizationId === userOrganizationId);

        const userRole = this.defineUserRole(userPermissions);
        const userDictionaries = this.groupDictionaries(userDictionariesData);
        const allDictionaries = this.groupDictionaries(allDictionariesData);

        return {
            budget,
            userDictionaries,
            allDictionaries,
            users: filteredUsers,
            allUsers: users,
            userRole,
        };
    }

    private defineUserRole(userPermissions: string[]): UserRole {
        const userIsBudgetExpert = lodash.includes(userPermissions, PlanPermissionsSysNames.OrganizationAccess);
        const userIsSupervisor = lodash.includes(userPermissions, PlanPermissionsSysNames.DepartmentAccess);

        if (userIsBudgetExpert) {
            return UserRole.BUDGET_EXPERT;
        }

        if (userIsSupervisor) {
            return UserRole.SUPERVISOR;
        }

        return UserRole.BRAND_MANAGER;
    }

    @autobind
    private groupCorrections(corrections: Correction[]): CorrectionsGroup {
        const groupedCorrections = lodash.groupBy(corrections, (item) => item.type);

        const activityCorrections = this.groupActivityCorrections(
            groupedCorrections[CorrectionType.Activity] as Correction<CorrectionType.Activity>[],
        );
        const budgetItemCorrections = this.groupBudgetItemCorrections(
            groupedCorrections[CorrectionType.BudgetItem] as Correction<CorrectionType.BudgetItem>[],
        );
        const planCorrections = this.groupPlanCorrections(
            groupedCorrections[CorrectionType.PlanFundsTransfer] as Correction<CorrectionType.PlanFundsTransfer>[],
        );
        const reserveCorrections = this.groupReserveCorrections(
            groupedCorrections[CorrectionType.ReservedFunds] as Correction<CorrectionType.ReservedFunds>[],
        );
        const factCorrections = this.groupFactCorrections(
            groupedCorrections[CorrectionType.FactFunds] as Correction<CorrectionType.FactFunds>[],
        );
        const incomeExternalPlanCorrections = this.groupIncomeExternalPlanCorrections(
            groupedCorrections[
                CorrectionType.IncomeExternalPlanFundsTransfer
            ] as Correction<CorrectionType.IncomeExternalPlanFundsTransfer>[],
        );
        const outcomeExternalPlanCorrections = this.groupOutcomeExternalPlanCorrections(
            groupedCorrections[
                CorrectionType.OutcomeExternalPlanFundsTransfer
            ] as Correction<CorrectionType.OutcomeExternalPlanFundsTransfer>[],
        );

        return {
            activityCorrections,
            budgetItemCorrections,
            planCorrections,
            reserveCorrections,
            factCorrections,
            incomeExternalPlanCorrections,
            outcomeExternalPlanCorrections,
        };
    }

    @autobind
    private supplementCorrectionsList(corrections: CorrectionsGroup, lineIds: string[]): CorrectionsGroup {
        return lodash.mapValues(corrections, (item, key) => {
            lineIds.forEach((lineId) => {
                if (!item[lineId] && key !== CorrectionType.Activity) {
                    item[lineId] = [];
                }
            });

            return item as any;
        });
    }

    private groupActivityCorrections(
        corrections: Correction<CorrectionType.Activity>[] = [],
    ): GroupedCorrections<CorrectionType.Activity> {
        return lodash.groupBy(corrections, (item) => item.data.activity.current.id);
    }

    private groupBudgetItemCorrections(
        corrections: Correction<CorrectionType.BudgetItem>[] = [],
    ): GroupedCorrections<CorrectionType.BudgetItem> {
        return lodash.groupBy(corrections, (item) => item.data.budgetItem.current.id);
    }

    private groupPlanCorrections(
        corrections: Correction<CorrectionType.PlanFundsTransfer>[] = [],
    ): GroupedCorrections<CorrectionType.PlanFundsTransfer> {
        return corrections.reduce((acc, correction) => {
            const { donorId, acceptorId } = correction.data.params;

            if (!acc[donorId]) {
                acc[donorId] = [];
            }

            if (!acc[acceptorId]) {
                acc[acceptorId] = [];
            }

            acc[donorId].push(correction);

            if (acceptorId !== donorId) {
                acc[acceptorId].push(correction);
            }

            return acc;
        }, {});
    }

    private groupReserveCorrections(
        corrections: Correction<CorrectionType.ReservedFunds>[] = [],
    ): GroupedCorrections<CorrectionType.ReservedFunds> {
        return lodash.groupBy(corrections, (item) => item.data.budgetItem.current.id);
    }

    private groupFactCorrections(
        corrections: Correction<CorrectionType.FactFunds>[] = [],
    ): GroupedCorrections<CorrectionType.FactFunds> {
        return lodash.groupBy(corrections, (item) => item.data.budgetItem.current.id);
    }

    private groupIncomeExternalPlanCorrections(
        corrections: Correction<CorrectionType.IncomeExternalPlanFundsTransfer>[] = [],
    ): GroupedCorrections<CorrectionType.IncomeExternalPlanFundsTransfer> {
        return lodash.groupBy(corrections, (item) => item.data.acceptor.current.id);
    }

    private groupOutcomeExternalPlanCorrections(
        corrections: Correction<CorrectionType.OutcomeExternalPlanFundsTransfer>[] = [],
    ): GroupedCorrections<CorrectionType.OutcomeExternalPlanFundsTransfer> {
        return lodash.groupBy(corrections, (item) => item.data.donor.current.id);
    }

    private groupDictionaries(dictionaries: PlainDictionary[]): GroupedDicitonaries {
        const byId = dictionaries.reduce(
            (acc, dictionary) => ({
                ...acc,
                [dictionary.id]: dictionary,
            }),
            {},
        );
        const byType = lodash.groupBy(dictionaries, (item) => item.type);

        return { byId, byType };
    }

    private parseFiltersFromQuery(queryParams: queryString.ParsedQuery): Filters {
        const { defaultFilters } = this.getStoreProps();
        const { filters } = queryParams;

        const parcedQueryFilters = filters
            ? Array.isArray(filters)
                ? this.parseFilterFieldsFromQuery(filters)
                : this.parseFilterFieldsFromQuery([filters])
            : {};

        const updatedFilters = lodash.cloneDeep(defaultFilters);

        lodash.forEach(parcedQueryFilters, (filterValues, filterName) => {
            if (filterName == 'id') {
                const serialNumbers = lodash.compact(
                    filterValues.map((lineId) => this.getLineSerialNumberByLineId(lineId)),
                );

                updatedFilters[ColumnName.Id] = this.applyQueryFilters(updatedFilters[ColumnName.Id], serialNumbers);
            }

            if (filterName == 'activityId') {
                const serialNumbers = lodash.compact(
                    lodash.flatMap(filterValues, (activityId) => this.getLineSerialNumbersByActivityId(activityId)),
                );

                updatedFilters[ColumnName.Id] = this.applyQueryFilters(updatedFilters[ColumnName.Id], serialNumbers);
            }

            if (filterName == 'activityName') {
                updatedFilters[ColumnName.ActivityName] = this.applyQueryFilters(
                    updatedFilters[ColumnName.ActivityName],
                    filterValues,
                );
            }

            if (filterName == 'serialNumber') {
                updatedFilters[ColumnName.Id] = this.applyQueryFilters(updatedFilters[ColumnName.Id], filterValues);
            }

            if (filterName == 'division') {
                updatedFilters[ColumnName.Division] = this.applyQueryFilters(
                    updatedFilters[ColumnName.Division],
                    filterValues,
                );
            }
        });

        return updatedFilters;
    }

    private parseFilterFieldsFromQuery(filters: string[]): { [field: string]: string[] } {
        const parsedFilters = {};

        filters.forEach((item) => {
            const filterName = item.split(':')[0];
            const fieldNames = item.split(':')[1].split(',');

            parsedFilters[filterName] = fieldNames;
        });

        return parsedFilters;
    }

    private applyQueryFilters(columnFilters: ColumnFilters, values: string[]): ColumnFilters {
        const updatedColumnFilter = lodash.mapValues(columnFilters, () => false);

        values.forEach((item) => (updatedColumnFilter[item] = true));

        return updatedColumnFilter;
    }

    private getLineSerialNumberByLineId(lineId: string): string {
        // we can use activityReferenceBudgetItems because it contains all existing BudgetItems
        const budgetItem = this.activityReferenceBudgetItems.find((budgetItem) => budgetItem.id === lineId);

        return budgetItem ? String(budgetItem.serialNumber) : null;
    }

    private getLineSerialNumbersByActivityId(activityId: string): string[] {
        // we can use activityReferenceBudgetItems because it contains all existing BudgetItems
        const activityLines = this.activityReferenceBudgetItems.filter((item) => item.activity.id == activityId);

        return activityLines.map((item) => this.getLineSerialNumberByLineId(item.id));
    }

    private mergeActivities(newActivities: ActivityBudget[]): ActivityBudget[] {
        const { activityBudgets } = this.getStoreProps();

        const updatedActivityList = lodash.clone(activityBudgets);

        newActivities.forEach((newActivity) => {
            const index = lodash.findIndex(activityBudgets, (item) => item.id == newActivity.id);

            if (index !== -1) {
                updatedActivityList[index] = newActivity;
            }
        });

        return updatedActivityList;
    }

    private mergeBudgetItems(dest: BudgetItem[], source: BudgetItem[], updatedLineIds: string[]): BudgetItem[] {
        const destIds = dest.map((budgetItem) => budgetItem.id);

        return [
            ...dest.filter((budgetItem) => !updatedLineIds.includes(budgetItem.id)),
            ...source.filter((budgetItem) => destIds.includes(budgetItem.id)),
        ];
    }

    private getActivityBudgetByLineId(lineId: string): ActivityBudget {
        const { budgetItems, budgetItemsToIgnoreFilters } = this.getStoreProps();

        const budgetItem = [...budgetItems, ...budgetItemsToIgnoreFilters].find((item) => item.id == lineId);

        return budgetItem ? budgetItem.activity : null;
    }

    private getStoreProps(): StoreProps {
        const storeState = store.getState();

        const { budgetId } = getBudgetByStatusUserConfig(storeState, BudgetStatus.Execution);
        const { activityBudgets, budgetItems, budgetItemsToIgnoreFilters } = getPageData(storeState);
        const {
            columnFilters: { filters },
            computedData: { tableLines },
        } = getBudgetExecutionPageState(storeState);
        const { budgetItems: transitionMenuBudgetItems } = getBudgetTransferMenuState(storeState).data;

        const { budgets } = getBudgetState(storeState, BudgetStatus.Execution);
        const selectedBudget = budgets.find((budget) => budget.id === budgetId);

        return {
            budgetId,
            selectedBudget,
            activityBudgets,
            budgetItems,
            budgetItemsToIgnoreFilters,
            transitionMenuBudgetItems,
            user: getLoginUser(storeState),
            defaultFilters: getDefaultFilters(storeState),
            lines: tableLines,
            filters,
        };
    }
}
