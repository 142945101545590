import { axios } from '../../lib/axios';
import {
    AutopilotTV,
    AutopilotTVMediaplanPlanDataQueryParams,
    AutopilotTVMediaplanPlanDataResponse,
    MediaplanPlanDataTVTotalResponse,
    GetAutopilotTVListParams,
    CreateAutopilotTVParams,
    UpdateAutopilotTVParams,
    UpdateAutopilotTVBriefParams,
    UpdateAutopilotTVBudgetParams,
    AutopilotTVTargetGroup,
    AutopilotTVVideoDuration,
} from 'sber-marketing-types/backend';
import { CheckPermissionService } from './CheckPermissionService';
import { User } from '@store/user';

const BASE_URL = '/api/autopilot-tv';

export class AutopilotTvApi {
    public static async getAutopilot(autopilotId: string, activityId: number): Promise<AutopilotTV> {
        await CheckPermissionService.check(activityId);

        const res = await axios.get(`${BASE_URL}/${autopilotId}`);

        return res.data;
    }

    public static async getMediaplan(
        autopilotId: string,
        activityId: number,
        params: AutopilotTVMediaplanPlanDataQueryParams,
    ): Promise<AutopilotTVMediaplanPlanDataResponse> {
        await CheckPermissionService.check(activityId);

        const res = await axios.get(`${BASE_URL}/${autopilotId}/mediaplan`, { params });

        return res.data;
    }

    public static async getMediaplanTotal(
        autopilotId: string,
        activityId: number,
    ): Promise<MediaplanPlanDataTVTotalResponse> {
        await CheckPermissionService.check(activityId);

        const res = await axios.get(`${BASE_URL}/${autopilotId}/mediaplan-total`);

        return res.data;
    }

    public static async getAutopilotList(params?: GetAutopilotTVListParams): Promise<AutopilotTV[]> {
        if (params !== undefined && params.activityIds !== undefined) {
            await Promise.all(params.activityIds.map((activityId) => CheckPermissionService.check(activityId)));
        }

        const res = await axios.post(`${BASE_URL}/list`, params);

        return res.data;
    }

    public static async createAutopilot(user: User, params: CreateAutopilotTVParams): Promise<void> {
        const roles = ['Autopilot Client'];
        await CheckPermissionService.check(params.activityId, { roles, user });

        const res = await axios.post(BASE_URL, params);

        return res.data;
    }

    public static async updateAutopilot(
        autopilotId: string,
        activityId: number,
        user: User,
        params: UpdateAutopilotTVParams,
    ): Promise<void> {
        const roles = ['Autopilot Client'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.put<void>(`${BASE_URL}/${autopilotId}`, params);
    }

    public static async updateBrief(
        autopilotId: string,
        activityId: number,
        user: User,
        params: UpdateAutopilotTVBriefParams,
    ): Promise<void> {
        const roles = ['Autopilot Client'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.put<void>(`${BASE_URL}/${autopilotId}/brief`, params);
    }

    public static async updateMediaplan(autopilotId: string, activityId: number, user: User): Promise<void> {
        const roles = ['Autopilot Client'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.put<void>(`${BASE_URL}/${autopilotId}/mediaplan`);
    }

    public static async updateBudget(
        autopilotId: string,
        activityId: number,
        user: User,
        params: UpdateAutopilotTVBudgetParams,
    ): Promise<void> {
        const roles = ['Autopilot Client'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.put<void>(`${BASE_URL}/${autopilotId}/budget`, params);
    }

    public static async getTargetGroups(autopilotId: string, activityId: number): Promise<AutopilotTVTargetGroup[]> {
        await CheckPermissionService.check(activityId);

        const res = await axios.get(`${BASE_URL}/${autopilotId}/mediaplan-target-groups`);

        return res.data;
    }

    public static async getVideoDurations(
        autopilotId: string,
        activityId: number,
    ): Promise<AutopilotTVVideoDuration[]> {
        await CheckPermissionService.check(activityId);

        const res = await axios.get(`${BASE_URL}/${autopilotId}/mediaplan-video-durations`);

        return res.data;
    }

    public static async getMediaplanXLS(autopilotId: string, activityId: number): Promise<Blob> {
        await CheckPermissionService.check(activityId);

        const res = await axios.get<Blob>(`${BASE_URL}/${autopilotId}/mediaplan-xls`, { responseType: 'blob' });

        return res.data;
    }
}
