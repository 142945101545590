import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { setCurrentOnboarding } from '@store/onboarding';

export function useCancelOnboarding() {
    const dispatch = useDispatch();

    return useCallback(() => dispatch(setCurrentOnboarding()), [dispatch]);
}
