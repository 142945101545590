import * as React from 'react';
import classNames from 'classnames';
import { Icon, IconType, Skeleton } from 'sber-marketing-ui';

import { FileSearchResult } from '@store/search';

import { DatesFormatter } from '@common/Utils';
import { FileAsset } from '@common/FileAssetRedesign';

import { Activity, Task } from '../WithSkeleton';
import { HighlightQuery } from '../HighlightQuery';

import styles from './FileCard.scss';

interface Props extends FileSearchResult {}

export function FileCard(props: Props): JSX.Element {
    const { id, name, type, activity, task, commentId, originName, createTime } = props;

    const createTimeFormatted = createTime ? DatesFormatter.ddMonthyy(createTime) : null;

    return (
        <div
            className={styles.root}
            {...{
                'qa-id': 'searchFileCard',
                'qa-file-id': id,
                'qa-file-type': type,
            }}
        >
            <div className={styles.extension}>
                <Skeleton
                    isReady={type}
                    customStyle={{
                        borderRadius: '4px',
                        height: '100%',
                    }}
                >
                    <FileAsset
                        id={id}
                        name={name}
                        originName={originName}
                        type={type}
                        activityId={activity ? String(activity.id) : null}
                        taskId={task ? task.id : null}
                        commentId={commentId}
                    />
                </Skeleton>
            </div>

            <div className={styles.content}>
                <div className={styles.row}>
                    <Activity {...activity} />

                    <div
                        className={classNames(styles.text, styles.date)}
                        {...{
                            'qa-id': 'searchFileCardCreateTime',
                            'qa-value': createTimeFormatted,
                        }}
                    >
                        <Skeleton isReady={type}>
                            <Icon svgSize={16} type={IconType.CALENDAR_ICON} className={styles.icon} />
                            {createTimeFormatted}
                        </Skeleton>
                    </div>
                </div>

                <div className={classNames(styles.row, styles.fileCardRow)}>
                    <div className={classNames(styles.text, styles.task)}>
                        <Task {...task} activityId={activity && activity.id} />
                    </div>
                </div>

                <div
                    className={classNames(styles.row, styles.fileCardRow)}
                    {...{
                        'qa-id': 'searchFileCardFileName',
                        'qa-value': originName,
                    }}
                >
                    <Skeleton isReady={originName} className={styles.skeleton}>
                        <div className={styles.fileName}>
                            <HighlightQuery text={originName} />
                        </div>
                    </Skeleton>

                    <Skeleton
                        isReady={originName}
                        className={styles.skeleton}
                        loadingClassName={styles.fileTypeLoading}
                    >
                        <div className={styles.fileType}>{type}</div>
                    </Skeleton>
                </div>
            </div>
        </div>
    );
}
