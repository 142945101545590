import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { UserConfigType } from 'sber-marketing-types/openid';

import { StoreState } from '@store';
import { getActivityTasksPageState } from '@store/activityTasksPage';
import { updateUserConfig, saveUserConfig } from '@store/userConfig';
import { emptyTaskFilter } from '@store/userConfig/dashboard';
import { getActivityTasksUserConfig } from '@store/userConfig/activityTasks';

import {
    ActivityTasksUserConfig,
    LoadActivityTasksUserConfig,
    UpdateActivityTasksUserConfig,
    SaveActivityTasksUserConfigPayload,
} from './types';
import {
    loadActivityTasksUserConfig as loadActivityTasksUserConfigAction,
    updateActivityTasksUserConfig as updateActivityTasksUserConfigAction,
    saveActivityTasksUserConfig as saveActivityTasksUserConfigAction,
} from './actions';
import { addFilterToUserConfig } from './misc';

export const loadActivityTasksUserConfig = bindThunkAction<
    StoreState,
    ActivityTasksUserConfig,
    LoadActivityTasksUserConfig,
    Error
>(loadActivityTasksUserConfigAction, async (receivedUserConfig, dispatch, getState) => {
    const activityId = getActivityTasksPageState(getState()).activityId;

    return {
        receivedUserConfig: addFilterToUserConfig(
            receivedUserConfig,
            activityId,
            receivedUserConfig?.entries?.[activityId]?.filters || emptyTaskFilter(),
        ),
    };
});

export const updateActivityTasksUserConfig = bindThunkAction<StoreState, UpdateActivityTasksUserConfig, void, Error>(
    updateActivityTasksUserConfigAction,
    async (payload, dispatch, getState) => {
        dispatch(updateUserConfig(UserConfigType.ActivityTasks));
    },
);

export const saveActivityTasksUserConfig = bindThunkAction<StoreState, SaveActivityTasksUserConfigPayload, void, Error>(
    saveActivityTasksUserConfigAction,
    async (payload, dispatch, getState) => {
        const { activityId, filters } = payload;

        const existingUserConfigEntries = getActivityTasksUserConfig(getState()).entries;

        dispatch(
            saveUserConfig({
                type: UserConfigType.ActivityTasks,
                payload: {
                    entries: {
                        [activityId]: {
                            updateTime: +new Date(),
                            filters: {
                                ...(existingUserConfigEntries[activityId].filters || {}),
                                ...filters,
                            },
                        },
                    },
                },
            }),
        );
    },
);
