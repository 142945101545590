import { createSelector } from 'reselect';
import { UserConfigType } from 'sber-marketing-types/openid';

import { LoadingStatus } from '@store/commonTypes';
import { UserConfigState } from '@store/userConfig';
import { getUserConfigState } from '../selectors';

import { ActivityTasksUserConfigState, ActivityTasksUserConfig } from './types';

export const getActivityTasksUserConfigState = createSelector(
    getUserConfigState,
    (state: UserConfigState): ActivityTasksUserConfigState => state[UserConfigType.ActivityTasks],
);

export const getActivityTasksUserConfig = createSelector(
    getActivityTasksUserConfigState,
    (state: ActivityTasksUserConfigState): ActivityTasksUserConfig => state.fields,
);

export const getLoadingStatus = createSelector(
    getActivityTasksUserConfigState,
    (state: ActivityTasksUserConfigState): LoadingStatus => state.status,
);
