import { unionBy } from 'lodash';
import { bindThunkAction } from 'typescript-fsa-redux-thunk';

import { StoreState } from '@store';
import { LoadParams, LoadResult, SaveParams, SaveResult } from './types';
import * as actions from './actions/async';
import { PlacementSpecToFormStoreConverter, buildAutopilotCreatives } from './converters';
import { mergeFormStateWithAutopilotCreatives } from './mergers';
import { getForm } from './selectors';
import { getAutopilot } from '../selectors';
import { AutopilotApi } from '@api';

export const load = bindThunkAction<StoreState, LoadParams, LoadResult, Error>(
    actions.load,
    async ({ specId }, _, getState) => {
        const { creatives } = getAutopilot(getState());

        const placementSpec = await AutopilotApi.getCreativeSpecs(specId);

        const formState = await PlacementSpecToFormStoreConverter.convert(placementSpec);
        const mergedFormState = mergeFormStateWithAutopilotCreatives(formState, creatives, specId);

        return {
            form: mergedFormState,
        };
    },
);

export const save = bindThunkAction<StoreState, SaveParams, SaveResult, Error>(
    actions.save,
    async ({ autopilotId, placementId }, _, getState) => {
        const form = getForm(getState());
        const { creatives } = getAutopilot(getState());

        const typeCreatives = await buildAutopilotCreatives({ autopilotId, placementId, form });
        const params = {
            creativeGroups: unionBy(typeCreatives.creativeGroups, creatives?.creativeGroups, 'engine'),
        };
        await AutopilotApi.updateCreative(autopilotId, params);
        return params;
    },
);
