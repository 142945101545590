import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { PlainDictionary } from '@mrm/dictionary';
import { uniq, uniqBy } from 'lodash';

import { LoadingStatus } from '@store/commonTypes';

import * as actions from './actions';

import { ProductsState as State, SetStoreIdsParams, SetLoadingStatusParams, StoreTypes, EntitiesStore } from './types';

class Reducer {
    public static emptyState(): State {
        return {
            entities: [],
            byIds: {},
            stores: {
                [StoreTypes.GENERAL]: Reducer.emptyEntitiesStore(),
                [StoreTypes.AVAILABLE_ACTIVITIES_FILTER]: Reducer.emptyEntitiesStore(),
                [StoreTypes.MY_ACTIVITIES_FILTER]: Reducer.emptyEntitiesStore(),
            },
        };
    }

    public static loadEntities(state: State, products: PlainDictionary[]): State {
        const entities = uniqBy([...state.entities, ...products], (product) => product.id);

        const byIds = products.reduce(
            (acc, product) => ({
                ...acc,
                [product.id]: product,
            }),
            state.byIds,
        );

        return {
            ...state,
            entities,
            byIds,
        };
    }

    public static setStoreIds(state: State, payload: SetStoreIdsParams): State {
        const { store, ids } = payload;

        return Reducer.entitiesStoreReducer(state, store, (entitiesStore) => ({
            ...entitiesStore,
            ids: uniq([...entitiesStore.ids, ...ids]),
        }));
    }

    public static setLoadingStatus(state: State, payload: SetLoadingStatusParams): State {
        const { store, status } = payload;

        return Reducer.entitiesStoreReducer(state, store, (entitiesStore) => ({
            ...entitiesStore,
            loadingStatus: status,
        }));
    }

    public static resetStore(state: State, store: StoreTypes): State {
        return Reducer.entitiesStoreReducer(state, store, (_) => Reducer.emptyEntitiesStore());
    }

    public static incFetchersCount(state: State, store: StoreTypes): State {
        return Reducer.entitiesStoreReducer(state, store, (entitiesStore) => ({
            ...entitiesStore,
            fetchersCount: entitiesStore.fetchersCount + 1,
        }));
    }

    public static decFetchersCount(state: State, store: StoreTypes): State {
        return Reducer.entitiesStoreReducer(state, store, (entitiesStore) => ({
            ...entitiesStore,
            fetchersCount: entitiesStore.fetchersCount - 1,
        }));
    }

    private static entitiesStoreReducer(
        state: State,
        store: StoreTypes,
        entitiesStoreReducer: (state: EntitiesStore) => EntitiesStore,
    ): State {
        return {
            ...state,
            stores: {
                ...state.stores,
                [store]: entitiesStoreReducer(state.stores[store]),
            },
        };
    }

    private static emptyEntitiesStore(): EntitiesStore {
        return {
            loadingStatus: LoadingStatus.NOT_LOADED,
            ids: [],
            fetchersCount: 0,
        };
    }
}

export const productsReducer = reducerWithInitialState(Reducer.emptyState())
    .case(actions.loadEntities, Reducer.loadEntities)
    .case(actions.setStoreIds, Reducer.setStoreIds)
    .case(actions.setLoadingStatus, Reducer.setLoadingStatus)
    .case(actions.resetStore, Reducer.resetStore)
    .case(actions.incFetchersCount, Reducer.incFetchersCount)
    .case(actions.decFetchersCount, Reducer.decFetchersCount);
