import { ReactChild } from 'react';

export interface CommonState {
    isRequestInProgress: boolean;
    errorCode: string;
    errorMessage: string;
    pageOptions: { [path: string]: PageOptions };
    currentPath?: string;
    previousPath?: string;
    headerHeight: number;
    disableRequestErrors?: boolean;
    notificationList?: { [key: string]: NotificationMessage };
    filesToUploadStatus: {
        [fileName: string]: FileUploadStatus;
    };
}

export const PageOptionsStub: PageOptions = {
    withoutNavMenu: true,
    withoutFooter: true,
    withoutHeader: true,
};

export interface PageOptions {
    previousUrl?: string;
    previousLabel?: string;
    currentLabel?: string;
    withoutHeader?: boolean;
    withoutFooter: boolean;
    withoutNavMenu?: boolean;
    withoutDownPadding?: boolean;
    withoutSearch?: boolean;
}

export interface NotificationMessage {
    type: NotificationType;
    typeAction: NotificationActionType;
    comment?: ReactChild;
}

export enum NotificationActionType {
    CHANGE_SAVE_ON_EXECUTION_BUDGET_PAGE = 'changeSavedOnExecutionBudgetPage',
    ACTIVITY_CREATED_ON_EXECUTION_BUDGET_PAGE = 'activityCreatedOnExecutionBudgetPage',
    CHANGE_SAVE_ON_PLANNED_BUDGET_PAGE = 'changeSavedOnPlannedBudgetPage',
    SAVING_CORRECTION_ON_BUDGET_CORRECTION_PAGE = 'savingCorrectionOnBudgetCorrectionPage',
    CHANGES_SENT_TO_APPROVAL_ON_BUDGET_EXECUTION_PAGE = 'changesSentToApprovalOnBudgetExecutionPage',
    TABLE_VALIDATION_ON_BUDGET_EXECUTION_PAGE = 'tableValidationOnBudgetExecutionPage',
    DATA_IS_NOT_ACTIAL_ON_BUDGET_EXECUTION_PAGE = 'dataIsNotActialOnBudgetExecutionPage',
    CHANGE_REFERENCE_OF_ACTIVITY_IN_REFERENCE_MENU = 'ChangeReferenceOfActivityInReferenceMenu',
    CREATE_PLANNED_BUDGET_IN_TRANSFER_MENU_BUDGET = 'createPlannedBudgetInTransferMenuBudget',
    PLANNED_BUDGET_SEND_TO_APPROVEMENT = 'plannedBudgetSendToApprovement',
    SEND_TERM_ON_BUDGET_PLANNING_PAGE = 'sendTermOnBudgetPlanningPage',
    SAVE_AND_PUBLISH_ON_BUDGET_PLANNING_PAGE = 'saveAndPlanningOnBudgetPlanningPage',
    TABLE_VALIDATION_ON_BUDGET_PLANING_PAGE = 'tableValidationOnBudgetPlaningPage',
    BUDGET_PLANNING_LINE_UPDATE = 'budgetPlanningLineUpdate',
    BRIEF_COPIED_ON_BRIEF_PAGE = 'briefСopiedOnBriefPage',
    PLAN_EDIT_PAGE_ACTIVITY_CREATE = 'planEditPageActivityCreate',
    PLAN_EDIT_PAGE_ACTIVITY_UPDATE = 'planEditPageActivityUpdate',
    PLAN_EDIT_PAGE_BUDGET_ITEM_CREATE = 'planEditPageBudgetItemCreate',
    PLAN_EDIT_PAGE_BUDGET_ITEM_UPDATE = 'planEditPageBudgetItemUpdate',
    PLAN_EDIT_PAGE_BUDGET_ITEM_DELETE = 'planEditPageBudgetItemDelete',
    TELEGRAM_PLUGGED = 'telegramPlugged',
    TELEGRAM_UNPLUGGED = 'telegramUnplugged',
    XLSX_IMPORT_SUCCESSFUL = 'xlsxImportSuccessful',
    VACATION_EDIT_SUCCESSFULL = 'vacationEditSuccessfull',
    COMMENT_ASSETS_SEND = 'commentAssetsSend',
    NOTIFICATIONS_EDIT_SUCCESSFULL = 'notificationsEditSuccessfull',
    FMP_TABLE_WAS_EXPORTED = 'fmpTableWasExported',
}

export enum NotificationType {
    SUCCESS = 'Success',
    ERROR = 'Error',
    NEUTRAL = 'Neutral',
}

export enum FileUploadStatus {
    ReadyToUpload,
    Uploaded,
    UploadError,
    VirusFound,
}

export interface SetFileUploadStatusPayload {
    fileName: string;
    status: FileUploadStatus;
}
