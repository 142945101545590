import * as React from 'react';
import * as moment from 'moment';
import { sanitize } from 'dompurify';

import styles from './ReleaseNotesPopup.scss';

import { ReleaseNote } from '../ReleaseNotesPage';

import { Popup, CustomScrollbar, Icon, IconType } from 'sber-marketing-ui';

interface Props {
    onCloseButtonClick: () => void;
    messages: ReleaseNote[];
}

const ReleaseNotesPopup = ({ onCloseButtonClick, messages }: Props) => {
    return (
        <Popup>
            <div className={styles.root}>
                <CustomScrollbar fixedHeight={500} hideHorizontalScrollbar hideTracksWhenNotNeeded>
                    {messages.map((item) => (
                        <div className={styles.messageWrapper} key={item.date}>
                            <div className={styles.date}>{formatDate(item.date)}</div>

                            <div
                                className={styles.message}
                                dangerouslySetInnerHTML={{
                                    __html: sanitize(formatMessage(item.message), {
                                        ALLOWED_TAGS: ['br', 'b', 'img'],
                                    }),
                                }}
                            />
                        </div>
                    ))}
                </CustomScrollbar>

                <div onClick={onCloseButtonClick} className={styles.closeButton}>
                    <Icon type={IconType.CROSS} svgSize={14} />
                </div>
            </div>
        </Popup>
    );
};

function formatDate(date: string) {
    moment.locale('ru');
    return moment(date, 'YYYY-MM-DD').format('D MMMM');
}

function formatMessage(message: string) {
    return message.replace(/\n/g, '<br />');
}

export { ReleaseNotesPopup };
