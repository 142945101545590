import { flatten, groupBy, map, values } from 'lodash';

import type { Spec } from '@store/autopilot/creativeForm/types';
import type { TypeItemCreative } from 'sber-marketing-types/backend';

import { buildProps } from '../buildProps';

interface Builder {
    (params: Params): Promise<TypeItemCreative[]>;
}

interface Params {
    formSpecs: Spec[];
    autopilotId: string;
    creativeGroupSpecId: string;
}

export const buildCreatives: Builder = async ({
    formSpecs,
    autopilotId,
    creativeGroupSpecId,
}): Promise<TypeItemCreative[]> => {
    const groupedFields = groupBy(
        flatten(formSpecs.map(({ fields }) => values(fields))),
        (field) => field.meta.creativeSpecId,
    );

    return await Promise.all(
        map(groupedFields, async (fields, creativeSpecId) => ({
            specId: creativeSpecId,
            globSpecId: creativeGroupSpecId,
            props: await buildProps({ fields, autopilotId }),
        })),
    );
};
