import { User } from '@store/user';
import { axios } from '../../lib/axios';
import { CheckPermissionService } from './CheckPermissionService';
import {
    Autopilot2,
    Autopilot2MediaplanPlanDataResponse,
    IAutopilotAdsItem,
    GetAutopilot2ListParams,
    CreateAutopilot2Params,
    UpdateAutopilot2Params,
    UpdateAutopilot2BriefParams,
    UpdateAutopilot2MediaplanSettingsParams,
    UpdateAutopilot2BudgetParams,
    UpdateAutopilot2SocialParams,
    UpdateAutopilot2ContextParams,
    UpdateAutopilot2ProgrammaticParams,
    Autopilot2MediaplanChannelsGroups,
    UpdateAutopilot2DashboardParams,
    UpdateAutopilot2PlacementParams,
    LaunchAutopilotCampaignParams,
    StopAutopilotCampaignParams,
    TradeProduct,
    ChatGPTResponse,
    ContextChatGPT,
    ILPlacementSpec,
    TypeCreative,
    TypeRefrenceItem,
    TypeRefrenceItemParams,
} from 'sber-marketing-types/backend';

const BASE_URL = '/api/autopilot/v2';

export class AutopilotApi {
    public static async getAutopilot(autopilotId: string, activityId: number): Promise<Autopilot2> {
        await CheckPermissionService.check(activityId);

        const res = await axios.get(`${BASE_URL}/${autopilotId}`);

        return res.data;
    }

    public static async getMediaplan(
        autopilotId: string,
        activityId: number,
    ): Promise<Autopilot2MediaplanPlanDataResponse> {
        await CheckPermissionService.check(activityId);

        const res = await axios.get(`${BASE_URL}/${autopilotId}/mediaplan`);

        return res.data;
    }

    public static async getVariationsProduct({
        autopilotId,
        activityId,
        productId,
    }: {
        autopilotId: string;
        activityId: number;
        productId: string;
    }): Promise<TradeProduct> {
        await CheckPermissionService.check(activityId);

        const res = await axios.get(`${BASE_URL}/${autopilotId}/products/${productId}`);
        return res.data;
    }

    public static async getAutopilotList(params?: GetAutopilot2ListParams): Promise<Autopilot2[]> {
        if (params !== undefined && params.activityIds !== undefined) {
            await Promise.all(params.activityIds.map((activityId) => CheckPermissionService.check(activityId)));
        }

        const res = await axios.post(`${BASE_URL}/list`, params);
        return res.data;
    }

    public static async generateAds({
        autopilotId,
        activityId,
        productId,
        params,
    }: {
        autopilotId: string;
        activityId: number;
        productId: string;
        params: ContextChatGPT;
    }): Promise<ChatGPTResponse> {
        await CheckPermissionService.check(activityId);

        const res = await axios.post(`${BASE_URL}/${autopilotId}/ads/${productId}`, params);
        return res.data;
    }

    public static async getMediaplanXLS(autopilotId: string, activityId: number): Promise<Blob> {
        await CheckPermissionService.check(activityId);

        const res = await axios.get<Blob>(`${BASE_URL}/${autopilotId}/mediaplan-xls`, { responseType: 'blob' });

        return res.data;
    }

    public static async createAutopilot(user: User, params: CreateAutopilot2Params): Promise<void> {
        const roles = ['Autopilot Client', 'Autopilot Client Service'];
        await CheckPermissionService.check(params.activityId, { roles, user });

        const res = await axios.post(BASE_URL, params);

        return res.data;
    }

    public static async updateAutopilot(
        autopilotId: string,
        activityId: number,
        user: User,
        params: UpdateAutopilot2Params,
    ): Promise<void> {
        const roles = ['Autopilot Client', 'Autopilot Client Service'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.put<void>(`${BASE_URL}/${autopilotId}`, params);
    }

    public static async updateBrief(
        autopilotId: string,
        activityId: number,
        user: User,
        params: UpdateAutopilot2BriefParams,
    ): Promise<void> {
        const roles = ['Autopilot Client', 'Autopilot Client Service'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.put<void>(`${BASE_URL}/${autopilotId}/brief`, params);
    }

    public static async updateMediaplanSettings(
        autopilotId: string,
        activityId: number,
        user: User,
        params: UpdateAutopilot2MediaplanSettingsParams,
    ): Promise<void> {
        const roles = ['Autopilot Client'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.put<void>(`${BASE_URL}/${autopilotId}/mediaplan-settings`, params);
    }

    public static async updateMediaplan(autopilotId: string, activityId: number, user: User): Promise<void> {
        const roles = ['Autopilot Client', 'Autopilot Client Service'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.put<void>(`${BASE_URL}/${autopilotId}/mediaplan`);
    }

    public static async updateBudget(
        autopilotId: string,
        activityId: number,
        user: User,
        params: UpdateAutopilot2BudgetParams,
    ): Promise<void> {
        const roles = ['Autopilot Client'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.put<void>(`${BASE_URL}/${autopilotId}/budget`, params);
    }

    public static async updateSocial(
        autopilotId: string,
        activityId: number,
        user: User,
        params: UpdateAutopilot2SocialParams,
    ): Promise<void> {
        const roles = ['Autopilot Client'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.put<void>(`${BASE_URL}/${autopilotId}/social`, params);
    }

    public static async updateContext(
        autopilotId: string,
        activityId: number,
        user: User,
        params: UpdateAutopilot2ContextParams,
    ): Promise<void> {
        const roles = ['Autopilot Client'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.put<void>(`${BASE_URL}/${autopilotId}/context`, params);
    }

    public static async updateCreative(autopilotId: string, params: TypeCreative): Promise<void> {
        await axios.put<void>(`${BASE_URL}/${autopilotId}/creative`, params);
    }

    public static async saveAds(
        autopilotId: string,
        activityId: number,
        user: User,
        params: IAutopilotAdsItem[],
    ): Promise<void> {
        const roles = ['Autopilot Client'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.put<void>(`${BASE_URL}/${autopilotId}/ads`, params);
    }

    public static async updateProgrammatic(
        autopilotId: string,
        activityId: number,
        user: User,
        params: UpdateAutopilot2ProgrammaticParams,
    ): Promise<void> {
        const roles = ['Autopilot Client'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.put<void>(`${BASE_URL}/${autopilotId}/programmatic`, params);
    }

    public static async getChannelsGroups(
        autopilotId: string,
        activityId: number,
    ): Promise<Autopilot2MediaplanChannelsGroups> {
        await CheckPermissionService.check(activityId);

        const res = await axios.get<Autopilot2MediaplanChannelsGroups>(
            `${BASE_URL}/${autopilotId}/mediaplan-channels-groups`,
        );

        return res.data;
    }

    public static async getSpecReferences(refId: string, params: TypeRefrenceItemParams): Promise<TypeRefrenceItem[]> {
        const res = await axios.get<TypeRefrenceItem[]>(`${BASE_URL}/creative/${refId}/references`, { params });
        return res.data;
    }

    public static async getDownloadLink(fileId: string, activityId: number): Promise<void> {
        await CheckPermissionService.check(activityId);

        await axios.post<void>(`${BASE_URL}/file/${fileId}`);
    }

    public static async getCreativeSpecs(specId: string): Promise<ILPlacementSpec> {
        const res = await axios.get<ILPlacementSpec>(`${BASE_URL}/creative/${specId}/specs`);
        return res.data;
    }

    public static async updateDashboard(
        autopilotId: string,
        activityId: number,
        user: User,
        params: UpdateAutopilot2DashboardParams,
    ): Promise<void> {
        const roles = ['Autopilot Client Service'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.put<void>(`${BASE_URL}/${autopilotId}/dashboard`, params);
    }

    public static async updatePlacement(
        autopilotId: string,
        activityId: number,
        user: User,
        params: UpdateAutopilot2PlacementParams,
    ): Promise<void> {
        const roles = ['Autopilot Client'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.put<void>(`${BASE_URL}/${autopilotId}/placement`, params);
    }

    public static async launch(
        autopilotId: string,
        activityId: number,
        user: User,
        params: LaunchAutopilotCampaignParams,
    ): Promise<void> {
        const roles = ['Autopilot Client', 'Autopilot Client Service'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.post<void>(`${BASE_URL}/${autopilotId}/launch`, params);
    }

    public static async stop(
        autopilotId: string,
        activityId: number,
        user: User,
        params?: StopAutopilotCampaignParams,
    ): Promise<void> {
        const roles = ['Autopilot Client', 'Autopilot Client Service'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.post<void>(`${BASE_URL}/${autopilotId}/stop`, params);
    }
}
