import { actionCreatorFactory } from 'typescript-fsa';

import {
    InitPayload,
    TaskEditorValues,
    UserWorkTypeData,
    ActivityStagesData,
    Permissions,
    TaskEditorMisc,
} from '../types';

const actionCreator = actionCreatorFactory('TASK_EDITOR_ASYNC').async;

export const initState = actionCreator<InitPayload, void, Error>('INIT_STATE');
export const initEditorValues = actionCreator<InitPayload, TaskEditorValues, Error>('INIT_EDITOR_VALUES');
export const initActivityStages = actionCreator<InitPayload, Partial<ActivityStagesData>, Error>(
    'INIT_ACTIVITY_STAGES',
);
export const initWorkTypeUserData = actionCreator<InitPayload, Partial<UserWorkTypeData>, Error>(
    'INIT_WORK_TYPE_USER_DATA',
);
export const initPermissions = actionCreator<InitPayload, Partial<Permissions>, Error>('INIT_PERMISSIONS');
export const initMisc = actionCreator<InitPayload, TaskEditorMisc, Error>('TASK_EDITOR_MISC');

export const saveTask = actionCreator<null, void, Error>('SAVE_TASK');
