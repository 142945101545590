import * as React from 'react';

import { SearchEntities } from '@store/search';

import { CommonFormatter } from '@common/Utils';

import { ActivityCard, TaskCard, CommentCard, FileCard } from './entitiesCards';

import styles from './Group.scss';

interface Props {
    entityType: SearchEntities;
    showCollapseButton: boolean;
    isLoading: boolean;
    title: string;
    totalCount: number;
    isExpanded: boolean;
    isAnimated: boolean;
    entities: { id: string }[];
    onToggleCollapseStateButtonClick: () => void;
}

export function Group({
    entityType,
    showCollapseButton,
    entities,
    isLoading,
    title,
    totalCount,
    isExpanded,
    isAnimated,
    onToggleCollapseStateButtonClick,
}: Props): JSX.Element {
    return (
        <div>
            <div className={styles.header}>
                <div className={styles.headerLeftColumn}>
                    <span className={styles.title}>{title}</span>

                    {!isLoading && (
                        <span className={styles.totalCount}>{CommonFormatter.splitNumberByScales(totalCount)}</span>
                    )}
                </div>

                {!showCollapseButton && (
                    <div
                        className={styles.toggleCollapseButton}
                        onClick={onToggleCollapseStateButtonClick}
                        {...{
                            'qa-id': 'searchMenuItemGroupCollapseButton',
                        }}
                    >
                        {isExpanded ? 'Свернуть' : 'Развернуть'}
                    </div>
                )}
            </div>

            <div className={styles.cards}>
                <ItemsList
                    isLoading={isLoading}
                    isExpanded={isExpanded}
                    isAnimated={isAnimated}
                    entities={entities}
                    entityType={entityType}
                />
            </div>
        </div>
    );
}

type ItemsListProps = Pick<Props, 'isLoading' | 'isExpanded' | 'isAnimated' | 'entities' | 'entityType'>;

function ItemsList({ isLoading, isExpanded, isAnimated, entities, entityType }: ItemsListProps): JSX.Element {
    let CardRenderer: (props: any) => JSX.Element;
    switch (entityType) {
        case SearchEntities.Activities:
            CardRenderer = ActivityCard;
            break;
        case SearchEntities.Tasks:
            CardRenderer = TaskCard;
            break;
        case SearchEntities.Comments:
            CardRenderer = CommentCard as any;
            break;
        case SearchEntities.Files:
            CardRenderer = FileCard;
            break;
    }
    const entitiesToDisplay = isExpanded && !isAnimated ? entities : entities.slice(0, 3);
    const showSkeletonCard = isExpanded ? isLoading : isLoading && !entitiesToDisplay.length;

    return (
        <React.Fragment>
            {entitiesToDisplay.map((entity) => (
                <div key={entity.id} className={styles.card}>
                    <CardRenderer {...entity} />
                </div>
            ))}

            {showSkeletonCard && (
                <div className={styles.card}>
                    <CardRenderer />
                </div>
            )}
        </React.Fragment>
    );
}
