import { createSelector } from 'reselect';
import { uniq, compact, isNil } from 'lodash';
import { get } from 'lodash/fp';

import { StoreState } from '@store';
import { ActivityTasksUserConfig, getActivityTasksUserConfig } from '@store/userConfig/activityTasks';
import { LoadingStatus } from '@store/commonTypes';
import type { RestActivityPageState } from './types';

export const getActivityTasksPageState = (state: StoreState): RestActivityPageState =>
    state.activityTasksPage.restState;

export const getActivityLoadingStatus = createSelector(
    getActivityTasksPageState,
    (state): LoadingStatus => state.loadingStatus.activity,
);

export const getActivity = createSelector(getActivityTasksPageState, get('activity'));
export const getTaskFilters = createSelector(getActivityTasksPageState, get('filters'));

export const getActivityTasksFilterCount = createSelector(
    (state: StoreState) => getActivity(state)?.id,
    getActivityTasksUserConfig,
    (activityId: number, userConfig: ActivityTasksUserConfig): number => {
        const filters = userConfig.entries[activityId]?.filters;

        if (!filters) {
            return 0;
        }

        const { status, participation, author, executor, department, workType } = filters;

        let count = 0;

        if (!isNil(status)) {
            count++;
        }

        if (!isNil(participation)) {
            count++;
        }

        if (author.length) {
            count++;
        }

        if (executor.length) {
            count++;
        }

        if (department.length) {
            count++;
        }

        if (workType.length) {
            count++;
        }

        return count;
    },
);

export const getActivityParticipants = createSelector(getActivityTasksPageState, (state) => {
    const {
        activity: { authorId, responsibleId, participantIds },
    } = state;

    return compact(uniq([authorId, responsibleId, ...participantIds]));
});
