import * as React from 'react';
import { Link } from 'react-router-dom';
import { TaskProps } from '@mrm/search';
import { Skeleton } from 'sber-marketing-ui';

import styles from '../CommonStyles.scss';

interface Props extends TaskProps {
    activityId: number;
}

export function Task(props: Props): JSX.Element {
    const { id, name, activityId } = props;

    const isReady = id !== undefined;

    return (
        <Skeleton isReady={isReady} className={styles.skeleton} loadingClassName={styles.taskLoading}>
            <TaskContent id={id} name={name} activityId={activityId} />
        </Skeleton>
    );
}

interface TaskContentProps {
    id: string;
    name: string;
    activityId: number;
}

function TaskContent({ activityId, id, name }: TaskContentProps): JSX.Element {
    const taskName = name || 'Не задана';

    return (
        <div
            title={taskName}
            className={styles.text}
            {...{
                'qa-id': 'searchCardTask',
                'qa-value': name,
            }}
        >
            Задача:&nbsp;
            <Link to={`/activity/${activityId}/task/${id}`} target="_blank" className={styles.link}>
                {taskName}
            </Link>
        </div>
    );
}
