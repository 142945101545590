/** Type of target where asset attached to */
export const enum FileAssetTarget {
    /** File asset */
    FILE = 'file',
    /** Task asset */
    TASK = 'task',
    /** Commentary asset */
    COMMENTARY = 'commentary',
    /** Brief asset */
    BRIEF = 'brief',
    AUTOPILOT = 'autopilot',
}

/** File asset */
export interface FileAsset {
    id?: string;
    /** System name */
    name: string;
    /** Original name */
    originName: string;
    /** Creation date */
    createdAt: number;
    /** Type of file */
    type: string;
    /** Type of target - entity where asset was attached */
    targetType: FileAssetTarget;
    /** Identifier of target */
    targetId: string;
    /** Identifier of target's parent (optional, for task comment's and brief's fields) */
    parentTargetId?: string;
    /** Size of file */
    size: number;
    /** Type of storage */
    storage?: string;
    containerName?: string;
    /** Identifier of user, who uploaded this file */
    uploadedBy?: string;
    /** File data */
    file?: File;
    /** File preview URL (for images only) */
    previewUrl?: string;
    /** File full size URL (for images only) */
    fullSizeUrl?: string;
    /** Is file loading in progress */
    isLoading?: boolean;
    /** File asset loading progress in percents */
    loadingProgress?: number;
}
