import { groupBy, map } from 'lodash';

import type { Spec, Field } from '@store/autopilot/creativeForm/types';
import type { TypeItemCreativeGroups } from 'sber-marketing-types/backend';

import { buildCreatives } from '../buildCreatives';
import { buildProps } from '../buildProps';

interface Builder {
    (params: Params): Promise<TypeItemCreativeGroups[]>;
}

interface Params {
    formFields: Field[];
    formSpecs: Spec[];
    autopilotId: string;
    placementId: string;
}

export const buildCreativeGroups: Builder = async ({
    formSpecs,
    formFields,
    autopilotId,
    placementId,
}): Promise<TypeItemCreativeGroups[]> => {
    const groupedSpecs = groupBy(formSpecs, (spec) => spec.meta.creativeGroupSpecId);

    return await Promise.all(
        map(groupedSpecs, async (specs, creativeGroupSpecId) => ({
            creatives: await buildCreatives({
                formSpecs,
                autopilotId,
                creativeGroupSpecId,
            }),
            fields: await buildProps({
                fields: formFields,
                autopilotId,
            }),
            engine: placementId,
        })),
    );
};
