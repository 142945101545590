import { createSelector } from 'reselect';
import { values, keys, groupBy } from 'lodash';

import type { State, Form, Field, Spec } from './types';
import { LoadingStatus } from './types';

import type { StoreState } from '@store';

import { validateFormFieldsError, validateFormFieldsRequired } from './validators';

export const getCreativeFormState = (state: StoreState): State => state.autopilot.creativeFormState;

export const getForm = createSelector(getCreativeFormState, (state): Form => {
    return state.form;
});

export const getLoading = createSelector(getCreativeFormState, (state): LoadingStatus => {
    return state.loading;
});

export const getFormFields = createSelector(getForm, (form): Field[] => {
    return values(form.fields || null);
});

export const getFormSpecs = createSelector(getForm, (form): Spec[] => {
    return values(form.specs || []);
});

export const getSelectedFormSpecId = createSelector(getForm, (form: Form): string => {
    return form.selectedSpecId;
});

export const getSelectedFormSpec = createSelector(
    getFormSpecs,
    getSelectedFormSpecId,
    (formSpecs, selectedSpecId): Spec => {
        return formSpecs.find(({ meta: { creativeGroupSpecId } }) => creativeGroupSpecId === selectedSpecId);
    },
);

export const getSelectedFormSpecCreatives = createSelector(getSelectedFormSpec, (selectedFormSpec) => {
    const fieldsGroupByCreativeId = groupBy(
        values(selectedFormSpec?.fields || []),
        ({ meta: { creativeSpecId } }) => creativeSpecId,
    );
    return keys(fieldsGroupByCreativeId).map((creativeId) => ({
        id: creativeId,
        groupCreativeSpecId: selectedFormSpec.id,
        fields: fieldsGroupByCreativeId[creativeId],
    }));
});

export const isValidFormSpecsFields = createSelector(getSelectedFormSpec, (selectedFormSpec): boolean => {
    return (
        !!selectedFormSpec &&
        values(selectedFormSpec?.fields || []).every(
            (field) => validateFormFieldsError(field) && validateFormFieldsRequired(field),
        )
    );
});

export const isValidFormFields = createSelector(getForm, (form): boolean => {
    return values(form?.fields || []).every(
        (field) => validateFormFieldsError(field) && validateFormFieldsRequired(field),
    );
});

export const isValidForm = createSelector(
    isValidFormSpecsFields,
    isValidFormFields,
    (isValidFormSpecsFields, isValidFormFields): boolean => {
        return isValidFormSpecsFields && isValidFormFields;
    },
);
