import type {
    UserCardListResponse,
    UsersCardQuery,
    UserResponseParams,
    UserRelatedOrganizations,
} from 'sber-marketing-types/frontend';

import { UserListFilters } from '../UserApi';

import { IDPathParams, rtkApi } from './rtkApi';

export interface UserVacation {
    start: string;
    end: string;
    comment: string;
    userId: number;
}

export const userApi = rtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getUserCards: builder.query<UserCardListResponse, UserListFilters & UsersCardQuery>({
            query: (params) => ({
                url: '/user/cards',
                params,
            }),
            providesTags: ({ users }) => users.map(({ id }) => ({ type: 'user', id })),
        }),
        getUser: builder.query<UserResponseParams, IDPathParams<number>>({
            query: ({ id }) => `/user/${id}`,
            providesTags: ({ id }) => [{ type: 'user', id }],
        }),
        getUserVacation: builder.query<UserVacation, IDPathParams<number>>({
            query: ({ id }) => `/user-vacation/${id}`,
            providesTags: ({ userId }) => [{ type: 'user-vacation', id: userId }],
        }),
        getUserOrganizations: builder.query<UserRelatedOrganizations, number>({
            query: (userId) => `/user/${userId}/organizations`,
        }),
    }),
});

export const { useGetUserCardsQuery, useGetUserQuery, useGetUserVacationQuery, useGetUserOrganizationsQuery } = userApi;
