import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { v4 as uuidv4 } from 'uuid';
import { keyBy, head, chunk, values, flatten } from 'lodash';

import { StoreState } from '@store';
import * as actions from './actions/async';
import {
    getFeatures,
    getTextData,
    getTextDataTitles,
    getTextDataContents,
    getSelectedTextContents,
    getSelectedTextTitles,
} from './selectors';
import {
    LoadFeaturesParams,
    LoadFeaturesResult,
    LoadTextsParams,
    LoadTextsResult,
    UpdateTextTitleParams,
    UpdateTextTitleResult,
    UpdateTextContentParams,
    UpdateTextContentResult,
    SaveParams,
} from './types';
import { convertResponseToStore } from './converters';
import { requestGeneratedTexts } from './requesters';
import { TEXT_OPTIONS_LENGTH } from './const';
import { AutopilotApi } from '@api';
import { getLoginUser } from '@store/user';

export const loadFeatures = bindThunkAction<StoreState, LoadFeaturesParams, LoadFeaturesResult, Error>(
    actions.loadFeatures,
    async ({ autopilotId, activityId, productId }) => {
        const response = await AutopilotApi.getVariationsProduct({ autopilotId, productId, activityId });
        return {
            loadedFeaturesMap: keyBy(
                response.trades.map((trade) => ({ id: uuidv4(), value: trade })),
                'id',
            ),
        };
    },
);

export const loadTexts = bindThunkAction<StoreState, LoadTextsParams, LoadTextsResult, Error>(
    actions.loadTexts,
    async ({ autopilotId, activityId, productId }, _, getState) => {
        const features = getFeatures(getState());
        const context = features.map(({ value }) => value).join(' ');
        const response = await requestGeneratedTexts({ autopilotId, activityId, productId, params: { context } });

        const formTextData = convertResponseToStore(response);

        const [titlesMap, ...titlesDataMap] = chunk(values(formTextData.titles), TEXT_OPTIONS_LENGTH);
        const [contentsMap, ...contentsDataMap] = chunk(values(formTextData.contents), TEXT_OPTIONS_LENGTH);

        return {
            options: {
                titles: keyBy(
                    (titlesMap || []).map(({ id, value }) => ({ id, value, selected: false, disabled: false })),
                    'id',
                ),
                contents: keyBy(
                    (contentsMap || []).map(({ id, value }) => ({ id, value, selected: false, disabled: false })),
                    'id',
                ),
            },
            data: {
                titles: keyBy(flatten(titlesDataMap), 'id'),
                contents: keyBy(flatten(contentsDataMap), 'id'),
            },
        };
    },
);

export const updateTextTitle = bindThunkAction<StoreState, UpdateTextTitleParams, UpdateTextTitleResult, Error>(
    actions.updateTextTitle,
    async ({ autopilotId, productId, activityId, titleId }, _, getState) => {
        const state = getState();
        const textDataTitles = getTextDataTitles(state);

        if (textDataTitles.length <= 1) {
            const updatedTextTitle = head(textDataTitles);

            const features = getFeatures(state);
            const context = features.map(({ value }) => value).join(' ');
            const response = await requestGeneratedTexts({ autopilotId, productId, activityId, params: { context } });

            const textData = convertResponseToStore(response);

            return {
                updatedTextTitle: {
                    id: titleId,
                    value: updatedTextTitle.value,
                },
                removedTextDataTitleId: updatedTextTitle.id,
                textData,
            };
        } else {
            const updatedTextTitle = head(textDataTitles);

            return {
                updatedTextTitle: {
                    id: titleId,
                    value: updatedTextTitle.value,
                },
                removedTextDataTitleId: updatedTextTitle.id,
                textData: getTextData(state),
            };
        }
    },
);

export const updateTextContent = bindThunkAction<StoreState, UpdateTextContentParams, UpdateTextContentResult, Error>(
    actions.updateTextContent,
    async ({ autopilotId, activityId, productId, contentId }, _, getState) => {
        const state = getState();
        const textDataContents = getTextDataContents(state);

        if (textDataContents.length <= 1) {
            const updatedTextContent = head(textDataContents);

            const features = getFeatures(state);
            const context = features.map(({ value }) => value).join(' ');
            const response = await requestGeneratedTexts({ autopilotId, productId, activityId, params: { context } });

            const textData = convertResponseToStore(response);

            return {
                updatedTextContent: {
                    id: contentId,
                    value: updatedTextContent.value,
                },
                removedTextDataContentId: updatedTextContent.id,
                textData,
            };
        } else {
            const updatedTextContent = head(textDataContents);

            return {
                updatedTextContent: {
                    id: contentId,
                    value: updatedTextContent.value,
                },
                removedTextDataContentId: updatedTextContent.id,
                textData: getTextData(state),
            };
        }
    },
);

export const save = bindThunkAction<StoreState, SaveParams, void, Error>(
    actions.save,
    async ({ autopilotId, activityId }, __, getState) => {
        const state = getState();

        const titles = getSelectedTextTitles(state);
        const contents = getSelectedTextContents(state);
        const user = getLoginUser(state);

        await AutopilotApi.saveAds(autopilotId, activityId, user, [
            {
                type: 'title',
                texts: titles.map(({ value }) => value),
            },
            {
                type: 'description',
                texts: contents.map(({ value }) => value),
            },
        ]);
    },
);
