import { TypeCreativeValue, TypePlacementFieldEnum } from 'sber-marketing-types/backend';
import { FieldValueVersion } from '../../../types';
import {
    buildTextValue,
    buildTextArrValue,
    buildIntValue,
    buildFloatValue,
    buildLongValue,
    buildMoneyValue,
    buildBooleanValue,
    buildHrefValue,
    buildHrefArrValue,
    buildBundleValue,
    buildImageValue,
    buildImageArrValue,
    buildVideoValue,
    buildVideoArrValue,
    buildStringIdValue,
    buildStringIdArrValue,
    buildLongIdValue,
    buildLongIdArrValue,
    buildUnknownValue,
} from './builders';
import type { Converter } from './builders';

interface Convert {
    (params: Params): Promise<TypeCreativeValue>;
}

interface Params {
    autopilotId: string;
    fieldId: string;
    fieldType: TypePlacementFieldEnum;
    fieldValue: FieldValueVersion;
}

const builderMap: Record<TypePlacementFieldEnum, Converter> = {
    [TypePlacementFieldEnum.Image]: buildImageValue,
    [TypePlacementFieldEnum.ImageArr]: buildImageArrValue,
    [TypePlacementFieldEnum.Video]: buildVideoValue,
    [TypePlacementFieldEnum.VideoArr]: buildVideoArrValue,
    [TypePlacementFieldEnum.Bundle]: buildBundleValue,
    [TypePlacementFieldEnum.Text]: buildTextValue,
    [TypePlacementFieldEnum.TextArr]: buildTextArrValue,
    [TypePlacementFieldEnum.Href]: buildHrefValue,
    [TypePlacementFieldEnum.HrefArr]: buildHrefArrValue,
    [TypePlacementFieldEnum.Float]: buildFloatValue,
    [TypePlacementFieldEnum.Int]: buildIntValue,
    [TypePlacementFieldEnum.Boolean]: buildBooleanValue,
    [TypePlacementFieldEnum.StringId]: buildStringIdValue,
    [TypePlacementFieldEnum.StringIdArr]: buildStringIdArrValue,
    [TypePlacementFieldEnum.Long]: buildLongValue,
    [TypePlacementFieldEnum.LongId]: buildLongIdValue,
    [TypePlacementFieldEnum.LongIdArr]: buildLongIdArrValue,
    [TypePlacementFieldEnum.Unknown]: buildUnknownValue,
    [TypePlacementFieldEnum.Money]: buildMoneyValue,
};

export const buildValue: Convert = async ({ fieldId, autopilotId, fieldType, fieldValue }) => {
    return await builderMap[fieldType]({ value: fieldValue, fieldId, autopilotId });
};
