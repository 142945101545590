import { Moment } from 'moment';
import { Success } from 'typescript-fsa';
import { uniqBy } from 'lodash';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { OneTaskResponseParams } from 'sber-marketing-types/backend';

import { LoadingStatus } from '@store/commonTypes';

import {
    TaskEditorState as State,
    TaskEditorCommon,
    UserWorkTypeData,
    ActivityStagesData,
    TaskEditorValues,
    TaskEditorBudgetApprovalValues,
    TaskEditorMisc,
    TaskFiles,
    ParticipantParams,
    FileParams,
    SetFileStatusPayload,
    Permissions,
    MAX_BUDGET_ITEMS_COUNT,
    NO_BUDGET_ITEM_MARKER,
} from './types';

import * as syncActions from './actions/sync';
import * as asyncActions from './actions/async';

class Reducer {
    public static makeInitialState(): State {
        return {
            common: {
                taskId: null,
                tagsEditorId: null,
                activityId: null,
                taskIsCreated: false,
                taskRequestInProgress: false,
                taskRequestHasFinished: false,
            },
            data: {
                rawTask: null,
                userWorkType: {
                    loadingStatus: LoadingStatus.NOT_LOADED,
                    usersWithWorkTypes: [],
                    workTypesWithUsers: [],
                },
                activityStages: {
                    loadingStatus: LoadingStatus.NOT_LOADED,
                    entities: [],
                },
            },
            values: {
                loadingStatus: LoadingStatus.NOT_LOADED,
                title: '',
                description: '',
                workType: null,
                executor: null,
                deadline: null,
                files: {
                    byId: {},
                    entities: [],
                },
                uploadedFiles: [],
                uploadingFiles: [],
                participants: {
                    existing: [],
                    editing: [],
                },
                stageId: null,
            },
            permissions: {
                canChangeExecutorDeadlineAndFiles: true,
                canChangeRest: true,
            },
            misc: {
                currentExecutionBudget: null,
            },
        };
    }

    public static initEditorValuesDone(state: State, payload: Success<null, TaskEditorValues>): State {
        return { ...state, values: payload.result };
    }

    public static initWorkTypeUserDataDone(state: State, payload: Success<any, UserWorkTypeData>): State {
        return Reducer.userWorkTypeReducer(state, payload.result);
    }

    public static initActivityStagesDataDone(state: State, payload: Success<any, ActivityStagesData>): State {
        return Reducer.activityStagesReducer(state, payload.result);
    }

    public static initPermissionsDataDone(state: State, payload: Success<any, Permissions>): State {
        return Reducer.permissionsReducer(state, payload.result);
    }

    public static initMiscDone(state: State, payload: Success<any, TaskEditorMisc>): State {
        return { ...state, misc: payload.result };
    }

    public static resetState(state: State): State {
        const initialState = Reducer.makeInitialState();

        return { ...initialState, data: state.data };
    }

    public static setTaskEditorCommon(state: State, common: Partial<TaskEditorCommon>): State {
        return Reducer.commonReducer(state, common);
    }

    public static setTaskRequestHasFinished(state: State, taskRequestHasFinished: boolean): State {
        return Reducer.commonReducer(state, { taskRequestHasFinished });
    }

    public static setTaskRequestInProgress(state: State, taskRequestInProgress: boolean): State {
        return Reducer.commonReducer(state, { taskRequestInProgress });
    }

    public static setRawTask(state: State, rawTask: OneTaskResponseParams): State {
        const rawTaskFormatted = { ...rawTask };
        if (rawTaskFormatted.budgetApproval?.budgetItemIds === null) {
            rawTaskFormatted.budgetApproval.budgetItemIds = [NO_BUDGET_ITEM_MARKER];
        }

        return {
            ...state,
            data: { ...state.data, rawTask: rawTaskFormatted },
        };
    }

    public static setWorkTypeUserLoadingStatus(state: State, loadingStatus: LoadingStatus): State {
        return Reducer.userWorkTypeReducer(state, { loadingStatus });
    }

    public static setActivityStagesLoadingStatus(state: State, loadingStatus: LoadingStatus): State {
        return Reducer.activityStagesReducer(state, { loadingStatus });
    }

    public static setTitle(state: State, title: string): State {
        return Reducer.valuesReducer(state, { title });
    }

    public static setDescription(state: State, description: string): State {
        return Reducer.valuesReducer(state, { description });
    }

    public static setWorkType(state: State, workType: string): State {
        return Reducer.valuesReducer(state, { workType });
    }

    public static setBudgetApproval(state: State, budgetApproval: TaskEditorBudgetApprovalValues): State {
        return Reducer.valuesReducer(state, {
            budgetApproval: budgetApproval || null,
        });
    }

    public static setBudgetDivision(state: State, clientDivisionId: string): State {
        return Reducer.valuesReducer(state, {
            budgetApproval: {
                ...(state.values.budgetApproval || ({} as TaskEditorBudgetApprovalValues)),
                clientDivisionId,
            },
        });
    }

    public static setBudgetCustomer(state: State, clientName: string): State {
        return Reducer.valuesReducer(state, {
            budgetApproval: { ...(state.values.budgetApproval || ({} as TaskEditorBudgetApprovalValues)), clientName },
        });
    }

    public static setBudgetItemIds(state: State, budgetItemIds: string[]): State {
        let budgetItemIdsToUse: string[];
        if (budgetItemIds.includes(NO_BUDGET_ITEM_MARKER)) {
            budgetItemIdsToUse = [NO_BUDGET_ITEM_MARKER];
        } else {
            budgetItemIdsToUse = budgetItemIds.slice(0, MAX_BUDGET_ITEMS_COUNT);
        }

        return Reducer.valuesReducer(state, {
            budgetApproval: {
                ...(state.values.budgetApproval || ({} as TaskEditorBudgetApprovalValues)),
                budgetItemIds: budgetItemIdsToUse,
            },
        });
    }

    public static setBudgetProduct(state: State, productId: string): State {
        return Reducer.valuesReducer(state, {
            budgetApproval: { ...(state.values.budgetApproval || ({} as TaskEditorBudgetApprovalValues)), productId },
        });
    }

    public static setBudgetSegment(state: State, segmentId: string): State {
        return Reducer.valuesReducer(state, {
            budgetApproval: { ...(state.values.budgetApproval || ({} as TaskEditorBudgetApprovalValues)), segmentId },
        });
    }

    public static setBudgetPeriod(state: State, period: string): State {
        return Reducer.valuesReducer(state, {
            budgetApproval: { ...(state.values.budgetApproval || ({} as TaskEditorBudgetApprovalValues)), period },
        });
    }

    public static setBudgetIsMedia(state: State, hasLot: boolean): State {
        return Reducer.valuesReducer(state, {
            budgetApproval: { ...(state.values.budgetApproval || ({} as TaskEditorBudgetApprovalValues)), hasLot },
        });
    }

    public static setBudgetMediaRequest(state: State, mediaRequest: string): State {
        return Reducer.valuesReducer(state, {
            budgetApproval: {
                ...(state.values.budgetApproval || ({} as TaskEditorBudgetApprovalValues)),
                mediaRequest,
            },
        });
    }

    public static setBudgetIsNaming(state: State, hasTitle: boolean): State {
        return Reducer.valuesReducer(state, {
            budgetApproval: { ...(state.values.budgetApproval || ({} as TaskEditorBudgetApprovalValues)), hasTitle },
        });
    }

    public static setBudgetNaming(state: State, naming: string): State {
        return Reducer.valuesReducer(state, {
            budgetApproval: { ...(state.values.budgetApproval || ({} as TaskEditorBudgetApprovalValues)), naming },
        });
    }

    public static setBudgetProject(state: State, project: string): State {
        return Reducer.valuesReducer(state, {
            budgetApproval: { ...(state.values.budgetApproval || ({} as TaskEditorBudgetApprovalValues)), project },
        });
    }

    public static setExecutor(state: State, executor: number): State {
        return Reducer.valuesReducer(state, { executor });
    }

    public static setDeadline(state: State, deadline: Moment): State {
        return Reducer.valuesReducer(state, { deadline });
    }

    public static setParticipants(state: State, participants: ParticipantParams[]): State {
        return Reducer.valuesReducer(state, {
            participants: {
                existing: state.values.participants.existing,
                editing: uniqBy(participants, (participant) => participant.userId),
            },
        });
    }

    public static setStageId(state: State, stageId: string): State {
        return Reducer.valuesReducer(state, { stageId });
    }

    public static setFiles(state: State, updFiles: FileParams[]): State {
        const files: TaskFiles = {
            byId: updFiles.reduce(
                (acc, file) => ({
                    ...acc,
                    [file.asset.id]: file,
                }),
                {},
            ),
            entities: updFiles,
        };

        return Reducer.valuesReducer(state, { files });
    }

    public static setFileStatus(state: State, payload: SetFileStatusPayload): State {
        const { fileId, status } = payload;
        const oldById = state.values.files.byId;

        const byId = {
            ...oldById,
            [fileId]: { ...oldById[fileId], status },
        };
        const entities = Object.values(byId);
        const files: TaskFiles = { byId, entities };

        return Reducer.valuesReducer(state, { files });
    }

    public static setUploadingFiles(state: State, uploadingFiles: string[]): State {
        return Reducer.valuesReducer(state, { uploadingFiles });
    }

    public static removeUploadingFile(state: State, fileToRemove: string): State {
        const uploadingFiles = state.values.uploadedFiles.filter((uploadingFileId) => uploadingFileId !== fileToRemove);

        return Reducer.valuesReducer(state, { uploadingFiles });
    }

    public static commonReducer(state: State, common: Partial<TaskEditorCommon>): State {
        return { ...state, common: { ...state.common, ...common } };
    }

    public static permissionsReducer(state: State, permissions: Permissions): State {
        return { ...state, permissions: { ...state.permissions, ...permissions } };
    }

    private static valuesReducer(state: State, values: Partial<TaskEditorValues>): State {
        return {
            ...state,
            values: { ...state.values, ...values },
        };
    }

    private static userWorkTypeReducer(state: State, payload: Partial<UserWorkTypeData>): State {
        return {
            ...state,
            data: {
                ...state.data,
                userWorkType: { ...state.data.userWorkType, ...payload },
            },
        };
    }

    private static activityStagesReducer(state: State, payload: Partial<ActivityStagesData>): State {
        return {
            ...state,
            data: {
                ...state.data,
                activityStages: { ...state.data.activityStages, ...payload },
            },
        };
    }
}

export const taskEditorReducer = reducerWithInitialState(Reducer.makeInitialState())
    .case(asyncActions.initEditorValues.done, Reducer.initEditorValuesDone)
    .case(asyncActions.initWorkTypeUserData.done, Reducer.initWorkTypeUserDataDone)
    .case(asyncActions.initActivityStages.done, Reducer.initActivityStagesDataDone)
    .case(asyncActions.initPermissions.done, Reducer.initPermissionsDataDone)
    .case(asyncActions.initMisc.done, Reducer.initMiscDone)
    .case(syncActions.resetState, Reducer.resetState)
    .case(syncActions.setTaskEditorCommon, Reducer.setTaskEditorCommon)
    .case(syncActions.setTaskRequestHasFinished, Reducer.setTaskRequestHasFinished)
    .case(syncActions.setTaskRequestInProgress, Reducer.setTaskRequestInProgress)
    .case(syncActions.setRawTask, Reducer.setRawTask)
    .case(syncActions.setWorkTypeUserLoadingStatus, Reducer.setWorkTypeUserLoadingStatus)
    .case(syncActions.setActivityStagesLoadingStatus, Reducer.setActivityStagesLoadingStatus)
    .case(syncActions.setTitle, Reducer.setTitle)
    .case(syncActions.setDescription, Reducer.setDescription)
    .case(syncActions.setWorkType, Reducer.setWorkType)
    .case(syncActions.setBudgetApproval, Reducer.setBudgetApproval)
    .case(syncActions.setBudgetDivision, Reducer.setBudgetDivision)
    .case(syncActions.setBudgetCustomer, Reducer.setBudgetCustomer)
    .case(syncActions.setBudgetItemIds, Reducer.setBudgetItemIds)
    .case(syncActions.setBudgetProduct, Reducer.setBudgetProduct)
    .case(syncActions.setBudgetSegment, Reducer.setBudgetSegment)
    .case(syncActions.setBudgetPeriod, Reducer.setBudgetPeriod)
    .case(syncActions.setBudgetIsMedia, Reducer.setBudgetIsMedia)
    .case(syncActions.setBudgetMediaRequest, Reducer.setBudgetMediaRequest)
    .case(syncActions.setBudgetIsNaming, Reducer.setBudgetIsNaming)
    .case(syncActions.setBudgetNaming, Reducer.setBudgetNaming)
    .case(syncActions.setBudgetProject, Reducer.setBudgetProject)
    .case(syncActions.setExecutor, Reducer.setExecutor)
    .case(syncActions.setDeadline, Reducer.setDeadline)
    .case(syncActions.setParticipants, Reducer.setParticipants)
    .case(syncActions.setStageId, Reducer.setStageId)
    .case(syncActions.setFiles, Reducer.setFiles)
    .case(syncActions.setFileStatus, Reducer.setFileStatus)
    .case(syncActions.setUploadingFiles, Reducer.setUploadingFiles)
    .case(syncActions.removeUploadingFile, Reducer.removeUploadingFile);
