import { combineReducers } from 'redux';
import { Success, Failure } from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import type { ActivityParams, TaskCardParams } from 'sber-marketing-types/frontend';
import { TaskPageSortBy } from 'sber-marketing-types/frontend';
import { flatMap, groupBy, pick } from 'lodash';

import { LoadingStatus } from '@store/commonTypes';
import { resetStore } from '@store/common/actions';

import { activityStagesReducer } from './stages';

import * as actions from './actions/sync';
import * as asyncActions from './actions/async';
import {
    ActivityTasksState,
    ActivityTasksPageState,
    RestActivityPageState as State,
    TasksFilter,
    SwitchKeyActivityParams,
    UpdateActivityTaskParams,
} from './types';

function createEmptyTasksFilter(): TasksFilter {
    return {
        status: null,
        workType: [],
        division: [],
        author: [],
        executor: [],
        department: [],
        activityStage: [],
        participation: null,
        sorting: TaskPageSortBy.DEADLINE,
    };
}

class Reducer {
    public static makeInitialState(): State {
        return {
            activityId: null,
            isMounted: false,
            filters: createEmptyTasksFilter(),
            activity: null,
            activityTasks: {
                loadingStatus: LoadingStatus.NOT_LOADED,
                byStageId: {},
                byId: {},
            },
            loadingStatus: {
                activity: LoadingStatus.NOT_LOADED,
            },
        };
    }

    public static setActivityLoading(state: State, activityId: number): State {
        return {
            ...state,
            activityId,
            loadingStatus: {
                activity: LoadingStatus.LOADING,
            },
        };
    }

    public static setActivityLoadingError(state: State): State {
        return Reducer.setActivityLoadingStatus(state, LoadingStatus.ERROR);
    }

    public static loadActivity(state: State, payload: Success<number, ActivityParams>): State {
        return !state.isMounted
            ? state
            : {
                  ...state,
                  loadingStatus: {
                      ...state.loadingStatus,
                      activity: LoadingStatus.LOADED,
                  },
                  activity: payload.result,
              };
    }

    public static fillActivityTasks(state: State, payload: Success<number, TaskCardParams[]>): State {
        const tasks = payload.result;

        return { ...state, activityTasks: Reducer.fillAcitivityTasks(tasks) };
    }

    public static setTaskFilters(state: State, filters: Partial<TasksFilter>): State {
        return {
            ...state,
            filters: {
                ...state.filters,
                ...filters,
            },
        };
    }

    public static resetTaskFilters(state: State): State {
        return {
            ...state,
            filters: {
                ...state.filters,
                ...pick(
                    createEmptyTasksFilter(),
                    'status',
                    'author',
                    'workType',
                    'executor',
                    'department',
                    'participation',
                    'activityStage',
                ),
            },
        };
    }

    public static updateActivity(state: State, activity: Partial<ActivityParams>): State {
        return {
            ...state,
            activity: {
                ...state.activity,
                ...activity,
            },
        };
    }

    public static updateActivityTask(state: State, payload: UpdateActivityTaskParams): State {
        const { id, params } = payload;

        const updatedTasks = flatMap(state.activityTasks.byId, (task) =>
            task.id === id ? { ...task, ...params } : task,
        );

        return { ...state, activityTasks: Reducer.fillAcitivityTasks(updatedTasks) };
    }

    public static switchKeyActivityStarted(state: State, payload: SwitchKeyActivityParams): State {
        return {
            ...state,
            activity: {
                ...state.activity,
                isKey: payload.isKey,
            },
        };
    }

    public static switchKeyActivityFailed(state: State, payload: Failure<SwitchKeyActivityParams, Error>): State {
        return {
            ...state,
            activity: {
                ...state.activity,
                isKey: !payload.params.isKey,
            },
            loadingStatus: {
                ...state.loadingStatus,
                activity: LoadingStatus.ERROR,
            },
        };
    }

    public static setIsMounted(state: State, isMounted: boolean): State {
        return { ...state, isMounted };
    }

    private static fillAcitivityTasks(tasks: TaskCardParams[]): ActivityTasksState {
        const byId = tasks.reduce(
            (acc, task) => ({
                ...acc,
                [task.id]: task,
            }),
            {},
        );
        const byStageId = groupBy(tasks, 'stageId');

        return {
            loadingStatus: LoadingStatus.LOADED,
            byId,
            byStageId,
        };
    }

    private static setActivityLoadingStatus(state: State, activity: LoadingStatus): State {
        return {
            ...state,
            loadingStatus: {
                ...state.loadingStatus,
                activity,
            },
        };
    }
}

const restActivityStateReducer = reducerWithInitialState(Reducer.makeInitialState())
    .cases([actions.resetActivity, resetStore], Reducer.makeInitialState)
    .case(asyncActions.loadActivity.failed, Reducer.setActivityLoadingError)
    .case(asyncActions.loadActivity.started, Reducer.setActivityLoading)
    .case(asyncActions.loadActivity.done, Reducer.loadActivity)
    .case(asyncActions.fillActivityTasks.done, Reducer.fillActivityTasks)
    .case(actions.setTaskFilters, Reducer.setTaskFilters)
    .case(actions.resetTaskFilters, Reducer.resetTaskFilters)
    .case(actions.updateActivity, Reducer.updateActivity)
    .case(actions.updateActivityTask, Reducer.updateActivityTask)
    .case(actions.setIsMounted, Reducer.setIsMounted)
    .case(asyncActions.switchKeyActivity.started, Reducer.switchKeyActivityStarted)
    .case(asyncActions.switchKeyActivity.failed, Reducer.switchKeyActivityFailed);
export const activityTasksPageReducer = combineReducers<ActivityTasksPageState>({
    restState: restActivityStateReducer,
    stages: activityStagesReducer,
});
