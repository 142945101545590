import { UserConfigType } from 'sber-marketing-types/openid';

import { CommonUserConfigState } from './common';
import { DashboardUserConfigState } from './dashboard';
import { BudgetCorrectionsUserConfigState } from './budgetCorrections';
import { PersonalDashboardUserConfigState } from './personalDashboard';
import { BudgetUserConfigState } from './budget';
import { CreativeUserConfigState } from './creative';
import { ActivityTasksUserConfigState } from './activityTasks';

export interface UserConfigState {
    [UserConfigType.Common]: CommonUserConfigState;
    [UserConfigType.Dashboard]: DashboardUserConfigState;
    [UserConfigType.BudgetCorrections]: BudgetCorrectionsUserConfigState;
    [UserConfigType.PersonalDashboard]: PersonalDashboardUserConfigState;
    [UserConfigType.Budget]: BudgetUserConfigState;
    [UserConfigType.Creative]: CreativeUserConfigState;
    [UserConfigType.ActivityTasks]: ActivityTasksUserConfigState;
}

export type AllUserConfigs = keyof UserConfigState;

export interface SaveUserConfig<T extends AllUserConfigs> {
    type: T;
    payload: {
        [F in keyof UserConfigState[T]['fields']]?: Partial<UserConfigState[T]['fields'][F]>;
    };
    preventBackendUpdate?: boolean;
}

export { UserConfigType };
