import { LoadingStatus } from '@store/commonTypes';

import { TasksFilter } from '@store/userConfig';

export const MAX_USER_CONFIG_ENTRIES_COUNT = 30;

export interface ActivityTasksUserConfigState {
    status: LoadingStatus;
    fields: ActivityTasksUserConfig;
}

export interface ActivityTasksUserConfig {
    entries: Record<number, ActivityTasksUserConfigEntry>;
}

export interface ActivityTasksUserConfigEntry {
    updateTime: number;
    filters: TasksFilter;
}

export interface LoadActivityTasksUserConfig {
    receivedUserConfig: ActivityTasksUserConfig;
}

export interface UpdateActivityTasksUserConfig extends Partial<ActivityTasksUserConfig> {}

export interface SaveActivityTasksUserConfigPayload {
    activityId: number;
    filters: Partial<TasksFilter>;
}
