import { createSelector } from 'reselect';

import { AuthResponse } from 'sber-marketing-types/frontend';
import { UserConfigParams, UserConfigResponse, UserConfigType } from 'sber-marketing-types/openid';

import { StoreState } from '@store';

import { rtkApi } from './rtkApi';

export const authApi = rtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getAuthUser: builder.query<AuthResponse, void>({
            query: () => '/auth/user',
            providesTags: () => ['authUser'],
        }),
        getUserConfig: builder.query<UserConfigResponse, UserConfigType>({
            query: (type) => ({
                url: '/userConfig',
                params: {
                    type,
                },
            }),
            providesTags: ({ config: { type } }) => [{ type: 'authUser', id: type }],
        }),
        setUserConfig: builder.mutation<void, Omit<UserConfigParams, 'userId'>>({
            query: (body) => ({
                url: `/userConfig`,
                method: 'PUT',
                body,
            }),
            async onQueryStarted({ type, configData }, { dispatch, queryFulfilled }) {
                const patch = dispatch(
                    authApi.util.updateQueryData('getUserConfig', type, (draft) => {
                        draft.config.configData = configData;
                    }),
                );

                try {
                    await queryFulfilled;
                } catch {
                    patch.undo();
                }
            },
        }),
    }),
});

export const selectMyUserId = createSelector(
    (state: StoreState) => authApi.endpoints.getAuthUser.select()(state),
    ({ data }) => data?.user.attributes.id,
);

export const { useGetAuthUserQuery, useGetUserConfigQuery, useSetUserConfigMutation } = authApi;
