import * as lodash from 'lodash';
import { Dictionary, flatMap } from 'lodash';
import { TooltipThemes, TooltipAnchor } from 'sber-marketing-ui';

import { Correction, CorrectionType, Funds, BudgetItem } from '@mrm/budget';
import {
    TableLine,
    ColumnData,
    ColumnName,
    CellValueType,
    ChangeList,
    RESERVED_COLUMN_NAMES,
    REQUIRED_COLUMN_NAMES,
    UnsavedChange,
    UserRole,
    FACT_COLUMN_NAMES,
} from '@store/budgetExecution/types';

import { CellParams, CellBackgroundColor, CellBorderColor } from '../LayerManager';

import { getFieldValue, getMonthByIndex } from './Utils';
import { MoneyFormatter, Money, Utils } from '@common/Utils';

interface Props {
    line: TableLine;
    column: ColumnData;
    unsavedChanges: ChangeList;
    activityCorrections: Correction<CorrectionType.Activity>[];
    budgetItemCorrections: Correction<CorrectionType.BudgetItem>[];
    reserveCorrections: Correction<CorrectionType.ReservedFunds>[];
    validationStatus: boolean;
    isDisabled: boolean;
    userRole: UserRole;
    budgetItemsByActivityId: Dictionary<BudgetItem[]>;
}

interface CellInfoResult {
    cellInfo?: string;
    cellInfoTooltipTheme?: TooltipThemes;
    cellInfoTooltipAnchor?: TooltipAnchor;
}

export class InputCellParamsCreator {
    private line: TableLine;
    private column: ColumnData;
    private unsavedChanges: ChangeList;
    private activityCorrections: Correction<CorrectionType.Activity>[];
    private budgetItemCorrections: Correction<CorrectionType.BudgetItem>[];
    private reserveCorrections: Correction<CorrectionType.ReservedFunds>[];
    private validationStatus: boolean;
    private isDisabled: boolean;
    private budgetItemsByActivityId: Dictionary<BudgetItem[]>;
    private currencyFormulaValue: number;

    constructor(props: Props) {
        const {
            line,
            column,
            unsavedChanges,
            activityCorrections,
            budgetItemCorrections,
            reserveCorrections,
            validationStatus,
            isDisabled,
            userRole,
            budgetItemsByActivityId,
        } = props;

        this.line = line;
        this.column = column;
        this.unsavedChanges = unsavedChanges;
        this.activityCorrections = activityCorrections;
        this.budgetItemCorrections = budgetItemCorrections;
        this.reserveCorrections = reserveCorrections;
        this.validationStatus = validationStatus;
        this.isDisabled =
            isDisabled || (column.name === ColumnName.LastYearFact && userRole !== UserRole.BUDGET_EXPERT);
        this.budgetItemsByActivityId = budgetItemsByActivityId;
    }

    public makeCellParams(): CellParams {
        let value: string = null;
        let color: CellBackgroundColor = null;
        let isDisabled = this.isDisabled;

        const baseValue = this.getBaseValue(this.column);

        const unsavedChange = this.getUnsavedChangeForCurrentLine();

        let displayValidationError = false;
        let invalidCellBorder;

        if (unsavedChange !== undefined) {
            value = unsavedChange.value as string;
            color = CellBackgroundColor.UnsavedChange;
        } else {
            const correctionValue = this.getCorrectionValue(this.column);

            if (correctionValue !== undefined || (baseValue && correctionValue === '0')) {
                value = correctionValue;
                color = CellBackgroundColor.SavedChange;

                const isReservedBudgetCell = lodash.includes(RESERVED_COLUMN_NAMES, this.column.name);

                if (!isReservedBudgetCell) {
                    isDisabled = true;
                }
            } else {
                value = baseValue;
            }

            if (this.column.valueType === CellValueType.Currency && value) {
                value = String(parseFloat(value) / 100.0);
            }
        }

        if (this.cellIsInvalid(value)) {
            displayValidationError = true;
            invalidCellBorder = CellBorderColor.Invalid;
        }

        this.currencyFormulaValue =
            this.column.valueType === CellValueType.Currency && value ? Utils.calculateCurrencyFormula(value) : null;

        const { title, tooltip } = this.makeTitleAndTooltip();

        const { cellInfo, cellInfoTooltipTheme, cellInfoTooltipAnchor } = this.makeCellInfo();

        return {
            title,
            value,
            originalValue: baseValue,
            tooltip,
            bgColor: color,
            displayValidationError,
            disabled: isDisabled,
            cellBorder: invalidCellBorder,
            cellInfo,
            cellInfoTooltipTheme,
            cellInfoTooltipAnchor,
        };
    }

    private makeCellInfo(): CellInfoResult {
        if (this.column.name === ColumnName.ActivityName) {
            const activityBudgetItems = this.budgetItemsByActivityId[this.line.activityId] || [];
            const unsavedChangesByActvitiyBudgetItems = this.getUnsavedChanges(
                activityBudgetItems.map((budgetItem) => budgetItem.id),
            );

            if (unsavedChangesByActvitiyBudgetItems.length && activityBudgetItems.length > 1) {
                return {
                    cellInfo: `Изменение повлияет на другие ячейки (${activityBudgetItems.length}) в рамках данной активности`,
                    cellInfoTooltipTheme: TooltipThemes.Red,
                };
            }
        } else if (this.column.valueType === CellValueType.Currency && this.currencyFormulaValue) {
            return {
                cellInfo: `Итого: ${MoneyFormatter.toRoubles(Money.fromRoubles(this.currencyFormulaValue))}`,
                cellInfoTooltipTheme: TooltipThemes.Black,
                cellInfoTooltipAnchor: TooltipAnchor.ADDAPTIVE_HORIZONTAL,
            };
        }

        return {};
    }

    private makeTitleAndTooltip(): { title: string; tooltip: string } {
        const isFactBudgetCell = lodash.includes(FACT_COLUMN_NAMES, this.column.name);
        const isReservedBudgetCell = lodash.includes(RESERVED_COLUMN_NAMES, this.column.name);
        const valueTypeIsCurrency = this.column.valueType == CellValueType.Currency;

        let title: string;
        let tooltip = '';

        const unsavedChange = this.getUnsavedChangeForCurrentLine();
        const unsavedValue = unsavedChange ? (unsavedChange.value as string) : undefined;
        const hasUnsavedValue = unsavedValue !== undefined;

        const correctionValue = this.getCorrectionValue(this.column);
        const hasCorrectionValue = correctionValue !== undefined;

        const baseValue = this.getBaseValue(this.column);

        if (isFactBudgetCell || isReservedBudgetCell || this.column.name == ColumnName.LastYearFact) {
            const baseMoney = Money.fromStringCopecks(baseValue);
            const unsavedMoney = Money.fromRoubles(this.currencyFormulaValue);

            if (hasUnsavedValue) {
                title = MoneyFormatter.pairToThousands(baseMoney, unsavedMoney, { hideCaption: true });

                tooltip = MoneyFormatter.pairToRoubles(baseMoney, unsavedMoney);
            } else if (hasCorrectionValue || (baseValue && correctionValue === '0')) {
                const correctionMoney = Money.fromStringCopecks(correctionValue);

                title = MoneyFormatter.pairToThousands(baseMoney, correctionMoney, { hideCaption: true });
                tooltip = MoneyFormatter.pairToRoubles(baseMoney, correctionMoney);
            } else {
                title = MoneyFormatter.toThousands(baseMoney, { hideCaption: true });
                tooltip = MoneyFormatter.toRoubles(baseMoney);
            }
        } else if (valueTypeIsCurrency) {
            const baseMoney = Money.fromStringCopecks(baseValue);

            if (hasUnsavedValue) {
                const unsavedMoney = Money.fromStringRoubles(unsavedValue);

                title = MoneyFormatter.pairToThousands(baseMoney, unsavedMoney, { hideCaption: true });
                tooltip = MoneyFormatter.pairToRoubles(baseMoney, unsavedMoney);
            } else {
                title = MoneyFormatter.toThousands(baseMoney, { hideCaption: true });
                tooltip = MoneyFormatter.toRoubles(baseMoney);
            }
        } else {
            if (hasUnsavedValue) {
                title = unsavedValue;
                tooltip = title;
            } else if (hasCorrectionValue) {
                title = correctionValue;
                tooltip = title;
            } else {
                title = baseValue;
                tooltip = title;
            }
        }

        return {
            title,
            tooltip: tooltip || '-',
        };
    }

    private cellIsInvalid(value: string) {
        if (!this.validationStatus) {
            return false;
        }

        let displayError = false;

        const isActivityNameField = this.column.name == ColumnName.ActivityName;
        const isSapCommentField = this.column.name == ColumnName.SapComment;
        const isFormulaField = this.column.valueType === CellValueType.Currency;

        const isRequiredField = REQUIRED_COLUMN_NAMES.includes(this.column.name);

        if (isActivityNameField || isSapCommentField) {
            displayError = !value || value.length < 3;
        } else if (isRequiredField) {
            displayError = !value;
        } else if (isFormulaField) {
            const unsavedChange = this.getUnsavedChangeForCurrentLine();

            displayError = unsavedChange?.value
                ? Utils.calculateCurrencyFormula(unsavedChange.value as string) < 0
                : false;
        }

        return displayError;
    }

    private getUnsavedChangeForCurrentLine(): UnsavedChange {
        return this.getUnsavedChanges([this.line.id])[0];
    }

    private getUnsavedChanges(lineIds: string[]): UnsavedChange[] {
        const changes = flatMap(lineIds.map((lineId) => this.unsavedChanges[lineId] || []));

        return changes.filter((item) => item.columnName === this.column.name);
    }

    private getCorrectionValue(column: ColumnData): string {
        let correctionValue: string = undefined;

        let correction: Correction;

        const isReservedBudgetCell = lodash.includes(RESERVED_COLUMN_NAMES, column.name);

        if (isReservedBudgetCell) {
            correctionValue = this.getReserveCorrectionValue(column);
        } else {
            switch (column.name) {
                case ColumnName.ActivityName:
                    correction = this.activityCorrections.find((item) => !!item.data.params.name);

                    if (correction) {
                        correctionValue = correction.data.params.name;
                    }
                    break;

                case ColumnName.SapComment:
                    correction = this.budgetItemCorrections.find((item) => !!item.data.params.sapComment);

                    if (correction) {
                        correctionValue = correction.data.params.sapComment;
                    }
                    break;

                case ColumnName.LastYearFact:
                    correction = this.budgetItemCorrections.find(
                        (item) => item.data.params.previousFunds !== undefined,
                    );

                    if (correction) {
                        correctionValue = correction.data.params.previousFunds.toString();
                    }
                    break;

                case ColumnName.SapZns:
                    correction = this.budgetItemCorrections.find((item) => item.data.params.sapZns !== undefined);

                    if (correction) {
                        correctionValue = correction.data.params.sapZns;
                    }
                    break;

                case ColumnName.SapCorrectionNumber:
                    correction = this.budgetItemCorrections.find((item) => item.data.params.sapNumber !== undefined);

                    if (correction) {
                        correctionValue = correction.data.params.sapNumber;
                    }
                    break;

                case ColumnName.Comment:
                    correction = this.budgetItemCorrections.find((item) => item.data.params.comment !== undefined);

                    if (correction) {
                        correctionValue = correction.data.params.comment;
                    }
                    break;
            }
        }

        return correctionValue;
    }

    private getReserveCorrectionValue(column: ColumnData): string {
        let correctionValue: string = undefined;

        const lastReserveCorrection = lodash.last(lodash.sortBy(this.reserveCorrections, (item) => item.creationTime));

        if (lastReserveCorrection) {
            const baseValue = this.getBaseValue(column);

            const funds: Funds = lastReserveCorrection.data.params;
            const monthIndex = column.metaData.month - 1;
            const month = getMonthByIndex(monthIndex);

            const valueChanged = funds[month] !== undefined && funds[month].toString() !== baseValue;

            if (valueChanged) {
                correctionValue = funds[month].toString();
            }
        }

        return correctionValue;
    }

    private getBaseValue(column: ColumnData): string {
        return getFieldValue(this.line.fields[column.name], column.valueType) as string;
    }
}
