import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { DictionaryType } from '@mrm/budget';

import { DictionaryApi } from '@api';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { getLoginUser } from '@store/user';

import * as actions from './actions';
import { LoadDivisionsParams } from './types';

export const loadDivisions = bindThunkAction<StoreState, LoadDivisionsParams, void, Error>(
    actions.loadDivisionsAsync,
    async (params, dispatch, getState) => {
        const { store } = params;

        const setStatus: (status: LoadingStatus) => void = (status) =>
            dispatch(
                actions.setLoadingStatus({
                    store,
                    status,
                }),
            );

        const state = getState();
        const loginnedUser = getLoginUser(state);

        try {
            setStatus(LoadingStatus.LOADING);
            const divisions = await DictionaryApi.getDictionaryList({
                organizationId: loginnedUser.attributes.organizationId,
                types: [DictionaryType.Division],
            });

            if (divisions) {
                dispatch(actions.decFetchersCount(store));
                dispatch(actions.loadEntities(divisions));
                dispatch(
                    actions.setStoreIds({
                        store,
                        ids: divisions.map((division) => division.id),
                    }),
                );
            }
            setStatus(LoadingStatus.LOADED);
        } catch (e) {
            setStatus(LoadingStatus.ERROR);
        }
    },
);
