import { axios } from '../../lib/axios';
import {
    AutopilotRadio,
    AutopilotRadioMediaplanPlanDataResponse,
    GetAutopilotRadioListParams,
    CreateAutopilotRadioParams,
    UpdateAutopilotRadioParams,
    UpdateAutopilotRadioBriefParams,
    UpdateAutopilotRadioMediaplanParams,
    UpdateAutopilotRadioBudgetParams,
    AutopilotRadioTargetGroup,
} from 'sber-marketing-types/backend';
import { CheckPermissionService } from './CheckPermissionService';
import { User } from '@store/user';

const BASE_URL = '/api/autopilot-radio';

export class AutopilotRadioApi {
    public static async getAutopilot(autopilotId: string, activityId: number): Promise<AutopilotRadio> {
        await CheckPermissionService.check(activityId);

        const res = await axios.get(`${BASE_URL}/${autopilotId}`);

        return res.data;
    }

    public static async getMediaplan(
        autopilotId: string,
        activityId: number,
    ): Promise<AutopilotRadioMediaplanPlanDataResponse> {
        await CheckPermissionService.check(activityId);

        const res = await axios.get(`${BASE_URL}/${autopilotId}/mediaplan`);

        return res.data;
    }

    public static async calculateAutopilotMediaplanPlanData(
        autopilotId: string,
        activityId: number,
    ): Promise<AutopilotRadioMediaplanPlanDataResponse> {
        await CheckPermissionService.check(activityId);

        const res = await axios.get(`${BASE_URL}/${autopilotId}/mediaplan-calculate`);

        return res.data;
    }

    public static async createMediaplan(autopilotId: string, activityId: number, user: User): Promise<void> {
        const roles = ['Autopilot Client'];
        await CheckPermissionService.check(activityId, { roles, user });

        const res = await axios.post(`${BASE_URL}/${autopilotId}/mediaplan`);

        return res.data;
    }

    public static async getAutopilotList(params?: GetAutopilotRadioListParams): Promise<AutopilotRadio[]> {
        if (params !== undefined && params.activityIds !== undefined) {
            await Promise.all(params.activityIds.map((activityId) => CheckPermissionService.check(activityId)));
        }

        const res = await axios.post(`${BASE_URL}/list`, params);

        return res.data;
    }

    public static async createAutopilot(params: CreateAutopilotRadioParams, user: User): Promise<void> {
        const roles = ['Autopilot Client'];
        await CheckPermissionService.check(params.activityId, { roles, user });

        const res = await axios.post(BASE_URL, params);

        return res.data;
    }

    public static async updateAutopilot(
        autopilotId: string,
        activityId: number,
        user: User,
        params: UpdateAutopilotRadioParams,
    ): Promise<void> {
        const roles = ['Autopilot Client'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.put<void>(`${BASE_URL}/${autopilotId}`, params);
    }

    public static async updateBrief(
        autopilotId: string,
        activityId: number,
        user: User,
        params: UpdateAutopilotRadioBriefParams,
    ): Promise<void> {
        const roles = ['Autopilot Client'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.put<void>(`${BASE_URL}/${autopilotId}/brief`, params);
    }

    public static async updateMediaplan(
        autopilotId: string,
        activityId: number,
        user: User,
        params: UpdateAutopilotRadioMediaplanParams,
    ): Promise<void> {
        const roles = ['Autopilot Client'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.put<void>(`${BASE_URL}/${autopilotId}/mediaplan`, params);
    }

    public static async updateBudget(
        autopilotId: string,
        activityId: number,
        user: User,
        params: UpdateAutopilotRadioBudgetParams,
    ): Promise<void> {
        const roles = ['Autopilot Client'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.put<void>(`${BASE_URL}/${autopilotId}/budget`, params);
    }

    public static async getTargetGroups(autopilotId: string, activityId: number): Promise<AutopilotRadioTargetGroup[]> {
        await CheckPermissionService.check(activityId);

        const res = await axios.get(`${BASE_URL}/${autopilotId}/mediaplan-target-groups`);

        return res.data;
    }

    public static async getMediaplanXLS(autopilotId: string, activityId: number): Promise<Blob> {
        await CheckPermissionService.check(activityId);

        const res = await axios.get<Blob>(`${BASE_URL}/${autopilotId}/mediaplan-xls`, { responseType: 'blob' });

        return res.data;
    }
}
