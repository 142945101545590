import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const onboardingIds = ['searchActivity', 'searchBudget', 'newActivity', 'newBudget'] as const;

export type OnboardingId = typeof onboardingIds[number];

export interface OnboardingState {
    current?: OnboardingId;
}

const initialState: OnboardingState = {};

const slice = createSlice({
    initialState,
    name: 'app',
    reducers: {
        setCurrentOnboarding: (state, { payload }: PayloadAction<OnboardingId | undefined>) => {
            state.current = payload;
        },
    },
});

export const { setCurrentOnboarding } = slice.actions;

export const onboarding = slice.reducer;
