import * as React from 'react';
import { Moment } from 'moment';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import {
    Textarea_redesign as Textarea,
    Textarea_theme as TextareaTheme,
    Button_redesign as Button,
    ButtonTheme_redesign as ButtonTheme,
    WithTooltip,
    AnimatedEllipsis,
} from 'sber-marketing-ui';

import { StoreState } from '@store';
import { ComponentState, setComponentState } from '@store/profileMenu';
import {
    getProfileMenuVacationState,
    updatePendingVacation,
    saveUserVacation,
    setDeleteVacationPopupVisibility,
} from '@store/profileMenu/vacation';

import { DatepickerPosition, RangeDatepicker as CommonRangeDatepicker } from '@common/RangeDatepicker';

import commonStyles from '../CommonStyles.scss';
import styles from './VacationEditor.scss';

interface ContentProps {
    vacationAlreadyExists: boolean;
    isRequestInProgress: boolean;
}

function useInteractivity(): ContentProps {
    const vacationAlreadyExists = useSelector(
        (state: StoreState) => !!getProfileMenuVacationState(state).existingVacation,
    );
    const isRequestInProgress = useSelector(
        (state: StoreState) => getProfileMenuVacationState(state).isRequestInProgress,
    );

    return { vacationAlreadyExists, isRequestInProgress };
}

export function VacationEditor(): JSX.Element {
    const props = useInteractivity();

    return (
        <div className={classnames(commonStyles.paddingT, commonStyles.paddingB, commonStyles.paddingRL)}>
            <Title {...props} />

            <div className={styles.content}>
                <RangeDatepicker />

                <Comment />

                <Buttons {...props} />

                {props.isRequestInProgress && <div className={styles.inProgressMask} />}
            </div>
        </div>
    );
}

function Title({ vacationAlreadyExists, isRequestInProgress }: ContentProps): JSX.Element {
    const dispatch = useDispatch();

    function onBackButtonClick() {
        dispatch(setComponentState(ComponentState.Menu));
    }

    return (
        <div className={styles.title}>
            <svg
                width="16"
                height="8"
                viewBox="0 0 16 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={styles.titleArrow}
                onClick={isRequestInProgress ? null : onBackButtonClick}
            >
                <path
                    d="M3.62924 0.121796L3.55957 0.181942L0.180755 3.56075C-0.0397797 3.78129 -0.0598297 4.12639 0.120608 4.36957L0.180755 4.43925L3.55957 7.81806C3.80216 8.06065 4.19547 8.06065 4.43806 7.81806C4.65859 7.59752 4.67864 7.25242 4.4982 7.00924L4.43806 6.93957L2.15 4.65H15.35C15.709 4.65 16 4.35898 16 4C16 3.67365 15.7595 3.40347 15.4461 3.35705L15.35 3.35L2.149 3.349L4.43806 1.06043C4.65859 0.839898 4.67864 0.494796 4.4982 0.251613L4.43806 0.181942C4.21752 -0.0385936 3.87242 -0.0586423 3.62924 0.121796Z"
                    fill="#7E8681"
                />
            </svg>

            {vacationAlreadyExists ? 'Редактировать даты отпуска' : 'Назначить даты отпуска'}
        </div>
    );
}

function useRangeDatepickerInteractivity() {
    const dispatch = useDispatch();

    const vacationStart = useSelector((state: StoreState) => getProfileMenuVacationState(state).pendingVacation?.start);
    const vacationEnd = useSelector((state: StoreState) => getProfileMenuVacationState(state).pendingVacation?.end);

    function onVacationStartChange(vacationStart: Moment) {
        let start: Date = null;
        if (vacationStart) {
            vacationStart.set('hours', 0);
            vacationStart.set('minutes', 0);
            vacationStart.set('seconds', 0);
            start = vacationStart.toDate();
        }

        dispatch(updatePendingVacation({ start }));
    }
    function onVacationEndChange(vacationEnd: Moment) {
        let end: Date = null;
        if (vacationEnd) {
            vacationEnd.set('hours', 23);
            vacationEnd.set('minutes', 59);
            vacationEnd.set('seconds', 59);
            end = vacationEnd.toDate();
        }

        dispatch(updatePendingVacation({ end }));
    }

    return {
        vacationStart,
        onVacationStartChange,
        vacationEnd,
        onVacationEndChange,
    };
}

function RangeDatepicker(): JSX.Element {
    const { vacationStart, onVacationStartChange, vacationEnd, onVacationEndChange } =
        useRangeDatepickerInteractivity();

    return (
        <div className={styles.rangeDatepicker}>
            <CommonRangeDatepicker
                datepickerPosition={DatepickerPosition.Bottom}
                start={vacationStart}
                end={vacationEnd}
                onStartChange={onVacationStartChange}
                onEndChange={onVacationEndChange}
            />
        </div>
    );
}

function useCommentInteractivity() {
    const dispatch = useDispatch();

    const comment = useSelector((state: StoreState) => getProfileMenuVacationState(state).pendingVacation?.comment);

    function onCommentChange(comment: string) {
        dispatch(updatePendingVacation({ comment }));
    }

    return { comment, onCommentChange };
}

function Comment(): JSX.Element {
    const { comment, onCommentChange } = useCommentInteractivity();

    return (
        <Textarea
            theme={TextareaTheme.VACATION_EDITOR}
            placeholder="Комментарий (с кем связаться, пока вы в отпуске и т.д)"
            value={comment}
            onChange={onCommentChange}
            minRows={4}
            maxRows={4}
        />
    );
}

function useButtonsInteractivity(isRequestInProgress: boolean) {
    const dispatch = useDispatch();

    const vacationExists = useSelector((state: StoreState) => !!getProfileMenuVacationState(state).existingVacation);
    const vacationStart = useSelector((state: StoreState) => getProfileMenuVacationState(state).pendingVacation?.start);
    const vacationEnd = useSelector((state: StoreState) => getProfileMenuVacationState(state).pendingVacation?.end);

    const vacationIsOverdue = vacationEnd ? vacationEnd < new Date() : false;
    const saveButtonDisabled = !(vacationStart && vacationEnd) || isRequestInProgress || vacationIsOverdue;
    const deleteVacationButtonDisabled = !vacationExists || isRequestInProgress;

    function onSaveVacationButtonClick() {
        dispatch(saveUserVacation(null));
    }

    function onDeleteVacationButtonClick() {
        dispatch(setDeleteVacationPopupVisibility(true));
    }

    return {
        vacationIsOverdue,
        saveButtonDisabled,
        deleteVacationButtonDisabled,
        onSaveVacationButtonClick,
        onDeleteVacationButtonClick,
    };
}

function Buttons({ vacationAlreadyExists, isRequestInProgress }: ContentProps): JSX.Element {
    const {
        vacationIsOverdue,
        saveButtonDisabled,
        deleteVacationButtonDisabled,
        onSaveVacationButtonClick,
        onDeleteVacationButtonClick,
    } = useButtonsInteractivity(isRequestInProgress);

    let saveButtonContent: React.ReactNode;
    if (isRequestInProgress) {
        saveButtonContent = <AnimatedEllipsis text="Сохранение" />;
    } else if (vacationAlreadyExists) {
        saveButtonContent = 'Редактировать отпуск';
    } else {
        saveButtonContent = 'Назначить отпуск';
    }

    return (
        <div className={styles.buttons}>
            <div className={styles.button}>
                <Button
                    theme={ButtonTheme.GhostRounded}
                    disabled={deleteVacationButtonDisabled}
                    onClick={onDeleteVacationButtonClick}
                >
                    <div className={styles.buttonContent}>Удалить отпуск</div>
                </Button>
            </div>

            <div className={classnames(styles.button, styles.buttonMargin)}>
                <WithTooltip content="Вы выставили просроченный отпуск" hidden={!vacationIsOverdue}>
                    <Button
                        theme={ButtonTheme.GhostRoundedBlack}
                        disabled={saveButtonDisabled}
                        onClick={onSaveVacationButtonClick}
                    >
                        <div className={styles.buttonContent}>{saveButtonContent}</div>
                    </Button>
                </WithTooltip>
            </div>
        </div>
    );
}
