import type { Activity } from '@mrm/activity';
import type { PlainDictionary } from '@mrm/dictionary';
import type {
    TaskAttributes as Task,
    OrganizationView as Organization,
    DepartmentAttributes as Department,
    UserResponseParams as User,
} from 'sber-marketing-types/frontend';

export interface PageState {
    pageData?: PageData;
    filters?: Filters;
    currentPageMode?: PageMode;
    period?: Period;
    expandedGroupsIds?: string[];
    expandedActivitiesIds?: number[];
    hoveredItemId?: string | number;
    isDisabledUploadPdfButton?: boolean;
}

export const enum PageMode {
    WeekDigest = 'weekDigest',
    Chart = 'chart',
}

export const enum Period {
    Year = 'year',
    Quarter = 'quarter',
    Month = 'month',
}

export interface PageData {
    organizations?: Organization[];
    activities?: Activity[];
    accessibleActivitiesIds?: number[];
    activityTypes?: PlainDictionary[];
    activityTypesData?: { dictionaryId: string; data: any; schema: any }[];
    stageTemplates?: PlainDictionary[];
    stages?: Stage[];
    tasks?: GroupedTasks;
    responsibleUsers?: ResponsibleUser[];
    divisionNames?: DivisionNames;
    departments?: Department[];
    activityAuthors?: User[];
    divisions?: PlainDictionary[];
    blocks?: PlainDictionary[];
    products?: PlainDictionary[];
    calendarGroups?: PlainDictionary[];
    calendarGroupsNames?: CalendarGroupsNames;
}

export interface Stage {
    id: string;
    activityId: number;
    name: string;
    start: string;
    end: string;
    done: boolean;
    weight?: number;
}

export interface Filters {
    onlyKeyActivities?: boolean;
    organizationIds?: string[];
    activityTypeIds?: string[];
    divisionIds?: string[];
    departmentIds?: string[];
    productIds?: string[];
    responsibleUserIds?: number[];
    weekStart?: Date;
    expiredStagesFilter?: boolean;
}

export interface CalendarUserConfig {
    filters?: Filters;
}

export interface ResponsibleUser {
    id: number;
    name: string;
    organizationId: string;
}

export interface DivisionNames {
    [key: string]: string;
}

export interface CalendarGroupsNames {
    [key: string]: string;
}
export interface ActivityCardsGroup {
    id: string;
    organizationName: string;
    blockName: string;
    activities: ActivityCardParams[];
}

export interface ActivityCardParams {
    id: number;
    name: string;
    typeName: string;
    isActive: boolean;
    startDate: Date;
    color: ActivityTypeColor;
    tasks: Task[];
    stagesInfo: StagesInfo;
    needStages: boolean;
}

export interface ActivityTypeColor {
    realization: string;
    preparation: string;
    hover: string;
}

export const DEFAULT_TYPE_COLOR: ActivityTypeColor = {
    realization: '#95bdec',
    preparation: '#cadef6',
    hover: '#578ac7',
};

export interface StagesInfo {
    stagesCount: number;
    finishedStagesCount: number;
    hasExpiredStages: boolean;
}

export type GroupedActivities = { [blockId: string]: Activity[] };
export type GroupedBlocks = { [organizationId: string]: PlainDictionary[] };
export type GroupedDivisions = { [blockId: string]: PlainDictionary[] };
export type GroupedTasks = { [activityId: number]: Task[] };

export const enum LineType {
    GroupTitle = 'groupTitle',
    Activity = 'activity',
    Task = 'task',
}

export interface OrganizationGroups {
    id: string;
    name: string;
    groups: LinesGroup[];
}

export interface LinesGroup {
    id: string;
    lines: LineParams[];
    activitiesCount: number;
    isExpanded: boolean;
}

export type LineParams = GroupTitleParams | ActivityLineParams | TaskLineParams;

export interface BaseLineParams {
    type: LineType;
    title: string;
}

export interface GroupTitleParams extends BaseLineParams {
    id: string;
}

export interface ActivityLineParams extends BaseLineParams {
    id: number;
    isExpanded: boolean;
    isActive: boolean;
    hasTasks: boolean;
    hasExpiredStages: boolean;
    preparationStart: number;
    realizationStart: number;
    realizationEnd: number;
    debriefingEnd: number;
    isOneDayActivity: boolean;
    color: ActivityTypeColor;
}

export interface TaskLineParams extends BaseLineParams {
    id: string;
    activityId: number;
    activityIsActive: boolean;
    deadline: number;
    deadlineExpired: boolean;
    taskIsClosed: boolean;
    color: ActivityTypeColor;
}

export interface Position {
    x: number;
    y: number;
}

export interface Direction {
    horizontal: HorizontalDirection;
    vertical: VerticalDirection;
}

export const enum HorizontalDirection {
    Left = 'left',
    Center = 'center',
    Right = 'right',
}

export const enum VerticalDirection {
    Up = 'up',
    Down = 'down',
}
