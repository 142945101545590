// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-client-modules-onboarding-pages-OnboardingPage-_OnboardingPage__root{font-family:'Open Sans', sans-serif;min-height:100vh}.src-client-modules-onboarding-pages-OnboardingPage-_OnboardingPage__wrapper{max-width:990px}\n", "",{"version":3,"sources":["webpack://./src/client/modules/onboarding/pages/OnboardingPage/OnboardingPage.scss"],"names":[],"mappings":"AAAA,0EACE,mCAAoC,CACpC,gBAAiB,CAClB,6EAGC,eAAgB","sourcesContent":[".root {\n  font-family: 'Open Sans', sans-serif;\n  min-height: 100vh;\n}\n\n.wrapper {\n  max-width: 990px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-client-modules-onboarding-pages-OnboardingPage-_OnboardingPage__root",
	"wrapper": "src-client-modules-onboarding-pages-OnboardingPage-_OnboardingPage__wrapper"
};
export default ___CSS_LOADER_EXPORT___;
