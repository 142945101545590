import { Success, Failure } from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Month } from '@mrm/budget';

import { ImportFMPTableMenuState as State, LoadFMPTableResult, ComponentState } from './types';
import * as actions from './actions/sync';
import * as asyncActions from './actions/async';

class Reducer {
    public static makeInitialState(): State {
        return {
            componentState: ComponentState.Hidden,
            dataFetchingInProgress: false,
            dataUploadingInProgress: false,
            errorMessage: '',
            workbookDescriptor: null,
            fetchedData: [],
            budgetItems: {
                entities: [],
                byId: {},
            },
            targetMonth: Month.Jan,
        };
    }

    public static loadFMPTableStarted(state: State): State {
        return { ...state, dataFetchingInProgress: true };
    }

    public static loadFMPTableDone(state: State, payload: Success<any, LoadFMPTableResult>): State {
        return {
            ...state,
            ...payload.result,
            errorMessage: '',
            dataFetchingInProgress: false,
            componentState: ComponentState.ReadyToLoad,
        };
    }

    public static loadFMPTableError(state: State, payload: Failure<any, any>): State {
        return {
            ...state,
            dataFetchingInProgress: false,
            errorMessage: payload.error.message,
        };
    }

    public static initCorrectionsStarted(state: State): State {
        return { ...state, dataUploadingInProgress: true };
    }

    public static initCorrectionsEnded(state: State): State {
        return { ...state, dataUploadingInProgress: false };
    }

    public static setComponentState(state: State, componentState: ComponentState): State {
        return { ...state, componentState };
    }

    public static setErrorMessage(state: State, errorMessage: string): State {
        return { ...state, errorMessage };
    }

    public static setTargetMonth(state: State, targetMonth: Month): State {
        return { ...state, targetMonth };
    }
}

export const importFMPTableMenuReducer = reducerWithInitialState(Reducer.makeInitialState())
    .case(asyncActions.loadFMPTable.started, Reducer.loadFMPTableStarted)
    .case(asyncActions.loadFMPTable.done, Reducer.loadFMPTableDone)
    .case(asyncActions.loadFMPTable.failed, Reducer.loadFMPTableError)
    .case(asyncActions.initCorrections.started, Reducer.initCorrectionsStarted)
    .cases([asyncActions.initCorrections.done, asyncActions.initCorrections.failed], Reducer.initCorrectionsEnded)
    .case(actions.setComponentState, Reducer.setComponentState)
    .case(actions.setErrorMessage, Reducer.setErrorMessage)
    .case(actions.setTargetMonth, Reducer.setTargetMonth)
    .case(actions.resetComponentState, Reducer.makeInitialState);
