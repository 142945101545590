import { mapValues, keyBy, flatten, flattenDeep, head } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { TypeCreative, TypeCreativeValue, TypePlacementFieldEnum, TypeFileValue } from 'sber-marketing-types/backend';

import {
    Form,
    FieldValue,
    FieldValueText,
    FieldValueTextArr,
    FieldValueImage,
    FieldValueImageArr,
    FieldValueVideo,
    FieldValueVideoArr,
    FieldValueBundle,
    FieldValueStringId,
    FieldValueStringIdArr,
    FieldValueLongId,
    FieldValueLongIdArr,
} from '@store/autopilot/creativeForm/types';

export const mergeFormStateWithAutopilotCreatives = (form: Form, typeCreative: TypeCreative, specId: string): Form => {
    return {
        ...form,
        selectedSpecId: head(
            flattenDeep(
                typeCreative?.creativeGroups
                    ?.filter(({ engine }) => engine === specId)
                    ?.map(({ creatives }) => creatives.map(({ globSpecId }) => globSpecId)) || [],
            ),
        ),
        fields: mapValues(form.fields, (formField) => {
            const targetCreativeGroup = typeCreative?.creativeGroups?.find(({ engine }) => engine === specId);
            const targetField = targetCreativeGroup?.fields?.find(({ fieldId }) => fieldId === formField.meta.fieldId);

            return {
                ...formField,
                value: targetField?.value
                    ? convertValueByType(targetField.fieldType, targetField.value, formField.value.current)
                    : formField.value,
            };
        }),
        specs: mapValues(form.specs, (spec) => {
            return {
                ...spec,
                fields: mapValues(spec.fields, (formField) => {
                    const creatives = flatten(typeCreative?.creativeGroups?.map(({ creatives }) => creatives));
                    const creative = creatives?.find(
                        ({ specId, globSpecId }) =>
                            specId === formField.meta.creativeSpecId && globSpecId === spec.meta.creativeGroupSpecId,
                    );
                    const props = creative?.props?.find(({ fieldId }) => fieldId === formField.meta.fieldId);

                    return {
                        ...formField,
                        value:
                            props && props.value
                                ? convertValueByType(props.fieldType, props.value, formField.value.current)
                                : formField.value,
                    };
                }),
            };
        }),
    };
};

const convertValueByType = (
    type: TypePlacementFieldEnum,
    value: TypeCreativeValue,
    formFieldValue: FieldValue,
): { current: FieldValue; previous: FieldValue } => {
    switch (type) {
        case TypePlacementFieldEnum.Long:
            return {
                current: {
                    value: value.toString(),
                    error: null,
                },
                previous: {
                    value: value.toString(),
                    error: null,
                },
            };
        case TypePlacementFieldEnum.Money:
            return {
                current: {
                    value: value.toString(),
                    error: null,
                },
                previous: {
                    value: value.toString(),
                    error: null,
                },
            };
        case TypePlacementFieldEnum.Int:
            return {
                current: {
                    value: value.toString(),
                    error: null,
                },
                previous: {
                    value: value.toString(),
                    error: null,
                },
            };
        case TypePlacementFieldEnum.Float:
            return {
                current: {
                    value: value.toString(),
                    error: null,
                },
                previous: {
                    value: value.toString(),
                    error: null,
                },
            };
        case TypePlacementFieldEnum.Href:
            return {
                current: {
                    value: value.toString(),
                    error: null,
                },
                previous: {
                    value: value.toString(),
                    error: null,
                },
            };
        case TypePlacementFieldEnum.Text:
            return ((): { current: FieldValueText; previous: FieldValueText } => {
                const responseValue = value as string;
                const convertedValue: FieldValueText = {
                    id: uuidv4(),
                    value: responseValue,
                    error: null,
                };
                return {
                    current: convertedValue,
                    previous: convertedValue,
                };
            })();
        case TypePlacementFieldEnum.TextArr:
            return ((): { current: FieldValueTextArr; previous: FieldValueTextArr } => {
                const responseValue = value as string[];
                const convertedValue: FieldValueTextArr = keyBy(
                    responseValue.map((value) => ({
                        id: uuidv4(),
                        value,
                        error: null,
                    })),
                    'id',
                );
                return {
                    current: convertedValue,
                    previous: convertedValue,
                };
            })();
        case TypePlacementFieldEnum.HrefArr:
            return {
                current: keyBy(
                    (value as string[]).map((item) => ({ id: uuidv4(), value: item, error: '' })),
                    'id',
                ),
                previous: keyBy(
                    (value as string[]).map((item) => ({ id: uuidv4(), value: item, error: '' })),
                    'id',
                ),
            };
        case TypePlacementFieldEnum.Image:
            return ((): { current: FieldValueImage; previous: FieldValueImage } => {
                const responseValue = value as TypeFileValue;
                const id = uuidv4();
                const convertedFile: FieldValueImage = {
                    id,
                    file: {
                        description: {
                            id: responseValue.fileId,
                            name: responseValue.originalFilename || '',
                            type: responseValue.contentType || 'png',
                            size: responseValue.size || 0,
                            downloadLink: responseValue.downloadLink || '',
                        },
                        entity: null,
                    },
                    error: null,
                    warning: null,
                };
                return {
                    current: convertedFile,
                    previous: convertedFile,
                };
            })();
        case TypePlacementFieldEnum.ImageArr:
            return ((): { current: FieldValueImageArr; previous: FieldValueImageArr } => {
                const responseValues = (value as TypeFileValue[]) || [];
                const convertedFiles = keyBy(
                    responseValues.map((responseValue) => ({
                        id: uuidv4(),
                        file: {
                            description: {
                                id: responseValue.fileId,
                                name: responseValue.originalFilename || '',
                                type: responseValue.contentType || 'png',
                                size: responseValue.size || 0,
                                downloadLink: responseValue.downloadLink || '',
                            },
                            entity: null,
                        },
                        error: null,
                        warning: null,
                    })),
                    'id',
                );
                return {
                    current: convertedFiles,
                    previous: convertedFiles,
                };
            })();
        case TypePlacementFieldEnum.Video:
            return ((): { current: FieldValueVideo; previous: FieldValueVideo } => {
                const responseValue = value as TypeFileValue;
                const convertedFile: FieldValueVideo = {
                    id: uuidv4(),
                    file: {
                        description: {
                            id: responseValue.fileId,
                            name: responseValue.originalFilename || '',
                            type: responseValue.contentType || 'png',
                            size: responseValue.size || 0,
                            downloadLink: responseValue.downloadLink,
                        },
                        entity: null,
                    },
                    error: null,
                    warning: null,
                };
                return {
                    current: convertedFile,
                    previous: convertedFile,
                };
            })();
        case TypePlacementFieldEnum.VideoArr:
            return ((): { current: FieldValueVideoArr; previous: FieldValueVideoArr } => {
                const responseValues = (value as TypeFileValue[]) || [];
                const convertedFiles = keyBy(
                    responseValues.map((responseValue) => ({
                        id: uuidv4(),
                        file: {
                            description: {
                                id: responseValue.fileId,
                                name: responseValue.originalFilename || '',
                                type: responseValue.contentType || 'png',
                                size: responseValue.size || 0,
                                downloadLink: responseValue.downloadLink || '',
                            },
                            entity: null,
                        },
                        status: 'uploaded',
                        error: null,
                        warning: null,
                    })),
                    'id',
                );
                return {
                    current: convertedFiles,
                    previous: convertedFiles,
                };
            })();
        case TypePlacementFieldEnum.Bundle:
            return ((): { current: FieldValueBundle; previous: FieldValueBundle } => {
                const responseValue = value as TypeFileValue;
                const convertedValue: FieldValueBundle = {
                    id: uuidv4(),
                    file: {
                        description: {
                            id: responseValue.fileId,
                            name: responseValue.originalFilename || '',
                            type: responseValue.contentType || 'png',
                            size: responseValue.size || 0,
                            downloadLink: responseValue.downloadLink || '',
                        },
                        entity: null,
                    },
                    error: null,
                    warning: null,
                };
                return {
                    current: convertedValue,
                    previous: convertedValue,
                };
            })();
        case TypePlacementFieldEnum.StringId:
            return ((): { current: FieldValueStringId; previous: FieldValueStringId } => {
                const convertedValue = {
                    ...(formFieldValue as FieldValueStringId),
                    value: (value as string) || (formFieldValue as FieldValueStringId).value,
                };
                return {
                    current: convertedValue,
                    previous: convertedValue,
                };
            })();
        case TypePlacementFieldEnum.StringIdArr:
            return ((): { current: FieldValueStringIdArr; previous: FieldValueStringIdArr } => {
                const convertedValues = {
                    ...(formFieldValue as FieldValueStringId),
                    value: (value as string[]) || (formFieldValue as FieldValueStringIdArr).value,
                };
                return {
                    current: convertedValues,
                    previous: convertedValues,
                };
            })();
        case TypePlacementFieldEnum.LongId:
            return ((): { current: FieldValueLongId; previous: FieldValueLongId } => {
                const convertedValue = {
                    ...(formFieldValue as FieldValueLongId),
                    value: (value as string) || (formFieldValue as FieldValueLongId).value,
                };
                return {
                    current: convertedValue,
                    previous: convertedValue,
                };
            })();
        case TypePlacementFieldEnum.LongIdArr:
            return ((): { current: FieldValueLongIdArr; previous: FieldValueLongIdArr } => {
                const convertedValues = {
                    ...(formFieldValue as FieldValueLongIdArr),
                    value: (value as string[]) || (formFieldValue as FieldValueLongIdArr).value,
                };
                return {
                    current: convertedValues,
                    previous: convertedValues,
                };
            })();
        default:
            return {
                current: value.toString(),
                previous: value.toString(),
            };
    }
};
