import * as React from 'react';
import { Icon, IconType, Input_redesign as Input, InputTheme_redesign as InputTheme } from 'sber-marketing-ui';

import styles from './SearchInput.scss';

interface Props {
    searchQuery: string;
    onSearchInputChange(value: string): void;
    onSearchInputClearButtonClick(): void;
}

export function SearchInput({ searchQuery, onSearchInputChange, onSearchInputClearButtonClick }: Props): JSX.Element {
    return (
        <div className={styles.root}>
            <Input
                qaId="searchMenuInput"
                placeholder="Введите запрос"
                theme={InputTheme.Search}
                value={searchQuery}
                onInputChange={onSearchInputChange}
                autofocus
            />

            {searchQuery && (
                <div
                    className={styles.clearButton}
                    onClick={onSearchInputClearButtonClick}
                    {...{
                        'qa-id': 'searchMenuInputClearButton',
                    }}
                >
                    <Icon type={IconType.PROJECT_STAGED_CLOSE} svgSize={20} />
                </div>
            )}
        </div>
    );
}
