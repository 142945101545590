import { v4 as uuidv4 } from 'uuid';
import { ILPlacementSpec, ILCreativeFieldSpec, ILCreativeGroupSpec } from 'sber-marketing-types/backend';
import { Dictionary, flatten, keyBy } from 'lodash';
import { Form, Field, Spec, FieldValue } from '../types';

export class PlacementSpecToFormStoreConverter {
    public static async convert({ fieldSpecs, creativeGroupSpecs, name }: ILPlacementSpec): Promise<Form> {
        return {
            title: name,
            selectedSpecId: null,
            fields: await PlacementSpecToFormStoreConverter.convertResponseToFields(fieldSpecs),
            specs: await PlacementSpecToFormStoreConverter.convertCreativeGroupSpecsToFormSpecs(creativeGroupSpecs),
        };
    }

    private static async convertCreativeGroupSpecsToFormSpecs(
        creativeGroupSpecs: ILCreativeGroupSpec[],
    ): Promise<Dictionary<Spec>> {
        const convertedSpecs = await Promise.all(
            creativeGroupSpecs?.map((creativeGroupSpec) =>
                PlacementSpecToFormStoreConverter.convertResponseToSpec(creativeGroupSpec),
            ) || [],
        );
        return keyBy(convertedSpecs, 'id');
    }

    private static async convertResponseToSpec({
        title,
        id: creativeGroupSpecId,
        creativeSpecs,
    }: ILCreativeGroupSpec): Promise<Spec> {
        return {
            id: uuidv4(),
            title,
            fields: await PlacementSpecToFormStoreConverter.convertResponseToFields(
                flatten(
                    creativeSpecs.map(({ id: creativeSpecId, fieldSpecs }) =>
                        fieldSpecs.map((fieldSpec) => ({ ...fieldSpec, creativeSpecId })),
                    ),
                ),
            ),
            meta: {
                creativeGroupSpecId,
            },
        };
    }

    private static async convertResponseToFields(
        fields: (ILCreativeFieldSpec & { creativeSpecId: string })[],
    ): Promise<Dictionary<Field>> {
        const convertedFields = await Promise.all(
            fields?.map((field) => PlacementSpecToFormStoreConverter.convertResponseToField(field)) || [],
        );
        return keyBy(convertedFields, 'id');
    }

    private static async convertResponseToField(
        field: ILCreativeFieldSpec & { creativeSpecId: string },
    ): Promise<Field> {
        const {
            fieldType,
            description,
            creativeSpecId,
            fieldId,
            fieldTitle,
            required,
            constraints,
            referenceId,
            defaultValue,
        } = field;

        return {
            id: uuidv4(),
            type: fieldType,
            value: {
                current: defaultValue || '',
                previous: defaultValue || '',
            },
            title: fieldTitle,
            error: '',
            description,
            required,
            constraints,
            referenceId,
            meta: {
                creativeSpecId,
                fieldId,
            },
        };
    }
}
