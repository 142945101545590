import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setAutoFreeze } from 'immer';

export interface IDPathParams<T> {
    id: T;
}

setAutoFreeze(false);

export const rtkApi = createApi({
    reducerPath: 'rtkApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
    }),
    tagTypes: [
        'activity',
        'task',
        'brief',
        'stage',
        'authUser',
        'user',
        'user-vacation',
        'work-type',
        'tag',
        'creative-request-comment',
        'creative-request-item',
        'task-participant',
        'task-participants',
    ],
    endpoints: () => ({}),
});
