import * as moment from 'moment';
import { v4 } from 'uuid';
import * as lodash from 'lodash';
import { createSelector } from 'reselect';
import { EditActivityParams } from 'sber-marketing-types/backend';

import { StoreState } from '@store';
import { getLoginUser } from '@store/user';

import { NewActivityPageState } from './types';
import { defineDictionaryOrganizationIdByOrganizationId } from './utils';

export const getNewActivityPageData = (state: StoreState): NewActivityPageState => state.newActivity;

export const makeCreateActivityData = createSelector(getNewActivityPageData, getLoginUser, (state, user) => {
    const {
        name,
        description,
        divisionId,
        productId,
        calendarGroupId,
        responsibleId,
        activityTypeId,
        preparationDate,
        realizationStart,
        realizationEnd,
        debriefingDate,
        autoCreateTasks,
    } = state.values;

    return {
        uuid: v4(),
        authorId: user.attributes.id,
        dictionaryOrganizationId: defineDictionaryOrganizationIdByOrganizationId(user.attributes.organizationId),
        name: name.trim(),
        description,
        divisionId,
        productId,
        calendarGroupId,
        responsibleId,
        typeId: activityTypeId,
        preparationDate: moment(preparationDate).format('YYYY-MM-DD'),
        realizationStart: moment(realizationStart).format('YYYY-MM-DD'),
        realizationEnd: moment(realizationEnd).format('YYYY-MM-DD'),
        debriefingDate: moment(debriefingDate).format('YYYY-MM-DD'),
        autoCreateTasks,
    };
});

export const makeUpdateActivityData = createSelector(getNewActivityPageData, (state) => {
    const {
        activity,
        values: {
            name,
            description,
            divisionId,
            responsibleId,
            activityTypeId,
            preparationDate,
            realizationStart,
            realizationEnd,
            debriefingDate,
            productId,
            calendarGroupId,
            autoCreateTasks,
            bpmWasInit,
        },
    } = state;

    const updateActivityData: any = {
        name: activity.name != name ? name.trim() : null,
        description: activity.description != description ? description : null,
        divisionId: activity.divisionId != divisionId ? divisionId : null,
        responsibleId: activity.responsibleId != responsibleId ? responsibleId : null,
        productId: activity.productId != productId ? productId : null,
        calendarGroupId: activity.calendarGroupId != calendarGroupId ? calendarGroupId : null,
        typeId: activity.typeId != activityTypeId ? activityTypeId : null,
        preparationDate: moment(preparationDate).format('YYYY-MM-DD'),
        realizationStart: moment(realizationStart).format('YYYY-MM-DD'),
        realizationEnd: moment(realizationEnd).format('YYYY-MM-DD'),
        debriefingDate: moment(debriefingDate).format('YYYY-MM-DD'),
    };

    if (!bpmWasInit && autoCreateTasks) {
        updateActivityData.autoCreateTasks = true;
    }

    return lodash.omitBy(updateActivityData, lodash.isNil) as EditActivityParams;
});
