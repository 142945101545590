import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as lodash from 'lodash';

import {
    PageState,
    FormData,
    FieldType,
    BriefLoading,
    FieldChangeParams,
    FileToRemove,
    BriefFile,
    BriefScheme,
    BriefBlock,
    Brief,
    FieldValue,
    activityBudgetRequiredFields,
    budgetItemRequiredToCreateFields,
    BriefsData,
} from './types';
import * as actions from './actions';

const REQUIRED_FIELD_ERROR = 'Обязательное поле';
const ACTIVITY_BUDGET_NAME_LENGTH_ERROR = 'Название активности не может быть короче 3 символов';
const BUDGET_ITEM_NAME_LENGTH_ERROR = 'Название проекта не может быть короче 3 символов';
const BUDGET_ITEM_DATES_RANGE_ERROR = 'Дата выходит за период Планирования';

export const initialState: PageState = {
    activityBudget: null,
    budgetItems: [],
    activityForm: {
        id: null,
        fields: [],
    },
    budgetItemForms: [],
    availableDictionaries: { byId: {}, byType: {} },
    usedDictionaries: { byId: {}, byType: {} },
    users: [],
    isNameInputFocused: false,
    originalBriefs: {},
    changedBriefs: {},
    briefsLoading: {},
    briefsSchemes: [],
    filesToRemove: [],
    briefOrganizations: [],
    activityAlreadyExists: false,
};

class Reducer {
    public static loadEditBudgetPage(state: PageState, payload: PageState): PageState {
        return { ...state, ...payload };
    }

    public static resetEditBudgetPage(state: PageState): PageState {
        return lodash.cloneDeep(initialState);
    }

    public static setBudgetCardCollapseStatus(state: PageState, payload: { id: string; status: boolean }): PageState {
        const updatedForms = lodash.clone(state.budgetItemForms);

        updatedForms.find((item) => item.id == payload.id).collapsed = payload.status;

        return { ...state, budgetItemForms: updatedForms };
    }

    public static addBudgetForm(state: PageState, payload: FormData): PageState {
        return { ...state, budgetItemForms: [payload, ...state.budgetItemForms] };
    }

    public static deleteBudgetForm(state: PageState, payload: string): PageState {
        const updatedBudgetItemForms = state.budgetItemForms.filter((item) => item.id !== payload);

        return { ...state, budgetItemForms: updatedBudgetItemForms };
    }

    public static setBudgetCardDeleteStatus(state: PageState, payload: { id: string; status: boolean }): PageState {
        const updatedForms = lodash.clone(state.budgetItemForms);

        updatedForms.find((updatedForm) => updatedForm.id === payload.id).deleted = payload.status;

        return { ...state, budgetItemForms: updatedForms };
    }

    public static updateActivityForm(state: PageState, payload: FormData): PageState {
        return { ...state, activityForm: { ...payload } };
    }

    public static updateBudgetForm(state: PageState, payload: FormData): PageState {
        const updatedBudgetItemForms = state.budgetItemForms.map((budgetItemForm) => {
            return budgetItemForm.id === payload.id ? { ...budgetItemForm, ...payload } : budgetItemForm;
        });

        return { ...state, budgetItemForms: updatedBudgetItemForms };
    }

    public static updateActivityFormValidation(state: PageState): PageState {
        const { activityForm } = state;

        const updatedFields = activityForm.fields.map((item) => {
            const isRequired = lodash.includes(activityBudgetRequiredFields, item.name);
            const hasValue = !!item.value;

            return { ...item, errorMessage: isRequired && !hasValue ? REQUIRED_FIELD_ERROR : '' };
        });

        const activityNameField = updatedFields.find((item) => item.name == 'name');

        const activityNameIsTooShort = activityNameField.value && (activityNameField.value as string).length < 3;

        if (activityNameIsTooShort) {
            activityNameField.errorMessage = ACTIVITY_BUDGET_NAME_LENGTH_ERROR;
        }

        return {
            ...state,
            activityForm: {
                ...activityForm,
                fields: updatedFields,
            },
        };
    }

    public static updateBudgetFormsValidation(state: PageState): PageState {
        const { budgetItemForms, yearForDataValidation } = state;
        const updatedForms = budgetItemForms.map((form) => {
            const formHasFilledMonthAmmountPair = form.fields.some(
                (item, index) =>
                    item.name === 'month' &&
                    item.value &&
                    form.fields[index + 1].name === 'plannedAmount' &&
                    form.fields[index + 1].value,
            );

            const updatedFields = form.fields.map((item) => {
                if (!item.disabled) {
                    const isRequired = lodash.includes(budgetItemRequiredToCreateFields, item.name);
                    // const isRequired = item.isRequired;
                    let hasValue = !!item.value;
                    if (item.type === 'select' && item.items && !item.items.length) {
                        hasValue = true; // no values means we should not need to fill this value
                    } else if (item.name === 'month' || item.name === 'plannedAmount') {
                        hasValue = formHasFilledMonthAmmountPair || !!item.value;
                    }

                    return { ...item, errorMessage: isRequired && !hasValue ? REQUIRED_FIELD_ERROR : '' };
                }

                return item;
            });

            const sapCommentField = updatedFields.find((item) => item.name == 'sapComment');

            const sapCommentIsTooShort = sapCommentField.value && (sapCommentField.value as string).length < 3;

            if (sapCommentIsTooShort) {
                sapCommentField.errorMessage = BUDGET_ITEM_NAME_LENGTH_ERROR;
            }

            const startDate = updatedFields.find((item) => item.name == 'startDate');
            const endDate = updatedFields.find((item) => item.name == 'endDate');
            [
                { field: startDate, allowedYears: yearForDataValidation ? [yearForDataValidation] : [] },
                {
                    field: endDate,
                    allowedYears: yearForDataValidation ? [yearForDataValidation, yearForDataValidation + 1] : [],
                },
            ].forEach((params) => {
                if (!params.field.value) {
                    params.field.errorMessage = REQUIRED_FIELD_ERROR;
                } else if (
                    params.allowedYears.length &&
                    !params.allowedYears.includes((params.field.value as moment.Moment).year())
                ) {
                    params.field.errorMessage = BUDGET_ITEM_DATES_RANGE_ERROR;
                } else {
                    params.field.errorMessage = '';
                }
            });

            const shouldExpandForm = updatedFields.some((item) => !!item.errorMessage);

            return {
                ...form,
                fields: updatedFields,
                collapsed: shouldExpandForm ? false : form.collapsed,
            };
        });

        return { ...state, budgetItemForms: updatedForms };
    }

    public static setNameInputFocus(state: PageState, isNameInputFocused: boolean): PageState {
        return { ...state, isNameInputFocused };
    }

    public static setBriefs(state: PageState, payload: lodash.Dictionary<Brief>): PageState {
        return {
            ...state,
            originalBriefs: payload,
            changedBriefs: payload,
        };
    }

    public static setBriefSchemes(state: PageState, schemes: BriefScheme[]): PageState {
        return { ...state, briefsSchemes: schemes };
    }

    public static setActivityInputFocus(
        state: PageState,
        payload: { budgetItemId: string; isFocused: boolean },
    ): PageState {
        const { budgetItemId, isFocused } = payload;

        const updatedForms: FormData[] = lodash.clone(state.budgetItemForms);

        const budgetItem = updatedForms.find((item) => item.id === budgetItemId);

        budgetItem.activityNameIsFocused = isFocused;

        return { ...state, budgetItemForms: updatedForms };
    }

    public static setBudgetItemTransferDestination(
        state: PageState,
        payload: { budgetItemId: string; activityBudgetId: string },
    ): PageState {
        const { budgetItemId, activityBudgetId } = payload;

        const updatedForms: FormData[] = lodash.clone(state.budgetItemForms);

        const budgetItemForm = updatedForms.find((item) => item.id === budgetItemId);

        budgetItemForm.transferDestinationId = activityBudgetId;

        return { ...state, budgetItemForms: updatedForms };
    }

    public static setBriefsData(state: PageState, payload: BriefsData): PageState {
        const { originalBriefs, changedBriefs, briefsLoading, briefsSchemes, budgetItemForms } = payload;

        const mergedBudgetItemForm = state.budgetItemForms.map((budgetItemFormsFromState) => {
            const targetBudgetItem = budgetItemForms.find(({ id }) => budgetItemFormsFromState.id === id);

            if (targetBudgetItem) {
                return {
                    ...budgetItemFormsFromState,
                    briefId: targetBudgetItem.briefId,
                };
            }

            return budgetItemFormsFromState;
        });

        return {
            ...state,
            originalBriefs,
            changedBriefs,
            briefsLoading,
            briefsSchemes,
            budgetItemForms: mergedBudgetItemForm,
        };
    }

    public static setBrief(state: PageState, brief: Brief): PageState {
        return {
            ...state,
            originalBriefs: {
                ...state.originalBriefs,
                [brief.id]: brief,
            },
            changedBriefs: {
                ...state.changedBriefs,
                [brief.id]: brief,
            },
        };
    }

    public static setChangedBrief(state: PageState, payload: Brief): PageState {
        return {
            ...state,
            changedBriefs: {
                ...state.changedBriefs,
                [payload.id]: payload,
            },
        };
    }

    public static setBriefLoading(state: PageState, briefLoading: BriefLoading): PageState {
        return {
            ...state,
            briefsLoading: {
                ...state.briefsLoading,
                [briefLoading.briefId]: {
                    ...state.briefsLoading[briefLoading.briefId],
                    loading: briefLoading.loading,
                },
            },
        };
    }

    public static setFieldValue(state: PageState, payload: FieldChangeParams): PageState {
        const { briefId, fieldId, uniqId = 0, parentUniqId = 0, value } = payload;
        const { changedBriefs } = state;

        const clonedCurrentBrief = lodash.cloneDeep(changedBriefs[briefId]);

        const blocks = (clonedCurrentBrief.blocks as BriefBlock[]) || [];
        const fields = lodash.flatMap(blocks, (item) => item.fields);
        const field = fields.find(
            (item) => item.id === fieldId && (item.uniqId || 0) === uniqId && (item.parentUniqId || 0) === parentUniqId,
        );

        if (field) {
            field.value = value;

            if (field.type === FieldType.TOGGLE && value.togglePosition === 'left') {
                function toggleChild(blockId: string) {
                    blocks.forEach((block) => {
                        if (block.briefBlockId === blockId) {
                            const fieldChild = block.fields.find(
                                (item) =>
                                    item.type === FieldType.TOGGLE &&
                                    (item.uniqId || 0) === uniqId &&
                                    (item.parentUniqId || 0) === parentUniqId,
                            );
                            if (fieldChild) {
                                fieldChild.value = { togglePosition: 'left' };
                            }
                            toggleChild(block.id);
                        }
                    });
                }
                blocks.forEach((block) => {
                    if (block.id === field.briefBlockId) {
                        toggleChild(block.id);
                    }
                });
            }
        } else {
            const fieldOrigin = fields.find((item) => item.id == fieldId);
            const blockOrigin = blocks.find((item) => item.id == fieldOrigin.briefBlockId);

            blockOrigin.fields.push({
                ...fieldOrigin,
                value: value,
                uniqId,
                parentUniqId,
            });
        }

        return {
            ...state,
            changedBriefs: {
                ...changedBriefs,
                [briefId]: clonedCurrentBrief,
            },
        };
    }

    public static setValidationDisplayStatus(state: PageState, payload: boolean): PageState {
        return { ...state, displayValidation: payload };
    }

    public static setFileListToRemove(state: PageState, payload: FileToRemove[]): PageState {
        return { ...state, filesToRemove: payload };
    }

    public static removeMarkedFilesFromBrief(state: PageState): PageState {
        const clonedCurrentBriefs = lodash.cloneDeep(state.changedBriefs);
        const briefs = lodash.values(clonedCurrentBriefs);
        const blocks = lodash.flatMap(briefs.map((brief) => brief.blocks)) || [];
        const fields = lodash.flatMap(blocks, (item) => item.fields);
        const fileFields = fields.filter((item) => item.type == FieldType.FILE);
        const filesToRemove = lodash.keyBy(state.filesToRemove, (item: FileToRemove) => item.fileName);

        fileFields.forEach((field) => {
            const files: BriefFile[] = lodash.get(field, 'value.files') || [];

            if (files) {
                field.value = {
                    files: files.filter(({ name }) => !filesToRemove[name]),
                } as FieldValue;
            }
        });

        return { ...state, changedBriefs: clonedCurrentBriefs };
    }

    public static setActivityAlreadyExists(state: PageState, activityAlreadyExists: boolean): PageState {
        return { ...state, activityAlreadyExists };
    }
}

export const plannedBudgetEditPageReducer = reducerWithInitialState(initialState)
    .case(actions.loadEditBudgetPage, Reducer.loadEditBudgetPage)
    .case(actions.resetEditBudgetPage, Reducer.resetEditBudgetPage)
    .case(actions.setBudgetCardCollapseStatus, Reducer.setBudgetCardCollapseStatus)
    .case(actions.addBudgetForm, Reducer.addBudgetForm)
    .case(actions.deleteBudgetForm, Reducer.deleteBudgetForm)
    .case(actions.setBudgetCardDeletedStatus, Reducer.setBudgetCardDeleteStatus)
    .case(actions.updateActivityForm, Reducer.updateActivityForm)
    .case(actions.updateBudgetForm, Reducer.updateBudgetForm)
    .case(actions.updateActivityFormValidation, Reducer.updateActivityFormValidation)
    .case(actions.updateBudgetFormsValidation, Reducer.updateBudgetFormsValidation)
    .case(actions.setNameInputFocus, Reducer.setNameInputFocus)
    .case(actions.setActivityInputFocus, Reducer.setActivityInputFocus)
    .case(actions.setBudgetItemTransferDestination, Reducer.setBudgetItemTransferDestination)
    .case(actions.setBrief, Reducer.setBrief)
    .case(actions.setChangedBrief, Reducer.setChangedBrief)
    .case(actions.setBriefs, Reducer.setBriefs)
    .case(actions.setBriefSchemes, Reducer.setBriefSchemes)
    .case(actions.setBriefsData, Reducer.setBriefsData)
    .case(actions.setFieldValue, Reducer.setFieldValue)
    .case(actions.setBriefLoading, Reducer.setBriefLoading)
    .case(actions.setFileListToRemove, Reducer.setFileListToRemove)
    .case(actions.removeMarkedFilesFromBrief, Reducer.removeMarkedFilesFromBrief)
    .case(actions.setActivityAlreadyExists, Reducer.setActivityAlreadyExists);
