import createCashedSelector from 're-reselect';
import { PlainDictionary } from '@mrm/dictionary';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';

import { ProductsState, StoreTypes } from './types';

const getProductsState = (state: StoreState): ProductsState => state.products;

export const getFetchersCount = createCashedSelector(
    getProductsState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: ProductsState, store: StoreTypes): number => state.stores[store].fetchersCount,
)((state: StoreState, store: StoreTypes): StoreTypes => store);

export const getProductById = createCashedSelector(
    getProductsState,
    (state: StoreState, id: string): string => id,
    (state: ProductsState, id: string): PlainDictionary => state.byIds[id],
)((state: StoreState, id: string): string => id);

export const getProducts = createCashedSelector(
    getProductsState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: ProductsState, store: StoreTypes): PlainDictionary[] => {
        const entitiesStore = state.stores[store];

        if (entitiesStore.loadingStatus === LoadingStatus.LOADED) {
            return state.stores[store].ids.map((id) => state.byIds[id]);
        }

        return [];
    },
)((state: StoreState, store: StoreTypes): StoreTypes => store);

export const getProductIds = createCashedSelector(
    getProductsState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: ProductsState, store: StoreTypes): string[] => state.stores[store].ids,
)((state: StoreState, store: StoreTypes): StoreTypes => store);

export const getLoadingStatus = createCashedSelector(
    getProductsState,
    (state: StoreState, store: StoreTypes): StoreTypes => store,
    (state: ProductsState, store: StoreTypes): LoadingStatus => state.stores[store].loadingStatus,
)((state: StoreState, store: StoreTypes): StoreTypes => store);
