import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as lodash from 'lodash';
import * as moment from 'moment';

import type { BriefDraft, BriefBlock, Brief, FieldValue, BriefFile, BriefHistory } from 'sber-marketing-types/frontend';
import { BriefScheme, FieldType } from 'sber-marketing-types/frontend';
import type {
    PageState,
    FieldChangeParams,
    FileToRemove,
    TaskDeadlineChangesParams,
    BriefLoading,
    ActivityReferenceData,
} from './types';
import type { BudgetItem } from '@mrm/budget';

import * as actions from './actions';

const initialState: PageState = {
    initialActivity: null,
    changedActivity: null,
    initialTasks: {},
    changedTasks: {},
    budgetItem: null,
    briefs: {},
    currentBriefs: {},
    briefsLoading: {},
    schemes: [],
    users: [],
    currentBriefWasCopied: false,
    filesToRemove: [],
    displayValidation: false,
    activityReferenceData: {
        displayActivityReferencesMenu: false,
        selectedActivityId: null,
        selectedExpertId: null,
        budgetItems: [],
        selectedBudgetItemIds: [],
    },
};

export class BriefReducer {
    public static loadBriefPage(state: PageState, payload: PageState): PageState {
        return { ...state, ...payload };
    }

    public static resetBriefPage(): PageState {
        return lodash.cloneDeep(initialState);
    }

    public static applyBriefDraft(state: PageState, payload: BriefDraft): PageState {
        const { changedFields, briefId } = payload;

        const combinedBrief = lodash.cloneDeep(state.briefs[briefId]);

        const fields = lodash.flatMap(combinedBrief.blocks, (item) => item.fields);

        fields.forEach((field) => {
            const foundField = changedFields.find(
                (item) =>
                    item.id == field.id && item.uniqId === field.uniqId && item.parentUniqId === field.parentUniqId,
            );

            if (foundField) {
                field.value = foundField.value;
            }
        });

        return {
            ...state,
            currentBriefs: {
                ...state.currentBriefs,
                [briefId]: combinedBrief,
            },
        };
    }

    public static setCurrentBrief(state: PageState, payload: Brief): PageState {
        return {
            ...state,
            currentBriefs: {
                ...state.currentBriefs,
                [payload.id]: payload,
            },
        };
    }

    public static setCurrentBriefWasCopied(state: PageState, payload: boolean): PageState {
        return { ...state, currentBriefWasCopied: payload };
    }

    public static setActivityPreparationDate(state: PageState, payload: moment.Moment): PageState {
        return {
            ...state,
            changedActivity: {
                ...state.changedActivity,
                preparationDate: payload,
            },
        };
    }

    public static setActivityRealizationStart(state: PageState, payload: moment.Moment): PageState {
        return {
            ...state,
            changedActivity: {
                ...state.changedActivity,
                realizationStart: payload,
            },
        };
    }

    public static setActivityRealizationEnd(state: PageState, payload: moment.Moment): PageState {
        return {
            ...state,
            changedActivity: {
                ...state.changedActivity,
                realizationEnd: payload,
            },
        };
    }

    public static setActivityDebriefingDate(state: PageState, payload: moment.Moment): PageState {
        return {
            ...state,
            changedActivity: {
                ...state.changedActivity,
                debriefingDate: payload,
            },
        };
    }

    public static setTaskDeadlineDate(state: PageState, { briefId, deadline }: TaskDeadlineChangesParams): PageState {
        return {
            ...state,
            changedTasks: {
                ...state.changedTasks,
                [briefId]: {
                    deadline,
                },
            },
        };
    }

    public static setBudgetItem(state: PageState, payload: BudgetItem): PageState {
        return {
            ...state,
            budgetItem: payload,
        };
    }

    public static setFieldValue(state: PageState, payload: FieldChangeParams): PageState {
        const { briefId, fieldId, uniqId = 0, parentUniqId = 0, value } = payload;
        const { currentBriefs } = state;

        const clonedCurrentBrief = lodash.cloneDeep(currentBriefs[briefId]);

        const blocks = (clonedCurrentBrief.blocks as BriefBlock[]) || [];
        const fields = lodash.flatMap(blocks, (item) => item.fields);
        const field = fields.find(
            (item) => item.id === fieldId && (item.uniqId || 0) === uniqId && (item.parentUniqId || 0) === parentUniqId,
        );

        if (field) {
            field.value = value;

            if (field.type === FieldType.TOGGLE && value.togglePosition === 'left') {
                function toggleChild(blockId: string) {
                    blocks.forEach((block) => {
                        if (block.briefBlockId === blockId) {
                            const fieldChild = block.fields.find(
                                (item) =>
                                    item.type === FieldType.TOGGLE &&
                                    (item.uniqId || 0) === uniqId &&
                                    (item.parentUniqId || 0) === parentUniqId,
                            );
                            if (fieldChild) {
                                fieldChild.value = { togglePosition: 'left' };
                            }
                            toggleChild(block.id);
                        }
                    });
                }
                blocks.forEach((block) => {
                    if (block.id === field.briefBlockId) {
                        toggleChild(block.id);
                    }
                });
            }
        } else {
            const fieldOrigin = fields.find((item) => item.id == fieldId);
            const blockOrigin = blocks.find((item) => item.id == field.briefBlockId);

            blockOrigin.fields.push({
                ...fieldOrigin,
                value: value,
                uniqId,
                parentUniqId,
            });
        }

        return {
            ...state,
            currentBriefs: {
                ...currentBriefs,
                [briefId]: clonedCurrentBrief,
            },
        };
    }

    public static setBlockDouble(state: PageState, payload: any): PageState {
        const { briefId, tree, blocks } = payload;
        const { currentBriefs } = state;

        const clonedCurrentBrief = lodash.cloneDeep(currentBriefs[briefId]);

        clonedCurrentBrief.tree = tree;
        const blocksOrigin = (clonedCurrentBrief.blocks as BriefBlock[]) || [];
        blocks.forEach((block) => {
            const blockOrigin = blocksOrigin.find((item) => item.id === block.id);
            block.fields.forEach((field) => {
                const fieldOrigin = blockOrigin.fields.find(
                    (item) =>
                        item.uniqId === field.uniqId &&
                        item.id === field.id &&
                        item.parentUniqId === field.parentUniqId,
                );
                if (!fieldOrigin) {
                    blockOrigin.fields.push(field);
                }
            });
        });

        return {
            ...state,
            currentBriefs: {
                ...currentBriefs,
                [briefId]: clonedCurrentBrief,
            },
        };
    }

    public static removeBlockDouble(state: PageState, payload: any): PageState {
        const { briefId, tree, blocks } = payload;
        const { currentBriefs } = state;

        const clonedCurrentBrief = lodash.cloneDeep(currentBriefs[briefId]);

        clonedCurrentBrief.tree = tree;
        const blocksOrigin = (currentBriefs[briefId].blocks as BriefBlock[]) || [];
        const blocksUpdated = (clonedCurrentBrief.blocks as BriefBlock[]) || [];
        blocksOrigin.forEach((blockOrigin) => {
            const block = blocks.find((item) => item.id === blockOrigin.id);
            const blockUpdated = blocksUpdated.find((item) => item.id === blockOrigin.id);
            blockOrigin.fields.forEach((field) => {
                const fieldIndex = block.fields.findIndex(
                    (item) =>
                        item.id === field.id &&
                        item.uniqId === field.uniqId &&
                        item.parentUniqId === field.parentUniqId,
                );

                if (fieldIndex === -1) {
                    blockUpdated.fields = blockUpdated.fields.filter(
                        (item) =>
                            !(
                                item.uniqId === field.uniqId &&
                                item.id === field.id &&
                                item.parentUniqId === field.parentUniqId
                            ),
                    );
                }
            });
        });

        return {
            ...state,
            currentBriefs: {
                ...currentBriefs,
                [briefId]: clonedCurrentBrief,
            },
        };
    }

    public static setFileListToRemove(state: PageState, payload: FileToRemove[]): PageState {
        return { ...state, filesToRemove: payload };
    }

    public static setValidationDisplayStatus(state: PageState, payload: boolean) {
        return { ...state, displayValidation: payload };
    }

    public static setBrief(state: PageState, brief: Brief): PageState {
        return {
            ...state,
            briefs: {
                ...state.briefs,
                [brief.id]: brief,
            },
            currentBriefs: {
                ...state.currentBriefs,
                [brief.id]: brief,
            },
            briefsLoading: {
                ...state.briefsLoading,
                [brief.id]: {
                    id: brief.id,
                    loading: false,
                },
            },
        };
    }

    public static setBriefSchemes(state: PageState, schemes: BriefScheme[]): PageState {
        return { ...state, schemes };
    }

    public static replaceBrief(state: PageState, brief: Brief): PageState {
        return {
            ...state,
            briefs: {
                [brief.id]: brief,
            },
            currentBriefs: {
                [brief.id]: brief,
            },
            briefsLoading: {
                [brief.id]: {
                    id: brief.id,
                    loading: false,
                },
            },
        };
    }

    public static setBriefLoading(state: PageState, briefLoading: BriefLoading): PageState {
        return {
            ...state,
            briefsLoading: {
                ...state.briefsLoading,
                [briefLoading.briefId]: {
                    ...state.briefsLoading[briefLoading.briefId],
                    loading: briefLoading.loading,
                },
            },
        };
    }

    public static removeMarkedFilesFromBrief(state: PageState) {
        const clonedCurrentBriefs = lodash.cloneDeep(state.currentBriefs);
        const briefs = lodash.values(clonedCurrentBriefs);
        const blocks = lodash.flatMap(briefs.map((brief) => brief.blocks)) || [];
        const fields = lodash.flatMap(blocks, (item) => item.fields);
        const fileFields = fields.filter((item) => item.type == FieldType.FILE);
        const filesToRemove = lodash.keyBy(state.filesToRemove, (item: FileToRemove) => item.fileName);

        fileFields.forEach((field) => {
            const files: BriefFile[] = lodash.get(field, 'value.files') || [];

            if (files) {
                field.value = {
                    files: files.filter(({ name }) => !filesToRemove[name]),
                } as FieldValue;
            }
        });

        return { ...state, currentBriefs: clonedCurrentBriefs };
    }

    public static setActivityReferenceData(state: PageState, payload: ActivityReferenceData): PageState {
        return {
            ...state,
            activityReferenceData: {
                ...state.activityReferenceData,
                ...payload,
            },
        };
    }

    public static setActivityReferenceMenuSelectedBudgetItemIds(
        state: PageState,
        selectedBudgetItemIds: string[],
    ): PageState {
        return {
            ...state,
            activityReferenceData: {
                ...state.activityReferenceData,
                selectedBudgetItemIds,
            },
        };
    }

    public static setBriefHistory(state: PageState, briefHistory: BriefHistory): PageState {
        return { ...state, briefHistory };
    }
}

export const briefReducer = reducerWithInitialState(initialState)
    .case(actions.loadBriefPage, BriefReducer.loadBriefPage)
    .case(actions.resetBriefPage, BriefReducer.resetBriefPage)
    .case(actions.replaceBrief, BriefReducer.replaceBrief)
    .case(actions.applyBriefDraft, BriefReducer.applyBriefDraft)
    .case(actions.setTaskDeadlineDate, BriefReducer.setTaskDeadlineDate)
    .case(actions.setBudgetItem, BriefReducer.setBudgetItem)
    .case(actions.setActivityPreparationDate, BriefReducer.setActivityPreparationDate)
    .case(actions.setActivityRealizationStart, BriefReducer.setActivityRealizationStart)
    .case(actions.setActivityRealizationEnd, BriefReducer.setActivityRealizationEnd)
    .case(actions.setActivityDebriefingDate, BriefReducer.setActivityDebriefingDate)
    .case(actions.setFieldValue, BriefReducer.setFieldValue)
    .case(actions.setBlockDouble, BriefReducer.setBlockDouble)
    .case(actions.removeBlockDouble, BriefReducer.removeBlockDouble)
    .case(actions.setFileListToRemove, BriefReducer.setFileListToRemove)
    .case(actions.setCurrentBrief, BriefReducer.setCurrentBrief)
    .case(actions.setCurrentBriefWasCopied, BriefReducer.setCurrentBriefWasCopied)
    .case(actions.setValidationDisplayStatus, BriefReducer.setValidationDisplayStatus)
    .case(actions.setBrief, BriefReducer.setBrief)
    .case(actions.setBriefLoading, BriefReducer.setBriefLoading)
    .case(actions.setBriefSchemes, BriefReducer.setBriefSchemes)
    .case(actions.removeMarkedFilesFromBrief, BriefReducer.removeMarkedFilesFromBrief)
    .case(actions.setActivityReferenceData, BriefReducer.setActivityReferenceData)
    .case(
        actions.setActivityReferenceMenuSelectedBudgetItemIds,
        BriefReducer.setActivityReferenceMenuSelectedBudgetItemIds,
    )
    .case(actions.setBriefHistory, BriefReducer.setBriefHistory);
