import { isEmpty, values } from 'lodash';

import { TypePlacementFieldEnum } from 'sber-marketing-types/backend';

import {
    Field,
    FieldTypeEnum,
    FieldValueHref,
    FieldValueHrefArr,
    FieldValueText,
    FieldValueTextArr,
    FieldValueImage,
    FieldValueImageArr,
    FieldValueVideo,
    FieldValueVideoArr,
    FieldValueBundle,
    FieldValueFloat,
    FieldValueMoney,
} from '@store/autopilot/creativeForm';

interface ValidateFormFieldsError {
    (formField: Field): boolean;
}

type GetFormSpec = {
    [P in FieldTypeEnum]: (field: Field) => boolean;
};

const validatorsByFieldTypeMap: GetFormSpec = {
    [TypePlacementFieldEnum.Image]: (field: Field<FieldValueImage>) => isEmpty(field.value.current?.error),
    [TypePlacementFieldEnum.ImageArr]: (field: Field<FieldValueImageArr>) =>
        values(field.value.current).every((value) => isEmpty(value?.error)),
    [TypePlacementFieldEnum.Video]: (field: Field<FieldValueVideo>) => isEmpty(field.value.current?.error),
    [TypePlacementFieldEnum.VideoArr]: (field: Field<FieldValueVideoArr>) =>
        values(field.value.current).every((value) => isEmpty(value?.error)),
    [TypePlacementFieldEnum.Bundle]: (field: Field<FieldValueBundle>) => isEmpty(field.value.current?.error),
    [TypePlacementFieldEnum.Text]: (field: Field<FieldValueText>) => isEmpty(field.value.current?.error),
    [TypePlacementFieldEnum.TextArr]: (field: Field<FieldValueTextArr>) =>
        values(field.value.current).every((value) => isEmpty(value?.error)),
    [TypePlacementFieldEnum.Href]: (field: Field<FieldValueHref>) => isEmpty(field.value.current?.error),
    [TypePlacementFieldEnum.HrefArr]: (field: Field<FieldValueHrefArr>) =>
        values(field.value.current).every((value) => isEmpty(value?.error)),
    [TypePlacementFieldEnum.Float]: (field: Field<FieldValueFloat>) => isEmpty(field.value.current?.error),
    [TypePlacementFieldEnum.Int]: (field: Field<FieldValueFloat>) => isEmpty(field.value.current?.error),
    [TypePlacementFieldEnum.Boolean]: () => true,
    [TypePlacementFieldEnum.StringId]: () => true,
    [TypePlacementFieldEnum.StringIdArr]: () => true,
    [TypePlacementFieldEnum.Long]: (field: Field<FieldValueMoney>) => isEmpty(field.value.current?.error),
    [TypePlacementFieldEnum.LongId]: () => true,
    [TypePlacementFieldEnum.LongIdArr]: () => true,
    [TypePlacementFieldEnum.Unknown]: () => true,
    [TypePlacementFieldEnum.Money]: (field: Field<FieldValueMoney>) => isEmpty(field.value.current?.error),
};

export const validateFormFieldsError: ValidateFormFieldsError = (field) => {
    const validate = validatorsByFieldTypeMap[field.type];
    return validate(field);
};
