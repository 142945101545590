import * as React from 'react';
import classNames from 'classnames';

import { StyledPopup } from 'sber-marketing-ui';

import { FileUploadErrorPopup } from './FileUploadErrorPopup';

import styles from './ErrorHandler.scss';

interface ErrorHandlerProps {
    errorCode?: string;
    errorMessage?: string;
    closePopup?: () => void;
    children?: React.ReactNode;
}

const PopupParams = {
    Validation: {
        title: 'Неправильный формат данных',
        fullMessage:
            'Напишите в чат технической поддержки в правом нижнем углу экрана, ' +
            'либо по адресу: support@sbermarketing.ru',
    },
    CvValidation: {}, // same as Validation
    PermissionDenied: {
        title: 'Недостаточно прав для выполнения действия',
        fullMessage:
            'Напишите в чат технической поддержки в правом нижнем углу экрана, ' +
            'либо по адресу: support@sbermarketing.ru',
    },
    Forbidden: {}, // same as PermisionDenied
    NotFound: {
        title: 'Запрашиваемые данные не найдены',
        fullMessage:
            'Напишите в чат технической поддержки в правом нижнем углу экрана, ' +
            'либо по адресу: support@sbermarketing.ru',
    },
    BadRequest: {
        title: 'Произошла неизвестная ошибка',
        fullMessage:
            'Напишите в чат технической поддержки в правом нижнем углу экрана, ' +
            'либо по адресу: support@sbermarketing.ru',
    },
    GatewayTimeout: {
        title: 'Сервер не отвечает',
        fullMessage:
            'Проверьте соединение с интернетом. Если с ним все в порядке, ' +
            'напишите в чат технической поддержки в правом нижнем углу экрана, либо ' +
            'по адресу: support@sbermarketing.ru',
    },
    InternalServer: {
        title: 'Произошла неизвестная ошибка',
        fullMessage:
            'Напишите в чат технической поддержки в правом нижнем углу экрана, ' +
            'либо по адресу: support@sbermarketing.ru',
    },
    FileUploadingError: {
        title: 'Загрузка файла не завершена',
        fullMessage:
            'Не удалось загрузить файл. Проверьте состояние файла, если с ним все в порядке - ' +
            'напишите в чат технической поддержки в правом нижнем углу экрана, либо по адресу: ' +
            'support@sbermarketing.ru',
    },
    FileDeletingError: {
        title: 'Удаление файла не завершено',
        fullMessage:
            'Не удалось удалить файл. Проверьте, существует ли еще этот файл, ' +
            'если проблема проявилась повторно - напишите в чат технической поддержки в ' +
            'правом нижнем углу экрана, либо по адресу: support@sbermarketing.ru',
    },
    ActivityWasDeleted: {
        title: 'Проект недоступен',
        fullMessage: 'Проект был удален или у вас больше нет к нему доступа',
    },
    TaskWasDeleted: {
        title: 'Задача недоступна',
        fullMessage: 'Задача была удалена или у вас больше нет к ней доступа',
    },
    ChannelNotFound: {
        title: 'Канал не найден',
        fullMessage: 'Запрашиваемый канал был удален или никогда не существовал',
    },
};
PopupParams.CvValidation = PopupParams.Validation;
PopupParams.Forbidden = PopupParams.PermissionDenied;

const VIRUS_DETECTED_ERROR_CODE = 'VirusDetection';

const activityWasDeletedErrorMessageRegex = /User with id: \d{1,5} has no access to activity with id: \d{1,10}/;
const taskWasDeletedErrorMessageRegex =
    /TaskViewModel with id: [a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12} not found/;
function getStyledPopupParams(errorCode: string, errorMessage: string) {
    if (errorMessage) {
        if (errorMessage.match(activityWasDeletedErrorMessageRegex)) {
            return PopupParams.ActivityWasDeleted;
        }

        if (errorMessage.match(taskWasDeletedErrorMessageRegex)) {
            return PopupParams.TaskWasDeleted;
        }
    }

    return PopupParams[errorCode];
}

/* tslint:enable no-magic-numbers */

export function ErrorHandler({ errorMessage, errorCode, closePopup, children }: ErrorHandlerProps): JSX.Element {
    let content: JSX.Element;
    if (errorCode === VIRUS_DETECTED_ERROR_CODE) {
        content = <FileUploadErrorPopup closePopup={closePopup} />;
    } else if (errorCode) {
        content = (
            <StyledPopup
                {...getStyledPopupParams(errorCode, errorMessage)}
                firstButtonText="ОК"
                firstButtonHandler={closePopup}
            />
        );
    } else {
        content = null;
    }

    return (
        <div className={styles.root}>
            {content}

            <div className={classNames(styles.content, errorCode && styles.contentHidden)}>{children}</div>
        </div>
    );
}
