import { BudgetItem, BudgetItemListQueryParams } from '@mrm/budget';

import { BudgetItemApi } from '@api';

import { rtkApi } from './rtkApi';

export const budgetItemApi = rtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getBudgetItems: builder.query<BudgetItem[], BudgetItemListQueryParams>({
            query: (body) => ({
                url: '/budgetItem/list',
                body,
                method: 'post',
            }),
        }),
        getBudgetItem: builder.query<BudgetItem, string>({
            query: (budgetId) => ({
                url: `/budgetItem/single/${budgetId}`,
                method: 'post',
            }),
        }),
        getBudgetItemsSerialNumbers: builder.query<number[], string[]>({
            queryFn: async (ids) => ({
                data: await Promise.all(ids.map(BudgetItemApi.getSerialNumber)),
            }),
        }),
    }),
});

export const { useGetBudgetItemQuery, useGetBudgetItemsQuery, useGetBudgetItemsSerialNumbersQuery } = budgetItemApi;
