import 'sber-marketing-ui/public/ui-components.css';
import 'react-circular-progressbar/dist/styles.css';
import 'minireset.css/minireset.css';

import './Page_.scss';

import * as React from 'react';
import inlineSvgSprite from 'inline-svg-sprite';
import { useSelector } from 'react-redux';

import { getWithoutNavMenu } from '@store/common';

import { HeaderViewProvider } from '@common/Page/helpers';

import { TagEmojiSprites } from './TagEmojiSprites';

import { PageRouter } from '../Router';
import { HeaderView } from './types';

import style from './page.scss';

export const Page: React.FC = () => {
    const headerView = React.useState<HeaderView>({});
    const [state, setState] = headerView;
    const withoutNavMenu = useSelector(getWithoutNavMenu);

    const setHeaderView = (view?: HeaderView) => {
        if (!view) {
            setState({});
            return;
        }

        setState(view);
    };

    return (
        <div className={style.root}>
            <HeaderViewProvider value={headerView}>
                <PageRouter
                    firstLine={state.firstLine}
                    additionalContent={state.additionalContent as JSX.Element}
                    setHeaderView={setHeaderView}
                    withoutNavMenu={withoutNavMenu}
                />
            </HeaderViewProvider>
            <div className={style.inlineIcons} dangerouslySetInnerHTML={{ __html: inlineSvgSprite }} />
            <div className={style.inlineTagEmoji}>
                <TagEmojiSprites />
            </div>
        </div>
    );
};
