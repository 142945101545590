import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import * as lodash from 'lodash';
import { BudgetItem, BudgetStatus, Filter, BudgetItemStatus } from '@mrm/budget';

import { BudgetItemApi, BudgetCorrectionApi } from '@api';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { getBudgetByStatusUserConfig } from '@store/userConfig/budget';
import { getTagsState } from '@store/tags';
import {
    ComponentState as BudgetTransferMenuComponentState,
    getBudgetTransferMenuState,
    toggleLineStatus,
} from '@store/budgetExecution/budgetTransferMenu';
import {
    ComponentState as TransferBudgetItemsToPlanningMenuComponentState,
    getTransferBudgetItemsToPlanningMenuState,
    isHoveredLineClickable,
    toggleBudgetItemForTransfer,
} from '@store/budgetExecution/transferBudgetItemsToPlanningMenu';

import * as actions from './actions';
import {
    getBudgetExecutionPageState,
    getFilteredTableLines,
    getLinesGroupedByActivities,
    makeLine,
    getDropdownsOptionsByLineId,
} from './selectors';
import {
    AppliedFiltersNames,
    BUDGET_ITEM_DICTIONARY_TYPES,
    Filters,
    ColumnName,
    ColumnNameToFilterMap,
    FACT_COLUMN_NAMES,
    MONTH_BY_COLUMN_NAMES,
    PLANNED_COLUMN_NAMES,
    RESERVED_COLUMN_NAMES,
    FilterKey,
    ChangeCellValueParams,
    TOTAL_PLAN_QUARTER_COLUMN_NAMES,
    TOTAL_RESERVE_QUARTER_COLUMN_NAMES,
    TOTAL_FACT_QUARTER_COLUMN_NAMES,
    ColumnsNameWithCodes,
    ColumnData,
    TableLine,
    CustomCellType,
    CellValueType,
    DownloadTableAsXLSXPaylaod,
    DropdownOptions,
} from './types';
import { getAppliedFilterValues, getColumnNameFromDictionaryType } from './utils';

import { LayerManager } from '@modules/budget/BudgetPage/BudgetExecution/Table/LayerManager';
import { ColumnsList } from '@modules/budget/BudgetPage/BudgetExecution/ColumnsConfig';
import { Utils } from '@modules/common/Utils';
import { getLoginUser } from '@store/user';

// injects tags into payload
export const changeCellValue = bindThunkAction<StoreState, ChangeCellValueParams, ChangeCellValueParams, Error>(
    actions.changeCellValueAsync,
    async (payload, dispatch, getState) => {
        const tags = getTagsState(getState()).byId.dictionary;

        return { ...payload, tags };
    },
);

export const loadFiltersByColumn = bindThunkAction<StoreState, FilterKey, void, Error>(
    actions.loadFiltersByColumnNameAsync,
    async (columnName, dispatch, getState) => {
        const state = getState();
        const { budgetId } = getBudgetByStatusUserConfig(state, BudgetStatus.Execution);
        const {
            columnFilters: { filters: existingColumnsFilters, loadingStatus },
            pageFilters: { appliedFiltersNames },
        } = getBudgetExecutionPageState(state);

        if (loadingStatus[columnName] === LoadingStatus.NOT_LOADED) {
            dispatch(
                actions.setFiltersLoadingStatus({
                    columnName,
                    loadingStatus: LoadingStatus.LOADING,
                }),
            );
            const filters = (
                await BudgetItemApi.getBudgetItemFilters({
                    budgetId,
                    column: ColumnNameToFilterMap[columnName],
                    filter: convertFilters(existingColumnsFilters, appliedFiltersNames, columnName),
                })
            ).reduce((acc, key) => {
                acc[key] = existingColumnsFilters[columnName][key] || false;

                return acc;
            }, {});
            dispatch(
                actions.loadFilters({
                    columnName,
                    filters,
                }),
            );
            dispatch(
                actions.setPreviouslyLoadedFilter({
                    columnName,
                    filter: filters,
                }),
            );
            dispatch(
                actions.setFiltersLoadingStatus({
                    columnName,
                    loadingStatus: LoadingStatus.LOADED,
                }),
            );
        }
    },
);

export const onLineClick = bindThunkAction<StoreState, string, void, Error>(
    actions.onLineClickAsync,
    async (lineId, dispatch, getState) => {
        const state = getState();

        const {
            controls: {
                isHoveredLineClickable: isHoveredLineClickableForTransition,
                componentState: budgetTransferMenuComponentState,
            },
        } = getBudgetTransferMenuState(state);

        const isLineSelectingModeEnabledForTransition =
            budgetTransferMenuComponentState === BudgetTransferMenuComponentState.InternalTransferLineSelection;

        const { componentState: transferBudgetItemsToPlanningMenuState } =
            getTransferBudgetItemsToPlanningMenuState(state).props;

        const isLineSelectionModeEnabledForLineTransferToPlanning =
            transferBudgetItemsToPlanningMenuState === TransferBudgetItemsToPlanningMenuComponentState.Opened;
        const isHoveredLineClickableForTransferToPlanning = isHoveredLineClickable(state);

        if (isLineSelectingModeEnabledForTransition && isHoveredLineClickableForTransition) {
            dispatch(toggleLineStatus(lineId));
        } else if (isLineSelectionModeEnabledForLineTransferToPlanning && isHoveredLineClickableForTransferToPlanning) {
            dispatch(toggleBudgetItemForTransfer(lineId));
        }
    },
);

export const downloadTableAsXLSX = bindThunkAction<StoreState, DownloadTableAsXLSXPaylaod, void, Error>(
    actions.downloadTableAsXLSXAsync,
    async (payload, dispatch, getState) => {
        const { useFilters } = payload;

        dispatch(actions.setPreloaderStatus(true));

        const state = getState();

        const user = getLoginUser(state);
        const {
            fixedColumnsNames,
            multiReferenceDictionaryApi,
            pageFilters: { columnsVisiblityFilter, sortingMode },
            unsavedChanges,
            computedData,
            pageData: { allUsers, activityBudgets, budget, users, allDictionaries },
        } = getBudgetExecutionPageState(state);
        const tags = getTagsState(state).byId.dictionary;

        const columnVisibilityKeys = Object.keys(columnsVisiblityFilter);
        const hasVisibleColumns = columnVisibilityKeys.some((columnName) => columnsVisiblityFilter[columnName]);
        const everyColumnIsVisible = columnVisibilityKeys.every(
            (columnName) => columnsVisiblityFilter[columnName] === columnsVisiblityFilter[columnVisibilityKeys[0]],
        );

        // generating array of columns in display order
        const visibleColumns: ColumnName[] = [
            ...fixedColumnsNames.filter((columnName) => everyColumnIsVisible || columnsVisiblityFilter[columnName]),
        ];

        for (const columnName in columnsVisiblityFilter) {
            if (hasVisibleColumns) {
                const shouldAddColumn =
                    columnsVisiblityFilter[columnName] && visibleColumns.indexOf(columnName as any) === -1;

                if (shouldAddColumn) {
                    visibleColumns.push(columnName as any);
                }
            } else {
                visibleColumns.push(columnName as any);
            }
        }

        // generating column data
        const columnsToUpload: lodash.Dictionary<ColumnData> = visibleColumns.reduce((acc, column) => {
            const columnData = ColumnsList.find((c) => c.name === column);

            return {
                ...acc,
                [column]: columnData,
            };
        }, {});

        // generating lines to process
        let lines: TableLine[];
        let dropdownOptions: DropdownOptions;
        if (useFilters) {
            const linesAreDisplayedByGroup = sortingMode.columnName == ColumnName.ActivityName;

            const filteredLines = getFilteredTableLines(state);
            const lineGroups = getLinesGroupedByActivities(state);

            lines = linesAreDisplayedByGroup
                ? lineGroups.reduce((acc, lineGroup) => [...acc, ...lineGroup.lines], [])
                : filteredLines;

            dropdownOptions = computedData.dropdownOptions;
        } else {
            lines = (
                await BudgetItemApi.getBudgetItemList({
                    budgetId: budget.id,
                    sort: [{ field: 'serialNumber', order: 'ASC' }],
                })
            )
                .filter((budgetItem) => budgetItem.status === BudgetItemStatus.OnExecution)
                .map((budgetItem) => makeLine(budgetItem, activityBudgets, users, tags, user));

            dropdownOptions = lines.reduce(
                (acc, line) => ({
                    ...acc,
                    [line.id]: getDropdownsOptionsByLineId(
                        line,
                        users,
                        allDictionaries,
                        unsavedChanges[line.id] || [],
                        multiReferenceDictionaryApi,
                    ),
                }),
                {} as DropdownOptions,
            );
        }
        const cells = LayerManager.getInstance().makeTalbeCellsParamsForXLSXExport(lines, dropdownOptions);

        function getCellValue(
            line: TableLine,
            cell: any,
            columnParams: ColumnData,
            valueTransformer: (value: number) => number,
        ): string | number {
            if (columnParams.name === ColumnName.Responsible) {
                const ids: string[] = cell.value ? cell.value.split(',') : null;

                return ids
                    ? ids
                          .map((id) => {
                              const user = allUsers.find((user) => user.id == (id as any));
                              return user ? `${user.secondName} ${user.firstName}` : 'Значение не найдено';
                          })
                          .join(', ')
                    : '-';
            }

            if (columnParams.customCellType === CustomCellType.Dropdown) {
                const selectedOptions = cell.options.find((option: any) => option.id === cell.value);

                if (selectedOptions) {
                    return selectedOptions.title;
                }

                const column = ColumnsList.find((column) => column.name === columnParams.name);
                const budgetItemDictionary =
                    allDictionaries.byId[line.budgetItem?.dictionary?.[column.metaData?.dictionaryType]?.id];

                if (budgetItemDictionary) {
                    return ColumnsNameWithCodes.includes(columnParams.name)
                        ? budgetItemDictionary.code
                        : budgetItemDictionary.value;
                }

                return '-';
            }

            if (columnParams.valueType === CellValueType.Currency) {
                const value = cell.tooltip.replace(/ /g, '').replace(',', '.');

                return valueTransformer(value ? parseFloat(value as string) : 0);
            }

            return cell.title || '-';
        }

        function setColumnValue(row: object, optText = '') {
            return (row[' '] = optText);
        }

        function generateSumLine(data: any[], nDigits: number, titleTransformer: (title: string) => string): any {
            const sumRow = {};
            for (const column in columnsToUpload) {
                const columnParams = columnsToUpload[column];
                const title = titleTransformer(columnParams.title);

                if (columnParams.valueType === CellValueType.Currency) {
                    sumRow[title] = +data.reduce((acc, row) => acc + row[title], 0).toFixed(nDigits);
                } else {
                    sumRow[title] = '-';
                }
            }

            return sumRow;
        }

        // processing lines
        const rublesTitleTransofrmer = (title: string) => title.replace('тыс. ₽', '₽');
        const thousandsTitleTransformer = (title: string) => title;
        const rublesCurrencyData: any[] = lines.map((line: TableLine) => {
            const row = {};
            setColumnValue(row);

            for (const column in columnsToUpload) {
                const columnParams = columnsToUpload[column];

                row[rublesTitleTransofrmer(columnParams.title)] = getCellValue(
                    line,
                    cells[line.id][column],
                    columnParams,
                    (value) => +value.toFixed(2),
                );
            }

            return row;
        });

        const thousandsCurrencyData: any[] = lines.map((line: any) => {
            const row = {};
            setColumnValue(row);

            for (const column in columnsToUpload) {
                const columnParams = columnsToUpload[column];

                row[columnParams.title] = getCellValue(
                    line,
                    cells[line.id][column],
                    columnParams,
                    (value) => +(value / 1000.0).toFixed(5),
                );
            }

            return row;
        });

        // adding sum string
        const rublesSumLine = generateSumLine(rublesCurrencyData, 2, rublesTitleTransofrmer);
        const thousandsSumLine = generateSumLine(thousandsCurrencyData, 5, thousandsTitleTransformer);
        setColumnValue(rublesSumLine, 'Сумма:');
        setColumnValue(thousandsSumLine, 'Сумма:');

        rublesCurrencyData.push({});
        rublesCurrencyData.push(rublesSumLine);
        thousandsCurrencyData.push({});
        thousandsCurrencyData.push(thousandsSumLine);

        const result = {
            'В тысячах рублей': thousandsCurrencyData,
            'В рублях': rublesCurrencyData,
        };

        const xlsxContent: Buffer = await BudgetCorrectionApi.getDataAsXLSX(result);

        Utils.downloadAsXLSX(xlsxContent, 'Исполнение бюджета');

        dispatch(actions.setPreloaderStatus(false));
    },
);

export const updateDropdownsOptions = bindThunkAction<StoreState, DropdownOptions, void, Error>(
    actions.updateDropdownOptionsAsync,
    async (updDropdownOptions, dispatch, getState) => {
        const oldDropdownOptions = getBudgetExecutionPageState(getState()).computedData.dropdownOptions;

        const dropdownOptionsToSet: DropdownOptions = { ...oldDropdownOptions };

        Object.keys(updDropdownOptions).forEach((lineId) => {
            dropdownOptionsToSet[lineId] = !lodash.isEqual(updDropdownOptions[lineId], oldDropdownOptions[lineId])
                ? updDropdownOptions[lineId]
                : oldDropdownOptions[lineId];
        });

        dispatch(actions.setDropdownOptions(dropdownOptionsToSet));
    },
);

const convertFilters = (
    externalsFilters: Filters,
    appliedFiltersColumnNames: AppliedFiltersNames,
    targetFilterColumnName: FilterKey,
): Filter<BudgetItem> => {
    let filters: Filter<BudgetItem> = {};

    const parentColumnFilters = getParentColumnFilters({
        columnFilters: externalsFilters,
        targetFilterColumnName,
        appliedFiltersColumnNames,
    });

    const dictionary = buildDictionaryFromColumnFilters(parentColumnFilters);
    const donors = buildDonorFromColumnFilters(parentColumnFilters);
    const serialNumbers = buildSerialNumbersFromColumnFilters(parentColumnFilters);
    const comments = buildCommentFromColumnFilters(parentColumnFilters);
    const activityNames = buildActivityNamesFromColumnFilters(parentColumnFilters);
    const sapComments = buildSapCommentFromColumnFilters(parentColumnFilters);
    const responsibles = buildResponsiblesFromColumnFilters(parentColumnFilters);
    const realizationStart = buildRealizationStartFromColumnFilters(parentColumnFilters);
    const realizationEnd = buildRealizationEndFromColumnFilters(parentColumnFilters);
    const sapZnc = buildSapZncFromColumnFilters(parentColumnFilters);
    const sapNumbers = buildSapNumberColumnFilters(parentColumnFilters);
    const plannedFunds = buildPlannedFundsFilters(parentColumnFilters);
    const reservedFunds = buildReservedFundsFilters(parentColumnFilters);
    const factFunds = buildFactFundsFilters(parentColumnFilters);
    const computedFunds = buildComputedFundsFilters(parentColumnFilters);
    const authors = buildAuthorsFromColumnFilters(parentColumnFilters);
    const tags = buildTagsFromColumnFilters(parentColumnFilters);

    if (lodash.keys(dictionary).length) {
        filters = {
            ...filters,
            dictionary,
        };
    }

    if (donors.length) {
        filters = {
            ...filters,
            donors,
        };
    }

    if (serialNumbers.length) {
        filters = {
            ...filters,
            serialNumber: serialNumbers,
        };
    }

    if (comments.length) {
        filters = {
            ...filters,
            comment: comments,
        };
    }

    if (activityNames.length) {
        filters = {
            ...filters,
            activity: {
                name: activityNames,
            },
        };
    }

    if (sapComments.length) {
        filters = {
            ...filters,
            sapComment: sapComments,
        };
    }

    if (responsibles.length) {
        filters = {
            ...filters,
            responsibleIds: responsibles,
        };
    }

    if (realizationStart.length) {
        filters = {
            ...filters,
            realizationStart,
        };
    }

    if (realizationEnd.length) {
        filters = {
            ...filters,
            realizationEnd,
        };
    }

    if (sapZnc.length) {
        filters = {
            ...filters,
            sapZns: sapZnc,
        };
    }

    if (sapNumbers.length) {
        filters = {
            ...filters,
            sapNumber: sapNumbers,
        };
    }

    if (lodash.keys(plannedFunds).length) {
        filters = {
            ...filters,
            plannedFunds,
        };
    }

    if (lodash.keys(reservedFunds).length) {
        filters = {
            ...filters,
            reservedFunds,
        };
    }

    if (lodash.keys(factFunds).length) {
        filters = {
            ...filters,
            factFunds,
        };
    }

    if (lodash.keys(computedFunds).length) {
        filters = {
            ...filters,
            computedFunds,
        };
    }

    if (authors.id.length) {
        filters = {
            ...filters,
            author: authors,
        };
    }

    if (tags.length) {
        filters = {
            ...filters,
            tags,
        };
    }

    return filters;
};

const buildComputedFundsFilters = (columnFilters: Filters) => {
    let computedFundsFilters = {};

    const activityBalanceYear = getAppliedFilterValueKeysNumber(columnFilters[ColumnName.ActivityBalanceYear]);
    const factBalanceYear = getAppliedFilterValueKeysNumber(columnFilters[ColumnName.FactBalanceYear]);
    const totalPlan = getAppliedFilterValueKeysNumber(columnFilters[ColumnName.TotalPlan]);
    const totalReserved = getAppliedFilterValueKeysNumber(columnFilters[ColumnName.TotalReserve]);
    const totalFact = getAppliedFilterValueKeysNumber(columnFilters[ColumnName.TotalFact]);

    const quarterBalances = [
        ...TOTAL_PLAN_QUARTER_COLUMN_NAMES,
        ...TOTAL_RESERVE_QUARTER_COLUMN_NAMES,
        ...TOTAL_FACT_QUARTER_COLUMN_NAMES,
    ].reduce((filters, columnName) => {
        const quarterBalances = getAppliedFilterValueKeysNumber(columnFilters[columnName]);

        if (quarterBalances.length) {
            filters[columnName] = quarterBalances;
        }

        return filters;
    }, {});

    if (activityBalanceYear.length) {
        computedFundsFilters = {
            ...computedFundsFilters,
            activityBalanceYear,
        };
    }

    if (factBalanceYear.length) {
        computedFundsFilters = {
            ...computedFundsFilters,
            factBalanceYear,
        };
    }

    if (totalPlan.length) {
        computedFundsFilters = {
            ...computedFundsFilters,
            totalPlan,
        };
    }

    if (totalReserved.length) {
        computedFundsFilters = {
            ...computedFundsFilters,
            totalReserved,
        };
    }

    if (totalFact.length) {
        computedFundsFilters = {
            ...computedFundsFilters,
            totalFact,
        };
    }

    if (lodash.keys(quarterBalances).length) {
        computedFundsFilters = {
            ...computedFundsFilters,
            ...quarterBalances,
        };
    }

    return computedFundsFilters;
};

const buildFactFundsFilters = (columnFilters: Filters): any => {
    return FACT_COLUMN_NAMES.reduce((fact, month) => {
        const factMonth = getAppliedFilterValueKeysNumber(columnFilters[month]);

        return factMonth.length
            ? {
                  ...fact,
                  [MONTH_BY_COLUMN_NAMES[month]]: factMonth,
              }
            : fact;
    }, {});
};

const buildReservedFundsFilters = (columnFilters: Filters): any => {
    return RESERVED_COLUMN_NAMES.reduce((reserved, month) => {
        const reservedMonth = getAppliedFilterValueKeysNumber(columnFilters[month]);

        return reservedMonth.length
            ? {
                  ...reserved,
                  [MONTH_BY_COLUMN_NAMES[month]]: reservedMonth,
              }
            : reserved;
    }, {});
};

const buildPlannedFundsFilters = (columnFilters: Filters): any => {
    return PLANNED_COLUMN_NAMES.reduce((planned, month) => {
        const plannedMonth = getAppliedFilterValueKeysNumber(columnFilters[month]);

        return plannedMonth.length
            ? {
                  ...planned,
                  [MONTH_BY_COLUMN_NAMES[month]]: plannedMonth,
              }
            : planned;
    }, {});
};

const buildSapNumberColumnFilters = (columnFilters: Filters): string[] => {
    return getAppliedFilterValueKeysString(columnFilters[ColumnName.SapCorrectionNumber]);
};

const buildSapZncFromColumnFilters = (columnFilters: Filters): string[] => {
    return getAppliedFilterValueKeysString(columnFilters[ColumnName.SapZns]);
};

const buildRealizationStartFromColumnFilters = (columnFilters: Filters): string[] => {
    return getAppliedFilterValueKeysString(columnFilters[ColumnName.EndDate]);
};

const buildRealizationEndFromColumnFilters = (columnFilters: Filters): string[] => {
    return getAppliedFilterValueKeysString(columnFilters[ColumnName.StartDate]);
};

const buildResponsiblesFromColumnFilters = (columnFilters: Filters): number[] => {
    return getAppliedFilterValueKeysNumber(columnFilters[ColumnName.Responsible]);
};

const buildAuthorsFromColumnFilters = (columnFilters: Filters): { id: number[] } => {
    const authorIds = getAppliedFilterValueKeysNumber(columnFilters[ColumnName.Author]);
    return { id: authorIds };
};

const buildSapCommentFromColumnFilters = (columnFilters: Filters): string[] => {
    return getAppliedFilterValueKeysString(columnFilters[ColumnName.SapComment]);
};

const buildActivityNamesFromColumnFilters = (columnFilters: Filters): string[] => {
    return getAppliedFilterValueKeysString(columnFilters[ColumnName.ActivityName]);
};

const buildCommentFromColumnFilters = (columnFilters: Filters): string[] => {
    return getAppliedFilterValueKeysString(columnFilters[ColumnName.Comment]);
};

const buildSerialNumbersFromColumnFilters = (columnFilters: Filters): number[] => {
    return getAppliedFilterValueKeysNumber(columnFilters[ColumnName.Id]);
};

const buildDonorFromColumnFilters = (columnFilters: Filters): number[] => {
    return getAppliedFilterValueKeysNumber(columnFilters[ColumnName.DonorIds]);
};

const buildTagsFromColumnFilters = (columnFilters: Filters): string[] => {
    return getAppliedFilterValueKeysString(columnFilters[ColumnName.Tags]);
};

const buildDictionaryFromColumnFilters = (columnFilters: Filters): Record<string, boolean> => {
    const withBudgetItemFiltersInjected = BUDGET_ITEM_DICTIONARY_TYPES.reduce(
        (prevDictionary, budgetItemDictionaryTypes) => {
            const columnFilterName = getColumnNameFromDictionaryType(budgetItemDictionaryTypes);
            const appliedFilterValuesIds = getAppliedFilterValueKeysString(columnFilters[columnFilterName]);

            return appliedFilterValuesIds.length
                ? {
                      ...prevDictionary,
                      [budgetItemDictionaryTypes]: {
                          id: appliedFilterValuesIds,
                      },
                  }
                : prevDictionary;
        },
        {},
    );

    const withCodesFilteredInjected = ColumnsNameWithCodes.reduce((acc, codeColumn) => {
        const filterColumn = ColumnNameToFilterMap[codeColumn];
        const filterValues = Object.keys(columnFilters[codeColumn]).filter((key) => columnFilters[codeColumn][key]);

        if (filterValues.length) {
            // slice cuts 'dicitonary.' part of column name to set
            lodash.set(acc, filterColumn.slice(11), filterValues);
        }

        return acc;
    }, withBudgetItemFiltersInjected);

    return withCodesFilteredInjected;
};

interface FilterColumnsFiltersParams {
    columnFilters: Filters;
    targetFilterColumnName: FilterKey;
    appliedFiltersColumnNames: AppliedFiltersNames;
}

const NOT_FOUND_KEY = -1;

const getParentColumnFilters = ({
    columnFilters,
    targetFilterColumnName,
    appliedFiltersColumnNames,
}: FilterColumnsFiltersParams): Filters => {
    const countFilters = lodash.findIndex(
        appliedFiltersColumnNames,
        (filterColumnName) => filterColumnName === targetFilterColumnName,
    );

    const applyAllParentFilters = countFilters == NOT_FOUND_KEY;

    if (applyAllParentFilters) {
        return columnFilters;
    }

    const appliedParentFiltersColumnNames = lodash.take(appliedFiltersColumnNames, countFilters);

    return lodash.keys(columnFilters).reduce((newColumnFilters, columnName) => {
        return {
            ...newColumnFilters,
            [columnName]: lodash.includes(appliedParentFiltersColumnNames, columnName) ? columnFilters[columnName] : {},
        };
    }, {});
};

interface GetAppliedFilterValueName<T extends number | string> {
    (filter: Record<string, boolean>): T[];
}

const getAppliedFilterValueKeysString: GetAppliedFilterValueName<string> = (filter) => {
    return lodash.keys(getAppliedFilterValues(filter)).map((filterKey) => {
        return filterKey === 'null' ? null : filterKey;
    });
};

const getAppliedFilterValueKeysNumber: GetAppliedFilterValueName<number> = (filter) => {
    return lodash.keys(getAppliedFilterValues(filter)).map((filterKey) => {
        return filterKey === 'null' ? null : Number(filterKey);
    });
};
