import { combineReducers } from 'redux';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as lodash from 'lodash';
import * as moment from 'moment';

import type { ActivityParams as Activity } from 'sber-marketing-types/frontend';
import type {
    Autopilot2,
    Autopilot2MediaplanPlanDataResponse as Autopilot2MediaplanPlanData,
    Autopilot2MediaplanChannelsGroups,
    ILPlacementSpec,
} from 'sber-marketing-types/backend';
import type {
    PageState,
    BriefStageForm,
    MediaplanSettingsForm,
    SocialStageForm,
    GroupedDictionaries,
    AutopilotPageState,
} from './types';
import * as actions from './actions';
import { generationTextFormReducer } from './generationTextForm';
import { creativeFormReducer } from './creativeForm';

export const initialState: PageState = {
    autopilot: null,
    activity: null,
    mediaplan: null,
    dictionaries: {},
    mediaplanChannelsGroups: null,
    briefStageForm: {
        blockId: null,
        productId: null,
        budget: null,
        budgetError: null,
        dateStart: null,
        dateEnd: null,
        targetAgeFrom: null,
        targetAgeTo: null,
        targetAgeError: null,
        landingPage: null,
        peakDates: [],
        yandexMetrikaTarget: null,
    },
    mediaplanSettingsForm: {
        hasYearCompanies: false,
        conversion: 0,
        isManuallySet: false,
        channelGroups: null,
    },
    placementSpecs: [],
    selectedBudgetItemIds: [],
    changedCreativeStatuses: {},
    socialStageForm: {
        vkUrl: '',
        okUrl: '',
    },
    calculatingMediaplan: false,
    preloader: false,
    sidebar: {
        opened: true,
    },
    context: {
        titles: [],
        descriptions: [],
    },
};

export class Reducer {
    public static loadAutopilot(state: PageState, payload: Autopilot2): PageState {
        const { brief, mediaplanSettings, budget, social, ads } = payload;

        const briefStageForm = brief
            ? {
                  blockId: brief.block,
                  productId: brief.product,
                  budget: brief.budget ? brief.budget.toString() : null,
                  budgetError: null,
                  dateStart: moment(brief.dateStart),
                  dateEnd: moment(brief.dateEnd),
                  targetAgeFrom: brief.ageFrom ? brief.ageFrom.toString() : null,
                  targetAgeTo: brief.ageTo ? brief.ageTo.toString() : null,
                  targetAgeError: '',
                  landingPage: brief.landingPage,
                  peakDates: brief.peakDates.map((item) => new Date(item)),
                  yandexMetrikaTarget: brief.yandexMetrikaTarget,
              }
            : lodash.cloneDeep(initialState.briefStageForm);

        const mediaplanSettingsForm: MediaplanSettingsForm = mediaplanSettings
            ? {
                  hasYearCompanies: mediaplanSettings.hasYearCompanies,
                  conversion: mediaplanSettings.conversion,
                  isManuallySet: mediaplanSettings.isManuallySet,
                  channelGroups: mediaplanSettings.channelGroups,
              }
            : lodash.cloneDeep(initialState.mediaplanSettingsForm);

        const selectedBudgetItemIds = budget ? budget.budgetIds : [];

        const creativeStageForm = social
            ? {
                  vkUrl: social.vk,
                  okUrl: social.ok,
              }
            : lodash.cloneDeep(initialState.socialStageForm);

        return {
            ...state,
            autopilot: payload,
            briefStageForm,
            mediaplanSettingsForm,
            selectedBudgetItemIds,
            socialStageForm: creativeStageForm,
            context: {
                titles:
                    lodash.flatten(ads?.data?.filter(({ type }) => type === 'title')?.map(({ texts }) => texts)) || [],
                descriptions:
                    lodash.flatten(
                        ads?.data?.filter(({ type }) => type === 'description')?.map(({ texts }) => texts),
                    ) || [],
            },
        };
    }

    public static loadActivity(state: PageState, payload: Activity): PageState {
        return { ...state, activity: payload };
    }

    public static loadMediaplan(state: PageState, payload: Autopilot2MediaplanPlanData): PageState {
        return { ...state, mediaplan: payload };
    }

    public static loadDictionaries(state: PageState, payload: GroupedDictionaries): PageState {
        return { ...state, dictionaries: payload };
    }

    public static loadMediaplanChannelsGroups(state: PageState, payload: Autopilot2MediaplanChannelsGroups): PageState {
        const updatedState = { ...state, mediaplanChannelsGroups: payload };

        if (!state.mediaplanSettingsForm.channelGroups) {
            state.mediaplanSettingsForm.channelGroups = lodash.cloneDeep(payload);
        }

        return updatedState;
    }

    public static loadPlacementSpecs(state: PageState, payload: ILPlacementSpec[]): PageState {
        return { ...state, placementSpecs: payload };
    }

    public static resetPageState(): PageState {
        return lodash.cloneDeep(initialState);
    }

    public static setBriefFormValues(state: PageState, payload: Partial<BriefStageForm>): PageState {
        return { ...state, briefStageForm: { ...state.briefStageForm, ...payload } };
    }

    public static setMediaplanSettingsFormValues(state: PageState, payload: Partial<MediaplanSettingsForm>): PageState {
        return { ...state, mediaplanSettingsForm: { ...state.mediaplanSettingsForm, ...payload } };
    }

    public static setSelectedBudgetItemIds(state: PageState, payload: string[]): PageState {
        return { ...state, selectedBudgetItemIds: payload };
    }

    public static setChangedCreativeStatuses(state: PageState, payload: Record<string, string>): PageState {
        return { ...state, changedCreativeStatuses: payload };
    }

    public static setSocialFormValues(state: PageState, payload: Partial<SocialStageForm>): PageState {
        return { ...state, socialStageForm: { ...state.socialStageForm, ...payload } };
    }

    public static setMediaplanStatus(state: PageState, payload: boolean): PageState {
        return { ...state, calculatingMediaplan: payload };
    }

    public static setPreloaderStatus(state: PageState, payload: boolean): PageState {
        return { ...state, preloader: payload };
    }

    public static openSidebar(state: PageState): PageState {
        return { ...state, sidebar: { ...state.sidebar, opened: true } };
    }

    public static closeSidebar(state: PageState): PageState {
        return { ...state, sidebar: { ...state.sidebar, opened: false } };
    }
}

export const autopilotStateReducer = reducerWithInitialState(initialState)
    .case(actions.loadAutopilot, Reducer.loadAutopilot)
    .case(actions.loadActivity, Reducer.loadActivity)
    .case(actions.loadMediaplan, Reducer.loadMediaplan)
    .case(actions.loadDictionaries, Reducer.loadDictionaries)
    .case(actions.loadMediaplanChannelsGroups, Reducer.loadMediaplanChannelsGroups)
    .case(actions.loadPlacementSpecs, Reducer.loadPlacementSpecs)
    .case(actions.resetPageState, Reducer.resetPageState)
    .case(actions.setBriefFormValues, Reducer.setBriefFormValues)
    .case(actions.setMediaplanSettingsFormValues, Reducer.setMediaplanSettingsFormValues)
    .case(actions.setSelectedBudgetItemIds, Reducer.setSelectedBudgetItemIds)
    .case(actions.setChangedCreativeStatuses, Reducer.setChangedCreativeStatuses)
    .case(actions.setSocialFormValues, Reducer.setSocialFormValues)
    .case(actions.setMediaplanStatus, Reducer.setMediaplanStatus)
    .case(actions.setPreloaderStatus, Reducer.setPreloaderStatus)
    .case(actions.openSidebar, Reducer.openSidebar)
    .case(actions.closeSidebar, Reducer.closeSidebar);

export const autopilotReducer = combineReducers<AutopilotPageState>({
    generationTextFormState: generationTextFormReducer,
    creativeFormState: creativeFormReducer,
    restState: autopilotStateReducer,
});
