import { actionCreatorFactory } from 'typescript-fsa';

import { ActivityParams as Activity } from 'sber-marketing-types/frontend';
import type {
    AutopilotDOOH,
    AutopilotDOOHRegionality,
    AutopilotDOOHFormatWithTimings,
    AutopilotDOOHMediaplanCalculation,
} from 'sber-marketing-types/backend';
import type { BriefStageForm, GroupedDictionaries, SwitchKeyActivityParams } from './types';

const actionCreator = actionCreatorFactory('AUTOPILOT_DOOH_PAGE');

const loadAutopilot = actionCreator<AutopilotDOOH>('LOAD_AUTOPILOT');
const loadActivity = actionCreator<Activity>('LOAD_ACTIVITY');
const loadMediaplan = actionCreator<AutopilotDOOHMediaplanCalculation[]>('LOAD_MEDIAPLAN');
const loadRegionalities = actionCreator<AutopilotDOOHRegionality[]>('LOAD_REGIONALITIES');
const loadFormats = actionCreator<AutopilotDOOHFormatWithTimings[]>('LOAD_FORMATS');
const loadDictionaries = actionCreator<GroupedDictionaries>('LOAD_DICTIONARIES');
const resetPageState = actionCreator('RESET_PAGE_STATE');
const setBriefFormValues = actionCreator<Partial<BriefStageForm>>('SET_BRIEF_FORM_VALUES');
const setManualMediaplanSideQuantityValues = actionCreator<Record<string, number>>(
    'SET_MANUAL_MEDIAPLAN_SIDE_QUANTITY_VALUES',
);
const setMediaplanLinesBudgetSnapShot = actionCreator<Record<string, number>>('SET_MEDIAPLAN_LINES_BUDGET_SNAP_SHOT');
const setMediaplanLinesSideQuantitySnapShot = actionCreator<Record<string, number>>(
    'SET_MEDIAPLAN_LINES_SIDE_QUANTITY_SNAP_SHOT',
);
const setSelectedBudgetItemIds = actionCreator<string[]>('SET_SELECTED_BUDGET_ITEM_IDS');
const setPreloaderStatus = actionCreator<boolean>('SET_PRELOADER_STATUS');
const openSidebar = actionCreator('OPEN_SIDEBAR');
const closeSidebar = actionCreator('CLOSE_SIDEBAR');

const switchKeyActivityAsync = actionCreator.async<SwitchKeyActivityParams, void, Error>('SWITCH_KEY_ACTIVITY_ASYNC');

export {
    loadAutopilot,
    loadActivity,
    loadMediaplan,
    loadRegionalities,
    loadFormats,
    loadDictionaries,
    resetPageState,
    setBriefFormValues,
    setManualMediaplanSideQuantityValues,
    setMediaplanLinesBudgetSnapShot,
    setMediaplanLinesSideQuantitySnapShot,
    setSelectedBudgetItemIds,
    setPreloaderStatus,
    openSidebar,
    closeSidebar,
    switchKeyActivityAsync,
};
