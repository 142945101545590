import * as React from 'react';

import type { Client } from '@sbermarketing/mrm-metacom-client';

import { MrmClient } from './';

export function useMrmClient() {
    const [client, setClient] = React.useState<Client<'creativeRequests' | 'tags' | 'projects' | 'budgets'>>(null);

    React.useEffect(() => {
        async function initClient() {
            setClient(await MrmClient.getInstance());
        }

        initClient();
    }, []);

    return client;
}
