import * as React from 'react';
import * as lodash from 'lodash';
import { NotificationMessage } from '@store/common/types';

import style from './NotificationList.scss';

import { Notification } from './Notification';

interface Props {
    notificationList: { [key: string]: NotificationMessage };
    onClose: (index: string) => void;
}

export const NotificationList = ({ notificationList, onClose }: Props): JSX.Element => {
    return (
        <div
            className={style.root}
            {...{
                'qa-id': 'notificationList',
            }}
        >
            {lodash.keys(notificationList).map((key, index) => (
                <div className={style.notificationWrapper} key={key}>
                    <Notification
                        index={index}
                        type={notificationList[key].type}
                        comment={notificationList[key].comment}
                        onClose={() => onClose(key)}
                    />
                </div>
            ))}
        </div>
    );
};
