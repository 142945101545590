import { isEmpty, values } from 'lodash';

import { TypePlacementFieldEnum } from 'sber-marketing-types/backend';

import {
    Field,
    FieldTypeEnum,
    FieldValueHref,
    FieldValueHrefArr,
    FieldValueText,
    FieldValueTextArr,
    FieldValueImage,
    FieldValueImageArr,
    FieldValueVideo,
    FieldValueVideoArr,
    FieldValueBundle,
    FieldValueFloat,
    FieldValueMoney,
    FieldValueBoolean,
    FieldValueStringId,
    FieldValueStringIdArr,
    FieldValueLong,
    FieldValueLongId,
    FieldValueLongIdArr,
} from '@store/autopilot/creativeForm';

interface Validator {
    (formField: Field): boolean;
}

type GetFormSpec = {
    [P in FieldTypeEnum]: (field: Field) => boolean;
};

const validatorsByFieldTypeMap: GetFormSpec = {
    [TypePlacementFieldEnum.Image]: (field: Field<FieldValueImage>) => !isEmpty(field.value.current),
    [TypePlacementFieldEnum.ImageArr]: (field: Field<FieldValueImageArr>) => !isEmpty(values(field.value.current)),
    [TypePlacementFieldEnum.Video]: (field: Field<FieldValueVideo>) => !isEmpty(field.value.current),
    [TypePlacementFieldEnum.VideoArr]: (field: Field<FieldValueVideoArr>) => !isEmpty(values(field.value.current)),
    [TypePlacementFieldEnum.Bundle]: (field: Field<FieldValueBundle>) => !isEmpty(field.value.current),
    [TypePlacementFieldEnum.Text]: (field: Field<FieldValueText>) => !isEmpty(field.value.current.value),
    [TypePlacementFieldEnum.TextArr]: (field: Field<FieldValueTextArr>) => !isEmpty(values(field.value.current)),
    [TypePlacementFieldEnum.Href]: (field: Field<FieldValueHref>) => !isEmpty(field.value.current.value),
    [TypePlacementFieldEnum.HrefArr]: (field: Field<FieldValueHrefArr>) => !isEmpty(values(field.value.current)),
    [TypePlacementFieldEnum.Float]: (field: Field<FieldValueFloat>) => !isEmpty(field.value.current.value),
    [TypePlacementFieldEnum.Int]: (field: Field<FieldValueFloat>) => !isEmpty(field.value.current.value),
    [TypePlacementFieldEnum.Boolean]: (field: Field<FieldValueBoolean>) => !isEmpty(field.value.current),
    [TypePlacementFieldEnum.StringId]: (field: Field<FieldValueStringId>) => !isEmpty(field.value.current.value),
    [TypePlacementFieldEnum.StringIdArr]: (field: Field<FieldValueStringIdArr>) => !isEmpty(field.value.current.value),
    [TypePlacementFieldEnum.Long]: (field: Field<FieldValueLong>) => !isEmpty(field.value.current.value),
    [TypePlacementFieldEnum.LongId]: (field: Field<FieldValueLongId>) => !isEmpty(field.value.current.value),
    [TypePlacementFieldEnum.LongIdArr]: (field: Field<FieldValueLongIdArr>) => !isEmpty(field.value.current.value),
    [TypePlacementFieldEnum.Unknown]: () => true,
    [TypePlacementFieldEnum.Money]: (field: Field<FieldValueMoney>) => !isEmpty(field.value.current.value),
};

export const validateFormFieldsRequired: Validator = (field) => {
    if (field.required) {
        const validate = validatorsByFieldTypeMap[field.type];
        return validate(field);
    }
    return true;
};
