import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Failure, Success } from 'typescript-fsa';

import type {
    ChangeFieldValueParams,
    ChangeSpecFieldValueParams,
    LoadParams,
    LoadResult,
    SaveParams,
    SaveResult,
    SetSelectedFormSpecIdParams,
    State,
} from './types';
import { LoadingStatus } from './types';
import * as syncActions from './actions/sync';
import * as asyncActions from './actions/async';

export const initialState: State = {
    loading: LoadingStatus.NOT_LOADED,
    form: {
        title: '',
        selectedSpecId: null,
        fields: {},
        specs: {},
    },
};

export class Reducer {
    public static loadStarted(state: State, payload: LoadParams): State {
        return {
            ...state,
            loading: LoadingStatus.LOADING,
        };
    }

    public static loadDone(state: State, payload: Success<LoadParams, LoadResult>): State {
        return {
            ...state,
            loading: LoadingStatus.LOADED,
            form: payload.result.form,
        };
    }

    public static loadFailed(state: State, payload: Failure<LoadParams, Error>): State {
        return {
            loading: LoadingStatus.ERROR,
            ...state,
        };
    }

    public static saveStarted(state: State, payload: SaveParams): State {
        return {
            ...state,
            loading: LoadingStatus.LOADING,
        };
    }

    public static saveDone(state: State, payload: Success<SaveParams, SaveResult>): State {
        return {
            ...state,
            loading: LoadingStatus.LOADED,
        };
    }

    public static saveFailed(state: State, payload: Failure<SaveParams, Error>): State {
        return {
            ...state,
            loading: LoadingStatus.ERROR,
        };
    }

    public static changeFieldValue(state: State, { fieldId, fieldError, value }: ChangeFieldValueParams): State {
        return {
            ...state,
            form: {
                ...state.form,
                fields: {
                    ...state.form.fields,
                    [fieldId]: {
                        ...state.form.fields[fieldId],
                        value: {
                            ...state.form.fields[fieldId].value,
                            current: value,
                        },
                        error: fieldError,
                    },
                },
            },
        };
    }

    public static changeSpecFieldValue(
        state: State,
        { specId, fieldId, fieldError, value }: ChangeSpecFieldValueParams,
    ): State {
        return {
            ...state,
            form: {
                ...state.form,
                specs: {
                    ...state.form.specs,
                    [specId]: {
                        ...state.form.specs[specId],
                        fields: {
                            ...state.form.specs[specId].fields,
                            [fieldId]: {
                                ...state.form.specs[specId].fields[fieldId],
                                value: {
                                    ...state.form.specs[specId].fields[fieldId].value,
                                    current: value,
                                },
                                error: fieldError,
                            },
                        },
                    },
                },
            },
        };
    }

    public static setSelectedSpecId(state: State, { selectedSpecId }: SetSelectedFormSpecIdParams): State {
        return {
            ...state,
            form: {
                ...state.form,
                selectedSpecId,
            },
        };
    }

    public static reset(): State {
        return {
            ...initialState,
        };
    }
}

export const creativeFormReducer = reducerWithInitialState(initialState)
    .case(asyncActions.load.started, Reducer.loadStarted)
    .case(asyncActions.load.done, Reducer.loadDone)
    .case(asyncActions.load.failed, Reducer.loadFailed)
    .case(asyncActions.save.started, Reducer.saveStarted)
    .case(asyncActions.save.done, Reducer.saveDone)
    .case(asyncActions.save.failed, Reducer.saveFailed)
    .case(syncActions.changeFieldValue, Reducer.changeFieldValue)
    .case(syncActions.changeSpecFieldValue, Reducer.changeSpecFieldValue)
    .case(syncActions.setSelectedSpecId, Reducer.setSelectedSpecId)
    .case(syncActions.reset, Reducer.reset);
