import { actionCreatorFactory } from 'typescript-fsa';
import { ActivityParams } from 'sber-marketing-types/frontend';

import { TasksFilter, UpdateActivityTaskParams } from '../types';

const creator = actionCreatorFactory('ACTIVITY_TASKS_PAGE_SYNC');

export const resetActivity = creator('RESET_ACTIVITY');
export const setTaskFilters = creator<Partial<TasksFilter>>('SET_TASKS_FILTERS');
export const resetTaskFilters = creator<void>('RESET_TASKS_FILTERS');
export const updateActivity = creator<Partial<ActivityParams>>('UPDATE_ACTIVITY_NEED_STAGES');
export const updateActivityTask = creator<UpdateActivityTaskParams>('UPDATE_ACTIVITY_TASK');
export const setIsMounted = creator<boolean>('SET_IS_MOUNTED');
