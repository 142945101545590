import { actionCreatorFactory } from 'typescript-fsa';

import {
    ActivityTasksUserConfig,
    UpdateActivityTasksUserConfig,
    LoadActivityTasksUserConfig,
    SaveActivityTasksUserConfigPayload,
} from './types';

const actionCreator = actionCreatorFactory('USER_CONFIG_COMMON');

const loadActivityTasksUserConfig = actionCreator.async<ActivityTasksUserConfig, LoadActivityTasksUserConfig, Error>(
    'LOAD_ACTIVITY_TASKS_USER_CONFIG',
);
const updateActivityTasksUserConfig = actionCreator.async<UpdateActivityTasksUserConfig, void, Error>(
    'UPDATE_ACTIVITY_TASKS_USER_CONFIG',
);

const saveActivityTasksUserConfig = actionCreator.async<SaveActivityTasksUserConfigPayload, void, Error>(
    'SAVE_ACTIUVITY_TASKS_USER_CONFIG',
);

export { loadActivityTasksUserConfig, updateActivityTasksUserConfig, saveActivityTasksUserConfig };
