import * as lodash from 'lodash';

import { TasksFilter } from '@store/userConfig/dashboard';

import { MAX_USER_CONFIG_ENTRIES_COUNT, ActivityTasksUserConfig } from './types';

export function addFilterToUserConfig(
    userConfig: ActivityTasksUserConfig,
    activityId: number,
    filters: TasksFilter,
): ActivityTasksUserConfig {
    if (!userConfig?.entries?.[activityId]) {
        const userConfigKeys = userConfig?.entries ? Object.keys(userConfig?.entries) : [];

        if (userConfigKeys.length >= MAX_USER_CONFIG_ENTRIES_COUNT) {
            const keyToReplace = lodash.minBy(userConfigKeys, (key) => userConfig.entries[key].updateTime);

            delete userConfig.entries[keyToReplace];
        }
    }

    return {
        entries: {
            ...userConfig.entries,
            [activityId]: {
                filters,
                updateTime: +new Date(),
            },
        },
    };
}
