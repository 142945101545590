import * as React from 'react';
import { connect } from 'react-redux';
import { Skeleton } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import * as usersStore from '@store/users';

import styles from '../CommonStyles.scss';

const usersEntities = usersStore.StoreTypes.DASHBOARD_SEARCH;

interface Props extends OwnProps, Partial<MapProps> {}

interface OwnProps {
    authorId: number;
}

interface MapProps {
    isReady: boolean;
    author: string;
}

@(connect(mapStateToProps) as any)
export class Author extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { isReady } = this.props;

        return (
            <Skeleton customStyle={{ width: '100%' }} isReady={isReady} className={styles.skeleton}>
                {this.contentRenderer()}
            </Skeleton>
        );
    }

    private contentRenderer(): JSX.Element {
        const { author } = this.props;

        return (
            <span
                title={author}
                className={styles.text}
                {...{
                    'qa-id': 'searchCardUser',
                    'qa-value': author,
                }}
            >
                {author}
            </span>
        );
    }
}

function mapStateToProps(state: StoreState, ownProps: OwnProps): MapProps {
    const { authorId } = ownProps;
    let isReady;
    let author;

    if (authorId === null) {
        isReady = true;
        author = 'Не указан';
    } else {
        isReady = !!authorId && usersStore.getLoadingStatus(state, usersEntities) === LoadingStatus.LOADED;

        author = null;
        if (isReady) {
            const userObj = usersStore.getUserById(state, authorId);
            author = `${userObj.firstName} ${userObj.secondName}`;
        }
    }

    return {
        isReady,
        author,
    };
}
