import { Action, combineReducers, createStore, Store, applyMiddleware, Middleware, compose } from 'redux';
import { isNil } from 'lodash';
import thunk from 'redux-thunk';

import { rtkApi } from '../api/rtkApi';

import { userReducer } from './user/reducers';
import { UserState } from './user/types';
import { NewActivityPageState, newActivityReducer } from './newActivity';
import { ProductsState, productsReducer } from './products';
import { organizationTypeReducer } from './organizationType/reducers';
import { OrganizationTypeState } from './organizationType/types';
import { calendarReducer } from './calendar/reducers';
import { PageState as CalendarPageState } from './calendar/types';
import { executionCalendarReducer } from './executionCalendar/reducers';
import { PageState as ExecutionCalendarPageState } from './executionCalendar/types';
import { creativeReducer } from './creative/reducers';
import { PageState as CreativePageState } from './creative/types';
import { autopilotTvReducer } from './autopilotTv/reducers';
import { autopilotRadioReducer } from './autopilotRadio/reducers';
import { autopilotDOOHReducer } from './autopilotDOOH/reducers';
import { autopilotReducer } from './autopilot/reducers';
import { autopilotReducer as autopilotReducer_legacy } from './autopilot_legacy/reducers';
import { PageState as AutopilotTvPageState } from './autopilotTv/types';
import { PageState as AutopilotRadioPageState } from './autopilotRadio/types';
import { PageState as AutopilotDOOHPageState } from './autopilotDOOH/types';
import { AutopilotPageState } from './autopilot/types';
import { PageState as AutopilotPageState_legacy } from './autopilot_legacy/types';
import { briefReducer } from './brief/reducers';
import { PageState } from './brief/types';
import { BudgetExecutionPageState } from './budgetExecution/types';
import { BudgetPlanningPageState } from './budgetPlanning/types';
import { BudgetCorrectionsPageState } from './budgetCorrections/types';
import { budgetExecutionPageReducer } from './budgetExecution/reducers';
import { budgetPlanningPageReducer } from './budgetPlanning/reducers';
import { budgetCorrectionsPageReducer } from './budgetCorrections/reducers';
import { commonReducer } from './common/reducers';
import { CommonState } from './common/types';
import { PageState as ExecutionBudgetEditPageState } from './executionBudgetEdit/types';
import { PageState as PlannedBudgetEditPageState } from './plannedBudgetEdit/types';
import { executionBudgetEditPageReducer } from './executionBudgetEdit/reducers';
import { plannedBudgetEditPageReducer } from './plannedBudgetEdit/reducers';
import { PageState as DashboardPageState } from './dashboardPage/types';
import { dashboardPageReducer } from './dashboardPage/reducers';
import { PageState as LeaderDashboardPageState } from './leaderDashboardPage/types';
import { leaderDashboardPageReducer } from './leaderDashboardPage/reducers';
import { PageState as LeaderDashboardTasksPageState } from './leaderDashboardTasksPage/types';
import { leaderDashboardTasksPageReducer } from './leaderDashboardTasksPage/reducers';
import { TaskPageState, taskPageReducer } from './taskPage';
import { tasksList, TasksList } from './tasksList';
import { appUsers, AppUsers } from './appUsers';
import { history, HistoryState } from './history';
import { activitiesList, ActivitiesList } from './activitiesList';
import { news, News } from './news';
import { activityPageReducer } from './activityPage/reducers';
import { PageState as ActivityPageState } from './activityPage/types';
import { activityTasksPageReducer, ActivityTasksPageState } from './activityTasksPage';
import { pivotTableReducer } from './pivotTable/reducers';
import { PageState as PivotTablePageState } from './pivotTable/types';
import { taskEditor, TaskEditor } from './taskEditor';
import { UserOrganizations, userOrganizations } from './userOrganizations';
import { fileAssets, FileAssets } from './fileAssets';
import { UserConfigState, userConfigReducer } from './userConfig';
import { activityTypesReducer, ActivityTypesState } from './activityTypes';
import { workTypesReducer, WorkTypesState } from './workTypes';
import { divisionsReducer, DivisionsState } from './divisions';
import { organizationsReducer, OrganizationsState } from './organizations';
import { usersReducer, UsersState } from './users';
import { departmentsReducer, DepartmentsState } from './departments';
import { searchReducer, SearchState } from './search';
import { tasksReducer, TasksState } from './tasks';
import { activitiesReducer, ActivitiesState } from './activities';
import { budgetPageReducer, BudgetPageState } from './budgetPage';
import { taskEditorReducer, TaskEditorState } from './taskEditor2';
import { profileMenuReducer, ProfileMenuState } from './profileMenu';
import { tagsEditorReducer, TagsEditorState } from './tagsEditor';
import { tagsPageReducer, TagsPageState } from './tagsPage';
import { tagsReducer, TagsState } from './tags';
import { videoPreviewsReducer, VideoPreviewsState } from './videoPreviews';
import { R7Reducer, R7State } from './R7';
import { activityFilesDAMArchiveReducer, ActivityFilesDAMArchiveState } from './activityFilesDAMArchive';
import { autopilotsTabReducer, AutopilotsTabState } from './autopilotsTab';
import { onboarding, OnboardingState } from './onboarding';

import { resetStore } from './common/actions';

export interface StoreState {
    onboarding: OnboardingState;
    rtkApi: ReturnType<typeof rtkApi.reducer>;
    common: CommonState;
    userOrganizations?: UserOrganizations;
    user?: UserState;
    products?: ProductsState;
    organizationType?: OrganizationTypeState;
    briefPage?: PageState;
    taskPage?: TaskPageState;
    budgetExecutionPage?: BudgetExecutionPageState;
    budgetPlanningPage?: BudgetPlanningPageState;
    budgetCorrectionsPage?: BudgetCorrectionsPageState;
    executionBudgetEditPage?: ExecutionBudgetEditPageState;
    plannedBudgetEditPage?: PlannedBudgetEditPageState;
    calendar?: CalendarPageState;
    executionCalendar?: ExecutionCalendarPageState;
    newActivity?: NewActivityPageState;
    tasksList?: TasksList;
    appUsers?: AppUsers;
    history?: HistoryState;
    activitiesList?: ActivitiesList;
    news?: News;
    activityPage?: ActivityPageState;
    activityTasksPage?: ActivityTasksPageState;
    dashboardPage?: DashboardPageState;
    leaderDashboardPage?: LeaderDashboardPageState;
    leaderDashboardTasksPage?: LeaderDashboardTasksPageState;
    taskEditor?: TaskEditor;
    fileAssets?: FileAssets;
    userConfig?: UserConfigState;
    activityTypes: ActivityTypesState;
    workTypes: WorkTypesState;
    divisions: DivisionsState;
    organizations: OrganizationsState;
    users: UsersState;
    departments: DepartmentsState;
    search: SearchState;
    tasks: TasksState;
    activities: ActivitiesState;
    budgetPage: BudgetPageState;
    creativePage: CreativePageState;
    pivotTablePage: PivotTablePageState;
    autopilotTv: AutopilotTvPageState;
    autopilotRadio: AutopilotRadioPageState;
    autopilotDOOH: AutopilotDOOHPageState;
    autopilot: AutopilotPageState;
    autopilotsTab: AutopilotsTabState;
    autopilot_legacy: AutopilotPageState_legacy;
    taskEditor2: TaskEditorState;
    profileMenu: ProfileMenuState;
    tagsEditor: TagsEditorState;
    tagsPage: TagsPageState;
    tags: TagsState;
    videoPreviews: VideoPreviewsState;
    R7: R7State;
    activityFilesDAMArchive: ActivityFilesDAMArchiveState;
}

const rootReducer = combineReducers<StoreState>({
    onboarding,
    rtkApi: rtkApi.reducer,
    tasksList,
    appUsers,
    history,
    activitiesList,
    news,
    activityPage: activityPageReducer,
    activityTasksPage: activityTasksPageReducer,
    dashboardPage: dashboardPageReducer,
    leaderDashboardPage: leaderDashboardPageReducer,
    leaderDashboardTasksPage: leaderDashboardTasksPageReducer,
    taskEditor,
    fileAssets,
    userOrganizations,
    user: userReducer,
    products: productsReducer,
    briefPage: briefReducer,
    taskPage: taskPageReducer,
    budgetExecutionPage: budgetExecutionPageReducer,
    budgetPlanningPage: budgetPlanningPageReducer,
    budgetCorrectionsPage: budgetCorrectionsPageReducer,
    executionBudgetEditPage: executionBudgetEditPageReducer,
    plannedBudgetEditPage: plannedBudgetEditPageReducer,
    calendar: calendarReducer,
    executionCalendar: executionCalendarReducer,
    organizationType: organizationTypeReducer,
    common: commonReducer,
    newActivity: newActivityReducer,
    userConfig: userConfigReducer,
    activityTypes: activityTypesReducer,
    workTypes: workTypesReducer,
    divisions: divisionsReducer,
    organizations: organizationsReducer,
    users: usersReducer,
    departments: departmentsReducer,
    search: searchReducer,
    tasks: tasksReducer,
    activities: activitiesReducer,
    budgetPage: budgetPageReducer,
    creativePage: creativeReducer,
    pivotTablePage: pivotTableReducer,
    autopilotTv: autopilotTvReducer,
    autopilotRadio: autopilotRadioReducer,
    autopilotDOOH: autopilotDOOHReducer,
    autopilot: autopilotReducer,
    autopilotsTab: autopilotsTabReducer,
    autopilot_legacy: autopilotReducer_legacy,
    taskEditor2: taskEditorReducer,
    profileMenu: profileMenuReducer,
    tagsEditor: tagsEditorReducer,
    tagsPage: tagsPageReducer,
    tags: tagsReducer,
    videoPreviews: videoPreviewsReducer,
    R7: R7Reducer,
    activityFilesDAMArchive: activityFilesDAMArchiveReducer,
});

const reducer = (state: StoreState, action: Action): StoreState =>
    rootReducer(action.type == resetStore.type ? undefined : state, action);

const env = process.env.NODE_ENV;

const middlewares: Middleware[] = [rtkApi.middleware, thunk];

const composeEnhancers =
    env != 'production' && !isNil(window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'])
        ? window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']
        : compose;

export const store: Store<StoreState> = createStore<StoreState>(
    reducer,
    composeEnhancers(applyMiddleware(...middlewares)),
);
