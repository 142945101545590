import { axios } from '../lib/axios';

import { BriefHistory } from 'sber-marketing-types/frontend';

const BASE_PATH = '/api/history';

export class HistoryApi {
    public static async setRecentView(reference: string): Promise<void> {
        await axios.post(`${BASE_PATH}/recent-view/${reference}`);
    }

    public static async getNewTaskCount(): Promise<any> {
        const res = await axios.get(`${BASE_PATH}/new-task-count`);

        return res.data;
    }

    public static async getSettings(): Promise<any> {
        const res = await axios.get(`${BASE_PATH}/settings`);

        return res.data;
    }

    public static async putSettings(settings: any): Promise<void> {
        await axios.put(`${BASE_PATH}/settings/`, settings);
    }

    public static async getBriefHistory(id: string): Promise<BriefHistory> {
        const res = await axios.get(`${BASE_PATH}/brief/${id}`);

        return res.data;
    }
}
