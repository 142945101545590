import { values } from 'lodash';

import type { TypeCreative } from 'sber-marketing-types/backend';
import type { Form } from '../../types';

import { buildCreativeGroups } from './buildCreativeGroups';

interface Builder {
    (params: Params): Promise<TypeCreative>;
}

interface Params {
    autopilotId: string;
    placementId: string;
    form: Form;
}

export const buildAutopilotCreatives: Builder = async ({ autopilotId, placementId, form }) => {
    return {
        creativeGroups: await buildCreativeGroups({
            formSpecs: values(form.specs).filter(
                ({ meta: { creativeGroupSpecId } }) => creativeGroupSpecId === form.selectedSpecId,
            ),
            formFields: values(form.fields),
            autopilotId,
            placementId,
        }),
    };
};
