import { Success } from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { LoadingStatus } from '@store/commonTypes';

import {
    ActivityTasksUserConfigState,
    ActivityTasksUserConfig,
    LoadActivityTasksUserConfig,
    UpdateActivityTasksUserConfig,
} from './types';
import * as actions from './actions';
import { addFilterToUserConfig } from './misc';

const initialState: ActivityTasksUserConfigState = {
    status: LoadingStatus.NOT_LOADED,
    fields: {
        entries: {},
    },
};

class Reducer {
    public static loadUserConfig(
        state: ActivityTasksUserConfigState,
        payload: Success<ActivityTasksUserConfig, LoadActivityTasksUserConfig>,
    ): ActivityTasksUserConfigState {
        const {
            result: { receivedUserConfig },
        } = payload;

        return {
            status: LoadingStatus.LOADED,
            fields: {
                ...state.fields,
                ...receivedUserConfig,
            },
        };
    }

    public static updateUserConfig(
        state: ActivityTasksUserConfigState,
        payload: UpdateActivityTasksUserConfig,
    ): ActivityTasksUserConfigState {
        // payload should include object with single key (activityId)
        const activityIdToUpdate = +Object.keys(payload.entries)[0];
        const activityIdToUpdateUserConfig = payload.entries[activityIdToUpdate].filters;

        return {
            ...state,
            fields: addFilterToUserConfig(state.fields, activityIdToUpdate, activityIdToUpdateUserConfig),
        };
    }
}

export const activityTasksUserConfigReducer = reducerWithInitialState(initialState)
    .case(actions.loadActivityTasksUserConfig.done, Reducer.loadUserConfig)
    .case(actions.updateActivityTasksUserConfig.started, Reducer.updateUserConfig);
