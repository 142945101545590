import { User } from '@store/user';

type CheckParams = {
    roles: string[];
    user: User;
};

export class CheckPermissionService {
    public static async check(activityId: number, params?: CheckParams) {
        if (params !== undefined) {
            const { roles, user } = params;
            const userHasRoles = roles.some((role) => user.attributes.roles.map(({ name }) => name).includes(role));
            if (!userHasRoles) {
                throw new Error(`User with id = ${user.attributes.id} has no appropriate role to access autopilot`);
            }
        }
    }
}
