import { actionCreatorFactory } from 'typescript-fsa';
import { Month } from '@mrm/budget';

import { ComponentState } from '../types';

const actionCreator = actionCreatorFactory('IMPORT_FMP_TABLE_MENU');

export const setComponentState = actionCreator<ComponentState>('SET_COMPONENT_STATE');
export const setErrorMessage = actionCreator<string>('SET_ERROR_MESSAGE');
export const resetComponentState = actionCreator('RESET_COMPONENT_STATE');
export const setTargetMonth = actionCreator<Month>('SET_TARGET_MONTH');
