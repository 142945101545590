import { values, isEmpty } from 'lodash';

import { TypeFileValue } from 'sber-marketing-types/backend';
import { FieldValueImageArr, FieldValueVersion } from '../../../../types';
import { Converter } from './types';

import { handleFiles } from './utils';

export const buildImageArrValue: Converter<FieldValueVersion<FieldValueImageArr>, TypeFileValue[]> = async ({
    value,
    autopilotId,
    fieldId,
}) => {
    if (isEmpty(value.current) && isEmpty(value.previous)) return null;

    const result = await handleFiles(values(value.current), values(value.previous), autopilotId, fieldId);
    return !isEmpty(result) ? result : null;
};
