// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html{height:100%}body{overflow:hidden;height:100%;margin:0;padding:0;border:0;background-color:#f1f5f7;font-family:Roboto}.src-client-modules-common-Page-_Page___i-hidden{display:none}\n", "",{"version":3,"sources":["webpack://./src/client/modules/common/Page/Page_.scss"],"names":[],"mappings":"AAAA,KACI,WAAY,CACf,KAGG,eAAgB,CAEhB,WAAY,CACZ,QAAS,CACT,SAAU,CAEV,QAAS,CACT,wBAAyB,CAEzB,kBAAmB,CACtB,iDAGG,YAAa","sourcesContent":["html {\n    height: 100%;\n}\n\nbody {\n    overflow: hidden;\n\n    height: 100%;\n    margin: 0;\n    padding: 0;\n\n    border: 0;\n    background-color: #f1f5f7;\n\n    font-family: Roboto;\n}\n\n.i-hidden {\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iHidden": "src-client-modules-common-Page-_Page___i-hidden"
};
export default ___CSS_LOADER_EXPORT___;
