import * as lodash from 'lodash';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';

import { RoleId, UserCard } from 'sber-marketing-types/frontend';
import { User } from '@store/user';
import { AppUsers } from './types';

import { StoreState } from '../';
import { LoadingStatus } from '../commonTypes';
import { getLoginUser } from '@store/user/selector';

const getState = (state: StoreState) => state;

export const getAppUsers = (state: StoreState): AppUsers => state.appUsers;

const getId = (_: StoreState, id: number): number => id;

const getIds = (_: StoreState, ids: number[]): number[] => lodash.uniq(ids);

export const getLoadingStatus = createSelector(getAppUsers, ({ status }): LoadingStatus => status);

export const getError = createSelector(getAppUsers, ({ error }): Error | null => error);

export const getUserById = createSelector(
    [getAppUsers, getId],
    ({ entities }, id): UserCard | undefined => entities[id],
);

export const getUsersByIds = createSelector([getAppUsers, getIds], ({ entities }, ids): UserCard[] => {
    const founded: lodash.Dictionary<UserCard> = lodash.pick(entities, ids);
    return lodash.compact(lodash.toPairsIn(founded).map(([_, user]) => user));
});

export const getAllUsersIds = createSelector(getAppUsers, ({ entities }): number[] =>
    Object.keys(entities).map(Number),
);

export const getAllUsers = createSelector([getState, getAllUsersIds], getUsersByIds);

export const getUserOfUserOrganization = createSelector(
    [getLoginUser, getAllUsers],
    (currentUser: User, appUsers: UserCard[]): UserCard[] => {
        const userOrganizationsIds = currentUser.attributes.organizationId;

        const filteredUsers = appUsers.filter(
            (user) => lodash.includes(userOrganizationsIds, user.organizationId) && user.isActive,
        );

        return lodash.sortBy(filteredUsers, (item) => item.secondName);
    },
);

export const getUsersByRole = createCachedSelector(
    getAppUsers,
    (state: StoreState, roleId: RoleId): RoleId => roleId,
    (users: AppUsers, roleId: RoleId): UserCard[] => {
        if (users.status === LoadingStatus.LOADED) {
            return lodash.values(users.entities).filter((user) => user.roles.some((role) => roleId === role.id));
        }

        return [];
    },
)((state: StoreState, roleId: RoleId): RoleId => roleId);
