import { FileDownloadLinkParams } from 'sber-marketing-types/backend';
import { GetDocumentStatusPayload, GetDocumentStatusResponse, GetEditorPayload, GetEditorResponse } from '@mrm/R7';

import { FileAsset } from '@store/commonTypes';

import { rtkApi } from './rtkApi';

import { FileApi, FileApiUploadParams, UploadImageResponse } from '../file-api';
import { selectMyUserId } from './auth';

export interface FileMutation {
    file: File;
}

export interface AddImageMutation extends FileMutation {
    previewWidth: number;
    previewHeight?: number;
}

export type FileOrAsset = File | FileAsset;

export interface AddFileMutation {
    file: FileOrAsset;
    params?: Partial<FileApiUploadParams>;
}

export const fileApi = rtkApi.injectEndpoints({
    endpoints: (builder) => ({
        addFile: builder.mutation<AsyncIterable<FileAsset>, AddFileMutation>({
            queryFn: async ({ file, params = {} }, { getState }) => {
                const userId = selectMyUserId(getState() as any);

                try {
                    const data = await FileApi.uploadFileProcess(params as any, file, String(userId), true);
                    return { data };
                } catch (e) {
                    console.error(e);
                    return { error: e };
                }
            },
        }),
        connectFile: builder.mutation<void, FileAsset>({
            query: (file) => {
                return {
                    url: `/file/${file.id}/file`,
                    method: 'POST',
                    body: file,
                };
            },
        }),
        addImage: builder.mutation<UploadImageResponse, AddImageMutation>({
            query: ({ file, previewWidth, previewHeight = previewWidth }) => {
                return {
                    url: '/image/image',
                    method: 'POST',
                    body: file,
                    credentials: 'include',
                    params: {
                        defaultPreviews: `${previewWidth}x${previewHeight}`,
                    },
                };
            },
        }),
        getVideoPreview: builder.query<{ url: string }, FileDownloadLinkParams>({
            query: (body) => ({
                url: '/video-preview/preview',
                method: 'POST',
                body,
            }),
        }),
        getR7Editor: builder.query<GetEditorResponse, GetEditorPayload>({
            query: (body) => ({
                url: '/r7/editor',
                method: 'POST',
                body,
            }),
        }),
        getR7Status: builder.query<GetDocumentStatusResponse, GetDocumentStatusPayload>({
            query: (body) => ({
                url: '/r7/document',
                method: 'POST',
                body,
            }),
        }),
        getDownloadLink: builder.mutation<string, FileDownloadLinkParams>({
            query: (body) => ({
                url: '/object/cloud/download-link',
                method: 'POST',
                body,
            }),
            transformResponse: ({ link }) => link,
        }),
    }),
});

export const {
    useConnectFileMutation,
    useGetVideoPreviewQuery,
    useAddFileMutation,
    useGetR7EditorQuery,
    useGetR7StatusQuery,
    useGetDownloadLinkMutation,
} = fileApi;
