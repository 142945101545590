import { axios } from '../../lib/axios';
import {
    AutopilotDOOH,
    // AutopilotDOOHMediaplanPlanDataResponse,
    AutopilotDOOHMediaplanCalculation,
    GetAutopilotDOOHListParams,
    CreateAutopilotDOOHParams,
    UpdateAutopilotDOOHParams,
    UpdateAutopilotDOOHBriefParams,
    UpdateAutopilotDOOHBudgetParams,
    AutopilotDOOHRegionality,
    AutopilotDOOHFormatWithTimings,
} from 'sber-marketing-types/backend';
import { CheckPermissionService } from './CheckPermissionService';
import { User } from '@store/user';

const BASE_URL = '/api/autopilot-dooh';

export class AutopilotDOOHApi {
    public static async getAutopilot(autopilotId: string, activityId: number): Promise<AutopilotDOOH> {
        await CheckPermissionService.check(activityId);

        const res = await axios.get(`${BASE_URL}/${autopilotId}`);

        return res.data;
    }

    // public static async getMediaplan(autopilotId: string): Promise<AutopilotDOOHMediaplanPlanDataResponse> {
    //     const res = await axios.get(`${BASE_URL}/${autopilotId}/mediaplan`);

    //     return res.data;
    // }

    public static async calculateMediaplan(
        autopilotId: string,
        activityId: number,
    ): Promise<AutopilotDOOHMediaplanCalculation[]> {
        await CheckPermissionService.check(activityId);

        const res = await axios.post(`${BASE_URL}/${autopilotId}/mediaplan-calculate`);

        return res.data;
    }

    public static async getAutopilotList(params?: GetAutopilotDOOHListParams): Promise<AutopilotDOOH[]> {
        if (params !== undefined) {
            await Promise.all(params.activityIds.map((activityId) => CheckPermissionService.check(activityId)));
        }

        const res = await axios.post(`${BASE_URL}/list`, params);

        return res.data;
    }

    public static async createAutopilot(user: User, params: CreateAutopilotDOOHParams): Promise<void> {
        const roles = ['Autopilot Client'];
        await CheckPermissionService.check(params.activityId, { roles, user });

        const res = await axios.post(BASE_URL, params);

        return res.data;
    }

    public static async updateAutopilot(
        autopilotId: string,
        activityId: number,
        user: User,
        params: UpdateAutopilotDOOHParams,
    ): Promise<void> {
        const roles = ['Autopilot Client'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.put<void>(`${BASE_URL}/${autopilotId}`, params);
    }

    public static async updateBrief(
        autopilotId: string,
        activityId: number,
        user: User,
        params: UpdateAutopilotDOOHBriefParams,
    ): Promise<void> {
        const roles = ['Autopilot Client'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.put<void>(`${BASE_URL}/${autopilotId}/brief`, params);
    }

    public static async updateMediaplan(autopilotId: string, activityId: number, user: User): Promise<void> {
        const roles = ['Autopilot Client'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.put<void>(`${BASE_URL}/${autopilotId}/mediaplan`);
    }

    public static async updateBudget(
        autopilotId: string,
        activityId: number,
        user: User,
        params: UpdateAutopilotDOOHBudgetParams,
    ): Promise<void> {
        const roles = ['Autopilot Client'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.put<void>(`${BASE_URL}/${autopilotId}/budget`, params);
    }

    public static async getRegions(autopilotId: string, activityId: number): Promise<AutopilotDOOHRegionality[]> {
        await CheckPermissionService.check(activityId);

        const res = await axios.get(`${BASE_URL}/${autopilotId}/mediaplan-regionality`);

        return res.data;
    }

    public static async getFormats(autopilotId: string, activityId: number): Promise<AutopilotDOOHFormatWithTimings[]> {
        await CheckPermissionService.check(activityId);

        const res = await axios.get(`${BASE_URL}/${autopilotId}/mediaplan-format`);

        return res.data;
    }
}
