import { Dictionary } from 'lodash';

import type { ILCreativeSpec, ILCreativeFieldSpec, ILCreativeFieldConstraints } from 'sber-marketing-types/backend';
import { FieldTypeEnum, TypeCreative } from 'sber-marketing-types/backend';

export interface State {
    loading: LoadingStatus;
    form: Form;
}

export interface Form {
    title: string;
    selectedSpecId: string;
    fields: Dictionary<Field>;
    specs: Dictionary<Spec>;
}

export interface Spec {
    id: string;
    title: string;
    fields: Dictionary<Field>;
    meta: SpecMeta;
}

export type Field<T extends FieldValue = FieldValue> = {
    id: string;
    type: FieldTypeEnum;
    value: FieldValueVersion<T>;
    error: string;
    title: string;
    description: string;
    required: boolean;
    constraints: FiledConstraints;
    referenceId: string;
    meta: FieldMeta;
};

export interface FieldValueVersion<T extends FieldValue = FieldValue> {
    current: T;
    previous: T;
}

export interface SpecMeta {
    creativeGroupSpecId: string;
}

export interface FieldMeta {
    creativeSpecId: string;
    fieldId: string;
}

export type FieldValue =
    | FieldValueHref
    | FieldValueHrefArr
    | FieldValueText
    | FieldValueMoney
    | FieldValueTextArr
    | FieldValueInt
    | FieldValueLong
    | FieldValueBoolean
    | FieldValueFloat
    | FieldValueImage
    | FieldValueImageArr
    | FieldValueVideo
    | FieldValueVideoArr
    | FieldValueBundle
    | FieldValueStringId
    | FieldValueStringIdArr
    | FieldValueLongId
    | FieldValueLongIdArr
    | FieldValueUndefined;

export type FieldValueTextArr = Dictionary<FieldValueText>;

export type FieldValueText = {
    id: string;
    value: string;
    error: string;
};

export type FieldValueInt = {
    value: string;
    error: string;
};

export type FieldValueFloat = {
    value: string;
    error: string;
};

export type FieldValueBoolean = boolean;

export type FieldValueUndefined = boolean;

export interface FieldValueLongIdArr {
    value: string[];
}

export type FieldValueLong = {
    value: string;
    error: string;
};

export type FieldValueMoney = {
    value: string;
    error: string;
};

export interface FieldValueLongId {
    value: string;
}

export interface FieldValueStringIdArr {
    value: string[];
}

export interface FieldValueStringId {
    value: string;
}

export interface FieldValueHref {
    id: string;
    value: string;
    error: string;
}

export type FieldValueHrefArr = Dictionary<FieldValueHref>;

export interface FieldFile {
    id: string;
    file: {
        description: FileDescription;
        entity: FileEntity;
    };
    error: string;
    warning: string;
}

export type FileEntity = File;
export interface FileDescription {
    id: string;
    name: string;
    size: number;
    type: string;
    downloadLink?: string;
}

export type FieldValueVideo = FieldFile;
export type FieldValueVideoArr = Dictionary<FieldFile>;
export type FieldValueImage = FieldFile;
export type FieldValueImageArr = Dictionary<FieldFile>;
export type FieldValueBundle = FieldFile;

export type FiledConstraints = ILCreativeFieldConstraints;

import { LoadingStatus } from '@store/commonTypes';

interface CreativeSpec extends ILCreativeSpec {
    id: string;
}

interface FieldSpec extends ILCreativeFieldSpec {
    id: string;
}

export { CreativeSpec, FieldSpec, ILCreativeSpec, ILCreativeFieldSpec };

export interface LoadParams {
    specId: string;
}
export interface LoadResult {
    form: Form;
}
export interface SaveParams {
    autopilotId: string;
    placementId: string;
}
export type SaveResult = TypeCreative;

export interface ChangeFieldValueParams {
    fieldId: string;
    fieldError: string;
    value: FieldValue;
}

export interface ChangeSpecFieldValueParams {
    specId: string;
    fieldId: string;
    fieldError: string;
    value: FieldValue;
}

export interface SetSelectedFormSpecIdParams {
    selectedSpecId: string;
}

export { LoadingStatus, FieldTypeEnum };
