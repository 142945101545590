import * as React from 'react';

interface Props {
    fallback: React.FunctionComponent<{ error: Error }>;
}

interface State {
    error: Error;
}

export class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { error: null };
    }

    static getDerivedStateFromError(error: Error) {
        return { error };
    }

    render() {
        const { error } = this.state;
        const { fallback, children } = this.props;

        return error ? React.createElement(fallback, { error }) : children;
    }
}
