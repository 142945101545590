/* tslint:disable:max-file-line-count */
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import * as lodash from 'lodash';
import formatNumber from 'format-number';
import { UserResponseParams, AccountStatus } from 'sber-marketing-types/frontend';
import { Tag } from '@mrm/tags';

import { ActivityBudget, BudgetItem } from '@mrm/budget';
import { PlainDictionary, DictionaryType, DictionaryStatus } from '@mrm/dictionary';
import {
    PageState,
    PageData,
    PageFilters,
    TableLine,
    TableLineGroup,
    CustomCellType,
    CellValueType,
    ColumnFilters,
    ColumnName,
    Filters,
    SortingMode,
    OrderType,
    CellValue,
    NumericCellValue,
    DateCellValue,
    UnsavedChange,
    ChangeList,
    TotalBudgets,
    SelectableCellsList,
    ColumnData,
    CAPITAL_EXPENSES_ITEM,
    CORRECTION_FILTER_TYPE,
    CorrectionPopup,
    ColumnsNameWithCodes,
    ActivityReferenceMenuVisibility,
    CreativeReferenceMenuVisibility,
    ColumnsWithSameData,
    GroupedDicitonaries,
} from './types';
import { DropdownCellOptions } from 'client/modules/budget/BudgetPage/BudgetExecution/Table/CellTypes/DropdownCell/DropdownCell';
import { ActualValue } from 'client/modules/budget/BudgetPage/BudgetExecution/Table/LayerManager';

import { StoreState } from '../';
import { getTagsState } from '@store/tags';
import { User } from '../user/types';
import { getLoginUser } from '../user/selector';
import {
    ColumnsList,
    DictionaryColumns,
    CalculatableColumns,
    DictionaryDropdownColumns,
} from '../../modules/budget/BudgetPage/BudgetExecution/ColumnsConfig';

import {
    getBudgetTransferMenuState,
    isBudgetTransferMenuClosed,
    ComponentState,
    BudgetTransferMenuState,
    cellBelongsToLine,
    isCellSelected,
    isExternalTransferState,
    checkCellsEquality,
    CellPosition,
} from './budgetTransferMenu';
import { MultiReferenceDictionaryApi } from 'client/api';

const formatter = formatNumber({
    integerSeparator: ' ',
    decimal: ',',
    truncate: 3,
});

function formatCurrency(value: number): string {
    return formatter(Math.round(value) / 100000.0);
}

export const getBudgetExecutionPageState = (state: StoreState): PageState => state.budgetExecutionPage.restState;

export const getPageData = createSelector(getBudgetExecutionPageState, (state: PageState): PageData => state.pageData);

export const getCorrectionPopup = createSelector(
    getBudgetExecutionPageState,
    (state: PageState): CorrectionPopup => state.correctionPopup,
);

export const getTableFilters = createSelector(
    getBudgetExecutionPageState,
    (state: PageState): PageFilters => state.pageFilters,
);

export const getUpdatedFIlters = createSelector(
    getBudgetExecutionPageState,
    (state: PageState): Filters => state.columnFilters.filters,
);

export const getActivityReferenceMenuVisibility = createSelector(
    getBudgetExecutionPageState,
    (state: PageState): ActivityReferenceMenuVisibility => state.activityReferenceMenuVisibility,
);

export const getCreativeReferenceMenuVisibility = createSelector(
    getBudgetExecutionPageState,
    (state: PageState): CreativeReferenceMenuVisibility => state.creativeReferenceMenuVisibility,
);

export const getUnsavedChanges = createSelector(
    getBudgetExecutionPageState,
    (state: PageState): ChangeList => state.unsavedChanges,
);

export const getChangedLinesIds = createSelector(getUnsavedChanges, (unsavedChanges: ChangeList): string[] => {
    const lineIds: string[] = [];

    lodash.forEach(unsavedChanges, (lineChanges, lineId) => {
        if (lineChanges.length > 0) {
            lineIds.push(lineId);
        }
    });

    return lineIds;
});

export const canUndoUnsavedChanges = createSelector(
    getBudgetExecutionPageState,
    (state: PageState): boolean => state.currentChangePosition !== 0,
);

export const canRedoUnsavedChanges = createSelector(
    getBudgetExecutionPageState,
    (state: PageState): boolean => state.currentChangePosition !== state.changesHistory.length,
);

export const getActivitiesTotalBudgets = createSelector(getPageData, (pageData: PageData): TotalBudgets => {
    const { budgetItems, budgetItemsToIgnoreFilters } = pageData;

    const totalBudgets: TotalBudgets = {};

    lodash
        .uniqBy([...budgetItems, ...budgetItemsToIgnoreFilters], (budgetItem) => budgetItem.id)
        .forEach((budgetItem) => {
            if (totalBudgets[budgetItem.activity.id] === undefined) {
                totalBudgets[budgetItem.activity.id] = 0;
            }

            const linePlan = lodash.reduce(budgetItem.plannedFunds, (acc, item) => acc + item, 0);

            totalBudgets[budgetItem.activity.id] += linePlan;
        });

    return totalBudgets;
});

export const getTableTotalBudget = createSelector(getActivitiesTotalBudgets, (plannedBudgets: TotalBudgets): number => {
    return lodash.reduce(plannedBudgets, (acc, item) => acc + item, 0);
});

function calculateIncomePlanCorrections(pageData: PageData, budgetItemId: string): number {
    const { planCorrections, incomeExternalPlanCorrections } = pageData.corrections;

    const incomePlanCorrections = (planCorrections[budgetItemId] || []).filter(
        (item) => item.data.acceptor.current.id !== item.data.donor.current.id,
    );
    const externalPlanCorrections = incomeExternalPlanCorrections[budgetItemId] || [];

    return (
        lodash.sumBy(externalPlanCorrections, (item) => item.data.params.value) +
        lodash.sumBy(incomePlanCorrections, (item) => item.data.params.value)
    );
}

function calculateIncomeReserveCorrections(pageData: PageData, budgetItemId: string): number {
    const { reserveCorrections } = pageData.corrections;

    return lodash.sum(
        lodash.flatMap(reserveCorrections[budgetItemId] || [], (item) => lodash.values(item.data.params)),
    );
}

function calculateIncomeFactCorrections(pageData: PageData, budgetItemId: string): number {
    const { factCorrections } = pageData.corrections;

    return lodash.sum(lodash.flatMap(factCorrections[budgetItemId] || [], (item) => lodash.values(item.data.params)));
}

function calculatePlan(budgetItem: BudgetItem): number {
    return lodash.sum(lodash.values(budgetItem.plannedFunds));
}

function calculateReserve(budgetItem: BudgetItem): number {
    return lodash.sum(lodash.values(budgetItem.reservedFunds));
}

function calculateFact(budgetItem: BudgetItem): number {
    return lodash.sum(lodash.values(budgetItem.factFunds));
}

export function calculateCorrectedPlanReserveBudget(
    pageData: PageData,
    budgetItem: BudgetItem,
): { before: number; after: number } {
    if (budgetItem) {
        const planBudget = calculatePlan(budgetItem);
        const reserveBudget = calculateReserve(budgetItem);
        const incomePlanCorrections = calculateIncomePlanCorrections(pageData, budgetItem.id);
        const incomeReserveCorrections = calculateIncomeReserveCorrections(pageData, budgetItem.id);

        return {
            before: planBudget - reserveBudget,
            after: planBudget + incomePlanCorrections - (incomeReserveCorrections || reserveBudget),
        };
    }

    return {
        before: 0,
        after: 0,
    };
}

export function calculateCorrectedPlanFactBudget(
    pageData: PageData,
    budgetItem: BudgetItem,
): { before: number; after: number } {
    if (budgetItem) {
        const planBudget = calculatePlan(budgetItem);
        const factBudget = calculateFact(budgetItem);
        const incomePlanCorrections = calculateIncomePlanCorrections(pageData, budgetItem.id);
        const incomeFactCorrections = calculateIncomeFactCorrections(pageData, budgetItem.id);

        return {
            before: planBudget - factBudget,
            after: planBudget + incomePlanCorrections - (incomeFactCorrections || factBudget),
        };
    }

    return {
        before: 0,
        after: 0,
    };
}

export const getBudgetItemByLineId = createCachedSelector(
    (state: StoreState) => getBudgetExecutionPageState(state).computedData.pageBudgetItems,
    (state: StoreState, lineId: string): string => lineId,
    (budgetItems: BudgetItem[], lineId: string): BudgetItem =>
        budgetItems.find((budgetItem) => budgetItem.id === lineId),
)((state: StoreState, lineId: string): string => lineId);

export const getBudgetItemsWithCorrectedPlanOverrun = createSelector(getPageData, (pageData: PageData): string[] => {
    const { budgetItems, budgetItemsToIgnoreFilters } = pageData;

    return [...budgetItems, ...budgetItemsToIgnoreFilters].reduce((acc, budgetItem) => {
        const correctedBudget = calculateCorrectedPlanReserveBudget(pageData, budgetItem);

        return correctedBudget.before < 0 && correctedBudget.after >= 0 ? [...acc, budgetItem.id] : acc;
    }, []);
});

export const getBudgetItemsWithPlanOverrunedByReserve = createSelector(getPageData, (pageData: PageData): string[] => {
    const { budgetItems, budgetItemsToIgnoreFilters } = pageData;

    return [...budgetItems, ...budgetItemsToIgnoreFilters].reduce((acc, budgetItem) => {
        const correctedBudget = calculateCorrectedPlanReserveBudget(pageData, budgetItem);

        return correctedBudget.after < 0 ? [...acc, budgetItem.id] : acc;
    }, []);
});

export const getBudgetItemsWithPlanOverrunedByFact = createSelector(getPageData, (pageData: PageData): string[] => {
    const { budgetItems, budgetItemsToIgnoreFilters } = pageData;

    return [...budgetItems, ...budgetItemsToIgnoreFilters].reduce((acc, budgetItem) => {
        const correctedBudget = calculateCorrectedPlanFactBudget(pageData, budgetItem);

        return correctedBudget.after < 0 ? [...acc, budgetItem.id] : acc;
    }, []);
});

export const userCanEditOrCreateBudgetData = createSelector(
    getPageData,
    (pageData: PageData) => pageData.budget?.actions?.createActivity,
);

export function makeLine(
    budgetItem: BudgetItem,
    activityBudgets: ActivityBudget[],
    users: UserResponseParams[],
    tags: lodash.Dictionary<Tag>,
    user: User,
) {
    const line: TableLine = {
        id: budgetItem.id,
        budgetItem: budgetItem,
        activityId: budgetItem.activity.id,
        userIsAuthor: budgetItem.initiator.id == user.attributes.id,
        fields: {},
        isDisabled: !budgetItem.actions.canEdit,
        planValue: lodash.sum(lodash.values(budgetItem.plannedFunds)),
        factValue: lodash.sum(lodash.values(budgetItem.factFunds)),
        reserveValue: lodash.sum(lodash.values(budgetItem.reservedFunds)),
        creationTime: budgetItem.creationTime,
    };

    ColumnsList.forEach((column) => {
        if (column.accessor) {
            line.fields[column.name] = column.accessor({
                budgetItem,
                activityBudgets,
                users,
                tags,
            });
        }
    });

    CalculatableColumns.forEach((column) => {
        const { calculateActualValue } = column.metaData;

        const cellActualValue: ActualValue = calculateActualValue((columnName: ColumnName) => ({
            value: getCellValue(line, columnName),
        }));

        line.fields[column.name] = {
            number: parseInt(cellActualValue.value, 10),
            title: cellActualValue.value,
        } as NumericCellValue;
    });

    return line;
}

export const getTableLines = createSelector(
    (state: StoreState) => getPageData(state).activityBudgets,
    (state: StoreState) => getPageData(state).users,
    getLoginUser,
    (state: StoreState) => getTagsState(state).byId.dictionary,
    (state: StoreState) => getBudgetExecutionPageState(state).computedData.pageBudgetItems,
    (
        activityBudgets: ActivityBudget[],
        users: UserResponseParams[],
        user: User,
        tags: lodash.Dictionary<Tag>,
        pageBudgetItems: BudgetItem[],
    ): TableLine[] => {
        console.log(`calc: tableLines`);

        if (!activityBudgets.length || !pageBudgetItems.length || !users) {
            return [];
        }
        const lines: TableLine[] = [];

        pageBudgetItems.forEach((budgetItem) => {
            lines.push(makeLine(budgetItem, activityBudgets, users, tags, user));
        });

        const sortedLines = lodash.sortBy(lines, (item) => [
            getCellValue(item, ColumnName.ActivityName),
            getCellValue(item, ColumnName.Id),
        ]);

        return sortedLines;
    },
);

export const getDefaultFilters = createSelector(
    (state: StoreState) => getBudgetExecutionPageState(state).computedData.tableLines,
    (lines: TableLine[]): Filters => {
        const linesFields = lines.map((item) => item.fields);

        const filters: Filters = {
            id: {},
        };

        ColumnsList.forEach((column) => {
            if (!column.disableFilter) {
                const columnFilters: ColumnFilters = {};

                const columnFields = linesFields.map((item) => item[column.name]);

                let columnValues;

                if (column.name === ColumnName.Responsible) {
                    columnValues = columnFields.reduce((acc, item) => {
                        if (!item) {
                            return [...acc, item];
                        }

                        const splitedItems = (item as string).split(',');
                        return [...acc, ...splitedItems];
                    }, []);
                } else {
                    columnValues = columnFields.map((item) => {
                        switch (column.valueType) {
                            case CellValueType.String:
                                return item as string;
                            case CellValueType.Date:
                                return item ? (item as any).title : null;
                            case CellValueType.Number:
                                return getNumberValue(item).toString();
                            case CellValueType.Currency:
                                return item ? formatCurrency((item as any).number) : null;
                            default:
                                throw new Error(`No DefaultFilter getter for ${column.valueType}`);
                        }
                    });
                }

                let columnUniqueValues = lodash.uniq(columnValues);

                const hasNull = lodash.includes(columnUniqueValues, null);
                const hasEmptyString = lodash.includes(columnUniqueValues, '');

                if (hasNull) {
                    columnUniqueValues = lodash.remove(columnUniqueValues, null);
                    columnUniqueValues.push(null);
                }

                if (hasEmptyString) {
                    columnUniqueValues = lodash.compact(columnUniqueValues);
                    columnUniqueValues.push('');
                }

                columnUniqueValues.forEach((item) => {
                    columnFilters[item] = false;
                });

                filters[column.name] = columnFilters;
            }
        });

        return filters;
    },
);

export const getFilteredTableLines = createSelector(
    (state: StoreState) => getBudgetExecutionPageState(state).computedData.tableLines,
    getPageData,
    getTableFilters,
    getBudgetItemsWithCorrectedPlanOverrun,
    getBudgetItemsWithPlanOverrunedByReserve,
    (state: StoreState) => getBudgetTransferMenuState(state).cells.from,
    (state: StoreState) => getBudgetTransferMenuState(state).cells.to,
    (
        lines: TableLine[],
        pageData: PageData,
        pageFilters: PageFilters,
        budgetItemsWithCorrectedPlanOverrun: string[],
        budgetItemsWithPlanOverrun: string[],
        budgetTransferFromCells: CellPosition[],
        budgetTransferToCells: CellPosition[],
    ): TableLine[] => {
        const filteredLines = filterTableLines(
            lines,
            pageData,
            pageFilters,
            [...budgetItemsWithCorrectedPlanOverrun, ...budgetItemsWithPlanOverrun],
            { from: budgetTransferFromCells, to: budgetTransferToCells },
        );

        return pageFilters.sortingMode.columnName && pageFilters.sortingMode.columnName != ColumnName.ActivityName
            ? sortTableLines(
                  filteredLines,
                  pageData.userDictionaries.byId,
                  pageData.users,
                  pageFilters.sortingMode,
                  pageData.budgetItemsToIgnoreFilters,
                  pageFilters.useLinesWithoutPlanInSorting,
              )
            : filteredLines;
    },
);

export const getSelectableCellsList = createSelector(
    (state: StoreState) => getBudgetExecutionPageState(state).computedData.tableLines,
    getPageData,
    getBudgetTransferMenuState,
    (state) => isBudgetTransferMenuClosed(getBudgetTransferMenuState(state).controls.componentState),
    (
        lines: TableLine[],
        pageData: PageData,
        transitionData: BudgetTransferMenuState,
        isBudgetTransferMenuClosed: boolean,
    ): SelectableCellsList => {
        const {
            cells: { from, to },
            controls: { componentState },
        } = transitionData;

        const isExternalTransfer = isExternalTransferState(componentState);

        const {
            userDictionaries: { byId: dictionariesById },
        } = pageData;

        const selectableCellsList: SelectableCellsList = {};

        if (isBudgetTransferMenuClosed) {
            lines.forEach((line) => {
                selectableCellsList[line.id] = [];
            });

            return selectableCellsList;
        }

        const selectableColumnsNames = ColumnsList.filter(
            (column) => column.customCellType == CustomCellType.Selectable,
        ).map((column) => column.name);

        lines.forEach((line) => {
            selectableCellsList[line.id] = isExternalTransfer
                ? filterSelectableLinesForExternalPlanTransfer(line, selectableColumnsNames)
                : filterSelectableLinesForInternalPlanTransfer(line, selectableColumnsNames);
        });

        function filterSelectableLinesForInternalPlanTransfer(line: TableLine, selectableColumnsNames: ColumnName[]) {
            let activeCellsNames = lodash.clone(selectableColumnsNames);

            const donorIsSelected = isCellSelected(from);
            const acceptorIsSelected = isCellSelected(to);

            if (acceptorIsSelected) {
                activeCellsNames = activeCellsNames.filter((columnName) => {
                    const cellIsAcceptor = to.some((toCell) =>
                        checkCellsEquality(toCell, {
                            lineId: line.id,
                            columnName,
                        }),
                    );
                    const hasAmountToTransfer = (getCellValue(line, columnName) as number) > 0;

                    return cellIsAcceptor || hasAmountToTransfer;
                });
            }

            // if (donorIsSelected && acceptorIsSelected) {
            //     activeCellsNames = [];
            // }

            if ((donorIsSelected && !acceptorIsSelected) || (!donorIsSelected && acceptorIsSelected)) {
                const donorItemLocked =
                    from.length &&
                    from[0].lineId &&
                    getCellValue(
                        lines.find((item) => item.id === from[0].lineId),
                        ColumnName.Item,
                        dictionariesById,
                    ) === CAPITAL_EXPENSES_ITEM;
                const acceptorItemLocked =
                    from.length &&
                    from[0].lineId &&
                    getCellValue(
                        lines.find((item) => item.id === from[0].lineId),
                        ColumnName.Item,
                        dictionariesById,
                    ) === CAPITAL_EXPENSES_ITEM;

                const lineItemNotLocked =
                    getCellValue(line, ColumnName.Item, dictionariesById) !== CAPITAL_EXPENSES_ITEM;

                if (
                    ((donorIsSelected && donorItemLocked) || (acceptorIsSelected && acceptorItemLocked)) &&
                    lineItemNotLocked
                ) {
                    activeCellsNames = [];
                }
            }

            return activeCellsNames;
        }

        function filterSelectableLinesForExternalPlanTransfer(line: TableLine, selectableColumnsNames: ColumnName[]) {
            let activeCellsNames = lodash.clone(selectableColumnsNames);

            const donorIsSelected = isCellSelected(from);
            const acceptorIsSelected = isCellSelected(to);

            if (donorIsSelected || acceptorIsSelected) {
                activeCellsNames = [];
            } else if (componentState === ComponentState.ExternalOutcomeTransfer) {
                activeCellsNames = activeCellsNames.filter((columnName) => {
                    const hasAmountToTransfer = (getCellValue(line, columnName) as number) > 0;

                    return hasAmountToTransfer;
                });
            }

            return activeCellsNames;
        }

        return selectableCellsList;
    },
);

export const getLinesGroupedByActivities = createSelector(
    getFilteredTableLines,
    getTableFilters,
    (lines: TableLine[], pageFilters: PageFilters): TableLineGroup[] => {
        const { sortingMode } = pageFilters;

        const activityIds = lodash.uniq(lines.map((item) => item.activityId));

        let groupedLines = activityIds.map((activityId) => {
            const activityLines = lines.filter((line) => line.activityId == activityId);

            return {
                activityId,
                lines: lodash.sortBy(activityLines, 'serialNumber'),
                isDisabled: !!activityLines.some((line) => line.isDisabled),
            };
        });

        groupedLines = lodash.sortBy(groupedLines, (item) => lodash.first(item.lines).fields[ColumnName.ActivityName]);

        if (sortingMode.order == OrderType.Desc) {
            groupedLines.reverse();
        }

        return groupedLines;
    },
);

export const getUnsavedChangesByLineId = createCachedSelector(
    getUnsavedChanges,
    (state: StoreState, lineId: string): string => lineId,
    (unsavedChanges: ChangeList, lineId: string): UnsavedChange[] => {
        return unsavedChanges[lineId];
    },
)((state: StoreState, lineId: string): string => lineId);

export function getDropdownsOptionsByLineId(
    line: TableLine,
    users: UserResponseParams[],
    groupedDictionaries: GroupedDicitonaries,
    changes: UnsavedChange[],
    multiReferenceDictionaryApi: MultiReferenceDictionaryApi,
) {
    const lineDictionaryValue: Partial<Record<DictionaryType, PlainDictionary>> = {};
    DictionaryColumns.forEach((dictionaryColumn) => {
        if (!ColumnsNameWithCodes.includes(dictionaryColumn.name)) {
            const columnChange = changes?.find((change) => change.columnName === dictionaryColumn.name);
            const valueToUse = (columnChange ? columnChange?.value : line.fields[dictionaryColumn.name]) as string;

            if (valueToUse) {
                lineDictionaryValue[dictionaryColumn.metaData.dictionaryType] = groupedDictionaries.byId[valueToUse];
            }
        }
    });

    const dropdownsOptions: { [columnName: string]: DropdownCellOptions[] } = {};

    DictionaryDropdownColumns.forEach((column) => {
        const dictionaryType = column?.metaData?.dictionaryType as DictionaryType;

        const columnDictionaryTitleAccessor = ColumnsNameWithCodes.includes(column.name) ? 'code' : 'value';

        if (column.customCellType == CustomCellType.Dropdown && dictionaryType) {
            if (line.isDisabled) {
                const budetItemDictionary = groupedDictionaries.byId[line.budgetItem.dictionary?.[dictionaryType]?.id];

                dropdownsOptions[column.name] = makeDropdownOptions(
                    columnDictionaryTitleAccessor,
                    budetItemDictionary ? [budetItemDictionary] : [],
                );
            } else {
                const visibleDictionaries = multiReferenceDictionaryApi.getDictionariesForValue(
                    lineDictionaryValue,
                    dictionaryType,
                );
                const availableDictionaries =
                    visibleDictionaries[dictionaryType] || groupedDictionaries.byType[dictionaryType] || [];
                dropdownsOptions[column.name] = makeDropdownOptions(
                    columnDictionaryTitleAccessor,
                    availableDictionaries,
                );
            }
        } else if (column.metaData && column.metaData.dropdownItems) {
            dropdownsOptions[column.name] = column.metaData.dropdownItems({ users });
        }
    });

    dropdownsOptions[ColumnName.Responsible] = makeResponsibleDropdownItems(users);

    return dropdownsOptions;
}

const makeResponsibleDropdownItems = createSelector(
    (users: UserResponseParams[]) => users,
    (users: UserResponseParams[]) => {
        return users
            .filter((user) => user.status === AccountStatus.ACTIVE)
            .map((user) => {
                const { firstName, middleName, secondName } = user;
                const title = middleName ? `${secondName} ${firstName} ${middleName}` : `${secondName} ${firstName}`;

                return {
                    id: user.id.toString(),
                    title,
                };
            });
    },
);

export const getDropdownsOptions = createSelector(
    (state: StoreState) => getBudgetExecutionPageState(state).computedData.tableLines,
    (state: StoreState) => isBudgetTransferMenuClosed(getBudgetTransferMenuState(state).controls.componentState),
    (state: StoreState) => getUnsavedChanges(state),
    (state: StoreState) => getPageData(state).users,
    (state: StoreState) => getPageData(state).allDictionaries,
    (state: StoreState) => getBudgetExecutionPageState(state).multiReferenceDictionaryApi,
    (
        lines: TableLine[],
        isBudgetTransferMenuClosed: boolean,
        unsavedChanges: ChangeList,
        users: UserResponseParams[],
        groupedDictionaries: GroupedDicitonaries,
        multiReferenceDictionaryApi: MultiReferenceDictionaryApi,
    ): { [lineId: string]: { [columnName: string]: DropdownCellOptions[] } } => {
        const dropdownsOptions = {};

        if (isBudgetTransferMenuClosed) {
            lines.forEach((item) => {
                dropdownsOptions[item.id] = getDropdownsOptionsByLineId(
                    item,
                    users,
                    groupedDictionaries,
                    unsavedChanges[item.id] || [],
                    multiReferenceDictionaryApi,
                );
            });
        }

        return dropdownsOptions;
    },
);

function filterTableLines(
    lines: TableLine[],
    pageData: PageData,
    pageFilters: PageFilters,
    overrunnedPlanBudgetItemIds: string[],
    transitionDataCells: { from: CellPosition[]; to: CellPosition[] },
): TableLine[] {
    const {
        correctionsToDisplay,
        showOnlyLinesWithoutPlan,
        showOnlyLinesWithNegativeBalance,
        showOnlyLinesWithPlanBudget,
        showOnlyLinesWithNegativePlanFactDiff,
    } = pageFilters;
    const { from, to } = transitionDataCells;

    const correctionsFilter = lodash.every(correctionsToDisplay, () => false)
        ? lodash.mapValues(correctionsToDisplay, () => true)
        : correctionsToDisplay;

    const {
        activityCorrections,
        budgetItemCorrections,
        planCorrections,
        reserveCorrections,
        incomeExternalPlanCorrections,
        outcomeExternalPlanCorrections,
        factCorrections,
    } = pageData.corrections;

    let filteredLines = lodash.clone(lines);

    filteredLines = filteredLines.filter((line) => {
        const lineIsDonorLine = from.some((fromCell) => cellBelongsToLine(fromCell, line.id));
        const lineIsAcceptorLine = to.some((toCell) => cellBelongsToLine(toCell, line.id));
        const isLineUsedInTransfer = lineIsDonorLine || lineIsAcceptorLine;

        if (isLineUsedInTransfer) {
            return true;
        } else {
            if (showOnlyLinesWithoutPlan) {
                return !line.planValue;
            } else if (showOnlyLinesWithPlanBudget) {
                return line.planValue;
            } else if (showOnlyLinesWithNegativeBalance) {
                return lodash.includes(overrunnedPlanBudgetItemIds, line.id);
            } else if (showOnlyLinesWithNegativePlanFactDiff) {
                const lineIncomeExternalPlanCorrections = lodash.sum(
                    lodash
                        .values(incomeExternalPlanCorrections[line.id])
                        ?.map((correction) => correction.data.params.value),
                );
                const lineOutcomeExternalPlanCorrections = lodash.sum(
                    lodash.values(
                        outcomeExternalPlanCorrections[line.id]?.map((correction) => correction.data.params.value),
                    ),
                );
                const lineFactCorrections = lodash.sum(
                    lodash.values(factCorrections[line.id]?.map((correction) => correction.data.params)),
                );
                const linePlanCorrections = lodash.sum(
                    planCorrections[line.id]?.reduce((acc, correction) => {
                        const { donorId, acceptorId, value } = correction.data.params;

                        return donorId === acceptorId ? acc : donorId === line.id ? [...acc, -value] : [...acc, value];
                    }, [] as number[]),
                );

                const linePlanFactDiff =
                    line.planValue +
                    linePlanCorrections -
                    (line.factValue - lineFactCorrections) +
                    lineIncomeExternalPlanCorrections -
                    lineOutcomeExternalPlanCorrections;

                return linePlanFactDiff < 0;
            } else {
                return true;
            }
        }
    });

    if (showOnlyLinesWithoutPlan) {
        filteredLines = filteredLines.filter((line) => {
            const lineIsDonorLine = from.some((fromCell) => cellBelongsToLine(fromCell, line.id));
            const lineIsAcceptorLine = to.some((toCell) => cellBelongsToLine(toCell, line.id));
            const isLineUsedInTransfer = lineIsDonorLine || lineIsAcceptorLine;

            return isLineUsedInTransfer || !line.planValue;
        });
    }

    if (showOnlyLinesWithNegativeBalance) {
        filteredLines = filteredLines.filter((line) => {
            const lineIsDonorLine = from.some((fromCell) => cellBelongsToLine(fromCell, line.id));
            const lineIsAcceptorLine = to.some((toCell) => cellBelongsToLine(toCell, line.id));
            const isLineUsedInTransfer = lineIsDonorLine || lineIsAcceptorLine;

            return lodash.includes(overrunnedPlanBudgetItemIds, line.id) || isLineUsedInTransfer;
        });
    }

    // tslint:disable-next-line:cyclomatic-complexity
    filteredLines = filteredLines.filter((line) => {
        let shouldUseThisLine = false;
        let filterWasUsed = false;

        const lineIsDonorLine = from.some((fromCell) => cellBelongsToLine(fromCell, line.id));
        const lineIsAcceptorLine = to.some((toCell) => cellBelongsToLine(toCell, line.id));
        const isLineUsedInTransfer = lineIsDonorLine || lineIsAcceptorLine;

        if (isLineUsedInTransfer) {
            return true;
        }

        const hasActivityCorrections = !lodash.isEmpty(activityCorrections[line.activityId]);
        const hasBudgetItemCorrections = !lodash.isEmpty(budgetItemCorrections[line.id]);
        const hasPlanCorrection =
            !lodash.isEmpty(planCorrections[line.id]) ||
            !lodash.isEmpty(incomeExternalPlanCorrections[line.id]) ||
            !lodash.isEmpty(outcomeExternalPlanCorrections[line.id]);
        const hasReserveCorrection = !lodash.isEmpty(reserveCorrections[line.id]);

        if (correctionsFilter[CORRECTION_FILTER_TYPE.ACTIVITY_CORRECTION]) {
            shouldUseThisLine = shouldUseThisLine || hasActivityCorrections;
            filterWasUsed = true;
        }

        if (correctionsFilter[CORRECTION_FILTER_TYPE.BUDGET_ITEM_CORRECTION]) {
            shouldUseThisLine = shouldUseThisLine || hasBudgetItemCorrections;
            filterWasUsed = true;
        }

        if (correctionsFilter[CORRECTION_FILTER_TYPE.PLAN_CORRECTION]) {
            shouldUseThisLine = shouldUseThisLine || hasPlanCorrection;
            filterWasUsed = true;
        }

        if (correctionsFilter[CORRECTION_FILTER_TYPE.RESERVE_CORRECTION]) {
            shouldUseThisLine = shouldUseThisLine || hasReserveCorrection;
            filterWasUsed = true;
        }

        if (correctionsFilter[CORRECTION_FILTER_TYPE.NO_CORRECTIONS]) {
            shouldUseThisLine =
                shouldUseThisLine ||
                !(hasActivityCorrections || hasBudgetItemCorrections || hasPlanCorrection || hasReserveCorrection);
            filterWasUsed = true;
        }

        if (!filterWasUsed) {
            shouldUseThisLine = true;
        }

        return shouldUseThisLine;
    });

    // const columnProps = ColumnsList.map(column => {
    //     const valueIsString = column.valueType === CellValueType.String;
    //     const valueIsNumber = column.valueType === CellValueType.Number;
    //     const valueIsDate = column.valueType === CellValueType.Date;
    //     const valueIsCurrency = column.valueType === CellValueType.Currency;
    //     const columnIsResponsible = column.name === ColumnName.Responsible;
    //     const noFiltersSelected = lodash.every(filters[column.name], item => item === false);

    //     return {
    //         column,
    //         valueIsString,
    //         valueIsNumber,
    //         valueIsDate,
    //         valueIsCurrency,
    //         columnIsResponsible,
    //         noFiltersSelected
    //     };
    // });

    // filteredLines = filteredLines.filter(line => {
    //     const lineHasDonorCell = line.id == from.lineId || line.id == lockedFrom.lineId;
    //     const lineHasAcceptorCell = line.id == to.lineId || line.id == lockedTo.lineId;
    //     const isLineUsedInTransfer = lineHasDonorCell || lineHasAcceptorCell;

    //     if (isLineUsedInTransfer) {
    //         return true;
    //     }

    //     return lodash.every(columnProps, ({
    //         column,
    //         valueIsString,
    //         valueIsNumber,
    //         valueIsDate,
    //         valueIsCurrency,
    //         columnIsResponsible,
    //         noFiltersSelected
    //     }) => {
    //         const columnFilters = filters[column.name];

    //         if (columnIsResponsible && !noFiltersSelected) {
    //             const keys: string[] = line.fields[column.name] ?
    //                 (line.fields[column.name] as any).split(',') :
    //                 null;

    //             return keys ?
    //                 keys.some(key => columnFilters[key]) :
    //                 columnFilters[null as any];
    //         }

    //         if (valueIsCurrency && !noFiltersSelected) {
    //             const key = line.fields[column.name] ?
    //                 formatCurrency((line.fields[column.name] as any).number) :
    //                 null;

    //             return columnFilters[key];
    //         }

    //         if (valueIsDate && !noFiltersSelected) {
    //             const key = line.fields[column.name] ?
    //                 (line.fields[column.name] as any).title :
    //                 null;

    //             return columnFilters[key];
    //         }

    //         if (valueIsString && !noFiltersSelected) {
    //             const cellValue = line.fields[column.name] as string;

    //             return columnFilters[cellValue];
    //         }

    //         if (valueIsNumber && !noFiltersSelected) {
    //             const cellValue = getNumberValue(line.fields[column.name]);

    //             return columnFilters[cellValue.toString()];
    //         }

    //         return true;
    //     });
    // });

    return filteredLines;
}

function sortTableLines(
    lines: TableLine[],
    dictionaries: Record<string, PlainDictionary>,
    users: UserResponseParams[],
    sortingMode: SortingMode,
    budgetItemsToIgnoreFilters: BudgetItem[],
    useLinesWithoutPlanInSorting: boolean,
): TableLine[] {
    const { columnName, order } = sortingMode;
    const sortingMethod = getSortingMethod(columnName, order, dictionaries, users);

    let res: TableLine[];
    const linesToIgnoreFilters: TableLine[] = [];

    if (useLinesWithoutPlanInSorting) {
        const restLines: TableLine[] = [];

        lines.forEach((line) => {
            if (budgetItemsToIgnoreFilters.some((budgetItem) => budgetItem.id === line.id)) {
                linesToIgnoreFilters.push(line);
            } else {
                restLines.push(line);
            }
        });

        res = restLines.sort(sortingMethod);
    } else {
        const linesWithPlan: TableLine[] = [];
        const linesWithoutPlanAndDonorIds: TableLine[] = [];
        const linesWithoutPlanButWithDonorIds: TableLine[] = [];

        lines.forEach((line) => {
            const donorId = line.fields[ColumnName.DonorIds];

            if (budgetItemsToIgnoreFilters.some((budgetItem) => budgetItem.id === line.id)) {
                linesToIgnoreFilters.push(line);
            } else if (line.planValue) {
                linesWithPlan.push(line);
            } else if (donorId) {
                linesWithoutPlanButWithDonorIds.push(line);
            } else {
                linesWithoutPlanAndDonorIds.push(line);
            }
        });

        res = [
            ...linesWithoutPlanAndDonorIds.sort(sortingMethod),
            ...linesWithPlan.sort(sortingMethod),
            ...linesWithoutPlanButWithDonorIds.sort(sortingMethod),
        ];
    }

    const r = [...lodash.orderBy(linesToIgnoreFilters, [(line) => line.creationTime], ['desc']), ...res];

    return r;
}

function getSortingMethod(
    columnName: ColumnName,
    order: OrderType,
    dictionaries: Record<string, PlainDictionary>,
    users: UserResponseParams[],
) {
    const A_BEFORE_B = -1;
    const B_BEFORE_A = 1;
    const KEEP_ORDER = 0;

    // tslint:disable-next-line:cyclomatic-complexity
    return (lineA: TableLine, lineB: TableLine) => {
        let valueA = getCellValue(lineA, columnName, dictionaries, users);
        let valueB = getCellValue(lineB, columnName, dictionaries, users);

        if (!valueA && !valueB) {
            return KEEP_ORDER;
        }

        if (!valueA) {
            return order === OrderType.Asc ? A_BEFORE_B : B_BEFORE_A;
        }

        if (!valueB) {
            return order === OrderType.Asc ? B_BEFORE_A : A_BEFORE_B;
        }

        if (columnName === ColumnName.DonorIds || columnName === ColumnName.Id) {
            valueA = parseInt(valueA as string, 10);
            valueB = parseInt(valueB as string, 10);
        } else {
            if (typeof valueA == 'string') {
                valueA = valueA.toUpperCase();
            }

            if (typeof valueB == 'string') {
                valueB = valueB.toUpperCase();
            }
        }

        if (order == OrderType.Asc) {
            return valueA < valueB ? A_BEFORE_B : valueA > valueB ? B_BEFORE_A : KEEP_ORDER;
        }

        if (order == OrderType.Desc) {
            return valueA < valueB ? B_BEFORE_A : valueA > valueB ? A_BEFORE_B : KEEP_ORDER;
        }

        return KEEP_ORDER;
    };
}

export function getCellValue(
    line: TableLine,
    columnName: ColumnName,
    dictionaries?: Record<string, PlainDictionary>,
    users?: UserResponseParams[],
): React.ReactText | React.ReactText[] | Date {
    let value: React.ReactText | React.ReactText[] | Date = null;

    const column = ColumnsList.find((item) => item.name == columnName);

    if (columnName === ColumnName.Responsible) {
        value = line.fields[columnName] as string;
        value = value
            ? value.split(',').map((id) => {
                  const user = users.find((user) => user.id === Number(id));
                  return user ? `${user.secondName} ${user.firstName}` : '';
              })
            : [];
    } else if (column.customCellType == CustomCellType.Dropdown) {
        value = getDropdownValue(line.fields[columnName], column, dictionaries, users);
    } else {
        const valueType = ColumnsList.find((item) => item.name == columnName).valueType;

        switch (valueType) {
            case CellValueType.String:
                value = getStringValue(line.fields[columnName]);
                break;

            case CellValueType.Number:
                value = getNumberValue(line.fields[columnName]);
                break;

            case CellValueType.Currency:
                value = getNumberValue(line.fields[columnName]);
                break;

            case CellValueType.Date:
                value = getDateValue(line.fields[columnName]);
                break;
        }
    }

    return value;
}

function getStringValue(cellValue: CellValue): string {
    return cellValue as string;
}

function getNumberValue(cellValue: CellValue): number {
    const value = cellValue as NumericCellValue;

    return value ? value.number : null;
}

function getDateValue(cellValue: CellValue): Date {
    const value = cellValue as DateCellValue;

    return value ? value.date : null;
}

function getDropdownValue(
    cellValue: CellValue,
    column: ColumnData,
    dictionaries?: Record<string, PlainDictionary>,
    users?: UserResponseParams[],
): string {
    let value: string = null;

    const selectedId = cellValue as string;

    if (column.metaData && column.metaData.dictionaryType) {
        const dictionaryItem = dictionaries[selectedId];

        if (dictionaryItem) {
            value = lodash.includes(ColumnsNameWithCodes, column.name) ? dictionaryItem.code : dictionaryItem.value;
        }
    }

    if (column.name === ColumnName.Responsible) {
        const userId = Number(cellValue);
        const user = users.find((user) => user.id === userId);

        value = user ? `${user.secondName} ${user.firstName}` : null;
    }

    return value;
}

function makeDropdownOptions(
    titleAccessor: 'code' | 'value',
    dictionaryItems: PlainDictionary[],
): DropdownCellOptions[] {
    return dictionaryItems
        .filter((item) => (titleAccessor === 'code' ? !!item.code : true))
        .map((item) => makeDropdownOption(titleAccessor, item));
}

const makeDropdownOption = lodash.memoize(
    (titleAccessor: 'code' | 'value', dictionary: PlainDictionary) => {
        const isDeleted = dictionary.status === DictionaryStatus.DELETED;

        const titleStatus = isDeleted ? ' (Удален)' : '';

        return {
            id: dictionary.id,
            title: `${dictionary[titleAccessor]}${titleStatus}`,
            order: dictionary.order,
            hide: isDeleted,
        };
    },
    (titleAccessor, dictionary) => `${dictionary.id}-${titleAccessor}`,
);
