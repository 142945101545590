/* tslint:disable:max-file-line-count */
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Success } from 'typescript-fsa';
import * as lodash from 'lodash';
import { combineReducers } from 'redux';

import { MultiReferenceDictionaryApi } from '@api';

import { DictionaryType, BudgetItemStatus, BudgetItemApproverStatus, BudgetItem } from '@mrm/budget';
import {
    PageState,
    PageData,
    BudgetPlanningPageState,
    ColumnsVisiblityFilter,
    SortingMode,
    OrderType,
    Filters,
    ColumnName,
    ColumnsWidth,
    UnsavedChange,
    ChangeList,
    LineStatusChange,
    ACTIVITY_FIELDS_COLUMN_NAMES,
    CURRENCY_COLUMN_NAMES,
    CustomCellType,
    CellValueType,
    BudgetItemTypes,
    LoadFiltersPaylaod,
    SetFiltersLoadingStatusPayload,
    ChangeCellValueParams,
    ResetFilterPayload,
    AppliedFiltersNames,
    SetPreviouslyLoadedFilterPayload,
    SetPreviouslyLoadedFiltersPayload,
    BudgetItemApproversToDisplay,
    XLSXImportErrorState,
    XLSXImportValidationError,
    ColumnsNameWithCodes,
    ColumnsWithSameData,
} from './types';
import { updateAppliedFiltersNamesUseAllFilters, updateColumnFilterLoadingStatus } from './utils';

import { LoadingStatus } from '@store/commonTypes';

import * as actions from './actions/sync';
import * as asyncActions from './actions/async';
import { ColumnsList, DictionaryColumns } from '../../modules/budget/BudgetPage/BudgetPlanning/ColumnsConfig';
import { LinkedList } from './lib/LinkedList';
import { lineModalReducer } from './lineModal';
import { miscBudgetItemsReducer } from './miscBudgetItems';

const columnsWidthInitialState = ColumnsList.reduce((acc, item) => ({ ...acc, [item.name]: item.width }), {});

class Reducer {
    public static makeInitialState(): PageState {
        const allItemsAreHiddenByDefault = ColumnsList.every((column) => column.hiddenByDefault);
        const allItemsAreVisibleByDefault = ColumnsList.every((column) => !column.hiddenByDefault);

        const columnsVisiblityFilterInitialState = ColumnsList.reduce((acc, item) => {
            if (allItemsAreHiddenByDefault || allItemsAreVisibleByDefault) {
                acc[item.name] = false;
            } else {
                acc[item.name] = !item.hiddenByDefault;
            }

            return acc;
        }, {});

        return {
            pageData: {
                budget: null,
                activityBudgets: [],
                budgetItems: [],
                budgetItemsToIgnoreFilters: [],
                userDictionaries: { byId: {}, byType: {} },
                allDictionaries: { byId: {}, byType: {} },
                users: [],
                allUsers: [],
                dictionariesForXLSXTemplate: {},
                budgetItemSnapshots: {},
            },
            pageFilters: {
                columnsVisiblityFilter: columnsVisiblityFilterInitialState,
                sortingMode: {
                    columnName: ColumnName.Id,
                    order: OrderType.Asc,
                },
                filters: ColumnsList.reduce((acc, item) => {
                    acc[item.name] = {};
                    return acc;
                }, {}),
                appliedFiltersNames: [],
                displayOnlyUnapproved: false,
                displayOnlyWithSnapshots: false,
                displayDisabledLines: false,
                validationStatus: false,
                budgetItemsToDisplay: {
                    [BudgetItemStatus.Draft]: false,
                    [BudgetItemStatus.Published]: false,
                    [BudgetItemStatus.OnExpertApprovement]: false,
                    [BudgetItemStatus.Approved]: false,
                    [BudgetItemStatus.Rejected]: false,
                },
                budgetItemsApproversToDisplay: {
                    [BudgetItemApproverStatus.Approved]: false,
                    [BudgetItemApproverStatus.Rejected]: false,
                    [BudgetItemApproverStatus.Waiting]: false,
                },
                showOnlyLinesWithPlanBudget: false,
                showOnlyLinesWithoutPlanBudget: false,
            },
            unsavedChanges: {},
            columnsWidth: columnsWidthInitialState,
            changesHistory: new LinkedList<UnsavedChange[]>(),
            currentChangePosition: 0,
            fixedColumnsNames: [],
            lineStatusChanges: {},
            rejectMenuLineId: null,
            approversMenuLineId: null,
            approverStatusChanges: {},
            displayErrorPopup: false,
            xlsxImportErrorState: {
                error: XLSXImportValidationError.None,
                missingColumns: [],
                fileName: null,
            },
            columnFilters: {
                filters: {
                    [ColumnName.Comment]: {},
                    [ColumnName.Id]: {},
                    [ColumnName.Regionality]: {},
                    [ColumnName.ActivityName]: {},
                    [ColumnName.ActivityType]: {},
                    [ColumnName.Direction]: {},
                    [ColumnName.Channel]: {},
                    [ColumnName.Tool]: {},
                    [ColumnName.Block]: {},
                    [ColumnName.Division]: {},
                    [ColumnName.Item]: {},
                    [ColumnName.Resource]: {},
                    [ColumnName.Responsible]: {},
                    [ColumnName.Customer]: {},
                    [ColumnName.BusinessGoal]: {},
                    [ColumnName.Segment]: {},
                    [ColumnName.Product]: {},
                    [ColumnName.Territory]: {},
                    [ColumnName.StartDate]: {},
                    [ColumnName.EndDate]: {},
                    [ColumnName.PlanJan]: {},
                    [ColumnName.PlanFeb]: {},
                    [ColumnName.PlanMar]: {},
                    [ColumnName.PlanApr]: {},
                    [ColumnName.PlanMay]: {},
                    [ColumnName.PlanJun]: {},
                    [ColumnName.PlanJul]: {},
                    [ColumnName.PlanAug]: {},
                    [ColumnName.PlanSep]: {},
                    [ColumnName.PlanOct]: {},
                    [ColumnName.PlanNov]: {},
                    [ColumnName.PlanDec]: {},
                    [ColumnName.TotalPlan]: {},
                    [ColumnName.SapComment]: {},
                    [ColumnName.Author]: {},
                    [ColumnName.LocationDriver]: {},
                    [ColumnName.RegionalityCode]: {},
                    [ColumnName.ActivityTypeCode]: {},
                    [ColumnName.DirectionCode]: {},
                    [ColumnName.ToolCode]: {},
                    [ColumnName.BlockCode]: {},
                    [ColumnName.ResourceCode]: {},
                    [ColumnName.DivisionCode]: {},
                    [ColumnName.SegmentCode]: {},
                    [ColumnName.ProductCode]: {},
                    [ColumnName.LocationDriverCode]: {},
                    [ColumnName.CostCenter]: {},
                    [ColumnName.CostCenterCode]: {},
                    [ColumnName.FactPreviousPeriod]: {},
                    [ColumnName.TerritoryCode]: {},
                    [ColumnName.Tags]: {},
                    [ColumnName.TotalPlanQuarter1]: {},
                    [ColumnName.TotalPlanQuarter2]: {},
                    [ColumnName.TotalPlanQuarter3]: {},
                    [ColumnName.TotalPlanQuarter4]: {},
                    [ColumnName.ChannelCode]: {},
                },
                loadingStatus: {
                    [ColumnName.Comment]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Id]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Regionality]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ActivityName]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ActivityType]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Direction]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Channel]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Tool]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Block]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Division]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Item]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Resource]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Responsible]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Customer]: LoadingStatus.NOT_LOADED,
                    [ColumnName.BusinessGoal]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Segment]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Product]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Territory]: LoadingStatus.NOT_LOADED,
                    [ColumnName.StartDate]: LoadingStatus.NOT_LOADED,
                    [ColumnName.EndDate]: LoadingStatus.NOT_LOADED,
                    [ColumnName.PlanJan]: LoadingStatus.NOT_LOADED,
                    [ColumnName.PlanFeb]: LoadingStatus.NOT_LOADED,
                    [ColumnName.PlanMar]: LoadingStatus.NOT_LOADED,
                    [ColumnName.PlanApr]: LoadingStatus.NOT_LOADED,
                    [ColumnName.PlanMay]: LoadingStatus.NOT_LOADED,
                    [ColumnName.PlanJun]: LoadingStatus.NOT_LOADED,
                    [ColumnName.PlanJul]: LoadingStatus.NOT_LOADED,
                    [ColumnName.PlanAug]: LoadingStatus.NOT_LOADED,
                    [ColumnName.PlanSep]: LoadingStatus.NOT_LOADED,
                    [ColumnName.PlanOct]: LoadingStatus.NOT_LOADED,
                    [ColumnName.PlanNov]: LoadingStatus.NOT_LOADED,
                    [ColumnName.PlanDec]: LoadingStatus.NOT_LOADED,
                    [ColumnName.TotalPlan]: LoadingStatus.NOT_LOADED,
                    [ColumnName.SapComment]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Author]: LoadingStatus.NOT_LOADED,
                    [ColumnName.LocationDriver]: LoadingStatus.NOT_LOADED,
                    [ColumnName.RegionalityCode]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ActivityTypeCode]: LoadingStatus.NOT_LOADED,
                    [ColumnName.DirectionCode]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ToolCode]: LoadingStatus.NOT_LOADED,
                    [ColumnName.BlockCode]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ResourceCode]: LoadingStatus.NOT_LOADED,
                    [ColumnName.DivisionCode]: LoadingStatus.NOT_LOADED,
                    [ColumnName.SegmentCode]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ProductCode]: LoadingStatus.NOT_LOADED,
                    [ColumnName.LocationDriverCode]: LoadingStatus.NOT_LOADED,
                    [ColumnName.CostCenter]: LoadingStatus.NOT_LOADED,
                    [ColumnName.CostCenterCode]: LoadingStatus.NOT_LOADED,
                    [ColumnName.FactPreviousPeriod]: LoadingStatus.NOT_LOADED,
                    [ColumnName.TerritoryCode]: LoadingStatus.NOT_LOADED,
                    [ColumnName.Tags]: LoadingStatus.NOT_LOADED,
                    [ColumnName.TotalPlanQuarter1]: LoadingStatus.NOT_LOADED,
                    [ColumnName.TotalPlanQuarter2]: LoadingStatus.NOT_LOADED,
                    [ColumnName.TotalPlanQuarter3]: LoadingStatus.NOT_LOADED,
                    [ColumnName.TotalPlanQuarter4]: LoadingStatus.NOT_LOADED,
                    [ColumnName.ChannelCode]: LoadingStatus.NOT_LOADED,
                },
            },
            previouslyLoadedFilters: null,
            loadingFiltersCount: 0,
            filtersPreloader: false,
            showRejectionCommentPopup: false,
            showTagsHaveChangedMarker: false,
            multiReferenceDictionaryApi: null,
        };
    }

    public static loadPageData(state: PageState, payload: PageData): PageState {
        return { ...state, pageData: { ...state.pageData, ...payload } };
    }

    public static loadBudgetItems(state: PageState, budgetItems: BudgetItem[]): PageState {
        return {
            ...state,
            pageData: {
                ...state.pageData,
                budgetItems,
            },
        };
    }

    public static initUnsavedChanges(state: PageState, payload: ChangeList): PageState {
        return { ...state, unsavedChanges: { ...state.unsavedChanges, ...payload } };
    }

    public static setColumnsVisiblityFilter(state: PageState, payload: ColumnsVisiblityFilter): PageState {
        const columnsVisiblityFilter = lodash.keys(state.pageFilters.columnsVisiblityFilter).reduce(
            (acc, columnName) => ({
                ...acc,
                ...{
                    [columnName]: !lodash.isNil(payload[columnName])
                        ? payload[columnName]
                        : state.pageFilters.columnsVisiblityFilter[columnName],
                },
            }),
            {},
        );

        return {
            ...state,
            pageFilters: {
                ...state.pageFilters,
                columnsVisiblityFilter,
            },
        };
    }

    public static setSortingMode(state: PageState, payload: SortingMode): PageState {
        return { ...state, pageFilters: { ...state.pageFilters, sortingMode: payload } };
    }

    public static setFilters(state: PageState, payload: Filters): PageState {
        const filters = {
            ...state.columnFilters.filters,
            ...payload,
        };

        const appliedFiltersNames = updateAppliedFiltersNamesUseAllFilters({
            filters,
            appliedFiltersNames: state.pageFilters.appliedFiltersNames,
        });

        // const loadingStatus = updateColumnFilterLoadingStatus({
        //     filtersGroup: {
        //         prev: state.columnFilters.filters,
        //         current: filters,
        //     },
        //     appliedFiltersNamesGroup: {
        //         prev: state.pageFilters.appliedFiltersNames,
        //         current: appliedFiltersNames,
        //     },
        //     columnFiltersLoadingStatus: state.columnFilters.loadingStatus,
        // });

        return {
            ...state,
            pageFilters: {
                ...state.pageFilters,
                appliedFiltersNames,
            },
        };
    }

    public static setAppliedFiltersNames(state: PageState, payload: AppliedFiltersNames): PageState {
        return {
            ...state,
            pageFilters: {
                ...state.pageFilters,
                appliedFiltersNames: payload,
            },
        };
    }

    public static resetFilter(state: PageState, payload: ResetFilterPayload): PageState {
        const { filterName } = payload;

        const filters = {
            ...state.columnFilters.filters,
            [filterName]: lodash.keys(state.columnFilters.filters[filterName]).reduce((filter, filterName) => {
                return { ...filter, [filterName]: false };
            }, {}),
        };

        const appliedFiltersNames = updateAppliedFiltersNamesUseAllFilters({
            filters,
            appliedFiltersNames: state.pageFilters.appliedFiltersNames,
        });

        return {
            ...state,
            pageFilters: {
                ...state.pageFilters,
                appliedFiltersNames,
            },
            columnFilters: {
                ...state.columnFilters,
                filters: {
                    ...state.columnFilters.filters,
                    [filterName]: lodash.keys(state.columnFilters.filters[filterName]).reduce((filter, key) => {
                        return {
                            ...filter,
                            [key]: false,
                        };
                    }, {}),
                },
                loadingStatus: updateColumnFilterLoadingStatus({
                    filtersGroup: {
                        prev: state.columnFilters.filters,
                        current: filters,
                    },
                    appliedFiltersNamesGroup: {
                        prev: state.pageFilters.appliedFiltersNames,
                        current: appliedFiltersNames,
                    },
                    columnFiltersLoadingStatus: state.columnFilters.loadingStatus,
                }),
            },
        };
    }

    public static resetFilters(state: PageState): PageState {
        const cleanUpdatedFilters = lodash.cloneDeep(state.columnFilters.filters);
        const cleanUpdatedFiltersLoadingStatus = lodash.cloneDeep(state.columnFilters.loadingStatus);

        lodash.forEach(cleanUpdatedFilters, (columnFilters, columnName) => {
            lodash.forEach(columnFilters, (value, title) => {
                cleanUpdatedFilters[columnName][title] = false;
            });
        });

        lodash.forEach(
            cleanUpdatedFiltersLoadingStatus,
            (columnFilters, columnName) => (cleanUpdatedFiltersLoadingStatus[columnName] = LoadingStatus.NOT_LOADED),
        );

        return {
            ...state,
            pageFilters: {
                ...state.pageFilters,
                appliedFiltersNames: [],
            },
            columnFilters: {
                filters: cleanUpdatedFilters,
                loadingStatus: cleanUpdatedFiltersLoadingStatus,
            },
        };
    }

    public static resetViewSettings(state: PageState): PageState {
        const {
            pageFilters: { sortingMode, columnsVisiblityFilter },
        } = Reducer.makeInitialState();

        return {
            ...state,
            pageFilters: {
                ...state.pageFilters,
                sortingMode,
                columnsVisiblityFilter,
            },
            fixedColumnsNames: [],
            columnsWidth: columnsWidthInitialState,
        };
    }

    public static setResizingColumnName(state: PageState, payload: ColumnName): PageState {
        return { ...state, resizingColumnName: payload };
    }

    public static setDisplayOnlyUnapproved(state: PageState, displayOnlyUnapproved: boolean): PageState {
        return {
            ...state,
            pageFilters: { ...state.pageFilters, displayOnlyUnapproved },
        };
    }

    public static setDisplayOnlyWithSnapshots(state: PageState, displayOnlyWithSnapshots: boolean): PageState {
        return {
            ...state,
            pageFilters: { ...state.pageFilters, displayOnlyWithSnapshots },
        };
    }

    public static setDisplayDisabledLines(state: PageState, displayDisabledLines: boolean): PageState {
        return {
            ...state,
            pageFilters: { ...state.pageFilters, displayDisabledLines },
        };
    }

    public static setValidationStatus(state: PageState, payload: boolean): PageState {
        return {
            ...state,
            pageFilters: {
                ...state.pageFilters,
                validationStatus: payload,
            },
        };
    }

    public static toggleColumnFix(state: PageState, payload: ColumnName): PageState {
        return {
            ...state,
            fixedColumnsNames: lodash.xor(state.fixedColumnsNames, [payload]),
        };
    }

    public static setFixedColumnsNames(state: PageState, payload: ColumnName[]): PageState {
        return {
            ...state,
            fixedColumnsNames: payload,
        };
    }

    public static changeCellValueDone(
        state: PageState,
        payload: Success<ChangeCellValueParams, ChangeCellValueParams>,
    ): PageState {
        const { change, line, tags } = payload.result;
        const {
            pageData,
            unsavedChanges,
            changesHistory,
            currentChangePosition,
            lineStatusChanges,
            multiReferenceDictionaryApi,
        } = state;
        const { activityBudgets, budgetItems, budgetItemsToIgnoreFilters, userDictionaries, users } = pageData;
        const budgetItemsToUse = [...budgetItems, ...budgetItemsToIgnoreFilters];

        const budgetItem = budgetItemsToUse.find((budgetItem) => budgetItem.id === change.budgetItemId);
        const column = ColumnsList.find((item) => item.name == change.columnName);
        const dictionaryType = lodash.get(column, 'metaData.dictionaryType') as DictionaryType;

        const newChange = validateNewChange(change);
        let changes: UnsavedChange[] = [];

        if (ColumnsWithSameData[newChange.columnName]) {
            changes = ColumnsWithSameData[newChange.columnName].map((column: ColumnName) => ({
                ...newChange,
                columnName: column,
            }));
        } else {
            changes = [newChange];
        }

        if (dictionaryType) {
            const lineDictionaryValue: Partial<Record<DictionaryType, string>> = {};
            DictionaryColumns.forEach((dictionaryColumn) => {
                if (!ColumnsNameWithCodes.includes(dictionaryColumn.name)) {
                    const columnChange = unsavedChanges[line.id]?.find(
                        (change) => change.columnName === dictionaryColumn.name,
                    );
                    const valueToUse = (
                        columnChange ? columnChange.value : line.fields[dictionaryColumn.name]
                    ) as string;

                    if (valueToUse && userDictionaries.byId[valueToUse]) {
                        lineDictionaryValue[dictionaryColumn.metaData.dictionaryType] =
                            userDictionaries.byId[valueToUse].id;
                    }
                }
            });

            const updatedValue = multiReferenceDictionaryApi.performDictionaryUpdate(
                Object.values(lineDictionaryValue),
                dictionaryType,
                (newChange.value || null) as string,
            );

            const keysToCheck = lodash.uniq([
                ...Object.keys(lineDictionaryValue),
                ...Object.keys(updatedValue),
            ]) as DictionaryType[];

            keysToCheck.forEach((updatedValueKey: DictionaryType) => {
                const changeColumn = DictionaryColumns.find(
                    (column) => column.metaData.dictionaryType === updatedValueKey,
                );

                if (changeColumn) {
                    const value = updatedValue[updatedValueKey]?.id || null;
                    const originalValue = changeColumn.accessor({
                        budgetItem,
                        activityBudgets,
                        users,
                        tags,
                    }) as string;

                    let shouldAddChange;
                    if (
                        updatedValueKey === DictionaryType.Regionality &&
                        change?.columnName !== ColumnName.Regionality &&
                        value &&
                        originalValue
                    ) {
                        shouldAddChange = false;
                    } else if (change.columnName === changeColumn.name) {
                        shouldAddChange = true;
                    } else {
                        const valueAppearedOrDisappearedAfterMutation = lodash.xor([
                            column.metaData.dictionaryType in lineDictionaryValue,
                            column.metaData.dictionaryType in updatedValue,
                        ]);

                        shouldAddChange = valueAppearedOrDisappearedAfterMutation;
                    }

                    if (shouldAddChange) {
                        changes.push({
                            budgetItemId: newChange.budgetItemId,
                            columnName: changeColumn.name,
                            value,
                            originalValue,
                        });
                    }
                }
            });
        }

        const activityBudgetFieldChanged = lodash.includes(ACTIVITY_FIELDS_COLUMN_NAMES, column.name);

        if (activityBudgetFieldChanged) {
            const activityNameChange = changes[0];

            const activityId = budgetItem.activity.id;
            const activityBudgetItems = budgetItemsToUse.filter((item) => item.activity.id == activityId);

            activityBudgetItems.forEach((budgetItem) => {
                if (activityNameChange.budgetItemId !== budgetItem.id) {
                    changes.push({ ...activityNameChange, budgetItemId: budgetItem.id });
                }
            });
        }

        if (currentChangePosition < changesHistory.length) {
            changesHistory.remove(currentChangePosition + 1);
        }

        changesHistory.add(changes);

        const updatedChangesList = lodash.clone(unsavedChanges);

        applyChangesToChangeList(updatedChangesList, changes);

        const updatedLineStatusChanges = lodash.cloneDeep(lineStatusChanges);

        lodash.forEach(updatedChangesList, (lineChanges, lineId) => {
            if (lineChanges.length > 0) {
                delete updatedLineStatusChanges[lineId];
            }
        });

        return {
            ...state,
            unsavedChanges: updatedChangesList,
            currentChangePosition: changesHistory.length,
            lineStatusChanges: updatedLineStatusChanges,
        };
    }

    public static undoUnsavedChanges(state: PageState): PageState {
        const { unsavedChanges, changesHistory, currentChangePosition } = state;

        if (currentChangePosition < 1) {
            return state;
        }

        const newChangePosition = currentChangePosition - 1;

        const changesToUndo = changesHistory.getValueAtPosition(currentChangePosition);
        const changesToRestore = findLastChangesAtSameCells(newChangePosition, changesToUndo);

        let updatedChangeList = lodash.clone(unsavedChanges);

        updatedChangeList = removeChangesFromChangeList(updatedChangeList, changesToUndo);
        updatedChangeList = applyChangesToChangeList(updatedChangeList, changesToRestore);

        return {
            ...state,
            unsavedChanges: updatedChangeList,
            currentChangePosition: newChangePosition,
        };

        function findLastChangesAtSameCells(startPosition: number, changes: UnsavedChange[]): UnsavedChange[] {
            if (startPosition < 1) {
                return [];
            }

            let currentPosition = startPosition;

            let currentChanges: UnsavedChange[] = changesHistory.getValueAtPosition(currentPosition);

            while (!changesAreAtSameCells(currentChanges, changes) && currentPosition > 0) {
                currentPosition--;

                if (currentPosition > 0) {
                    currentChanges = changesHistory.getValueAtPosition(currentPosition);
                }
            }

            return currentPosition > 0 ? currentChanges : [];

            function changesAreAtSameCells(changesA: UnsavedChange[], changesB: UnsavedChange[]): boolean {
                return changesA.every((changeA) =>
                    changesB.some(
                        (changeB) =>
                            changeA.budgetItemId == changeB.budgetItemId && changeA.columnName == changeB.columnName,
                    ),
                );
            }
        }
    }

    public static redoUnsavedChanges(state: PageState): PageState {
        const { unsavedChanges, changesHistory, currentChangePosition } = state;

        const newChangePosition = currentChangePosition + 1;

        if (newChangePosition > changesHistory.length) {
            return state;
        }

        const changesToRestore = changesHistory.getValueAtPosition(newChangePosition);

        let updatedChangeList = lodash.clone(unsavedChanges);

        updatedChangeList = applyChangesToChangeList(updatedChangeList, changesToRestore);

        return {
            ...state,
            unsavedChanges: updatedChangeList,
            currentChangePosition: newChangePosition,
        };
    }

    public static setLineStatus(state: PageState, payload: LineStatusChange): PageState {
        const { lineStatusChanges } = state;
        const { lineId, status, rejectComment = null } = payload;

        const updatedLineStatusChanges = lodash.clone(lineStatusChanges);

        updatedLineStatusChanges[lineId] = { status, rejectComment };

        return { ...state, lineStatusChanges: updatedLineStatusChanges };
    }

    public static deleteLineStatus(state: PageState, lineId: string): PageState {
        const { lineStatusChanges } = state;

        const updatedLineStatusChanges = Object.keys(lineStatusChanges).reduce(
            (acc, id) =>
                id === lineId
                    ? acc
                    : {
                          ...acc,
                          [id]: lineStatusChanges[id],
                      },
            {},
        );

        return { ...state, lineStatusChanges: updatedLineStatusChanges };
    }

    public static setColumnsWidth(state: PageState, payload: ColumnsWidth): PageState {
        // Preventing the case when column width is not in received userConfig
        // This can happen after applying old userConfig to table with new fields
        const updatedColumnsWidth = {};
        for (const columnName in columnsWidthInitialState) {
            updatedColumnsWidth[columnName] = payload[columnName] || columnsWidthInitialState[columnName];
        }

        return { ...state, columnsWidth: updatedColumnsWidth };
    }

    public static setRejectMenuLineId(state: PageState, payload: string): PageState {
        return { ...state, rejectMenuLineId: payload };
    }

    public static setApproverMenuLineId(state: PageState, payload: string): PageState {
        return { ...state, approversMenuLineId: payload };
    }

    public static setApproverStatus(state: PageState, payload: LineStatusChange): PageState {
        const { approverStatusChanges } = state;
        const { lineId, status, rejectComment = null } = payload;

        const updatedApproverStatusChanges = lodash.clone(approverStatusChanges);

        if (status == null) {
            delete updatedApproverStatusChanges[lineId];
        } else {
            updatedApproverStatusChanges[lineId] = { status, rejectComment };
        }

        return { ...state, approverStatusChanges: updatedApproverStatusChanges };
    }

    public static clearLineStatuses(state: PageState, payload: string[]): PageState {
        const { lineStatusChanges } = state;

        const updatedLineStatusChanges = lodash.clone(lineStatusChanges);

        payload.forEach((lineId) => {
            delete updatedLineStatusChanges[lineId];
        });

        return { ...state, lineStatusChanges: updatedLineStatusChanges };
    }

    public static clearApproverStatuses(state: PageState, payload: string[]): PageState {
        const { approverStatusChanges } = state;

        const updatedApproverStatusChanges = lodash.clone(approverStatusChanges);

        payload.forEach((lineId) => {
            delete updatedApproverStatusChanges[lineId];
        });

        return { ...state, approverStatusChanges: updatedApproverStatusChanges };
    }

    public static clearUnsavedChanges(state: PageState): PageState {
        const { budgetItems, budgetItemsToIgnoreFilters } = state.pageData;

        const lineIds = [...budgetItems, ...budgetItemsToIgnoreFilters].map((item) => item.id);

        const initialChangesList = {};

        lineIds.forEach((lineId) => {
            initialChangesList[lineId] = [];
        });

        return { ...state, unsavedChanges: initialChangesList };
    }

    public static clearUnsavedChangesByLines(state: PageState, payload: string[]): PageState {
        const { budgetItems, budgetItemsToIgnoreFilters } = state.pageData;
        const budgetItemsToUse = [...budgetItems, ...budgetItemsToIgnoreFilters];

        const updatedChangeList = lodash.clone(state.unsavedChanges);

        let lineIds: string[] = [];

        payload.forEach((lineId) => {
            const activity = budgetItemsToUse.find((budgetItem) => budgetItem.id === lineId).activity;

            const activityLinesIds = budgetItemsToUse
                .filter((budgetItem) => budgetItem.activity.id === activity.id)
                .map((budgetItem) => budgetItem.id);

            lineIds.push(...activityLinesIds);
        });

        lineIds = lodash.uniq(lineIds);

        lineIds.forEach((lineId) => {
            const updatedLineChanges = (updatedChangeList[lineId] || []).filter(
                (change) => !lodash.includes(ACTIVITY_FIELDS_COLUMN_NAMES, change.columnName),
            );

            updatedChangeList[lineId] = updatedLineChanges;
        });

        payload.forEach((lineId) => {
            updatedChangeList[lineId] = [];
        });

        return { ...state, unsavedChanges: updatedChangeList };
    }

    public static setErrorPopupStatus(state: PageState, payload: boolean): PageState {
        return { ...state, displayErrorPopup: payload };
    }

    public static setPreloaderStatus(state: PageState, payload: boolean): PageState {
        return { ...state, preloader: payload };
    }

    public static resetChangesHistory(state: PageState): PageState {
        return {
            ...state,
            changesHistory: new LinkedList<UnsavedChange[]>(),
            currentChangePosition: 0,
        };
    }

    public static setBudgetItemsToDisplay(state: PageState, budgetItemsToDisplay: BudgetItemTypes): PageState {
        return {
            ...state,
            pageFilters: {
                ...state.pageFilters,
                budgetItemsToDisplay,
            },
        };
    }

    public static loadFilters(state: PageState, payload: LoadFiltersPaylaod): PageState {
        const { columnName, filters } = payload;

        return {
            ...state,
            columnFilters: {
                ...state.columnFilters,
                filters: {
                    ...state.columnFilters.filters,
                    [columnName]: filters,
                },
            },
        };
    }

    public static incLoadingFiltersCount(state: PageState): PageState {
        return {
            ...state,
            loadingFiltersCount: state.loadingFiltersCount + 1,
        };
    }

    public static decLoadingFiltersCount(state: PageState): PageState {
        return {
            ...state,
            loadingFiltersCount: state.loadingFiltersCount - 1,
        };
    }

    public static setFiltersLoadingStatus(state: PageState, payload: SetFiltersLoadingStatusPayload): PageState {
        const { columnName, loadingStatus } = payload;

        return {
            ...state,
            columnFilters: {
                ...state.columnFilters,
                loadingStatus: {
                    ...state.columnFilters.loadingStatus,
                    [columnName]: loadingStatus,
                },
            },
        };
    }

    public static setFiltersPreloaderStatus(state: PageState, filtersPreloader: boolean): PageState {
        return {
            ...state,
            filtersPreloader,
        };
    }

    public static updateInProgressData(state: PageState): PageState {
        const {
            pageData: { budgetItems },
            lineStatusChanges,
            approverStatusChanges,
        } = state;
        const loadedBudgetItemIds = budgetItems.map((budgetItem) => budgetItem.id);
        const updatedLineStatusChanges = Object.keys(lineStatusChanges)
            .filter((lineId) => loadedBudgetItemIds.includes(lineId))
            .reduce(
                (acc, lineId) => ({
                    ...acc,
                    [lineId]: lineStatusChanges[lineId],
                }),
                {},
            );
        const updatedApproverStatusChanges = Object.keys(approverStatusChanges)
            .filter((lineId) => loadedBudgetItemIds.includes(lineId))
            .reduce(
                (acc, lineId) => ({
                    ...acc,
                    [lineId]: approverStatusChanges[lineId],
                }),
                {},
            );

        return {
            ...state,
            lineStatusChanges: updatedLineStatusChanges,
            approverStatusChanges: updatedApproverStatusChanges,
        };
    }

    public static initPreviouslyLoadedFilters(state: PageState): PageState {
        return {
            ...state,
            previouslyLoadedFilters: state.columnFilters.filters,
        };
    }

    public static setPreviouslyLoadedFilter(state: PageState, payload: SetPreviouslyLoadedFilterPayload): PageState {
        const { columnName, filter } = payload;

        return {
            ...state,
            previouslyLoadedFilters: {
                ...state.previouslyLoadedFilters,
                [columnName]: filter,
            },
        };
    }

    public static setPreviouslyLoadedFilters(state: PageState, payload: SetPreviouslyLoadedFiltersPayload): PageState {
        const { filters } = payload;

        return {
            ...state,
            previouslyLoadedFilters: {
                ...state.previouslyLoadedFilters,
                ...filters,
            },
        };
    }

    public static resetPreviouslyLoadedFilters(state: PageState): PageState {
        return {
            ...state,
            previouslyLoadedFilters: state.columnFilters.filters,
        };
    }

    public static resetBudgetRelatedData(state: PageState): PageState {
        const {
            pageData: { activityBudgets, budgetItems, budgetItemsToIgnoreFilters },
            columnFilters,
        } = Reducer.makeInitialState();

        return {
            ...state,
            pageData: {
                ...state.pageData,
                activityBudgets,
                budgetItems,
                budgetItemsToIgnoreFilters,
            },
            columnFilters,
        };
    }

    public static setRejectionCommentPopupVisibility(state: PageState, showRejectionCommentPopup: boolean): PageState {
        return { ...state, showRejectionCommentPopup };
    }

    public static setBudgetItemsApproversToDisplay(
        state: PageState,
        budgetItemsApproversToDisplay: BudgetItemApproversToDisplay,
    ): PageState {
        return { ...state, pageFilters: { ...state.pageFilters, budgetItemsApproversToDisplay } };
    }

    public static setXLSXImportErrorState(state: PageState, xlsxImportErrorState: XLSXImportErrorState): PageState {
        return { ...state, xlsxImportErrorState };
    }

    public static setShowTagsHaveChangedMarker(state: PageState, showTagsHaveChangedMarker: boolean): PageState {
        return { ...state, showTagsHaveChangedMarker };
    }

    public static setShowOnlyLinesWithPlanBudget(state: PageState, showOnlyLinesWithPlanBudget: boolean): PageState {
        return { ...state, pageFilters: { ...state.pageFilters, showOnlyLinesWithPlanBudget } };
    }

    public static setShowOnlyLinesWithoutPlanBudget(
        state: PageState,
        showOnlyLinesWithoutPlanBudget: boolean,
    ): PageState {
        return { ...state, pageFilters: { ...state.pageFilters, showOnlyLinesWithoutPlanBudget } };
    }

    public static setMultiReferenceDictionaryApi(
        state: PageState,
        multiReferenceDictionaryApi: MultiReferenceDictionaryApi,
    ): PageState {
        return { ...state, multiReferenceDictionaryApi };
    }
}

function validateNewChange(newChange: UnsavedChange): UnsavedChange {
    const column = ColumnsList.find((item) => item.name == newChange.columnName);

    const updatedNewChange = lodash.clone(newChange);

    const isCurrencyCell = lodash.includes(CURRENCY_COLUMN_NAMES, newChange.columnName);

    if (isCurrencyCell && updatedNewChange.value) {
        const [intPart, fractionalPart] = (updatedNewChange.value as string).split('.');

        if (fractionalPart) {
            updatedNewChange.value = `${intPart}.${fractionalPart.slice(0, 2)}`;
        }
    }

    if (
        column.customCellType == CustomCellType.Input &&
        column.valueType == CellValueType.String &&
        lodash.get(column, 'metaData.maxLength') !== undefined &&
        updatedNewChange.value
    ) {
        updatedNewChange.value = (updatedNewChange.value as string).slice(0, column.metaData.maxLength);
    }

    return updatedNewChange;
}

function applyChangesToChangeList(changeList: ChangeList, changes: UnsavedChange[]): ChangeList {
    changes.forEach((change) => {
        const lineId = change.budgetItemId;

        const lineChanges = changeList[lineId] || [];

        const oldChange = lineChanges.find((item) => item.columnName == change.columnName);

        const newLineChanges = lodash.without(lineChanges, oldChange);

        if (change.value !== change.originalValue) {
            newLineChanges.push(change);
        }

        changeList[lineId] = newLineChanges;
    });

    return changeList;
}

function removeChangesFromChangeList(changeList: ChangeList, changes: UnsavedChange[]): ChangeList {
    changes.forEach((item) => {
        changeList[item.budgetItemId] = lodash.without(changeList[item.budgetItemId], item);
    });

    return changeList;
}

export const budgetPlanningPageStateReducer = reducerWithInitialState(Reducer.makeInitialState())
    .case(actions.loadPageData, Reducer.loadPageData)
    .case(actions.initUnsavedChanges, Reducer.initUnsavedChanges)
    .case(actions.resetPageStore, Reducer.makeInitialState)
    .case(actions.setColumnsVisiblityFilter, Reducer.setColumnsVisiblityFilter)
    .case(actions.setSortingMode, Reducer.setSortingMode)
    .case(actions.setFilters, Reducer.setFilters)
    .case(actions.resetFilter, Reducer.resetFilter)
    .case(actions.resetFilters, Reducer.resetFilters)
    .case(actions.resetViewSettings, Reducer.resetViewSettings)
    .case(actions.setColumnsWidth, Reducer.setColumnsWidth)
    .case(actions.setResizingColumnName, Reducer.setResizingColumnName)
    .case(actions.setDisplayOnlyUnapproved, Reducer.setDisplayOnlyUnapproved)
    .case(actions.setDisplayOnlyWithSnapshots, Reducer.setDisplayOnlyWithSnapshots)
    .case(actions.setDisplayDisabledLines, Reducer.setDisplayDisabledLines)
    .case(actions.setValidationStatus, Reducer.setValidationStatus)
    .case(actions.toggleColumnFix, Reducer.toggleColumnFix)
    .case(actions.setFixedColumnsNames, Reducer.setFixedColumnsNames)
    .case(asyncActions.changeCellValue.done, Reducer.changeCellValueDone)
    .case(actions.undoUnsavedChanges, Reducer.undoUnsavedChanges)
    .case(actions.redoUnsavedChanges, Reducer.redoUnsavedChanges)
    .case(actions.setLineStatus, Reducer.setLineStatus)
    .case(actions.deleteLineStatus, Reducer.deleteLineStatus)
    .case(actions.setRejectMenuLineId, Reducer.setRejectMenuLineId)
    .case(actions.setApproverMenuLineId, Reducer.setApproverMenuLineId)
    .case(actions.setApproverStatus, Reducer.setApproverStatus)
    .case(actions.clearLineStatuses, Reducer.clearLineStatuses)
    .case(actions.clearApproverStatuses, Reducer.clearApproverStatuses)
    .case(actions.clearUnsavedChanges, Reducer.clearUnsavedChanges)
    .case(actions.clearUnsavedChangesByLines, Reducer.clearUnsavedChangesByLines)
    .case(actions.setErrorPopupStatus, Reducer.setErrorPopupStatus)
    .case(actions.setPreloaderStatus, Reducer.setPreloaderStatus)
    .case(actions.resetChangesHistory, Reducer.resetChangesHistory)
    .case(actions.setBudgetItemsToDisplay, Reducer.setBudgetItemsToDisplay)
    .case(actions.loadFilters, Reducer.loadFilters)
    .case(actions.incLoadingFiltersCount, Reducer.incLoadingFiltersCount)
    .case(actions.decLoadingFiltersCount, Reducer.decLoadingFiltersCount)
    .case(actions.setFiltersLoadingStatus, Reducer.setFiltersLoadingStatus)
    .case(actions.setFiltersPreloaderStatus, Reducer.setFiltersPreloaderStatus)
    .case(actions.updateInProgressData, Reducer.updateInProgressData)
    .case(actions.loadBudgetItems, Reducer.loadBudgetItems)
    .case(actions.initPreviouslyLoadedFilter, Reducer.initPreviouslyLoadedFilters)
    .case(actions.setPreviouslyLoadedFilter, Reducer.setPreviouslyLoadedFilter)
    .case(actions.setPreviouslyLoadedFilters, Reducer.setPreviouslyLoadedFilters)
    .case(actions.resetPreviouslyLoadedFilters, Reducer.resetPreviouslyLoadedFilters)
    .case(actions.resetBudgetRelatedData, Reducer.resetBudgetRelatedData)
    .case(actions.setRejectionCommentPopupVisibility, Reducer.setRejectionCommentPopupVisibility)
    .case(actions.setAppliedFiltersNames, Reducer.setAppliedFiltersNames)
    .case(actions.setBudgetItemsApproversToDisplay, Reducer.setBudgetItemsApproversToDisplay)
    .case(actions.setXLSXImportErrorState, Reducer.setXLSXImportErrorState)
    .case(actions.setShowTagsHaveChangedMarker, Reducer.setShowTagsHaveChangedMarker)
    .case(actions.setShowOnlyLinesWithPlanBudget, Reducer.setShowOnlyLinesWithPlanBudget)
    .case(actions.setShowOnlyLinesWithoutPlanBudget, Reducer.setShowOnlyLinesWithoutPlanBudget)
    .case(actions.setMultiReferenceDictionaryApi, Reducer.setMultiReferenceDictionaryApi);

export const budgetPlanningPageReducer = combineReducers<BudgetPlanningPageState>({
    lineModal: lineModalReducer,
    miscBudgetItems: miscBudgetItemsReducer,
    restState: budgetPlanningPageStateReducer,
});
