import { Dictionary } from 'lodash';
import { PlainDictionary, DictionaryType, DictionaryStatus } from '@mrm/dictionary';

import { LoadingStatus } from '@store/commonTypes';

export interface ActivityTypesState {
    entities: PlainDictionary[];
    byIds: Dictionary<PlainDictionary>;
    stores: Record<StoreTypes, EntitiesStore>;
}

export interface EntitiesStore {
    fetchersCount: number;
    loadingStatus: LoadingStatus;
    ids: string[];
}

export const enum StoreTypes {
    GENERAL = 'GENERAL',
    CURRENT_ORGANIZATION = 'CURRENT_ORGANIZATION',
    AVAILABLE_ACTIVITIES_FILTER = 'AVAILABLE_ACTIVITIES_PAGE_FILTER',
    MY_ACTIVITIES_FILTER = 'MY_ACTIVITIES_PAGE_FILTER',
    NEWS_FILTER = 'NEWS_FILTER',
}

export interface LoadActivityTypesParams {
    store: StoreTypes;
    ids?: string[];
}

export interface SetStoreIdsParams {
    store: StoreTypes;
    ids: string[];
}

export interface SetLoadingStatusParams {
    store: StoreTypes;
    status: LoadingStatus;
}

export type LoadEntitiesPayload = PlainDictionary[];

export const ActivityTypeStub: PlainDictionary = {
    id: null,
    type: DictionaryType.ActivityType,
    code: '',
    status: DictionaryStatus.ACTIVE,
    updatedAt: `${new Date()}`,
    organizationId: null,
    value: 'Удаленный тип проекта',
};
