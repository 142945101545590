import { actionCreatorFactory } from 'typescript-fsa';

import { PageState, FormData } from './types';

const actionCreator = actionCreatorFactory('EXECUTION_BUDGET_EDIT_PAGE');

const loadEditBudgetPage = actionCreator<PageState>('LOAD_EDIT_BUDGET_PAGE');
const resetEditBudgetPage = actionCreator('RESET_EDIT_BUDGET_PAGE');
const setBudgetCardCollapseStatus = actionCreator<{ id: string; status: boolean }>('SET_BUDGET_CARD_COLLAPSE_STATUS1');
const addBudgetForm = actionCreator<FormData>('ADD_BUDGET_FORM');
const setBudgetForms = actionCreator<FormData[]>('SET_BUDGET_FORMS');
const removeBudgetForm = actionCreator<string>('REMOVE_BUDGET_FORM');
const updateActivityForm = actionCreator<FormData>('UPDATE_ACTIVITY_FORM');
const updateBudgetForm = actionCreator<FormData>('UPDATE_BUDGET_FORM');
const updateActivityFormValidation = actionCreator('UPDATE_ACTIVITY_FORM_VALIDATION');
const updateBudgetFormsValidation = actionCreator('UPDATE_BUDGET_FORMS_VALIDATION');
const setNameInputFocus = actionCreator<boolean>('SET_NAME_INPUT_FOCUS');
const setActivityInputFocus = actionCreator<{ budgetItemId: string; isFocused: boolean }>('SET_ACTIVITY_INPUT_FOCUS');
const setBudgetItemTransferDestination = actionCreator<{ budgetItemId: string; activityBudgetId: string }>(
    'SET_BUDGET_ITEM_TRANSFER_DESTINATION',
);

export {
    loadEditBudgetPage,
    resetEditBudgetPage,
    setBudgetCardCollapseStatus,
    addBudgetForm,
    setBudgetForms,
    removeBudgetForm,
    updateActivityForm,
    updateBudgetForm,
    updateActivityFormValidation,
    updateBudgetFormsValidation,
    setNameInputFocus,
    setActivityInputFocus,
    setBudgetItemTransferDestination,
};
