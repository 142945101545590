import * as React from 'react';
import { Link } from 'react-router-dom';
import { ProjectProps } from '@mrm/search';
import { Skeleton } from 'sber-marketing-ui';

import styles from '../CommonStyles.scss';

interface Props extends ProjectProps {
    className?: string;
}

export function Activity(props: Props): JSX.Element {
    const { id, name, className } = props;
    const isReady = id !== undefined;

    return (
        <Skeleton isReady={isReady} loadingClassName={styles.activityLoading} className={className}>
            <ActivityContent id={id} name={name} />
        </Skeleton>
    );
}

type ActivityContentProps = Pick<Props, 'id' | 'name'>;

function ActivityContent({ id, name }: ActivityContentProps): JSX.Element {
    const activityName = name || 'Не указан';

    return (
        <div
            title={activityName}
            className={styles.text}
            {...{
                'qa-id': 'searchCardActivity',
                'qa-value': activityName,
            }}
        >
            Проект:&nbsp;
            <Link to={`/activity/${id}`} target="_blank" className={styles.link}>
                {activityName}
            </Link>
        </div>
    );
}
