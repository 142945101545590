import { axios, AxiosResponse } from '../lib/axios';
import { UserResponseParams } from 'sber-marketing-types/frontend';
import { flatMap } from 'lodash';
import { PaginationParams, PaginationResponse, Comment } from '@store/budgetExecution/lineModal';

import {
    BudgetItem,
    BudgetItemListQueryParams,
    BudgetItemSnapshot,
    BudgetItemSnapshotQueryParams,
    CreateBudgetItemForm,
    UpdateBudgetItemForm,
    ChangeBudgetItemStatusForm,
    TransferPlanFundsForm,
    DeleteBudgetItemForm,
    GetBudgetItemFiltersParams,
    ImportBudgetItemFromExcelParams,
    GetBudgetItemFundsResult,
} from '@mrm/budget';

const BASE_URL = '/api/budgetItem';

export class BudgetItemApi {
    public static async getBudgetItemFilters(params: GetBudgetItemFiltersParams): Promise<string[]> {
        const res: AxiosResponse<any[]> = await axios.post(`${BASE_URL}/filter`, params);
        let result = res.data;

        if (params.column.startsWith('dictionary.') && !params.column.endsWith('.code')) {
            result = result.map((r) => (r ? JSON.parse(r).id : null));
        } else if (params.column === 'responsibles') {
            result = flatMap(result, (r) => (r ? flatMap(r, (user: UserResponseParams) => user.id) : null));
        } else if (params.column === 'author') {
            result = result.map((r) => r.id);
        }

        return result;
    }

    public static async getBudgetItem(budgetItemId: string, ignoreErrorDispatch?: boolean): Promise<BudgetItem> {
        const res: AxiosResponse<BudgetItem> = await axios.post(`${BASE_URL}/single/${budgetItemId}`, {
            ignoreErrorDispatch,
        });

        return res.data;
    }

    public static async getBudgetItemList(params: BudgetItemListQueryParams): Promise<BudgetItem[]> {
        const res: AxiosResponse<BudgetItem[]> = await axios.post(`${BASE_URL}/list`, params);

        return res.data;
    }

    public static async getSerialNumber(budgetItemId: string): Promise<number> {
        const res: AxiosResponse<number> = await axios.get(`${BASE_URL}/${budgetItemId}/serialNumber`);

        return res.data;
    }

    public static async getFunds(budgetItemId: string): Promise<GetBudgetItemFundsResult> {
        const res: AxiosResponse<GetBudgetItemFundsResult> = await axios.get(`${BASE_URL}/${budgetItemId}/funds`);

        return res.data;
    }

    public static async getBlock(budgetItemId: string): Promise<string> {
        const res: AxiosResponse<string> = await axios.get(`${BASE_URL}/${budgetItemId}/block`);

        return res.data;
    }

    public static async getBudgetItemSnapshots(
        budgetItemId: string,
        params: BudgetItemSnapshotQueryParams,
    ): Promise<BudgetItemSnapshot[]> {
        const res: AxiosResponse<BudgetItemSnapshot[]> = await axios.get(`${BASE_URL}/${budgetItemId}/snapshots`, {
            params,
        });

        return res.data;
    }

    public static async createBudgetItem(params: Partial<CreateBudgetItemForm>): Promise<void> {
        await axios.post(BASE_URL, params);
    }

    public static async updateBudgetItem(params: UpdateBudgetItemForm): Promise<string[]> {
        const res = await axios.put(BASE_URL, params);

        return res.data;
    }

    public static async deleteBudgetItem(params: DeleteBudgetItemForm): Promise<void> {
        await axios.delete(BASE_URL, { data: params });
    }

    public static async changeBudgetItemStatus(params: ChangeBudgetItemStatusForm): Promise<void> {
        await axios.post(`${BASE_URL}/change-status`, params);
    }

    public static async transferPlanFunds(params: TransferPlanFundsForm): Promise<string> {
        const res = await axios.post(`${BASE_URL}/transfer-plan-funds`, params);

        return res.data;
    }

    public static async getComments(id: string, params: PaginationParams): Promise<PaginationResponse<Comment>> {
        const res = await axios.post<PaginationResponse<Comment>>(`${BASE_URL}/${id}/comments`, params);

        return res.data;
    }

    public static async sendComment(id: string, comment: string): Promise<void> {
        await axios.put<void>(`${BASE_URL}/${id}/comment`, { comment });
    }

    public static async importBudgetItemsFromExcel(params: ImportBudgetItemFromExcelParams): Promise<void> {
        await axios.post<void>(`${BASE_URL}/import-from-excel`, params);
    }
}

window.BudgetItemApi = BudgetItemApi;
