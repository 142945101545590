import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as lodash from 'lodash';

import type { ActivityParams as Activity } from 'sber-marketing-types/frontend';
import type {
    AutopilotDOOH,
    AutopilotDOOHRegionality,
    AutopilotDOOHFormatWithTimings,
    AutopilotDOOHMediaplanCalculation,
} from 'sber-marketing-types/backend';
import type { PageState, BriefStageForm, GroupedDictionaries } from './types';
import * as actions from './actions';

export const initialState: PageState = {
    autopilot: null,
    activity: null,
    mediaplan: [],
    regionalities: [],
    formats: null,
    dictionaries: {},
    briefStageForm: {
        blockId: null,
        productId: null,
        budget: null,
        budgetError: null,
        dateStart: null,
        dateEnd: null,
        regionalityId: null,
        selectedRowsIds: [],
        selectedFormatTimings: {},
        budgetAutomation: true,
    },
    manualMediaplanSideQuantityValues: {},
    mediaplanLinesBudgetSnapShot: null,
    mediaplanLinesSideQuantitySnapShot: null,
    selectedBudgetItemIds: [],
    preloader: false,
    sidebar: {
        opened: true,
    },
};

export class Reducer {
    public static loadAutopilot(state: PageState, payload: AutopilotDOOH): PageState {
        const { brief, budget } = payload;

        const briefStageForm: BriefStageForm = brief
            ? {
                  blockId: brief.block,
                  productId: brief.product,
                  budget: brief.budget ? brief.budget.toString() : null,
                  budgetError: null,
                  dateStart: brief.dateStart,
                  dateEnd: brief.dateEnd,
                  regionalityId: brief.regionalityId || null,
                  selectedRowsIds: brief.selectedRows.map((item) => item.rowId) || [],
                  selectedFormatTimings: !lodash.isEmpty(state.briefStageForm.selectedFormatTimings)
                      ? state.briefStageForm.selectedFormatTimings
                      : {},
                  budgetAutomation: brief.budgetAutomation,
              }
            : lodash.cloneDeep(initialState.briefStageForm);

        const selectedBudgetItemIds = budget ? budget.budgetIds : [];

        return {
            ...state,
            autopilot: payload,
            briefStageForm,
            selectedBudgetItemIds,
        };
    }

    public static loadActivity(state: PageState, payload: Activity): PageState {
        return { ...state, activity: payload };
    }

    public static loadMediaplan(state: PageState, payload: AutopilotDOOHMediaplanCalculation[]): PageState {
        return { ...state, mediaplan: payload };
    }

    public static loadRegionalities(state: PageState, payload: AutopilotDOOHRegionality[]): PageState {
        return { ...state, regionalities: payload };
    }

    public static loadFormats(state: PageState, payload: AutopilotDOOHFormatWithTimings[]): PageState {
        return { ...state, formats: payload };
    }

    public static loadDictionaries(state: PageState, payload: GroupedDictionaries): PageState {
        return { ...state, dictionaries: payload };
    }

    public static resetPageState(): PageState {
        return lodash.cloneDeep(initialState);
    }

    public static setBriefFormValues(state: PageState, payload: Partial<BriefStageForm>): PageState {
        return { ...state, briefStageForm: { ...state.briefStageForm, ...payload } };
    }

    public static setManualMediaplanSideQuantityValues(state: PageState, payload: Record<string, number>): PageState {
        return { ...state, manualMediaplanSideQuantityValues: payload };
    }

    public static setMediaplanLinesBudgetSnapShot(state: PageState, payload: Record<string, number>): PageState {
        return { ...state, mediaplanLinesBudgetSnapShot: payload };
    }

    public static setMediaplanLinesSideQuantitySnapShot(state: PageState, payload: Record<string, number>): PageState {
        return { ...state, mediaplanLinesSideQuantitySnapShot: payload };
    }

    public static setSelectedBudgetItemIds(state: PageState, payload: string[]): PageState {
        return { ...state, selectedBudgetItemIds: payload };
    }

    public static setPreloaderStatus(state: PageState, payload: boolean): PageState {
        return { ...state, preloader: payload };
    }

    public static openSidebar(state: PageState): PageState {
        return { ...state, sidebar: { ...state.sidebar, opened: true } };
    }

    public static closeSidebar(state: PageState): PageState {
        return { ...state, sidebar: { ...state.sidebar, opened: false } };
    }
}

export const autopilotDOOHReducer = reducerWithInitialState(initialState)
    .case(actions.loadAutopilot, Reducer.loadAutopilot)
    .case(actions.loadActivity, Reducer.loadActivity)
    .case(actions.loadMediaplan, Reducer.loadMediaplan)
    .case(actions.loadRegionalities, Reducer.loadRegionalities)
    .case(actions.loadFormats, Reducer.loadFormats)
    .case(actions.loadDictionaries, Reducer.loadDictionaries)
    .case(actions.resetPageState, Reducer.resetPageState)
    .case(actions.setBriefFormValues, Reducer.setBriefFormValues)
    .case(actions.setManualMediaplanSideQuantityValues, Reducer.setManualMediaplanSideQuantityValues)
    .case(actions.setMediaplanLinesBudgetSnapShot, Reducer.setMediaplanLinesBudgetSnapShot)
    .case(actions.setMediaplanLinesSideQuantitySnapShot, Reducer.setMediaplanLinesSideQuantitySnapShot)
    .case(actions.setSelectedBudgetItemIds, Reducer.setSelectedBudgetItemIds)
    .case(actions.setPreloaderStatus, Reducer.setPreloaderStatus)
    .case(actions.openSidebar, Reducer.openSidebar)
    .case(actions.closeSidebar, Reducer.closeSidebar);
