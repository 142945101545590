import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { DictionaryType } from '@mrm/dictionary';

import { DictionaryApi, ActivityApi } from '@api';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { getLoginUser } from '@store/user';

import * as actions from './actions';
import { LoadActivityTypesParams } from './types';
import { getLoadingStatus, getActivityTypeById, getFetchersCount } from './selectors';

export const loadActivityTypes = bindThunkAction<StoreState, LoadActivityTypesParams, void, Error>(
    actions.loadActivityTypesAsync,
    async (params, dispatch, getState) => {
        const { store } = params;
        let { ids } = params;

        const setStatus: (status: LoadingStatus) => void = (status) =>
            dispatch(
                actions.setLoadingStatus({
                    store,
                    status,
                }),
            );
        const state = getState();
        const loadingStatus = getLoadingStatus(state, store);
        const loginnedUser = getLoginUser(state);

        try {
            let activityTypes;

            if (ids) {
                const notLoadedActivityTypeIds = ids.filter((id) => !getActivityTypeById(state, id));

                if (notLoadedActivityTypeIds.length) {
                    dispatch(actions.incFetchersCount(store));
                    setStatus(LoadingStatus.LOADING);
                    activityTypes = (
                        await DictionaryApi.getDictionaryList({
                            organizationId: loginnedUser.attributes.organizationId,
                            types: [DictionaryType.ActivityType],
                        })
                    ).filter((activityType) => notLoadedActivityTypeIds.includes(activityType.id));
                }
            } else {
                if (loadingStatus !== LoadingStatus.LOADED) {
                    dispatch(actions.incFetchersCount(store));
                    setStatus(LoadingStatus.LOADING);
                    activityTypes = await DictionaryApi.getDictionaryList({
                        organizationId: loginnedUser.attributes.organizationId,
                        types: [DictionaryType.ActivityType],
                    });

                    ids = activityTypes.map((activityType) => activityType.id);
                }
            }

            if (activityTypes) {
                dispatch(actions.decFetchersCount(store));
                dispatch(actions.loadEntities(activityTypes));
            }
            if (ids) {
                dispatch(
                    actions.setStoreIds({
                        store,
                        ids,
                    }),
                );
            }

            const fetchersCount = getFetchersCount(getState(), store);
            if (!fetchersCount) {
                setStatus(LoadingStatus.LOADED);
            }
        } catch (e) {
            setStatus(LoadingStatus.ERROR);
        }
    },
);
