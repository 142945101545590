import type { Field } from '@store/autopilot/creativeForm';
import type { TypeItemProps, TypePlacementFieldEnum } from 'sber-marketing-types/backend';

import { buildValue } from '../buildValue';

interface Builder {
    (params: Params): Promise<TypeItemProps[]>;
}

interface Params {
    fields: Field[];
    autopilotId: string;
}

export const buildProps: Builder = async ({ fields, autopilotId }) => {
    return await Promise.all(
        fields.map(async (field) => ({
            fieldId: field.meta.fieldId,
            value: await buildValue({
                fieldId: field.meta.fieldId,
                autopilotId,
                fieldType: field.type as any as TypePlacementFieldEnum,
                fieldValue: field.value,
            }),
            fieldType: field.type as any as TypePlacementFieldEnum,
        })),
    );
};
