import { axios, AxiosResponse } from '../lib/axios';

import {
    DictionaryListQuery,
    PlainDictionary,
    GetDictionaryChildrenParams,
    DictionaryData,
    DictionaryForBudgetQuery,
} from '@mrm/dictionary';

import { DictionariesByIdsQuery } from 'sber-marketing-types/budget';

const BASE_URL = '/api/dictionary';

export class DictionaryApi {
    public static async getDictionaryList(params: DictionaryListQuery): Promise<PlainDictionary[]> {
        const res: AxiosResponse<PlainDictionary[]> = await axios.get(BASE_URL, { params });

        return res.data;
    }

    public static async getDictionariesByIds(params?: DictionariesByIdsQuery): Promise<PlainDictionary[]> {
        const res: AxiosResponse<PlainDictionary[]> = await axios.post(`${BASE_URL}/list`, params);

        return res.data;
    }

    public static async getDictionariesForBudget(params: DictionaryForBudgetQuery): Promise<PlainDictionary[]> {
        const response = await axios.get<PlainDictionary[]>(`${BASE_URL}/budget`, {
            params: { ...params },
        });

        return response.data;
    }

    public static async getDictionaryChildren(params: GetDictionaryChildrenParams): Promise<PlainDictionary[]> {
        const res: AxiosResponse<PlainDictionary[]> = await axios.post(`${BASE_URL}/children`, params);

        return res.data;
    }

    public static async getDictionaryData(id: string): Promise<DictionaryData> {
        const res: AxiosResponse<DictionaryData> = await axios.get(`${BASE_URL}/${id}/data`);

        return res.data;
    }

    public static async getDataList(ids: string[]): Promise<{ dictionaryId: string; data: any; schema: any }[]> {
        const res = await axios.post(`${BASE_URL}/data/list`, { ids });

        return res.data;
    }
}
