import { Moment } from 'moment';
import { OneTaskResponseParams } from 'sber-marketing-types/backend';
import { UserVacation } from '@mrm/user-vacation';
import { Budget } from '@mrm/budget';

import { LoadingStatus } from '@store/commonTypes';

import { ActivityStageParams, FileParams, ParticipantParams } from './params';

export interface TaskEditorState {
    common: TaskEditorCommon;
    data: TaskEditorData;
    values: TaskEditorValues;
    permissions: Permissions;
    misc: TaskEditorMisc;
}

export interface TaskEditorCommon {
    taskId: string;
    tagsEditorId: string;
    activityId: number;
    taskIsCreated: boolean;
    taskRequestInProgress: boolean;
    taskRequestHasFinished: boolean;
}

export interface TaskEditorData {
    rawTask: OneTaskResponseParams;
    userWorkType: UserWorkTypeData;
    activityStages: ActivityStagesData;
}

export interface UserWorkTypeData {
    loadingStatus: LoadingStatus;
    usersWithWorkTypes: UserWithWorkTypes[];
    workTypesWithUsers: WorkTypeWithUsers[];
}

export interface ActivityStagesData {
    loadingStatus: LoadingStatus;
    entities: ActivityStageParams[];
}

export interface UserWithWorkTypes {
    id: number;
    title: string;
    departmentId: string;
    subTitle: string;
    vacation: UserVacation;
    subItems: {
        id: string;
        title: string;
    }[];
}

export interface WorkTypeWithUsers {
    id: string;
    title: string;
    subTitle?: string;
    subItems?: {
        id: number;
        title: string;
        onVacation: boolean;
        vacation: UserVacation;
    }[];
}

export interface TaskEditorValues {
    loadingStatus: LoadingStatus;
    title: string;
    description: string;
    workType: string;
    executor: number;
    deadline: Moment;
    files: TaskFiles;
    uploadedFiles: string[];
    uploadingFiles: string[];
    participants: {
        existing: number[];
        editing: ParticipantParams[];
    };
    stageId: string;
    budgetApproval?: TaskEditorBudgetApprovalValues;
}

export interface TaskEditorBudgetApprovalValues {
    clientDivisionId?: string;
    clientName?: string;
    budgetItemIds?: string[];
    productId?: string;
    segmentId?: string;
    reason?: string;
    period?: string;
    hasLot?: boolean;
    mediaRequest?: string;
    hasTitle: boolean;
    naming?: string;
    project?: string;
}

export interface TaskFiles {
    byId: {
        [id: string]: FileParams;
    };
    entities: FileParams[];
}

export interface Permissions {
    canChangeExecutorDeadlineAndFiles?: boolean;
    canChangeRest?: boolean;
}

export enum ComponentMode {
    enterRequiredFieldsForExistingTask = 'enterRequiredFieldsForExistingTask',
}

export interface TaskEditorMisc {
    currentExecutionBudget: Budget;
}

export const MAX_BUDGET_ITEMS_COUNT = 10;
export const NO_BUDGET_ITEM_MARKER = 'NO_BUDGET_ITEM_MARKER'; // cannot use false-like value, because ReactSelect filters it out
