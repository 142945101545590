import * as moment from 'moment';
import { axios } from '../../lib/axios';
import { v4 as uuid } from 'uuid';
import { isPlainObject, difference, isEmpty } from 'lodash';
import * as JSZip from 'jszip';

import type {
    FileResponse,
    FileUploadParams,
    FileDownloadLinkParams,
    CreativeFile,
} from 'sber-marketing-types/backend';
import { FileStorage } from 'sber-marketing-types/backend';
import { FileAssetTarget } from '@store/commonTypes';
import type { FileAsset } from '@store/commonTypes';
import { download, Utils } from '@common/Utils';

import { FileApiUploadParams } from './types';
import { FileService } from './FileService';
import { SelcdnService } from './SelcdnService';
import { CephService } from './CephService';
import { ImageService } from './ImageService';

const FILE_ASSET_NECESSARY_FIELDS = ['name', 'originName', 'createdAt', 'type', 'targetType', 'targetId'];

export class FileApi {
    public static isFileAsset(value: any): value is FileAsset {
        return isPlainObject(value) && isEmpty(difference(FILE_ASSET_NECESSARY_FIELDS, Object.keys(value)));
    }

    public static async uploadFiles(
        params: FileApiUploadParams,
        data: File[] | FileAsset[],
        uploadedBy?: string,
    ): Promise<FileAsset[]> {
        const result: FileAsset[] = [];
        for (const file of data) {
            const asset = await FileApi.uploadFile(params, file, uploadedBy);
            result.push(asset);
        }
        return result;
    }

    public static async *uploadFileProcess(
        params: FileApiUploadParams,
        data: File | FileAsset,
        uploadedBy?: string,
        skipCreatingFileRecord?: boolean,
    ): AsyncIterableIterator<FileAsset> {
        const fileAsset: FileAsset = FileApi.isFileAsset(data) ? data : this.makeFileAsset(params, data, uploadedBy);
        fileAsset.isLoading = true;
        fileAsset.loadingProgress = 0;
        yield fileAsset;

        const settings = await this.getUploadSettings(params, fileAsset.name, fileAsset.type);
        fileAsset.storage = settings.storageEngine;
        yield fileAsset;

        const fileService = this.getFileService(settings.storageEngine);
        yield* await fileService.uploadProcess(params, fileAsset, settings);

        delete fileAsset.file; // it is needed for differ assets with uploaded status (e.g. in saveCommentary thunk)
        yield fileAsset;

        if (!skipCreatingFileRecord) {
            await this.createFileRecord(params, fileAsset, settings);
        }

        fileAsset.isLoading = false;
        yield fileAsset;
    }

    public static async uploadFile(
        params: FileApiUploadParams,
        data: File | FileAsset,
        uploadedBy?: string,
        skipCreatingFileRecord?: boolean,
    ): Promise<FileAsset> {
        const fileAsset: FileAsset = FileApi.isFileAsset(data) ? data : this.makeFileAsset(params, data, uploadedBy);

        const settings = await this.getUploadSettings(params, fileAsset.name, fileAsset.type);
        fileAsset.storage = settings.storageEngine;

        const fileService = this.getFileService(settings.storageEngine);
        await fileService.upload(params, fileAsset, settings);

        delete fileAsset.file; // it is needed for differ assets with uploaded status (e.g. in saveCommentary thunk)

        if (!skipCreatingFileRecord) {
            await this.createFileRecord(params, fileAsset, settings);
        }
        return fileAsset;
    }

    public static async removeFile(params: FileApiUploadParams, fileName: string): Promise<void> {
        const assetTarget = this.getAssetTarget(params);
        const baseUrl = this.getUrlByAssetTarget(assetTarget, params);
        const queryParams = this.getQueryParamsByAssetTarget(assetTarget, params);
        const url = `${baseUrl}/${fileName}`;

        await axios.delete(url, { params: queryParams });
    }

    public static makeFileAsset(params: FileApiUploadParams, file: File, uploadedBy?: string): FileAsset {
        const fileName = uuid();
        const dotIndex = file.name.lastIndexOf('.');
        const type = file.name.slice(dotIndex + 1);
        if (!Object.keys(params).length) {
            params.fileId = fileName;
        }

        const fileAsset: FileAsset = {
            id: fileName,
            name: fileName,
            originName: type === file.name ? file.name : file.name.slice(0, dotIndex),
            size: file.size,
            type: type === file.name ? '' : type,
            targetType: this.getAssetTarget(params),
            targetId: this.getTargetId(params),
            parentTargetId: this.getParentTargetId(params),
            uploadedBy,
            file,
            isLoading: false,
            loadingProgress: 0,
            createdAt: Date.now(),
        };

        if (type === 'pdf' || Utils.isVideo(type) || Utils.isAudio(type)) {
            fileAsset.fullSizeUrl = this.getFilePermalink(fileAsset);
        }

        return fileAsset;
    }

    public static mapFiles(params: FileApiUploadParams, files: FileResponse[], uploadedBy: string): FileAsset[] {
        return files.map((file) => FileApi.mapFile(params, file, uploadedBy));
    }

    public static mapFile(params: FileApiUploadParams, file: FileResponse, uploadedBy?: string): FileAsset {
        const result = {
            ...file,
            uploadedBy,
            createdAt: moment(file.createTime).unix() * 1000,
            targetType: this.getAssetTarget(params),
            targetId: this.getTargetId(params),
            parentTargetId: this.getParentTargetId(params),
            fullSizeUrl: null as string,
            previewUrl: null as string,
        };

        if (result.storage === FileStorage.IMAGE_SERVICE) {
            const { original, preview } = ImageService.getUrlsByIdAndType(result.name, result.type);
            result.fullSizeUrl = original;
            result.previewUrl = preview;
        } else if (result.type === 'pdf' || Utils.isVideo(result.type) || Utils.isAudio(result.type)) {
            result.fullSizeUrl = this.getFilePermalink(result);
        }

        return result;
    }

    public static getFilePermalink({ targetType, parentTargetId, targetId, name }: FileAsset): string {
        let result: string;

        switch (targetType) {
            case FileAssetTarget.BRIEF:
                result = `/api/file/activity/${parentTargetId}/field/${targetId}/file/${name}`;
                break;
            case FileAssetTarget.COMMENTARY:
                result = `/api/file/task/${parentTargetId}/comment/${targetId}/file/${name}`;
                break;
            case FileAssetTarget.TASK:
                result = `/api/file/task/${targetId}/file/${name}`;
                break;
            default:
                result = `/api/file/file/${targetId}`;
                break;
        }

        return result;
    }

    public static async downloadFile(params: FileApiUploadParams, fileName: string): Promise<void> {
        const downloadLink = await this.getDownloadLink(params, fileName);

        download(downloadLink, `${params.originName}.${params.type}`);
    }

    public static async downloadMultipleFilesAsZip(
        fileName = 'Архив',
        files: {
            id: string;
            name: string;
            type: string;
            params: FileApiUploadParams;
        }[],
    ): Promise<void> {
        const zipConverter = new JSZip();
        const downloadedFiles: {
            [fileDescriptor: string]: {
                name: string;
                data: Blob;
            }[];
        } = {};

        await Promise.all(
            files.map(async (file) => {
                const link = await this.getDownloadLink(file.params, file.id);
                const data = (
                    await axios.get<ArrayBuffer>(link, {
                        responseType: 'arraybuffer',
                    })
                ).data;

                const fileDescriptor = `${file.name}.${file.type}`;
                if (!downloadedFiles[fileDescriptor]) {
                    downloadedFiles[fileDescriptor] = [];
                }

                const nameOptIndex = downloadedFiles[fileDescriptor].length
                    ? ` (${downloadedFiles[fileDescriptor].length})`
                    : '';
                downloadedFiles[fileDescriptor].push({
                    name: `${file.name}${nameOptIndex}.${file.type}`,
                    data: new Blob([new Uint8Array(data)]),
                });
            }),
        );

        Object.keys(downloadedFiles).forEach((fileDescriptor) =>
            downloadedFiles[fileDescriptor].forEach((file) => zipConverter.file(file.name, file.data)),
        );

        const zipFile = Buffer.from(await zipConverter.generateAsync({ type: 'uint8array' }));
        Utils.downloadAsZip(zipFile, fileName);
    }

    public static async deleteFile(fileId: string): Promise<void> {
        await axios.delete(`/api/file/${fileId}`);
    }

    public static async getUploadSettings(
        params: FileApiUploadParams,
        fileName: string,
        type: string,
    ): Promise<FileUploadParams> {
        const assetTarget = this.getAssetTarget(params);
        const url = this.getUrlByAssetTarget(assetTarget, params);
        const queryParams = this.getQueryParamsByAssetTarget(assetTarget, params);

        const response =
            assetTarget === FileAssetTarget.AUTOPILOT
                ? await axios.post<void, void, CreativeFile>(url, queryParams)
                : await axios.get<FileUploadParams>(url, { params: queryParams });

        const { fragmentSize, containerName, storageEngine } = response.data;

        return {
            fragmentSize,
            containerName,
            fileName,
            storageEngine: ImageService.isImageType(type) ? FileStorage.IMAGE_SERVICE : storageEngine,
        };
    }

    protected static getFileService(fileStorage: FileStorage): FileService {
        switch (fileStorage) {
            case FileStorage.SEL_CDN:
                return new SelcdnService();
            case FileStorage.CEPH:
                return new CephService();
            case FileStorage.IMAGE_SERVICE:
                return new ImageService();
            default:
                return new SelcdnService();
        }
    }

    private static async getDownloadLink(params: FileApiUploadParams, fileName: string): Promise<string> {
        const settings = await this.getDownloadSettings(params, fileName);
        const fileService = this.getFileService(settings.storageEngine);

        return await fileService.getDownloadLink(params, settings);
    }

    public static async getDownloadSettings(
        params: FileApiUploadParams,
        fileName: string,
    ): Promise<FileDownloadLinkParams> {
        const assetTarget = this.getAssetTarget(params);
        const baseUrl = this.getUrlByAssetTarget(assetTarget, params);
        const queryParams = this.getQueryParamsByAssetTarget(assetTarget, params);
        const url = `${baseUrl}/${fileName}`;

        const res = await axios.get<FileDownloadLinkParams>(url, { params: queryParams });

        const { storageEngine, originName, ...rest } = res.data;

        return {
            fileName: params.fileName,
            originName: originName || params.fileName,
            ...rest,
            containerName: params.containerName || rest.containerName,
            storageEngine,
        };
    }

    private static async createFileRecord(
        params: FileApiUploadParams,
        fileAsset: FileAsset,
        settings: FileUploadParams,
    ): Promise<void> {
        const assetTarget = this.getAssetTarget(params);
        const url = this.getUrlByAssetTarget(assetTarget, params);
        const queryParams = this.getQueryParamsByAssetTarget(assetTarget, params);

        const data = {
            ...fileAsset,
            storage: settings.storageEngine,
        };

        await axios.post(url, data, { data, params: queryParams });
    }

    private static getAssetTarget(params: FileApiUploadParams): FileAssetTarget {
        let res: FileAssetTarget;

        if (params.commentId) {
            res = FileAssetTarget.COMMENTARY;
        } else if (params.taskId) {
            res = FileAssetTarget.TASK;
        } else if (params.activityId || params.briefId) {
            res = FileAssetTarget.BRIEF;
        } else {
            res = FileAssetTarget.FILE;
        }

        return res;
    }

    private static getTargetId(params: FileApiUploadParams): string {
        let res: string;

        if (params.commentId) {
            res = params.commentId;
        } else if (params.taskId) {
            res = params.taskId;
        } else if (params.fieldId) {
            res = params.fieldId;
        } else if (params.activityId) {
            res = String(params.activityId);
        } else if (params.fileId) {
            res = params.fileId;
        }

        return res;
    }

    private static getParentTargetId(params: FileApiUploadParams): string | undefined {
        let res: string;

        if (params.commentId && params.taskId) {
            res = params.taskId;
        } else if (params.fieldId && params.activityId) {
            res = String(params.activityId);
        }

        return res;
    }

    private static getQueryParamsByAssetTarget(place: FileAssetTarget, params: FileApiUploadParams): any | undefined {
        const { uniqId, parentUniqId } = params;

        switch (place) {
            case FileAssetTarget.BRIEF:
                return {
                    uniqId,
                    parentUniqId,
                };
            case FileAssetTarget.AUTOPILOT:
                return {};
        }
    }

    private static getUrlByAssetTarget(place: FileAssetTarget, params: FileApiUploadParams): string {
        const { fileId, taskId, commentId, briefId, fieldId, autopilotId } = params;

        switch (place) {
            case FileAssetTarget.TASK:
                return `/api/task/${taskId}/file`;
            case FileAssetTarget.COMMENTARY:
                return `/api/task/${taskId}/comment/${commentId}/file`;
            case FileAssetTarget.BRIEF:
                return `/api/brief/${briefId}/field/${fieldId}/file`;
            case FileAssetTarget.FILE:
                return `/api/file/${fileId}/file`;
            case FileAssetTarget.AUTOPILOT:
                return `/api/autopilot/v2/${autopilotId}/file/${fileId}/file`;
        }
    }
}
