import { actionCreatorFactory } from 'typescript-fsa';
import { BudgetItem, ActivityBudget } from '@mrm/budget';

import { MultiReferenceDictionaryApi } from '@api';

import {
    PageData,
    ColumnName,
    ColumnsVisiblityFilter,
    ColumnsWidth,
    SortingMode,
    Filters,
    ChangeList,
    LineCorrectionTypes,
    CorrectionPopup,
    UpdateLinesDataParams,
    ChangeCellValueParams,
    LoadFiltersPaylaod,
    SetFiltersLoadingStatusPayload,
    ResetFilterPayload,
    AppliedFiltersNames,
    SetPreviouslyLoadedFilterPayload,
    SetPreviouslyLoadedFiltersPayload,
    FilterKey,
    ActivityReferenceMenuVisibility,
    CreativeReferenceMenuVisibility,
    TableLinesCellsParams,
    PageFilters,
    DownloadTableAsXLSXPaylaod,
    TableLine,
    DropdownOptions,
} from './types';

const actionCreator = actionCreatorFactory('BUDGET_EXECUTION_PAGE');

const loadPageData = actionCreator<Partial<PageData>>('LOAD_PAGE_DATA');
const loadBudgetItems = actionCreator<BudgetItem[]>('LOAD_BUDGET_ITEM');
const loadActivityBudgets = actionCreator<ActivityBudget[]>('LOAD_ACTIVITY_BUDGETS');
const updateLinesData = actionCreator<UpdateLinesDataParams>('UPDATE_LINES_DATA');
const initUnsavedChanges = actionCreator<ChangeList>('INIT_UNSAVED_CHANGES');
const resetPageStore = actionCreator('RESET_PAGE_STORE');
const setColumnsVisiblityFilter = actionCreator<ColumnsVisiblityFilter>('SET_COLUMNS_VISIBLITY_FILTER');
const setColumnsWidth = actionCreator<ColumnsWidth>('SET_COLUMNS_WIDTH');
const setResizingColumnName = actionCreator<ColumnName>('SET_RESIZING_COLUMN_NAME');
const setHoveredColumnName = actionCreator<ColumnName>('SET_HOVERED_COLUMN_NAME');
const setSortingMode = actionCreator<SortingMode>('SET_SORTING_MODE');
const setFilters = actionCreator<Filters>('SET_FILTERS');
const resetFilters = actionCreator('RESET_FILTERS');
const resetFilter = actionCreator<ResetFilterPayload>('RESET_FILTER');
const resetViewSettings = actionCreator('RESET_VIEW_SETTINGS');
const setValidationStatus = actionCreator<boolean>('TOGGLE_VALIDATION_STATUS');
const toggleColumnFix = actionCreator<ColumnName>('TOGGLE_COLUMN_FIX');
const setFixedColumnsNames = actionCreator<ColumnName[]>('SET_FIXED_COLUMNS_NAMES');
const setActivityReferenceMenuVisibility = actionCreator<ActivityReferenceMenuVisibility>(
    'SET_ACTIVITY_REFERENCE_MENU_VISIBILITY',
);
const setCreativeReferenceMenuVisibility = actionCreator<CreativeReferenceMenuVisibility>(
    'SET_CREATIVE_REFERENCE_MENU_VISIBILITY',
);
const undoUnsavedChanges = actionCreator('UNDO_UNSAVED_CHANGES');
const redoUnsavedChanges = actionCreator('RETURN_UNSAVED_CHANGES');
const clearUnsavedChanges = actionCreator('CLEAR_UNSAVED_CHANGES');
const clearUnsavedChangesByLines = actionCreator<string[]>('CLEAR_UNSAVED_CHANGES_BY_LINES');
const resetChangesHistory = actionCreator('RESET_CHANGES_HISTORY');
const setShowOnlyLinesWithoutPlan = actionCreator<boolean>('SET_SHOW_ONLY_LINES_WITHOUT_PLAN');
const setShowOnlyLinesWithNegativeBalance = actionCreator<boolean>('SET_SHOW_ONLY_LINES_WITH_NEGATIVE_BALANCE');
const setUseLinesWithoutPlanInSorting = actionCreator<boolean>('SET_USE_LINES_WITHOUT_PLAN_IN_SORTING');
const setShowOnlyLinesWithPlanBudget = actionCreator<boolean>('SET_SHOW_ONLY_LINES_WITH_PLAN_BUDGET');
const setShowOnlyLinesWithNegativePlanFactDiff = actionCreator<boolean>(
    'SET_SHOW_ONLY_LINES_WITH_NEGATIVE_PLAN_FACT_DIFF',
);
const setCorrectionsToDisplay = actionCreator<LineCorrectionTypes>('SET_CORRECTIONS_TO_DISPLAY');
const setCorrectionPopup = actionCreator<CorrectionPopup>('SET_CORRECTION_POPUP');
const setPreloaderStatus = actionCreator<boolean>('SET_PRELOADER_STATUS');
const loadFilters = actionCreator<LoadFiltersPaylaod>('LOAD_FILTERS_PAYLOAD');
const setFiltersState = actionCreator<Filters>('SET_FITLERS_STATE');
const setFiltersLoadingStatus = actionCreator<SetFiltersLoadingStatusPayload>('SET_FILTERS_LOADING_STATUS');
const resetBudgetRelatedData = actionCreator('RESET_BUDGET_RELATED_DATA');
const setAppliedFiltersNames = actionCreator<AppliedFiltersNames>('SET_APPLIED_FILTERS_NAMES');
const initPreviouslyLoadedFilter = actionCreator('INIT_PREVIOUSLY_LOADER_FILTER');
const setPreviouslyLoadedFilter = actionCreator<SetPreviouslyLoadedFilterPayload>('SET_PREVIOUSLY_LOADER_FILTER');
const setPreviouslyLoadedFilters = actionCreator<SetPreviouslyLoadedFiltersPayload>('SET_PREVIOUSLY_LOADED_FILTERS');
const resetPreviouslyLoadedFilters = actionCreator('RESET_PREVIOUSLY_LOADED_FILTERS');
const setHoveredLineId = actionCreator<string>('SET_HOVERED_LINE_ID');
const setShowTagsHaveChangedMarker = actionCreator<boolean>('SET_SHOW_TAGS_HAVE_CHANGED_MARKER');
const setMultiReferenceDictionaryApi = actionCreator<MultiReferenceDictionaryApi>('SET_MULTI_REFERENCE_DICITONARY_API');
const setTableLinesCellsParams = actionCreator<TableLinesCellsParams>('SET_TABLE_LINES_CELLS_PARAMS');
const setPageBudgetItems = actionCreator<BudgetItem[]>('SET_PAGE_BUDGET_ITEMS');
const setPageFilters = actionCreator<Partial<PageFilters>>('SET_PAGE_FILTERS');
const setLineIdsWithActualChanges = actionCreator<string[]>('SET_LINE_IDS_WITH_ACTULA_CHANGES');
const resetLoadedFilters = actionCreator('RESET_LOADED_FILTERS');
const setTableLines = actionCreator<TableLine[]>('SET_TABLE_LINES');
const setDropdownOptions = actionCreator<DropdownOptions>('SET_DROPDOWN_OPTIONS');
const setPreventUserConfigUpdate = actionCreator<boolean>('SET_PREVENT_USER_CONFIG_UPDATE');

const changeCellValueAsync = actionCreator.async<ChangeCellValueParams, ChangeCellValueParams, Error>(
    'CHANGE_CELL_VALUE_ASYNC',
);
const loadFiltersByColumnNameAsync = actionCreator.async<FilterKey, void, Error>('LOAD_FILTERS_BY_COLUMN_NAME_ASYNC');
const onLineClickAsync = actionCreator.async<string, void, Error>('ON_LINE_CLICK');
const downloadTableAsXLSXAsync = actionCreator.async<DownloadTableAsXLSXPaylaod, void, Error>(
    'DOWNLOAD_TABLE_AS_XLSX_ASYNC',
);
const updateDropdownOptionsAsync = actionCreator.async<DropdownOptions, void, Error>('UPDATE_DROPDOWN_OPTIONS_ASYNC');

export {
    loadPageData,
    loadBudgetItems,
    loadActivityBudgets,
    updateLinesData,
    initUnsavedChanges,
    resetPageStore,
    setColumnsVisiblityFilter,
    setColumnsWidth,
    setResizingColumnName,
    setHoveredColumnName,
    setSortingMode,
    setFilters,
    resetFilters,
    resetFilter,
    resetViewSettings,
    setValidationStatus,
    toggleColumnFix,
    setFixedColumnsNames,
    setActivityReferenceMenuVisibility,
    setCreativeReferenceMenuVisibility,
    undoUnsavedChanges,
    redoUnsavedChanges,
    clearUnsavedChanges,
    clearUnsavedChangesByLines,
    setShowOnlyLinesWithoutPlan,
    setShowOnlyLinesWithNegativeBalance,
    setUseLinesWithoutPlanInSorting,
    setShowOnlyLinesWithPlanBudget,
    setShowOnlyLinesWithNegativePlanFactDiff,
    setCorrectionsToDisplay,
    resetChangesHistory,
    setCorrectionPopup,
    setPreloaderStatus,
    loadFilters,
    setFiltersLoadingStatus,
    resetBudgetRelatedData,
    initPreviouslyLoadedFilter,
    setPreviouslyLoadedFilter,
    setPreviouslyLoadedFilters,
    resetPreviouslyLoadedFilters,
    setAppliedFiltersNames,
    setHoveredLineId,
    setShowTagsHaveChangedMarker,
    setMultiReferenceDictionaryApi,
    setTableLinesCellsParams,
    setPageBudgetItems,
    setFiltersState,
    setPageFilters,
    setLineIdsWithActualChanges,
    resetLoadedFilters,
    setTableLines,
    setDropdownOptions,
    setPreventUserConfigUpdate,
    changeCellValueAsync,
    loadFiltersByColumnNameAsync,
    onLineClickAsync,
    downloadTableAsXLSXAsync,
    updateDropdownOptionsAsync,
};
