import { head, compact, isEmpty } from 'lodash';

import { TypeFileValue } from 'sber-marketing-types/backend';
import { FieldValueBundle, FieldValueVersion } from '../../../../types';
import { Converter } from './types';

import { handleFiles } from './utils';

export const buildBundleValue: Converter<FieldValueVersion<FieldValueBundle>, TypeFileValue> = async ({
    value,
    autopilotId,
    fieldId,
}) => {
    if (isEmpty(value.current) && isEmpty(value.previous)) return null;

    const result = head(await handleFiles(compact([value.current]), compact([value.previous]), autopilotId, fieldId));
    return !isEmpty(result) ? result : null;
};
