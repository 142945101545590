import { DictionaryType } from '@sbermarketing/mrm-metacom-client';
import { DictionaryStatus, DictionaryForBudgetQuery } from '@mrm/dictionary';
import { DictionariesByIdsQuery } from 'sber-marketing-types/budget';

import { rtkApi } from './rtkApi';

export interface DictionaryData {
    id: string;
    type: DictionaryType;
    code: string;
    value: string;
    order?: number;
    organizationId: string;
    status: DictionaryStatus;
    shortValue: null;
    createdAt: string;
    updatedAt: string;
    serialNumber: number;
}

export interface GetDictionaryPathParams extends DictionariesByIdsQuery {
    organizationId?: string;
    types?: DictionaryType[];
}

export const dictionaryApi = rtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getDictionary: builder.query<DictionaryData[], GetDictionaryPathParams>({
            query: (params) => ({
                url: '/dictionary',
                params,
            }),
        }),
        getDictionariesForBudget: builder.query<DictionaryData[], DictionaryForBudgetQuery>({
            query: (params) => ({
                url: `/dictionary/budget`,
                params,
            }),
        }),
        getDictionaryItem: builder.query<DictionaryData, string>({
            query: (id) => ({
                url: '/dictionary/list',
                method: 'post',
                body: {
                    ids: [id],
                },
            }),
            transformResponse: ([data]) => data,
        }),
    }),
});

export const { useGetDictionaryQuery, useGetDictionaryItemQuery, useGetDictionariesForBudgetQuery } = dictionaryApi;
