import { axios } from '../../lib/axios';
import {
    Autopilot,
    GetAutopilotListParams,
    CreateAutopilotParams,
    UpdateAutopilotParams,
    UpdateAutopilotBriefParams,
    UpdateAutopilotBudgetParams,
    UpdateAutopilotCreativeParams,
    AutopilotPlacementPlanData,
    UpdateAutopilotPlacementParams,
    UpdateAutopilotDashboardParams,
} from 'sber-marketing-types/backend';
import { CheckPermissionService } from './CheckPermissionService';
import { User } from '@store/user';

const BASE_URL = '/api/autopilot/v1';

export class AutopilotLegacyApi {
    public static async getAutopilot(autopilotId: string, activityId: number): Promise<Autopilot> {
        await CheckPermissionService.check(activityId);

        const res = await axios.get(`${BASE_URL}/${autopilotId}`);

        return res.data;
    }

    public static async getMediaplan(autopilotId: string, activityId: number): Promise<AutopilotPlacementPlanData[]> {
        await CheckPermissionService.check(activityId);

        const res = await axios.get(`${BASE_URL}/${autopilotId}/mediaplan`);

        return res.data;
    }

    public static async getList(params?: GetAutopilotListParams): Promise<Autopilot[]> {
        if (params !== undefined && params.activityIds !== undefined) {
            await Promise.all(params.activityIds.map((activityId) => CheckPermissionService.check(activityId)));
        }

        const res = await axios.post(`${BASE_URL}/list`, params);

        return res.data;
    }

    public static async createAutopilot(user: User, params: CreateAutopilotParams): Promise<void> {
        const roles = ['Autopilot Client', 'Autopilot Client Service'];
        await CheckPermissionService.check(params.activityId, { roles, user });

        const res = await axios.post(BASE_URL, params);

        return res.data;
    }

    public static async updateAutopilot(
        autopilotId: string,
        activityId: number,
        user: User,
        params: UpdateAutopilotParams,
    ): Promise<void> {
        const roles = ['Autopilot Client', 'Autopilot Client Service'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.put<void>(`${BASE_URL}/${autopilotId}`, params);
    }

    public static async updateBrief(
        autopilotId: string,
        activityId: number,
        user: User,
        params: UpdateAutopilotBriefParams,
    ): Promise<void> {
        const roles = ['Autopilot Client', 'Autopilot Client Service'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.put<void>(`${BASE_URL}/${autopilotId}/brief`, params);
    }

    public static async updateMediaplan(autopilotId: string, activityId: number, user: User): Promise<void> {
        const roles = ['Autopilot Client', 'Autopilot Client Service'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.put<void>(`${BASE_URL}/${autopilotId}/mediaplan`, {});
    }

    public static async updateBudget(
        autopilotId: string,
        activityId: number,
        user: User,
        params: UpdateAutopilotBudgetParams,
    ): Promise<void> {
        const roles = ['Autopilot Client'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.put<void>(`${BASE_URL}/${autopilotId}/budget`, params);
    }

    public static async updateCreative(
        autopilotId: string,
        activityId: number,
        user: User,
        params: UpdateAutopilotCreativeParams,
    ): Promise<void> {
        const roles = ['Autopilot Client', 'Autopilot Client Service'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.put<void>(`${BASE_URL}/${autopilotId}/creative`, params);
    }

    public static async updatePlacement(
        autopilotId: string,
        activityId: number,
        user: User,
        params: UpdateAutopilotPlacementParams,
    ): Promise<void> {
        const roles = ['Autopilot Client Service'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.put<void>(`${BASE_URL}/${autopilotId}/placement`, params);
    }

    public static async updateDashboard(
        autopilotId: string,
        activityId: number,
        user: User,
        params: UpdateAutopilotDashboardParams,
    ): Promise<void> {
        const roles = ['Autopilot Client Service'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.put<void>(`${BASE_URL}/${autopilotId}/dashboard`, params);
    }

    public static async launchPlacements(autopilotId: string, activityId: number, user: User): Promise<void> {
        const roles = ['Autopilot Client', 'Autopilot Client Service'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.post<void>(`${BASE_URL}/${autopilotId}/launch`);
    }

    public static async stopPlacements(autopilotId: string, activityId: number, user: User): Promise<void> {
        const roles = ['Autopilot Client', 'Autopilot Client Service'];
        await CheckPermissionService.check(activityId, { roles, user });

        await axios.post<void>(`${BASE_URL}/${autopilotId}/stop`);
    }
}
