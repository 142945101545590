import { Moment } from 'moment';
import { actionCreatorFactory } from 'typescript-fsa';
import { OneTaskResponseParams } from 'sber-marketing-types/backend';

import { LoadingStatus } from '@store/commonTypes';

import {
    TaskEditorCommon,
    TaskEditorBudgetApprovalValues,
    ParticipantParams,
    FileParams,
    SetFileStatusPayload,
    Permissions,
} from '../types';

const actionCreator = actionCreatorFactory('TASK_EDITOR_SYNC');

export const resetState = actionCreator('RESET_STATE');

export const setTaskEditorCommon = actionCreator<Partial<TaskEditorCommon>>('SET_TASK_EDITOR_COMMON');
export const setTaskRequestHasFinished = actionCreator<boolean>('SET_TASK_REQUEST_HAS_FINISHED');
export const setTaskRequestInProgress = actionCreator<boolean>('SET_TASK_REQUEST_IN_PROGRESS');
export const setTaskPermissions = actionCreator<Permissions>('SET_TASK_PERMISSIONS');
export const setRawTask = actionCreator<OneTaskResponseParams>('SET_RAW_TASK');

export const setWorkTypeUserLoadingStatus = actionCreator<LoadingStatus>('SET_WORK_TYPE_USER_LOADING_STATUS');
export const setActivityStagesLoadingStatus = actionCreator<LoadingStatus>('SET_ACTIVITY_STAGES_LOADING_STATUS');

export const setTitle = actionCreator<string>('SET_TITLE');
export const setDescription = actionCreator<string>('SET_DESCRIPTION');
export const setWorkType = actionCreator<string>('SET_WORK_TYPE');
export const setBudgetApproval = actionCreator<TaskEditorBudgetApprovalValues>('SET_BUDGET_APPROVAL');
export const setBudgetDivision = actionCreator<string>('SET_BUDGET_DIVISION');
export const setBudgetCustomer = actionCreator<string>('SET_BUDGET_CUSTOMER');
export const setBudgetItemIds = actionCreator<string[]>('SET_BUDGET_ITEM_IDS');
export const setBudgetProduct = actionCreator<string>('SET_BUDGET_PRODUCT');
export const setBudgetSegment = actionCreator<string>('SET_BUDGET_SEGMENT');
export const setBudgetPeriod = actionCreator<string>('SET_BUDGET_PERIOD');
export const setBudgetIsMedia = actionCreator<boolean>('SET_BUDGET_IS_MEDIA');
export const setBudgetMediaRequest = actionCreator<string>('SET_BUDGET_MEDIA_REQUEST');
export const setBudgetIsNaming = actionCreator<boolean>('SET_BUDGET_IS_NAMING');
export const setBudgetNaming = actionCreator<string>('SET_BUDGET_NAMING');
export const setBudgetProject = actionCreator<string>('SET_BUDGET_PROJECT');
export const setExecutor = actionCreator<number>('SET_EXECUTOR');
export const setDeadline = actionCreator<Moment>('SET_DEADLINE');
export const setParticipants = actionCreator<ParticipantParams[]>('SET_PARTICIPANTS');
export const setStageId = actionCreator<string>('SET_STAGE_ID');
export const setFiles = actionCreator<FileParams[]>('SET_FILES');
export const setFileStatus = actionCreator<SetFileStatusPayload>('SET_FILE_STATUS');
export const setUploadingFiles = actionCreator<string[]>('SET_UPLOADING_FILES');
export const removeUploadingFile = actionCreator<string>('REMOVE_UPLOADING_FILE');
