import { actionCreatorFactory } from 'typescript-fsa';
import {
    TasksListType,
    TaskCardType,
    TaskCardParams,
    TaskCardParamsResponse,
    UpdateSavedTaskCardParams,
    WorkTypesState,
    TaskCountResponse,
} from './types';

import { LoadingStatus } from '@store/commonTypes';

const creator = actionCreatorFactory('MY_TASKS');

// Async actions

export const fetchMoreTasks = creator.async<TasksListType, boolean, Error>('FETCH_MORE_TASKS');
export const deleteTasks = creator.async<string | string[], void, Error>('DELETE_TASKS');
export const updateTaskCardById = creator.async<string, TaskCardParamsResponse, Error>('UPDATE_TASK_CARD');
export const loadWorkTypes = creator.async<null, WorkTypesState, Error>('LOAD_WORK_TYPES');

// Sync actions

export const setCanBeLoadedMore = creator<boolean>('SET_CAN_BE_LOADED_MORE');
export const setType = creator<TasksListType>('SET_TYPE');
export const setCardType = creator<TaskCardType>('SET_CARD_TYPE');
export const setActivityId = creator<number | null>('SET_ACTIVITY_ID');
export const resetMyTasks = creator('RESET_MY_TASKS');
export const resetTasksList = creator('CLEAR_TASKS_LIST');
export const pushTasks = creator<TaskCardParams | TaskCardParams[]>('PUSH_TASKS');
export const setTasksTotalCount = creator<number | null>('SET_TASKSTOTAL_COUNT');
export const removeTasks = creator<string | string[]>('REMOVE_TASKS');
export const updateSavedTaskCard = creator<UpdateSavedTaskCardParams>('UPDATE_SAVED_TASK_CARD');
export const setWorkTypesLoadingStatus = creator<LoadingStatus>('SET_WORK_TYPES_LOADING_STATUS');
export const setListLoadingStatus = creator<LoadingStatus>('SET_LIST_LOADING_STATUS');

export const setLastRequestDate = creator<string>('SET_LAST_REQUEST_DATE');
export const resetLastRequestDate = creator('RESET_LAST_REQUEST_DATE');
