import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { OnboardingId, setCurrentOnboarding } from '@store/onboarding';

export function useSetOnboarding() {
    const dispatch = useDispatch();

    return useCallback((current: OnboardingId) => dispatch(setCurrentOnboarding(current)), [dispatch]);
}
